import React, { FC, useState } from 'react'
import './TestMessage.scss'
import Swal from 'sweetalert2'
import smsService from 'Services/smsService'
import { useForm } from 'react-hook-form'

interface propsType {
    messageContent: string | undefined  ,  
    closeModal: ()=>void
}
type formType = {
    phoneNumbers: string,
    message: string
}

const TestMessage:FC<propsType> = ({messageContent, closeModal}) => {

    const [request, setRequest] = useState(false)
    const { register, handleSubmit, reset, formState: {errors} } = useForm<formType>();

    const submit = handleSubmit(async (data:formType)=>{
        setRequest(true) 
        await smsService.send({...data, phoneNumbers: data.phoneNumbers.split(', ')})
        .then((response)=>{ 
            setRequest(false)  
            reset();
            Swal.fire({
                title: 'Completed!',
                text: 'Your test sms has been sent succesfully',
                icon: 'success',
                confirmButtonText: 'Ok',
              });

              setTimeout(()=>{
                closeModal()
              }, 2000)
  
        }, error => { 
            setRequest(false)  
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: { 
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
                });

                swalWithBootstrapButtons.fire({
                title: 'Oops!',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: true, 
                showConfirmButton: false, 
                cancelButtonText: 'close',
                reverseButtons: true
                });
        })
    })

    
    return (
        <div className='TestMessage'>
            <form onSubmit={submit}>

                <div className="form-group">
                    <input type="text" id="" className='form-control' {...register('phoneNumbers',  {required: 'field is required'})} placeholder='Phone Numbers'/>
                    {errors.phoneNumbers && <span className='text-danger'>{errors.phoneNumbers.message}</span>}
                </div>

                <div className="form-group mt-3">
                    <textarea className="form-control" {...register('message', {required: 'field is required'})} id="" cols={30} rows={10} value={messageContent} placeholder='Message'></textarea>
                    {errors.message && <span className='text-danger'>{errors.message.message}</span>}
                </div>


                <div className="form-group mt-3 text-center">
                    <button className="btn btn-default text-dark">
                        {request && <span className='spinner-border spinner-border-sm'></span>}
                        Send Test SMS
                    </button> 
                </div>
            </form>
        </div>
    )
}

export default TestMessage
