import React, { Key, useEffect, useState } from 'react'
import './AsignTrucks.scss'
import { Logger } from 'Helpers/Logger'
import logisticsService from 'Services/logisticsService'
import { truckTypes } from 'Models'
import { Link, useNavigate } from 'react-router-dom'
import { HorizontalLoader } from 'Components';

const AsignTrucks = () => {
    const pageNumber = 1; 
    const sortOrder = 'DESC';
    const status  =  'ALL';


    const navigate = useNavigate()
    const [trucks, setTrucks] = useState<Array<truckTypes>>([]); 
    const [loading, setLoading] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [paginationData, setPaginationData] = useState<{
        pageNumber: number,
        pageSize: number,
        total:number
      }>({
        pageNumber: 1,
        pageSize: 20,
        total: 1
      })

    const getLogistics = async () => {
        setLoading(true)
        await logisticsService.index(pageNumber, sortOrder, status)
          .then((response)=>{    
            setLoading(false)
            setTrucks(response?.data.data.dataset)
            console.log('response getLogistics', response)

            setPaginationData({
                pageNumber: response?.data.data.pageNumber,
                pageSize: response?.data.data.pageSize,
                total: response?.data.data.total
              })

          }, (error: { response: object }) =>{     
            setLoading(false)
            Logger('response error', error.response) 
          })
    }

    useEffect(() => {
     getLogistics()
    }, [])
    
    return (
        <div className='asignTrucks'> 
            <div className="card">
                <div className="card-header bg-white border-0">
                    Delivery Trucks
                </div>
                <div className="card-body">
                    
                <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr> 
                                    <td>Truck No.</td>
                                    <td>State</td>  
                                    <td>Contact</td> 
                                    <td>Volume</td>  
                                </tr>
                            </thead>
                            <tbody>

                            {loading===true && trucks.length === 0 && <tr>
                                <td colSpan={7} className='text-center'><HorizontalLoader></HorizontalLoader></td>
                            </tr>}
                              {trucks.length > 0 && trucks.map((data: truckTypes, index:Key)=>
                                <tr key={index} style={{cursor: 'pointer'}} onClick={() => navigate(`/logistic/${data.id}`, {replace:true})}>                                    
                                    <td>{data.truckPlateNumber}</td>     
                                    <td>{data.sourceState}</td>    
                                    <td>{data.contactPhoneNumber}</td>  
                                    <td>{data.capacityInLitres}Litr</td>
                                    
                                </tr>)} 

                                {loading === false && trucks.length === 0 &&<tr>
                                    <td colSpan={6} className='text-center'>No truck found</td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-footer py-1 text-end bg-white">
                    <Link to="/logistics" className='view-all'>View All</Link>
                </div>
             </div>
        </div>
    )
}

export default AsignTrucks
