import React, { FC } from 'react'
import './BreadCrumb.scss'
import { Link, useLocation, useNavigate } from 'react-router-dom';

interface propsType {
    title:string;
    parent:string|null;
    parentUrl:string|null;
    showTitle?:boolean|undefined;
}

const BreadCrumb:FC<propsType> = ({ title, parent, parentUrl, showTitle }) => {

  const navigate = useNavigate(); 
  const location = useLocation();

  const goBack = () => {
      const previousPath = location.state?.from || -1;
      navigate(previousPath);
  };

    return (
        <div className='pageBreadcrumb'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"> 
                        <button className="btn p-0" onClick={() => goBack()} aria-label="Go back">
                            <i className='d-icon d-arrowhead-left is-medium'></i>
                        </button>
                    
                        {/* <Link to={'#'} onClick={() =>  goBack()}><i className='d-icon d-arrowhead-left is-medium'></i></Link> */}
                    </li> 
                    {parent && <li className="breadcrumb-item  breadcrumb-element"><Link to={`/${parentUrl}`}>{parent}</Link></li>}
                    <li className="breadcrumb-item  breadcrumb-element active" aria-current="page">{title}</li>
                </ol>
            </nav>
            {showTitle !==false && <h2 className='ps-0 ms-0'>{title}</h2>}
        </div>
    )
  }

export default BreadCrumb
