import React from 'react'
import './Dashboard.scss'
import { AsignTrucks, RecentOrders, RecentTransactions, SalesOverview, Statistics } from '../../Components'

 const Dashboard = () => {
    return (
        <div className='dashboard mb-4'>
            <div className='container'>
                <Statistics></Statistics>
                 
                <div className="container">
                    <div className="row"> 
                        <div className="col-md-8">
                            <SalesOverview></SalesOverview>
                        </div>
                        <div className="col-md-4">
                            <RecentTransactions></RecentTransactions>
                        </div>
                        <div className="col-md-8">
                            <RecentOrders></RecentOrders>
                        </div>
                        <div className="col-md-4">
                            <AsignTrucks></AsignTrucks>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
} 

export default Dashboard

