import React, { useEffect, useState } from 'react'
import './SalesOverview.scss'
import statsService from 'Services/statsService'
import { HorizontalLoader } from 'Components';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    Title,
    Tooltip,
    Legend,
    PointElement
  } from 'chart.js'; 
  import { Line } from 'react-chartjs-2';

const SalesOverview = () => {

    const [year, setYear] = useState(new Date().getFullYear());
    const [country, setCountry] = useState('Nigeria');
    const [loading, setLoading] = useState(false);
    const years = ['2021', '2022', '2023', '2024'];
 
    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    
    const [datas, setDatas] = useState<{labels: Array<string>, datasets: Array<any>}>({
        labels,
        datasets: [],
    })
    
    ChartJS.register(
        CategoryScale,
        LinearScale,
        LineElement,
        PointElement,
        Title,
        Tooltip,
        Legend,
    );

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
                display: false, 
            
            },
        
            title: {
                display: false,
                text: 'Chart.js Bar Chart',
            },
            label: {
                display: false,
            },
        },

        scales: {
            y: {
                min: 0,
                max: 10,
                display: true,
                ticks: {
                    font: {
                        size: 8,
                    }
                },
                grid: {
                display: false
                }
            },
            x: { 
                grid: {
                display: false,  
                },
                ticks: {
                    font: {
                        size: 8,
                    }
                }
            }, 
        } 
    };


  useEffect(()=>{
    console.log(datas)
  }, [datas])
  
    const getOverView = async () => {
        setLoading(true)
        await statsService.getStats(year, country).then((response)=>{
            setLoading(false)
         console.log('stats response', response.data.data.data)

        const newData = response.data.data.data;

         setDatas({
            labels,
            datasets: [
              {
                label: '',
                data: labels.map(month => {
                    const matchingData = newData.find((item: { month: string; }) => item.month === month);
                    return matchingData ? matchingData.totalOrders : 0;
                }),
                // data: [2, 5, 6, 9, 6, 2, 7, 3, 8, 5, 7, 9],
                backgroundColor: '#f54091',
                borderRadius: 4,
                barPercentage: 0.5,
                border: 0,
                font: {
                  size: 1
                }
    
              }
            ],
          })
        }, error => {
            setLoading(false)
            console.log('response', error.response)
       
        })
    }

    useEffect(() => {
        getOverView()
    }, [year, country])

    return (
        <div className='SalesOverview'>
             <div className="card">
                <div className="card-header bg-white border-0">
                    <div className="row">
                        <div className="col-8">Sales Overview </div>
                        <div className="col-2">
                            <select className='form-control' onChange={(e:React.ChangeEvent<HTMLSelectElement>)=>{setYear(+e.target.value)}}>
                                <option>Year</option>
                                {years.map((res, index)=><option key={index}>{res}</option>)}
                            </select>
                        </div>
                        <div className="col-2">
                            <select className='form-control' onChange={(e:React.ChangeEvent<HTMLSelectElement>)=>{setCountry(e.target.value)}}>
                                <option>Country</option>
                                <option>Nigeria</option>
                                <option>Kenya</option>
                            </select>
                        </div>
                    </div> 
                </div>
                <div className={loading ? 'card-body h-100 mx-auto' : 'card-body'}>
                      {loading && <HorizontalLoader></HorizontalLoader>}

                      {!loading && <Line options={options} data={datas} />}
                </div>
             </div>
        </div>
    )
}

export default SalesOverview
