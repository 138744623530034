import React, { FC } from 'react'
import './AdminHeader.scss'
import NavMenu from '../NavMenu/NavMenu'

interface propsType {
  publicAccess?: boolean
}
const AdminHeader:FC<propsType> = ({publicAccess}) => {
  return (
        <div className='admin-header'> 
          <NavMenu publicAccess={publicAccess}></NavMenu> 
        </div>
  )
}

export default AdminHeader
