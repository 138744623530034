import smsService from 'Services/smsService'
import { BreadCrumb, HorizontalLoader } from 'Components'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom' 
import { TimeConverter } from 'Helpers/TimeConverter';
import './BulkSmsHistory.scss'

interface historyType { 
        id: number,
        date: string,
        message: string,
        noOfRecipients: number,
        info: string,
        recipientsInfo: Array<
          {
            cost: string,
            number: string,
            status: string,
            messageId: string,
            statusCode: number
          }
        >
}
const BulkSmsHistoryDetails = () => {
    const { smsId } = useParams<{smsId:string}>();
    const [request, setRequest] = useState(false)
    const [data, setData] = useState<historyType>()


    const getSmsHistory = async () => {
        setRequest(true)
      await smsService.getHistoryDetail(smsId).then((response)=>{
        setRequest(false)
        console.log(response.data.data)
        setData(response.data.data)
      }, (error) => {
         console.log('sms history error ', error.response.data)
      })
    }

    useEffect(()=>{
        getSmsHistory()
    }, [])



   const getSentStats = (inputString2:string) =>{ 
    const regex = /\b1\/1\b/;
    const match = inputString2.match(regex);
    
    if (match) {
      const extractedPattern = match[0];
      return extractedPattern 
    } else {
        return '0/0' 
    }  
   }

   const getSmsCost = (inputString:string)=>{ 
        // Use a regular expression to extract the country code and number(PRICE)
        const regex = /([A-Z]+) (\d+(\.\d+)?)/;
        const match = inputString.match(regex);

        if (match) {
            const countryCode = match[1];
            const phoneNumber = match[2];  
            return `${countryCode} ${phoneNumber}`
        } else {
            return ''
        }
   
   }

   const deliveryRate = (inputString:string) => { 

        // Split the string into two components
        const components = inputString.split('/');

        if (components.length === 2) {
            // Extract the numerator and denominator
            const numerator = parseInt(components[0]);
            const denominator = parseInt(components[1]);

            // Calculate the percentage
            const percentage = (numerator / denominator) * 100;
 
            return `${percentage}%`
        } else {
            return '0%'
        }
   }

    return (
        <div className='BulkSmsHistory'>
            
            <div className="container">
                <BreadCrumb title={'SMS Details'} parent={'SMS'} parentUrl={'bulksms'} showTitle={false}></BreadCrumb>
                <div className="container mt-2">
                    <div className='card border-0'>
                        <div className='card-body'>
                            {request && <HorizontalLoader></HorizontalLoader>}
                              <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="company-item">
                                            <div className="name">
                                               Message
                                            </div>
                                            <div className="value">
                                                {data?.message}
                                            </div>
                                        </div>

                                        <div className="company-item mt-4">
                                            <div className="name">
                                                Number of recipient
                                            </div>
                                            <div className="value">
                                                {data?.noOfRecipients}
                                            </div>
                                        </div>

                                        <div className="company-item mt-4">
                                            <div className="name">
                                                Information
                                            </div>
                                            <div className="value">
                                                {data && data.info}
                                            </div>
                                        </div>
                                        <div className="company-item mt-4">
                                            <div className="name">
                                                Delivery Rate
                                            </div>
                                            <div className="value">
                                            {data && deliveryRate(getSentStats(data.info.toString()))}
                                            </div>
                                        </div>
                                        <div className="company-item mt-4">
                                            <div className="name">
                                               SMS Cost
                                            </div>
                                            <div className="value">
                                            {data && getSmsCost(data.info.toString())}
                                            </div>
                                        </div>
                                        
                                        <div className="company-item mt-4">
                                            <div className="name">
                                            Date
                                            </div>
                                            <div className="value">
                                                {data && TimeConverter(data.date)}
                                            </div>
                                        </div>

                                      
                                    </div>
                                </div>
                              </div>

                                   <div className="company-item mt-5\4">
                                            <div className="name">
                                                Recipients
                                            </div>
                                            <div className="value">
                                                 <div className='card mt-4 bg-light border-0'>
                                                    <div className='card-body'>
                                                        <div className='table-responsive'>
                                                            <table className='table'>
                                                                <thead>
                                                                    <tr>
                                                                        <td>S/N</td>
                                                                        <td>Number</td>
                                                                        <td>SMS Cost</td>
                                                                        <td>Status</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {data?.recipientsInfo && data.recipientsInfo.map((res, index) => {
                                                                    return <tr key={index} className={res.status !=='Success'? 'border-danger':''}>
                                                                            <td>{Number(index)+1}</td>
                                                                            <td>{res.number}</td>
                                                                            <td>{res.cost}</td>
                                                                            <td>{res.status}</td>
                                                                        </tr>})}
                                                                </tbody>
                                                            </table>
                                                            {/* {data?.recipientsInfo && data.recipientsInfo.map((res, index, array) => (
                                                                <React.Fragment key={index}>
                                                                    {res.number}
                                                                    {index < array.length - 1 ? ',' : '.'}
                                                                </React.Fragment>
                                                            ))} */}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                        </div>
                    </div>
                </div> 
            </div> 
        </div>
    )
}

export default BulkSmsHistoryDetails
