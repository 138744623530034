import React, { Key, useEffect, useState } from 'react';
import './LoanProviders.scss';
import { Logger } from 'Helpers/Logger';
import { HorizontalLoader } from 'Components';
import loanService from 'Services/loanServices';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from 'Components/AuthProvider/AuthProvider';
import VFDImage from '../../../../Assets/Images/VFD.png';
import MONIEPOINTImage from '../../../../Assets/Images/MONIEPOINT.png';
import PARALLAXImage from '../../../../Assets/Images/PARALLAX.png';
import ZEDVANCE from '../../../../Assets/Images/zedvance.png';

export const getImageForProvider = (provider: string) => {
  switch (provider) {
    case 'VFD':
      return VFDImage;
    case 'MONIEPOINT':
      return MONIEPOINTImage;
    case 'PARALLEX':
      return PARALLAXImage;
    case 'ZEDVANCE':
      return ZEDVANCE;
    default:
      return null;
  }
};

const LoanProviders = () => {
  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();

  const getLoanProviders = async () => {
    setLoading(true)
    await loanService.loanProvider()
      .then((response)=>{    
        setLoading(false)
        if(user.loanProviderAdminRole === true){
         const newData = response?.data.data.filter((res:string)=> res === user.user.loanProvider)
         setProviders(newData) 
        }else{ 
         setProviders(response?.data.data) 
        }

      }, (error: { response: object }) =>{     
        setLoading(false)
        Logger('response error', error.response) 
      })
}

  useEffect(() => {
    getLoanProviders();
  }, []);



  return (
    <div className="Logistics">
      <div className="container mt-4">
        <div className="card border-0">
          <div className="card-header border-0">
            <div className="row">
              <div className="col-12 col-md-8 my-auto">
                <div className="title">Loan Providers</div>
              </div>
            </div>
          </div>
        </div>
        { user.loanProviderAdminRole && <div className="container"> 
              {providers.length > 0 &&
                providers.map((lp, index) => (
                  <div key={index} className="card border-0 shadow-sm mb-3 lp-list">
                    <div className="card-header"></div>
                    <div className="card-body">
                      <img src={getImageForProvider(lp)} alt={''} title={''} />
                    </div>
                    <div
                      className="card-footer text-center"
                      onClick={() => ['VFD', 'ZEDVANCE'].includes(lp) === true && navigate(`/loan-providers/${lp}`)}
                      style={{ cursor: ['VFD', 'ZEDVANCE'].includes(lp) === true ? 'pointer' : 'not-allowed' }}
                    >
                      Proceed
                    </div>
                  </div>
                ))}
              {!loading && providers.length === 0 && <div className="text-center">No provider found</div>}
              {loading && providers.length === 0 && (
                <div className="text-center">
                  <HorizontalLoader></HorizontalLoader>
                </div>
              )}
            </div> }
 

            { !user.loanProviderAdminRole && <div className="container"> 
               <div className="card border-0"> 
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr> 
                                    <td width={'30%'}>No.</td>
                                    <td width={'60%'}>Provider</td>  
                                    <td width={'10%'} className='d-none'></td>
                                </tr>
                            </thead>
                            <tbody> 
                              {providers.length > 0 && providers.map((data: string, index:Key)=>{
                            
                               return <tr key={index}  onClick={() => ['VFD', 'B54', 'ZEDVANCE'].includes(data) && navigate(`/loan-providers/${data}`, { state: { from: location.pathname }})} style={{ cursor: ['VFD', 'B54', 'ZEDVANCE'].includes(data) ? 'pointer' : 'not-allowed' }}> 
                                    <td>{Number(index)+1}</td>     
                                    <td>{data}</td>     
                                    <td className='dropdown option-dropdown d-none'>
                                        <button className='btn border-0 dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false" data-bs-popper-config='{"strategy":"fixed"}'>
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.49991 8.4705C7.49991 7.72933 8.0955 7.14697 8.82344 7.14697C9.55138 7.14697 10.147 7.74256 10.147 8.4705C10.147 9.19844 9.55138 9.79403 8.82344 9.79403C8.0955 9.79403 7.49991 9.21168 7.49991 8.4705Z" fill="#707070"/>
                                                <path d="M7.49991 14.1175C7.49991 13.3763 8.0955 12.7939 8.82344 12.7939C9.55138 12.7939 10.147 13.3895 10.147 14.1175C10.147 14.8454 9.55138 15.441 8.82344 15.441C8.0955 15.441 7.49991 14.8587 7.49991 14.1175Z" fill="#707070"/>
                                                <path d="M7.49991 2.82353C7.49991 2.08235 8.0955 1.5 8.82344 1.5C9.55138 1.5 10.147 2.09559 10.147 2.82353C10.147 3.55147 9.55138 4.14706 8.82344 4.14706C8.0955 4.14706 7.49991 3.56471 7.49991 2.82353Z" fill="#707070"/>
                                            </svg> 
                                        </button>

                                        <ul className="dropdown-menu dropdown-menu-end export-dropdown border-0 shadow">
                                             <li className='dropdown-item'><Link to={`/loan-providers/${data}`}>View Providers Details</Link></li> 
                                        </ul> 
                                    </td>   
                                </tr>})} 

                                {loading === false && providers.length === 0 &&<tr>
                                    <td colSpan={7} className='text-center'>No provider found</td>
                                </tr>}
                                {loading===true && providers.length === 0 && <tr>
                                    <td colSpan={7} className='text-center'><HorizontalLoader></HorizontalLoader></td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                 </div>
               </div>  
          </div> }
      </div>
    </div>
  );
};

export default LoanProviders;
