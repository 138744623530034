import React, { useEffect, useState } from 'react'
import './TransactionDetails.scss'
import { Link, useParams } from 'react-router-dom'
import transactionService from 'Services/transactionService'
import { TransactionType } from 'Models/trasactionType'
import { Logger } from 'Helpers/Logger' 
 
const TransactionDetails = () => {
    const { uuid } = useParams<{uuid: string}>()
    const [transaction, setTransaction] = useState<TransactionType>()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loading, setLoading] = useState(false)

    const getTransaction = async () => {
        setLoading(true) 
        await transactionService.single(1, uuid)
          .then((response)=>{    
            setLoading(false)
            setTransaction(response?.data.data.dataset[0])
            console.log('response transactionService', response) 
          }, (error: { response: object }) =>{     
            setLoading(false) 
            Logger('response error', error.response) 
          })
    }

    useEffect(() => {
        getTransaction()
    }, [uuid])

    return (
        <div className='TransactionDetails'>
            <div className="container">
                <Link to="/transactions">
                    <button className='btn btn-default  mt-2'><span className="d-icon d-arrowhead-left mt-1"></span>Back</button>
                    </Link>
                <div className="card mt-3 mb-5 border-0">
                    <div className="card-body">   
                             <div className="row mt-4"> 
                                <div className="col-md-5 text-start"> 
                                    <div className='profile-item'>
                                        <div className='name'>Customer Name</div>
                                        <div className='value'>{transaction?.customer.firstName} {transaction?.customer.lastName}</div>
                                    </div>  
                                    <div className='profile-item'>
                                        <div className='name'>Customer Phone:</div>
                                        <div className='value'>{transaction?.customer.msisdn}</div>
                                    </div>  
                                </div> 
                           </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TransactionDetails
