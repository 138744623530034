import React, { Key, useEffect, useState } from 'react'
import './AnnouncementForm.scss' 
import marketControlService from 'Services/marketControlService';
import Swal from 'sweetalert2'; 
import { RiDeleteBin5Line } from 'react-icons/ri';
import { announcementType } from 'Models/announcementType';
import ImageSrc from '../../../../../Components/ImageSrc/ImageSrc';
import { HorizontalLoader, ModalWrapper } from 'Components';
import AnnouncementForm from './AnnouncementForm';
 
 
const AnnouncementDetails = () => {
    const [request, setRequest] = useState(false)
    const [announcement, setAnnouncement] = useState<announcementType[]>()
 

    const getAnnouncement = async () =>{ 
        await marketControlService.getAnnouncement().then((response)=>{
           console.log('getAnnouncement', response)
           setAnnouncement(response.data.data)
        }, error => { 
             console.log('getAnnouncement error', error)
             setRequest(false)  
         })
     }
 
     useEffect(()=>{
        getAnnouncement()
     }, [])

 
    const deleteAnnouncement = async (annId:number) => {
        Swal.showLoading();
        await marketControlService.deleteAnnouncement(annId) 
        .then((response)=>{   
            Swal.fire({ 
                text: 'deleted succesfully',
                icon: 'success',
                confirmButtonText: 'Ok',
            }); 
            getAnnouncement()
            Swal.close();
        }, (error: { response: { data: { error: string; }; }; }) => { 
            console.log(error)  
            Swal.close();
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: { 
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
            });

            swalWithBootstrapButtons.fire({
                title: 'Oops!',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: true, 
                showConfirmButton: false, 
                cancelButtonText: 'close',
                reverseButtons: true
            });
        })
    }

    const [showModal, setShowModal] = useState(false);
 

    const toggleModal = () => {
      setShowModal((prevState) => !prevState);
      getAnnouncement()
    };

    return (
        <div className='AnnouncementForm'> 
            <div className="card border-0">
                <div className="card-header bg-white text-dark">
                    <div className="row">
                        <div className="col-sm-6 my-auto text-start">
                            <div className="title">
                                Announcements
                            </div>
                        </div>
                        <div className="col-sm-6 text-sm-end">
                            <button className='btn btn-add-anouncement mt-2 mt-sm-0'  onClick={toggleModal}> 
                                + Create Announcement
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body market-price-list pb-5">
                    {request && <HorizontalLoader></HorizontalLoader>}
                    {announcement && announcement.map((announcement: announcementType, index:Key) => <div key={index} className="row mt-2 border-bottom border-light pb-3">
                        <div className="col-4 col-sm-2">
                            <a href={JSON.parse(announcement.image).url} target='_blank' rel="noreferrer">
                                <div className="card field-count ann-field-count border-0">
                                    <div className="card-body text-center py-2">
                                    <ImageSrc src={JSON.parse(announcement.image).url} alt={announcement.title} title={announcement.title} width={'w-100'}></ImageSrc>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-7 col-sm-9"> 
                            <div className="card border border-light">
                                <div className="card-body text-left py-2">
                                <div className='fw-bolder'>{announcement.title}</div>
                                <div className='fw-lighter'>{announcement.content}</div>
                                </div>
                            </div> 
                        </div>
                        <div className="col-1 col-sm-1 p-0 my-auto text-center">
                            <button className='btn p-0 border-0 text-danger' onClick={()=>deleteAnnouncement(announcement.id)}><RiDeleteBin5Line /></button> 
                        </div>
                    </div>)} 
                </div>
            </div>




            <ModalWrapper showModal={showModal} closeModal={toggleModal} title="Create New Announcement">
                <AnnouncementForm toggle={()=>toggleModal()}></AnnouncementForm>
            </ModalWrapper>
        </div>
    )
}

export default AnnouncementDetails
