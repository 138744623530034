import React, { Key, useEffect, useState } from 'react'
import './DeliveryUltra.scss' 
import { HorizontalLoader, ModalWrapper, Pagination } from '../../../../Components'  
import { TimeConverter } from '../../../../Helpers/TimeConverter'
import { Logger } from '../../../../Helpers/Logger'
import deliveryService from '../../../../Services/deliveryService'
import { AddDeliveryFee, EditDeliveryUltra } from '../../Components'
import { locationService } from '../../../../Services/locationService'
 
export type deliveryUltraType = {
    id: string,
    localGovernmentArea: string,
    deliveryFeeMajor: number,
    currency: string,
    createdAtISO8601: string
  }

  export type countryType = {
    name: string,
    iso2: string,
    phoneCode: string,
    currency: string,
    currencySymbol: string,
    states: [
      {
        name: string,
        localGovernmentAreas: [ string ]
      }
    ]
}

export type stateType = {  
        name: string,
        localGovernmentAreas: [ string ] 
}

const DeliveryUltra = () => {  
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    const toggleModal = () => {
        setShowModal((prevState) => !prevState);
      }; 

      const toggleEditModal = () => {
        setShowEditModal((prevState) => !prevState);
      }; 
      
    const [pageNumber, setPageNumber] = useState(1) 
    const [deliveries, setDeliveries] = useState<Array<deliveryUltraType>>([]);
    const [countries, setCountries] = useState<Array<countryType>>([]); 
    const [loading, setLoading] = useState(false); 
    const [country, setCountry] = useState<string>(); 
    const [state, setState] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [localGovernmentArea, setLocalGovernmentArea] = useState('');
    
    const [editData, setEditData] = useState<{
        id: string;
        localGovernmentArea: string;
        deliveryFeeMajor: number;
        currency: string;
        createdAtISO8601: string;
    }>({
        id: '',
        localGovernmentArea: '',
        deliveryFeeMajor: 0,
        currency: '',
        createdAtISO8601: ''
    });

    const [paginationData, setPaginationData] = useState<{
        pageNumber: number,
        pageSize: number,
        total:number
      }>({
        pageNumber: 1,
        pageSize: 20,
        total: 1
      })

    const getDeliveryUltra = async () => {
        setLoading(true)
        await deliveryService.index(pageNumber, country, state, localGovernmentArea)
          .then((response)=>{    
            setLoading(false)
            setDeliveries(response?.data.data.dataset)
            console.log('response', response)

            setPaginationData({
                pageNumber: response?.data.data.pageNumber,
                pageSize: response?.data.data.pageSize,
                total: response?.data.data.total
              })

          }, error =>{     
            setLoading(false)
            Logger('response error', error.response) 
          })
    }


    const getLocations = async () =>{
        await locationService.index().then((response)=>{
          console.log('response country', response)

          setCountries(response.data.data)
        }, (error) => {
            console.log('error country', error)
        })
    }

    useEffect(() => { 
        getLocations()
    }, [])

    useEffect(() => {
        getDeliveryUltra(); 
    }, [country, state, localGovernmentArea, pageNumber])
    
    
    const [states, setStates] = useState<Array<stateType>>([]); 

    const selectState = (countryName:{target: { value: string }})=>{
        setCountry(countryName.target.value)
        console.log(countryName.target.value)
         let country:countryType|undefined;
         if(countryName.target.value){ 
             country = countries.find((res)=>res.name === countryName.target.value)
             if(country)setStates(country.states)
         }
     }
 
     const selectLga = (stateName:{target: { value: string }})=>{ 
        setState(stateName.target.value) 
     }
    return (
    <div className='Users'>
        <div className="container mt-4">
            <div className="container">
               <div className="card border-0">
                <div className="card-header border-0 px-0">
                    <div className="row">
                        <div className="col-6 col-md-6 my-auto">
                          <div className="title">
                          {paginationData.total} total fees 
                           </div>
                        </div>
                        <div className="col-6 col-md-6 text-end">
                         <button className='btn btn-add-company'  onClick={toggleModal}>
                            <svg width="17" height="17" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.5 9.5625H4.5C4.1925 9.5625 3.9375 9.3075 3.9375 9C3.9375 8.6925 4.1925 8.4375 4.5 8.4375H13.5C13.8075 8.4375 14.0625 8.6925 14.0625 9C14.0625 9.3075 13.8075 9.5625 13.5 9.5625Z" fill="white"/>
                                <path d="M9 14.0625C8.6925 14.0625 8.4375 13.8075 8.4375 13.5V4.5C8.4375 4.1925 8.6925 3.9375 9 3.9375C9.3075 3.9375 9.5625 4.1925 9.5625 4.5V13.5C9.5625 13.8075 9.3075 14.0625 9 14.0625Z" fill="white"/>
                            </svg> 
                            Add Fee
                        </button>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-12 col-md-5">
                            <div  className='search-field'>
                                <span>
                                    Country:
                                </span>

                                <select className='form-control' onChange={selectState}>
                                    <option>--select country--</option>
                                    {countries.length > 0 && countries.map((country:countryType, index:Key)=><option key={index}>{country.name}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="col-12 col-md-5">
                            <div  className='search-field'>
                                <span>
                                    State:
                                </span>
                                <select  className='form-control' onChange={selectLga}>
                                    <option>--option--</option>
                                    {states.length > 0 && states.map((state:stateType, index:Key)=><option key={index}>{state.name}</option>)} 
                                </select> 
                            </div>
                        </div>
                        <div className="col-6 col-md-2"> 
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <td>ID</td>
                                    <td>LGA</td>
                                    <td>Delivery Fee</td>
                                    <td>Time Added</td> 
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                               {deliveries.length > 0 && deliveries.map((data: deliveryUltraType, index:Key)=><tr key={index}>
                                    <td>{Number(index) + 1}</td>
                                    <td>{data.localGovernmentArea}</td>  
                                    <td>{data.currency}{data.deliveryFeeMajor}</td> 
                                    <td> {TimeConverter(data.createdAtISO8601)} </td>
                                    <td className='dropdown option-dropdown'>
                                        <button className='btn border-0 dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false" data-bs-popper-config='{"strategy":"fixed"}'>
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.49991 8.4705C7.49991 7.72933 8.0955 7.14697 8.82344 7.14697C9.55138 7.14697 10.147 7.74256 10.147 8.4705C10.147 9.19844 9.55138 9.79403 8.82344 9.79403C8.0955 9.79403 7.49991 9.21168 7.49991 8.4705Z" fill="#707070"/>
                                                <path d="M7.49991 14.1175C7.49991 13.3763 8.0955 12.7939 8.82344 12.7939C9.55138 12.7939 10.147 13.3895 10.147 14.1175C10.147 14.8454 9.55138 15.441 8.82344 15.441C8.0955 15.441 7.49991 14.8587 7.49991 14.1175Z" fill="#707070"/>
                                                <path d="M7.49991 2.82353C7.49991 2.08235 8.0955 1.5 8.82344 1.5C9.55138 1.5 10.147 2.09559 10.147 2.82353C10.147 3.55147 9.55138 4.14706 8.82344 4.14706C8.0955 4.14706 7.49991 3.56471 7.49991 2.82353Z" fill="#707070"/>
                                            </svg>

                                        </button>

                                        <ul className="dropdown-menu dropdown-menu-end export-dropdown border-0 shadow"> 
                                            <li className='dropdown-item' onClick={()=>{ setEditData(data); setShowEditModal(true) }}>Edit</li> 
                                            <li className='dropdown-item text-danger'>Delete </li> 
                                        </ul> 
                                    </td>

                                </tr> )} 
                                {loading === false && deliveries.length === 0 &&<tr>
                                    <td colSpan={8} className='text-center'>No fee for this location</td>
                                </tr>}
                                {loading===true && <tr>
                                    <td colSpan={8} className='text-center'><HorizontalLoader></HorizontalLoader></td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>

                    {paginationData.total >= paginationData.pageSize && <div className="pagination w-100 d-flex justify-content-center">
                    {paginationData.total && <Pagination
                        className="pagination-bar ps-0"
                        currentPage={Number(paginationData.pageNumber)}
                        totalCount={paginationData.total}
                        pageSize={paginationData.pageSize}
                        onPageChange={(page: React.SetStateAction<number>) => setPageNumber(page)}/>
                        }
                   </div>}
                </div>
               </div>
            </div> 
        </div> 

        <ModalWrapper showModal={showModal} closeModal={toggleModal} title="Add New Ultra Delivery Fee">
            <AddDeliveryFee toggleModal={ toggleModal } countries={countries}></AddDeliveryFee>
        </ModalWrapper>

        <ModalWrapper showModal={showEditModal} closeModal={toggleEditModal} title="Edit Ultra Delivery Fee">
            <EditDeliveryUltra toggleEditModal={ toggleEditModal } countries={countries} editData={{...editData, country, state}}></EditDeliveryUltra>
        </ModalWrapper>
    </div>
    )
}

export default DeliveryUltra
