import React, { Fragment, useEffect, useState } from 'react'
import './ApproveWalletTransfer.scss'
import { useForm } from 'react-hook-form' 
import OtpInput from 'react-otp-input';
import { walletService } from 'Services/walletService';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { CurrencyFormatter } from '../../../../Helpers/CurrencyFormatter';

export interface walletTransferType { 
        id: number,
        accountId: string,
        customer: {
          emailAddress: string,
          msisdn: string,
          lastName: string,
          firstName: string
        },
        amountMajor: 0,
        status: string,
        transactionStatus: string,
        adminEmail: string,
        createdAt: string
    } 

const ApproveWalletTransfer = () => { 
    const [submit, setSubmit] = useState(false)
    const [detailsRequest, setDetailsRequest] = useState(false)
    const [transferDetails, setTransferDetails] = useState<walletTransferType>()
    const [otp, setOtp] = useState('')
    const navigate = useNavigate()
    const { transferRequestId, loanProvider } = useParams<{ transferRequestId: string | undefined, loanProvider: string | undefined }>()
  
    // const { handleSubmit } = useForm({ mode: 'onBlur' });
  
    // const onSubmit = handleSubmit(() => processOtp());
  
    const processOtp = async () => {
      setSubmit(true)
      await walletService.validateOtp(loanProvider, transferRequestId).then(()=>{
        
        Swal.fire({
            title: 'Success!',
            text: 'Wallet transfer approval completed.',
            icon: 'success'
          }); 
          
        setSubmit(false)

        setTimeout(()=>{
            navigate(`/loan-providers/${loanProvider}`, { replace: true})
        }, 1000)

      }, (error)=>{
        setSubmit(false)
        Swal.fire({
            title: 'Oops!',
            text: error.response.data.error,
            icon: 'warning'
          });
      })
    }
  
    // const handleChangeOtp = (otp:string) => {
    //   setOtp(otp);
    // }

    const getTransferRequestId = async () => {
        setDetailsRequest(true)
       await walletService.getTransferDetails(loanProvider, transferRequestId).then((response)=>{
         console.log('response', response)
         setDetailsRequest(false)
         setTransferDetails(response.data.data)
       }, (error)=>{ 
        setDetailsRequest(false)
        console.log('error', error.response)
        Swal.fire({
            title: 'Oops!',
            text: error.response.data.error,
            icon: 'warning'
          });
       })
    }

    useEffect(() => {
        getTransferRequestId()
    }, [transferRequestId])


    return (
        <div className='ApproveWalletTransfer mt-5'>
            <div className="container"> 
                <div className="row h-100 justify-content-center align-items-center"> 
                    <div className="col-md-6 my-auto"> 
                        <div className="card border-0 shadow-sm">
                            <div className="card-header bg-white fw-bold py-3">
                                Transaction Details
                            </div>
                            <div className="card-body">
                              {detailsRequest && <span className='spinner-border spiner-border-sm'></span>}
                              {transferDetails && <Fragment>
                                <div> 
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="fw-bold">Customer Name:</span>
                                            <span>{transferDetails.customer.firstName} {transferDetails.customer.lastName}</span>
                                        </li>

                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="fw-bold">Customer Contact:</span>
                                            <span>{transferDetails.customer.msisdn}</span>
                                        </li>

                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="fw-bold">Customer Email:</span>
                                            <span>{transferDetails.customer.emailAddress}</span>
                                        </li>

                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="fw-bold">Transfer Amount:</span>
                                            <span>₦{CurrencyFormatter(Number(transferDetails.amountMajor))}</span>
                                        </li>
  
                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <span className="fw-bold">Transfer Status:</span>
                                            <span>{transferDetails.status}</span>
                                        </li> 
                                    </ul> 
                                </div>
                                <button className="fw-bold w-100 btn btn-success mt-4" onClick={()=>processOtp()} disabled={submit}> Approve Transfer{submit && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}</button> 
                                </Fragment>
                                }
                              

                                {/* <div className="login-form h-100">  
                                    <h1 className='text-center'>Verify OTP</h1>
                                    <div className='text-center w-100'>Use the one time password sent to your email.</div>
                                    <form onSubmit={onSubmit}>
                                        <div className="form-group mt-4 mb-3 container-token text-center d-flex justify-content-center">
                                            <OtpInput
                                                value={otp}
                                                onChange={handleChangeOtp}
                                                numInputs={6}
                                                renderInput={(props) => <input {...props} />}
                                                renderSeparator={<span>   </span>}
                                                inputStyle={{
                                                    width: '3.1rem',
                                                    height: '2.5rem',
                                                    minHeight: '2.5rem',
                                                    margin: '0 .5rem',
                                                    fontSize: '1rem',
                                                    borderRadius: 4,
                                                    backgroundColor: '#ECF1F4',
                                                    boxShadow: 'inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)',
                                                    border: '1px solid rgba(0,0,0,0.1)'
                                                }}
                                            />
                                        </div>

                                        <div className="form-group mt-4 text-center">
                                            {!submit && (<button type="submit" className="btn btn-default">Verify</button>)}
                                            {submit && (<button type="submit" className="btn btn-default" disabled>
                                                Please wait
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            </button>)}
                                        </div>
                                    </form>
                                </div>  */}
                            </div> 
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApproveWalletTransfer

