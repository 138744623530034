export function ConvertToCustomISO8601(datetimeLocalValue: string | number | Date) {
    // Convert datetime-local value to ISO 8601 format
    const date = new Date(datetimeLocalValue);
    // Subtract one hour from the date
    date.setHours(date.getHours() - 1); 
    // Extract date and time components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    // Build ISO 8601 formatted string with seconds and Z indicator
    const iso8601String = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
    return iso8601String;
}