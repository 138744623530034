
import React, { FC, useState } from 'react' 
import { CustomerType } from '../../../../../Models' 
import { ShowTGAlert } from '../../../../../Helpers/ShowTGAlert'
import { yupResolver } from '@hookform/resolvers/yup';  
import * as yup from 'yup'; 
import { useForm } from 'react-hook-form';
import customerService from '../../../../../Services/customerService';
import { urlencodePhoneNumber } from '../../../../../Helpers';

interface propsType {
    toggleMultiPLPModal: ()=>void, 
    userData?: CustomerType
}


export type UpdateMultiPlpFormType = {   
  enableOrDisable: string
}
  
const schema = yup.object().shape({  
    enableOrDisable: yup.string().required('Field is required')
});


const MultiPLPModal:FC<propsType> = ({toggleMultiPLPModal, userData}) => {  

    const {handleSubmit, register, formState:{errors}} = useForm<UpdateMultiPlpFormType>({
        resolver: yupResolver(schema),
      })
    
      const { showAlert } = ShowTGAlert()  
      const [request, setRequest] = useState(false) 
    
      const submitNewUserInformation = handleSubmit(async (data:UpdateMultiPlpFormType) => { 
        setRequest(true)  
        const encodedPhone = urlencodePhoneNumber(userData?.msisdn); 
 
        await customerService.updateMultiPlp(encodedPhone, data.enableOrDisable).then(()=>{
            setRequest(false)  
            toggleMultiPLPModal()
    
            showAlert({message: 'Customer PLP status updated successfully', show:true, title: 'Success', type: 'success'}) 
    
            setTimeout(()=>{ 
                window.location.reload()
            }, 2000)
    
        }, (error: { response: { data: { error: string } } }) =>{ 
    
            setRequest(false)   
            toggleMultiPLPModal()  
            showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'}) 
       }) 
      }); 
     
 
        return (
            <div className='AddUser'> 
               <form onSubmit={submitNewUserInformation}> 
                    <div className='form-group mb-3'>
                        <label htmlFor="price-per-lit">Enable Multiple PLP</label>
                        <select  id="enableOrDisable" className='form-control' {...register('enableOrDisable')}>
                            <option>--option--</option>
                            <option value="enable">Enable</option>
                            <option value="disable">Disable</option>  
                        </select>
                        {errors.enableOrDisable && <div className='text-danger'>{errors.enableOrDisable.message}</div>}
                    </div>
    
                    <div className='form-group mb-3'>
                        <button type="submit" className='btn btn-default w-100' disabled={request}>Update {request && <span className='spinner-border spinner-border-sm'></span>}</button> 
                    </div> 
                </form>
            </div>);
}

export default MultiPLPModal
