import React from 'react'
import './AdminSideBar.scss' 
import AdminSideBarContent from './AdminSideBarContent/AdminSideBarContent'

const AdminSideBar = () => {
  return (
        <div className='adminSideBar d-none d-lg-block px-0'>
          <AdminSideBarContent></AdminSideBarContent>
        </div>
  )
}

export default AdminSideBar
