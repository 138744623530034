import React, { FC, useState } from 'react'
import './AddTransactionOption.scss'

interface propsType {
    toggleModal: (value:number)=>void, 
}

const AddTransactionOption:FC<propsType> = ({ toggleModal }) => {
    const [option, setOption] = useState<number>(0)

    return (
        <div className='AddTransactionOption'> 
            <div className={option === 1?'card selected':'card'} onClick={()=>setOption(1)}>
                <div className="card-body">
                    <div className='row'>
                        <div className="col-3 col-sm-2">
                            <svg width="55" height="55" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="32.3564" cy="32" r="32" fill="#DFE4EA"/>
                                <path d="M35.719 41.9409H29.5825C24.9613 41.9409 22.9849 39.9644 22.9849 35.3432V35.2209C22.9849 31.042 24.6319 29.0279 28.3213 28.6797C28.6978 28.6514 29.0555 28.9338 29.0931 29.3197C29.1307 29.7056 28.8484 30.0538 28.4531 30.0914C25.4978 30.3644 24.3966 31.7573 24.3966 35.2303V35.3526C24.3966 39.1832 25.7519 40.5385 29.5825 40.5385H35.719C39.5496 40.5385 40.9049 39.1832 40.9049 35.3526V35.2303C40.9049 31.7385 39.7849 30.3456 36.7731 30.0914C36.3872 30.0538 36.0955 29.715 36.1331 29.3291C36.1707 28.9432 36.5002 28.6514 36.8955 28.6891C40.6413 29.0091 42.3166 31.0326 42.3166 35.2397V35.362C42.3166 39.9644 40.3402 41.9409 35.719 41.9409Z" fill="#292D32"/>
                                <path d="M32.6507 35.8235C32.2648 35.8235 31.9448 35.5035 31.9448 35.1176V24.4071C31.9448 24.0212 32.2648 23.7012 32.6507 23.7012C33.0366 23.7012 33.3566 24.0212 33.3566 24.4071V35.1176C33.3566 35.5035 33.0366 35.8235 32.6507 35.8235Z" fill="#292D32"/>
                                <path d="M35.8034 27.2108C35.6245 27.2108 35.4457 27.1449 35.3045 27.0037L32.6504 24.3496L29.9963 27.0037C29.7234 27.2767 29.2716 27.2767 28.9987 27.0037C28.7257 26.7308 28.7257 26.279 28.9987 26.0061L32.1516 22.8531C32.4245 22.5802 32.8763 22.5802 33.1492 22.8531L36.3022 26.0061C36.5751 26.279 36.5751 26.7308 36.3022 27.0037C36.1704 27.1449 35.9822 27.2108 35.8034 27.2108Z" fill="#292D32"/>
                            </svg>
                        </div>
                        <div className="col-9 col-sm-10">
                            <div className="title">Upload Payment</div>
                            <div className="description">Record Transfer, Charge Plp Overdue etc.</div>
                        </div>
                    </div>
                </div>
            </div>

            <div  className={option === 2?'card selected mt-4':'card mt-4'} onClick={()=>setOption(2)}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-3 col-sm-2">
                            <svg width="55" height="54" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="32.3564" cy="32" r="32" fill="#DFE4EA"/>
                                <path d="M35.6066 42.8327H29.1066C23.6899 42.8327 21.5233 40.666 21.5233 35.2493V28.7493C21.5233 23.3327 23.6899 21.166 29.1066 21.166H35.6066C41.0233 21.166 43.1899 23.3327 43.1899 28.7493V35.2493C43.1899 40.666 41.0233 42.8327 35.6066 42.8327Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M35.6062 35.6616H30.2762C28.4345 35.6616 26.9395 34.1666 26.9395 32.3249C26.9395 30.4833 28.4345 28.9883 30.2762 28.9883H37.6104" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M36.0721 30.6684L37.7729 28.9567L36.0721 27.2559" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                        <div className="col-9 col-sm-10"> 
                            <div className="title">Internal Transfer</div>
                            <div className="description">Record product evacuation.</div>
                        </div>
                    </div>
                </div>
            </div>


            <button className='btn btn-default w-100 mt-4' onClick={()=>toggleModal(option)}>Continue</button>
        </div>
    )
}

export default AddTransactionOption
