
import React, { FC, useState } from 'react' 
import { CustomerType } from 'Models' 
import { ShowTGAlert } from 'Helpers/ShowTGAlert'
import { yupResolver } from '@hookform/resolvers/yup';  
import * as yup from 'yup'; 
import { useForm } from 'react-hook-form'; 
import { urlencodePhoneNumber } from 'Helpers';
import loanService from 'Services/loanServices'; 
interface propsType {
    toggleLoanProviderModal: ()=>void, 
    userData?: CustomerType
}


export type AssignProviderFormType = {   
    tag: string, 
}
  
const schema = yup.object().shape({  
    tag: yup.string().required()
});


const TagUsersModal:FC<propsType> = ({toggleLoanProviderModal, userData}) => {  
    
    const {handleSubmit, register, formState:{errors}} = useForm<AssignProviderFormType>({
        resolver: yupResolver(schema),
      })
    
      const { showAlert } = ShowTGAlert()  
      const [request, setRequest] = useState(false) 
      
      const submitNewUserInformation = handleSubmit(async (data:AssignProviderFormType) => { 
        setRequest(true)  
        const encodedPhone = urlencodePhoneNumber(userData?.msisdn); 
         

        await loanService.tagCustomer(encodedPhone, data.tag).then(()=>{
            setRequest(false)  
            toggleLoanProviderModal()
    
            showAlert({message: 'Customer tagged successfully', show:true, title: 'Success', type: 'success'}) 
     
        }, (error: { response: { data: { error: string } } }) =>{ 
    
            setRequest(false)   
            toggleLoanProviderModal()  
            showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'}) 
       }) 
      }); 
  
        return (
            <div className='AddUser'> 
               <form onSubmit={submitNewUserInformation}> 
                    <div className='form-group mb-3'>
                        <label htmlFor="price-per-lit">Tags</label>
                        <select  id="enableOrDisable" className='form-control' {...register('tag')}>
                            <option>--option--</option>
                            <option value="MoniePoint">MoniePoint</option> 
                          </select>
                        {errors.tag && <div className='text-danger'>{errors.tag.message}</div>}
                    </div> 
    
                    <div className='form-group mb-3'>
                        <button type="submit" className='btn btn-default w-100' disabled={request}>Add Tag {request && <span className='spinner-border spinner-border-sm'></span>}</button> 
                    </div> 
                </form>
            </div>);
}

export default TagUsersModal
