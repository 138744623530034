import React, { FC, useState } from 'react'
import './AddProductRate.scss' 
import productService from '../../../../../Services/productService'; 
import { useForm } from 'react-hook-form'; 
import { yupResolver } from '@hookform/resolvers/yup';  
import * as yup from 'yup';
import { ShowTGAlert } from '../../../../../Helpers/ShowTGAlert';
import { CompanyLocationType } from '../../../../../Models/companyType'; 

interface propsType {
    toggleModal: ()=>void;
    rateDate?: CompanyLocationType; 
    productId: string,

}

type formType = {
    isHidden: string|boolean
}

const schema = yup.object().shape({ 
    isHidden: yup.lazy((value) =>
        typeof value === 'boolean'
        ? yup.boolean().required('Required field') 
        : yup.string().required('Required field')
    ),
  }); 

const HideProductPrice:FC<propsType> = ({toggleModal, rateDate, productId}) => { 
 
    const [requests, setRequest] = useState(false)
    const { showAlert } = ShowTGAlert() 
    
    
    const { register, handleSubmit, formState: { errors } } =  useForm<formType>({
        resolver: yupResolver(schema),
      });
      
      const [isChecked, setIsChecked] = useState<boolean>(false);

      const handleToggle = () => {
          setIsChecked(!isChecked);
      };

    const submit = handleSubmit(async ()=>{
      
      setRequest(true)
      const newData = {isHidden: isChecked.toString(), productId: productId}
    
      await productService.setProductHidden(rateDate?.id, newData).then(
        ()=>{
            setRequest(false)
            showAlert({message: `Price set to ${isChecked? 'hidden': 'show'} successfully`, show:true, title: 'Success', type: 'success'})
           
            setTimeout(()=>{
                window.location.reload()
            }, 3000)
        }, error =>{       
            showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'})  
            setRequest(false)

            setTimeout(()=>{
                toggleModal();
            }, 1000)
           
        }
      )
      toggleModal()
    })


    
  
    return (
        <div className='HideProductPrice'>
            <div className="row mb-3"> 
                <div className="col-12 company-info">
                    <div className="name">{rateDate?.name}, Lagos</div>
                </div> 
            </div>
             <form onSubmit={submit}>  
                <div className='form-group mb-3'> 
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" {...register('isHidden',  {required: 'Field is required', onChange: handleToggle})} role="switch" id="flexSwitchCheckChecked" checked={isChecked} />
                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Hide Price</label>
                    </div>

                    {errors.isHidden && <div className='error-message text-danger'>{errors.isHidden?.message}</div>}
                </div>

                <div className='form-group mb-3'>
                    {!requests && <button className='btn btn-default w-100'>Save</button> }
                    {requests && <button className='btn btn-default w-100'>Save <span className='spinner-border spinner-border-sm'></span></button> }
                </div>
            </form>
        </div>
    )
}

export default HideProductPrice
