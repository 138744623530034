import React, { Fragment, useEffect, useState } from 'react'
import './PLPTracking.scss'
import { BreadCrumb } from '../../../../../Components'
import { useParams } from 'react-router-dom'
import plpService from '../../../../../Services/plpService'
import { plpType } from '../../../../../Models/plpType'

const PLPTracking = () => {
    const { referenceId } = useParams()
    const [plpData, setPlpData] = useState<plpType>()
    
    const getPlpDetails = async () => {
        await plpService.getDetails(referenceId).then((response: { data: { data: { dataset: any[] } } })=>{
          console.log('getPlpDetails', response.data.data.dataset[0])
          setPlpData(response.data.data.dataset[0])
        }, (error: { response: any }) =>{
            console.log('getPlpDetails error', error.response)
        })
    }

    useEffect(() => {
        getPlpDetails()
    }, [])
    return (
        <div className='PLPTracking'>
            <div className="container mt-4">
                 <BreadCrumb title={'#112434 -Christiana Johnson'} parent={'PLP'} parentUrl={'-1'} showTitle={false}></BreadCrumb>
            </div>

            <div className="container mt-2"> 
                <div className="card border-0">
                    <div className="card-header border-0">
                        <div className="row">
                            <div className="col-12 col-md-8 my-auto">
                                <div className="title">
                                #112434 -Christiana Johnson
                                </div>
                            </div>
                              <Fragment> 
                                <div className="col-6 col-md-2">
                                  <span className='order-status'>Active</span>
                                </div>
                                <div className="col-6 col-md-2"> 
                                  <button className='btn btn-respond-req'>
                                        <svg width="16" height="16" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21.09 6.97953C20.24 6.03953 18.82 5.56953 16.76 5.56953H16.52V5.52953C16.52 3.84953 16.52 1.76953 12.76 1.76953H11.24C7.48004 1.76953 7.48004 3.85953 7.48004 5.52953V5.57953H7.24004C5.17004 5.57953 3.76004 6.04953 2.91004 6.98953C1.92004 8.08953 1.95004 9.56953 2.05004 10.5795L2.06004 10.6495L2.16004 11.6995C2.17004 11.7095 2.19004 11.7295 2.21004 11.7395C2.54004 11.9595 2.88004 12.1795 3.24004 12.3795C3.38004 12.4695 3.53004 12.5495 3.68004 12.6295C5.39004 13.5695 7.27004 14.1995 9.18004 14.5095C9.27004 15.4495 9.68004 16.5495 11.87 16.5495C14.06 16.5495 14.49 15.4595 14.56 14.4895C16.6 14.1595 18.57 13.4495 20.35 12.4095C20.41 12.3795 20.45 12.3495 20.5 12.3195C20.96 12.0595 21.39 11.7795 21.81 11.4695C21.83 11.4595 21.85 11.4395 21.86 11.4195L21.9 11.0595L21.95 10.5895C21.96 10.5295 21.96 10.4795 21.97 10.4095C22.05 9.39953 22.03 8.01953 21.09 6.97953ZM13.09 13.8295C13.09 14.8895 13.09 15.0495 11.86 15.0495C10.63 15.0495 10.63 14.8595 10.63 13.8395V12.5795H13.09V13.8295ZM8.91004 5.56953V5.52953C8.91004 3.82953 8.91004 3.19953 11.24 3.19953H12.76C15.09 3.19953 15.09 3.83953 15.09 5.52953V5.57953H8.91004V5.56953Z" fill="#D4AF37"/>
                                            <path opacity="0.4" d="M20.5002 12.2984C20.4502 12.3284 20.4002 12.3584 20.3502 12.3884C18.5702 13.4284 16.6002 14.1284 14.5602 14.4684C14.4802 15.4284 14.0602 16.5284 11.8702 16.5284C9.68016 16.5284 9.26016 15.4384 9.18016 14.4884C7.27016 14.1884 5.39016 13.5584 3.68016 12.6084C3.53016 12.5284 3.38016 12.4484 3.24016 12.3584C2.88016 12.1584 2.54016 11.9384 2.21016 11.7184C2.19016 11.7084 2.17016 11.6884 2.16016 11.6784L2.77016 18.1884C2.98016 20.1784 3.80016 22.2284 8.20016 22.2284H15.8202C20.2202 22.2284 21.0402 20.1784 21.2502 18.1784L21.8802 11.3984C21.8702 11.4184 21.8502 11.4384 21.8302 11.4484C21.4002 11.7584 20.9602 12.0484 20.5002 12.2984Z" fill="#D4AF37"/>
                                        </svg>
                                        Upgrade to PLP Ultra
                                    </button>
                                </div>
                            </Fragment>
                        </div> 
                    </div>
                </div>


                <div className="card border-0 mt-2">
                    <div className="card-body pb-5 pb-4">
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="24" cy="24" r="24" fill="#0E1F33"/>
                            <path d="M33.09 18.9795C32.24 18.0395 30.82 17.5695 28.76 17.5695H28.52V17.5295C28.52 15.8495 28.52 13.7695 24.76 13.7695H23.24C19.48 13.7695 19.48 15.8595 19.48 17.5295V17.5795H19.24C17.17 17.5795 15.76 18.0495 14.91 18.9895C13.92 20.0895 13.95 21.5695 14.05 22.5795L14.06 22.6495L14.16 23.6995C14.17 23.7095 14.19 23.7295 14.21 23.7395C14.54 23.9595 14.88 24.1795 15.24 24.3795C15.38 24.4695 15.53 24.5495 15.68 24.6295C17.39 25.5695 19.27 26.1995 21.18 26.5095C21.27 27.4495 21.68 28.5495 23.87 28.5495C26.06 28.5495 26.49 27.4595 26.56 26.4895C28.6 26.1595 30.57 25.4495 32.35 24.4095C32.41 24.3795 32.45 24.3495 32.5 24.3195C32.96 24.0595 33.39 23.7795 33.81 23.4695C33.83 23.4595 33.85 23.4395 33.86 23.4195L33.9 23.0595L33.95 22.5895C33.96 22.5295 33.96 22.4795 33.97 22.4095C34.05 21.3995 34.03 20.0195 33.09 18.9795ZM25.09 25.8295C25.09 26.8895 25.09 27.0495 23.86 27.0495C22.63 27.0495 22.63 26.8595 22.63 25.8395V24.5795H25.09V25.8295ZM20.91 17.5695V17.5295C20.91 15.8295 20.91 15.1995 23.24 15.1995H24.76C27.09 15.1995 27.09 15.8395 27.09 17.5295V17.5795H20.91V17.5695Z" fill="white"/>
                            <path opacity="0.4" d="M32.5002 24.2984C32.4502 24.3284 32.4002 24.3584 32.3502 24.3884C30.5702 25.4284 28.6002 26.1284 26.5602 26.4684C26.4802 27.4284 26.0602 28.5284 23.8702 28.5284C21.6802 28.5284 21.2602 27.4384 21.1802 26.4884C19.2702 26.1884 17.3902 25.5584 15.6802 24.6084C15.5302 24.5284 15.3802 24.4484 15.2402 24.3584C14.8802 24.1584 14.5402 23.9384 14.2102 23.7184C14.1902 23.7084 14.1702 23.6884 14.1602 23.6784L14.7702 30.1884C14.9802 32.1784 15.8002 34.2284 20.2002 34.2284H27.8202C32.2202 34.2284 33.0402 32.1784 33.2502 30.1784L33.8802 23.3984C33.8702 23.4184 33.8502 23.4384 33.8302 23.4484C33.4002 23.7584 32.9602 24.0484 32.5002 24.2984Z" fill="white"/>
                        </svg>

                        <div className="label mt-3">
                            PLP Value
                        </div>
                        <div className="value">
                            ₦5,000,000.00
                        </div>
                        <div className="label mt-4">
                            Next due payment
                        </div>
                        <div className="value">
                            ₦1,000,000.00 in 29th May, 2022
                        </div> 

                        <div className='repay'>
                            <button className='repay-btn'>Repay Now</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PLPTracking
