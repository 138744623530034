import React, { ChangeEvent, FC, Key, useEffect, useState } from 'react'
import './InternalTransferTransaction.scss' 
import { useForm } from 'react-hook-form'; 
import { yupResolver } from '@hookform/resolvers/yup';  
import * as yup from 'yup'; 
import { ShowTGAlert } from '../../../../../Helpers/ShowTGAlert';   
import customerService from 'Services/customerService';
import { Logger } from 'Helpers/Logger'; 
import productService from 'Services/productService';
import { ProductType } from 'Models';
import transactionService from 'Services/transactionService';
import orderService from 'Services/orderService';
import { OrderType } from '@/Models/orderType';

    interface propsType {
        toggleModal: ()=>void, 
    }

    export type AddTransactionTransferFormType = {    
        senderMsisdn: string,
        receiverMsisdn: string,
        productName: string,
        quantity: number,
        freightCostPerUnit: number,
        productCostPerUnit: number,
        negotiatedPricePerUnit: number,
        totalFreightCost: number,
        totalProductCost: number,
        totalNegotiatedPrice: number,
        orderReferenceNumber: number,
    } 
    
    export type AddTransactionType = {   
        msisdn: string,
        amountMajor: number,
        description: string ,
        createdAtISO8601: string
    }

    

    export type stateType = {  
        name: string,
        localGovernmentAreas: [ string ] 
    }
  
  const schema = yup.object().shape({      
    senderMsisdn:yup.string().required('Field is required'),
    receiverMsisdn: yup.string().required('Field is required'),
    productName: yup.string().required('Field is required'),
    quantity: yup.number().required('Field is required'),
    freightCostPerUnit: yup.number().required('Field is required'),
    productCostPerUnit: yup.number().required('Field is required'),
    negotiatedPricePerUnit: yup.number().required('Field is required'),
    totalFreightCost: yup.number().required('Field is required'),
    totalProductCost: yup.number().required('Field is required'),
    totalNegotiatedPrice: yup.number().required('Field is required'),
    orderReferenceNumber: yup.number().required('Field is required'),
  });
  

const InternalTransferTransaction:FC<propsType> = ({toggleModal}) => { 
   
   const {handleSubmit, register, watch, setValue, formState:{errors}} = useForm<AddTransactionTransferFormType>({
    resolver: yupResolver(schema),
  })

  const { showAlert } = ShowTGAlert()  
  const [request, setRequest] = useState(false) 
//   const [orderDetails, setOrderDetails] = useState<OrderType>() 
 
  const submitNewUserInformation = handleSubmit(async (data:AddTransactionTransferFormType) => { 
    setRequest(true)  
       
    await transactionService.debitTransfer(data).then(()=>{
        setRequest(false)  
        toggleModal() 
        showAlert({message: 'Transfer successful', show:true, title: 'Success', type: 'success'}) 

        setTimeout(()=>{ 
            window.location.reload()
        }, 2000)

    }, (error: { response: { data: { error: string } } }) =>{ 

        setRequest(false)    
        showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'}) 
   }) 
  }); 

    


  const [msisdnErrors, setMsisdnErrors] = useState<boolean>();
  const [msisdnDetails, setMsisdnDetails] = useState<{firstName: string; lastName: string;}>();
   



  const verifyMsisdn = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const msisdn: string = e.target.value; 
    const endpoint = `customerMsisdn=${encodeURIComponent(msisdn)}`; 
    if (msisdn.length >= 10 && msisdn.length <= 15) {
        setMsisdnDetails(undefined)
          await customerService.searchCustomer(endpoint, 1, 'DESC').then((response)=>{  
              setMsisdnErrors(false); 
              setMsisdnDetails({
                firstName: response.data.data.dataset[0].firstName,
                lastName: response.data.data.dataset[0].lastName
             }) 
          }, (error: {response: {data: {error:string}}}) => { 
              setMsisdnErrors(true);
              Logger(error.response);
              setMsisdnDetails(undefined)
          });
      
      }
  }


  const [msisdnReceiverErrors, setMsisdnReceiverErrors] = useState<boolean>();
  const [msisdnReceiverDetails, setMsisdnReceiverDetails] = useState<{firstName: string; lastName: string;}>();
   
  const verifyReceiverMsisdn = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const msisdn: string = e.target.value; 
    const endpoint = `customerMsisdn=${encodeURIComponent(msisdn)}`; 
    if (msisdn.length >= 10 && msisdn.length <= 15) {
        setMsisdnReceiverDetails(undefined)
          await customerService.searchCustomer(endpoint, 1, 'DESC').then((response)=>{  
              setMsisdnReceiverErrors(false); 
              setMsisdnReceiverDetails({
                firstName: response.data.data.dataset[0].firstName,
                lastName: response.data.data.dataset[0].lastName
             }) 
          }, (error: {response: {data: {error:string}}}) => { 
              setMsisdnReceiverErrors(true);
              Logger(error.response);
              setMsisdnReceiverDetails(undefined)
          });
      
      }
  }

  const [products, setProducts] = useState<ProductType[]>();  

  const getProducts = async () =>{
     await productService.index(1).then((response)=>{
        console.log('productService', response)
        setProducts(response.data.data.dataset)
      }, error =>{
        console.log('error', error)
      })
  }
  useEffect( ()=>{
    getProducts()
  },[])


  useEffect(() => {
    setValue('totalFreightCost', (watch('freightCostPerUnit') * watch('quantity')))
  }, [watch('freightCostPerUnit'), watch('quantity')])
  
  useEffect(() => {
    setValue('totalProductCost', (watch('productCostPerUnit') * watch('quantity'))) // - watch('totalFreightCost'))
  }, [watch('productCostPerUnit'), watch('quantity'),  watch('totalFreightCost')])
  
  useEffect(() => {
    setValue('totalNegotiatedPrice', (watch('negotiatedPricePerUnit') * watch('quantity')))
  }, [watch('negotiatedPricePerUnit'), watch('quantity')])
  
  const orderRefer = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof e.target.value === 'string' && e.target.value.length > 5) {
            await orderService.getDetails(e.target.value).then((response: { data: { data: { dataset: OrderType[]}}})=>{ 
                console.log(response.data.data.dataset[0])
                // setOrderDetails(response.data.data.dataset[0])
                const orderObj = response.data.data.dataset[0];
                setValue('senderMsisdn', orderObj?.quoteRequest?.customer.msisdn)
                setValue('productCostPerUnit', orderObj?.pricePerUnitMajor) 
            }) 
        }
    }
    return (
        <div className='InternalTransferTransaction'> 
            <form onSubmit={submitNewUserInformation}>
                <div className='form-group mb-3'>
                    <label htmlFor="orderReferenceNumber">Order Reference No.</label>
                    <input type="number" id="orderReferenceNumber" className='form-control' {...register('orderReferenceNumber', {onChange: orderRefer})} placeholder='e.g 2000'/>
                    {errors.orderReferenceNumber && <div className='text-danger error'>{errors.orderReferenceNumber.message}</div>}
                </div>  

                <div className='form-group mb-3'>
                    <label htmlFor="productName">Product</label>
                    <select id="productName" className='form-control' {...register('productName')}>
                        {products && products.map((product:ProductType, index:Key)=><option key={index}>{product.product.informalName}</option>)}
                    </select>
                    {errors.productName && <div className='text-danger error'>{errors.productName.message}</div>}
                </div>  
                   
 
                <div className='form-group mb-3'>
                    <label htmlFor="productCostPerUnit">Product Cost/lit</label>
                    <input type="number" id="productCostPerUnit" className='form-control' {...register('productCostPerUnit')} placeholder='e.g 2000'/>
                    {errors.productCostPerUnit && <div className='text-danger error'>{errors.productCostPerUnit.message}</div>}
                </div> 

                <div className='form-group mb-3'>
                    <label htmlFor="productCostMajor">Negotiated Price/lit</label>
                    <input type="number" id="productCostMajor" className='form-control' {...register('negotiatedPricePerUnit')} placeholder='e.g 2000'/>
                    {errors.negotiatedPricePerUnit && <div className='text-danger error'>{errors.negotiatedPricePerUnit.message}</div>}
                </div>  
 
                <div className='form-group mb-3'>
                    <label htmlFor="freightCostPerUnit">Freight Cost/lit</label>
                    <input type="number" id="freightCostPerUnit" className='form-control' {...register('freightCostPerUnit')} placeholder='e.g 2000'/>
                    {errors.freightCostPerUnit && <div className='text-danger error'>{errors.freightCostPerUnit.message}</div>}
                </div>  
 
                <div className='form-group mb-3'>
                    <label htmlFor="transferQuantity">Quantity</label>
                    <input type="number" id="transferQuantity" className='form-control' {...register('quantity')} placeholder='e.g 2000'/>
                    {errors.quantity && <div className='text-danger error'>{errors.quantity.message}</div>}
                </div>  

                <div className='form-group mb-3'>
                    <label htmlFor="totalFreightCost">Total Freight Cost</label>
                    <input type="number" disabled id="totalFreightCost" className='form-control' {...register('totalFreightCost')} placeholder='e.g 2000'/>
                    {errors.totalFreightCost && <div className='text-danger error'>{errors.totalFreightCost.message}</div>}
                </div>  
 
 
                <div className='form-group mb-3'>
                    <label htmlFor="totalProductCost">Total Product Cost</label>
                    <input type="number" disabled id="totalProductCost" className='form-control' {...register('totalProductCost')} placeholder='e.g 2000'/>
                    {errors.totalProductCost && <div className='text-danger error'>{errors.totalProductCost.message}</div>}
                </div>  
  
                <div className='form-group mb-3'>
                    <label htmlFor="totalNegotiatedPrice">Total Negotiated Price</label>
                    <input type="number" disabled id="totalNegotiatedPrice" className='form-control' {...register('totalNegotiatedPrice')} placeholder='e.g 2000'/>
                    {errors.totalNegotiatedPrice && <div className='text-danger error'>{errors.totalNegotiatedPrice.message}</div>}
                </div>  
  

               <div className='form-group mb-3'>
                    <label htmlFor="msisdn">Defaulter&apos;s Msisdn</label>
                    <input type="text" id="msisdn" className='form-control' {...register('senderMsisdn', {onChange: (e)=>verifyMsisdn(e),})} placeholder='e.g +2347023321234'/>
                    {errors.senderMsisdn && <div className='text-danger error'>{errors.senderMsisdn.message}</div>} 
                    {msisdnErrors && <div className='error text-danger'>Phone number does not exist</div>}  
                    {msisdnDetails && <small className='error text-success fw-bolder'>{msisdnDetails.firstName} {msisdnDetails.lastName}</small>}              
                </div> 
 
                <div className='form-group mb-3'>
                    <label htmlFor="receiverMsisdn">Recipient&apos;s Msisdn</label>
                    <input type="text" id="receiverMsisdn" className='form-control' {...register('receiverMsisdn', {onChange: (e)=>verifyReceiverMsisdn(e),})} placeholder='e.g +2347023321234'/>
                    {errors.receiverMsisdn && <div className='text-danger error'>{errors.receiverMsisdn.message}</div>} 
                    {msisdnReceiverErrors && <div className='error text-danger'>Phone number does not exist</div>}  
                    {msisdnReceiverDetails && <small className='error text-success fw-bolder'>{msisdnReceiverDetails.firstName} {msisdnReceiverDetails.lastName}</small>}              
                </div>   
 
                <div className='form-group mb-3'>
                    <button className='btn btn-default w-100' onClick={(e) => submitNewUserInformation(e)} disabled={request}>Submit {request && <span className='spinner-border spinner-border-sm'></span>}</button> 
                </div> 
            </form>
        </div>
    )
}

export default InternalTransferTransaction
