import axios from 'axios'

import { AxiosInterceptor } from '../Config/AxiosInterceptor';
import { ProductRateType } from '../Models/productType';

AxiosInterceptor()

const productService = {
  async index (pageNumber: number) {
    return axios.get(`/depots/productPrices?pageNumber=${pageNumber}`);
  } ,
 
  async getAll () {
    return axios.get('/products');
  },

  
  async getOne (productUuid:string|undefined) {
    return axios.get(`/products?productUuid=${productUuid}`);
  },


  async getOneDepoPrice (productUuid:string|undefined) {
    return axios.get(`/depots/productPrices?productId=${productUuid}&pageNumber=${1}`);
  } ,

  async setProductRate (id: string | undefined, data:ProductRateType) {
    return axios.put(`/depots/location/${id}/productPrices`, data);
  } ,

  async setProductHidden (id: string | undefined, data:{productId: string, isHidden:string|boolean}) {
    return axios.put(`/depots/location/${id}/productPrices`, data);
  } 
}
export default productService
