import axios from 'axios' 
import EventBus from './../Helpers/EventBus';


 export const AxiosInterceptor = () => {
    let baseUrl = process.env.REACT_APP_BASEURL
    if(process.env.REACT_APP_ENV === 'prod') {
      baseUrl =  process.env.REACT_APP_PROD_BASEURL
    }
    axios.defaults.baseURL = baseUrl;
    axios.defaults.headers.post['Content-Type'] = 'application/json'; 

    axios.interceptors.request.use(function (config) {
      
      config.headers['Access-Control-Allow-Origin'] = '*'; 
    
      const userObject = localStorage.getItem('__TG') 

      if (userObject !== null && userObject !== 'null' && userObject) {
        const token = userObject && JSON.parse(userObject).token

        // config.headers['X-Access-Token'] = token
        
        config.headers.Authorization = `Bearer ${token}`  

      }
    
      return config;
    }, function (error) {
      // Do something with request error
      return Promise.reject(error);
    });
 
    axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      // Do something with request error
      console.log('error.response')
      console.log(error.response)
      // if(error.response && error.response.status === 401) {
      //   console.log('Inside response status: 401 if block')
      //   EventBus.dispatch('logout', 'session token expired');
      //   localStorage.removeItem('__TG')
      //   window.location.href = '/login'  
      // }

      if (error.response && error.response.data.error === 'Unauthorized') {
        // Redirect to the login page 
        localStorage.removeItem('__TG')
        window.location.href = '/login' 
      }

      if (error.response && error.response.data.error === 'Access token expired') {
        // Redirect to the login page 
        localStorage.removeItem('__TG')
        window.location.href = '/login' 
      }
      return Promise.reject(error);
    });
}
 
