'use client'
import React, { ChangeEvent, FC } from 'react'  
import { BsCheck2 } from 'react-icons/bs'
import './fileUpload.scss'

interface propsType {
    files: { name: string; file: File|undefined, selected: boolean }[]
    handleFileChange: (val: ChangeEvent<HTMLInputElement>, val2:string)=>void
}
const FileUpload:FC<propsType> = ({files, handleFileChange}) => {
    return (
        <div className="file-upload">
            <div className="upload-container mt-3">
        
                <label htmlFor="" className="d-block text mb-2">Upload CAC Certificate  {files && files.some((file) => file.name === 'TRADEGRID_PLUS_APPLICATION_CAC_CERTIFICATE' && file.selected) && <BsCheck2 className="fw-bold text-success"/>}
                    
                <input
                    type="file"
                    id="TRADEGRID_PLUS_APPLICATION_CAC_CERTIFICATE"
                    className=""
                    onChange={(e) => handleFileChange(e, 'TRADEGRID_PLUS_APPLICATION_CAC_CERTIFICATE')}
                    hidden
                /><br></br>
                <button className="btn px-4 rounded-pill mt-2" 
                    id={'TRADEGRID_PLUS_APPLICATION_CAC_CERTIFICATE'}  
                    type="button"
                        onClick={() =>
                            document
                                .getElementById('TRADEGRID_PLUS_APPLICATION_CAC_CERTIFICATE')
                                ?.click()
                        }
                >Upload</button>
                </label> 
            </div>

            <div className="upload-container mt-3"> 
                <label htmlFor="" className="d-block text mb-2">Upload CAC7A or Documents showing ownership of retail station {files.some((file) => file.name === 'TRADEGRID_PLUS_APPLICATION_CAC_7' && file.selected) && <BsCheck2 className="fw-bold text-success"/>}
                    
                <input
                    type="file"
                    id="TRADEGRID_PLUS_APPLICATION_CAC_7"
                    className=""
                    onChange={(e) => handleFileChange(e, 'TRADEGRID_PLUS_APPLICATION_CAC_7')}
                    hidden
                /><br></br>
                <button className="btn px-4 rounded-pill mt-2" 
                    id={'TRADEGRID_PLUS_APPLICATION_CAC_7'} 
                    type="button"
                        onClick={() =>
                            document
                                .getElementById('TRADEGRID_PLUS_APPLICATION_CAC_7')
                                ?.click()
                        }
                >Upload</button>
                </label> 
            </div>
        </div>
    )
}

export default FileUpload
