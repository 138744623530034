 
  enum ValidationResult {
    PhoneNumber = 'PhoneNumber',
    paystackReference = 'paystackReference',
    AlphanumericWithUnderscore = 'AlphaNumeric',
    Unknown = 'Unknown',
  }
  
  export const checkSearchTransactionInputType = (inputValue: string): ValidationResult => {
    const isPhoneNumber = (value: string): boolean => {
      //   Check if the value matches a phone number pattern
      const phoneNumberPattern = /^\d{10}$/;
      return phoneNumberPattern.test(value);
    };

    function hasTenOrMoreConsecutiveNumbers(input: string): boolean {
        const consecutiveNumbersPattern = /\d{10,}/;
        return consecutiveNumbersPattern.test(input);
      }
  
   

    function isValidPaystackReference(reference:string) {
        // Define a regular expression pattern for Paystack reference
        const pattern = /^[A-Z0-9]{12}$/;

        // Use the test() method to check if the reference matches the pattern
        return pattern.test(reference);
      }

  
    const containsUnderscoreAndAlphanumeric = (value: string): boolean => {
      // Check if the value contains at least one underscore and alphanumeric characters
      return /.*\w+.*_.*\w+.*/.test(value);
    };
  
    if (isPhoneNumber(inputValue) || hasTenOrMoreConsecutiveNumbers(inputValue)) {
      return ValidationResult.PhoneNumber;
    } else if (isValidPaystackReference(inputValue)) {
      return ValidationResult.paystackReference;
    } else if (containsUnderscoreAndAlphanumeric(inputValue)) {
      return ValidationResult.AlphanumericWithUnderscore;
    } else {
      return ValidationResult.Unknown;
    }
  };