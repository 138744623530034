import React, { FC, useState } from 'react'
import './EditRespondToQuote.scss'
import { useForm } from 'react-hook-form'
import { RespondToPriceMatrixType } from '../../../../../../Models/requestType' 
import { yupResolver } from '@hookform/resolvers/yup';  
import * as yup from 'yup';
import { ShowTGAlert } from '../../../../../../Helpers/ShowTGAlert';
import requestService from '../../../../../../Services/requestService';

interface propsType {
    toggleModal: ()=>void, 
    referenceId?: string | undefined,
    priceMatrix?: RespondToPriceMatrixType
}

const schema = yup.object().shape({ 
    unitDepotPrice: yup.number().required('Delivery fee per unit Litre is required') ,
    premium: yup.number().required('Premium is required') ,
    unitProductCost: yup.number().required('Unit Product Cost is required') ,
    freightCostPerUnit: yup.number().required('Freight Cost Per Unit is required') ,
    margin: yup.number().required('Margin is required') ,
    retailFillingStationName: yup.string().required('Retail Filling Station Name is required') 
  });

const EditRespondToQuote:FC<propsType> = ({toggleModal, referenceId, priceMatrix}) => { 
    const [request, setRequest] = useState(false)
    const { showAlert } = ShowTGAlert() 
    
    const {handleSubmit, register, formState: {errors}} = useForm<RespondToPriceMatrixType>({
        resolver: yupResolver(schema),
      })

    const submit = handleSubmit(async (data: RespondToPriceMatrixType)=>{
        setRequest(true)
        await requestService.priceMatrixUpdate(referenceId, data).then(()=>{
            setRequest(false) 
            toggleModal()
            showAlert({message: 'Price matrix updated successfully', show:true, title: 'Success', type: 'success'}) 
        }, error =>{ 
            console.log('priceMatrixUpdate error', error)
        setRequest(false)    
        toggleModal();  
        showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'}) 
    
       })
    })

    return (
        <div className='EditRespondToQuote'> 
            <div className="title">
            Fill in the details below to update the price matrix
            </div> 
            <form onSubmit={submit}> 
                <div className='form-group mb-3 mt-4'>
                    <label htmlFor="retailFillingStationName">Retail Filling Station Name</label>
                    <input type="text" id="retailFillingStationName" className='form-control' defaultValue={priceMatrix?.retailFillingStationName} {...register('retailFillingStationName')}/>
                    {errors.retailFillingStationName && <span className='errors'>{errors.retailFillingStationName.message}</span>}
                </div>  
                <div className='form-group mb-3 mt-4'>
                    <label htmlFor="freightCostPerUnit">Freight Cost Per Unit</label>
                    <input type="text" id="freightCostPerUnit" className='form-control' defaultValue={priceMatrix?.freightCostPerUnit} {...register('freightCostPerUnit')}/>
                    {errors.freightCostPerUnit && <span className='errors'>{errors.freightCostPerUnit.message}</span>}
                </div>  
                <div className='form-group mb-3 mt-4'>
                    <label htmlFor="margin">Margin</label>
                    <input type="text" id="margin" className='form-control' defaultValue={priceMatrix?.margin} {...register('margin')}/>
                    {errors.margin && <span className='errors'>{errors.margin.message}</span>}
                </div>  
                <div className='form-group mb-3 mt-4'>
                    <label htmlFor="premium">Premium</label>
                    <input type="text" id="premium" className='form-control' defaultValue={priceMatrix?.premium} {...register('premium')}/>
                    {errors.premium && <span className='errors'>{errors.premium.message}</span>}
                </div> 
                <div className='form-group mb-3 mt-4'>
                    <label htmlFor="unitDepotPrice">Unit Depot Price</label>
                    <input type="text" id="unitDepotPrice" className='form-control' defaultValue={priceMatrix?.unitDepotPrice} {...register('unitDepotPrice')}/>
                    {errors.unitDepotPrice && <span className='errors'>{errors.unitDepotPrice.message}</span>}
                </div>  
                <div className='form-group mb-3 mt-4'>
                    <label htmlFor="unitProductCost">Unit Product Cost</label>
                    <input type="text" id="unitProductCost" className='form-control' defaultValue={priceMatrix?.unitProductCost} {...register('unitProductCost')}/>
                    {errors.unitProductCost && <span className='errors'>{errors.unitProductCost.message}</span>}
                </div>  

                <div className='form-group mb-3'>
                    {!request && <button className='btn btn-default w-100'>Send Quote</button> }
                    {request && <button className='btn btn-default w-100 disabled'>Send Quote <span className='spinner-border spinner-border-sm'></span></button> }
                </div> 
            </form>
        </div>
    )
}

export default EditRespondToQuote
