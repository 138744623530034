import axios from 'axios'

import { AxiosInterceptor } from '../Config/AxiosInterceptor';
import { CreateCompanyFormData } from '../Pages/Frontend/Components/Companies/AddCompany/AddCompany';
 
AxiosInterceptor()

const companyService = {

  index: async (pageNumber:number) => {
    return await axios.get(`/depots/companies?pageNumber=${pageNumber}`);
  }, 
  create: async (data: CreateCompanyFormData) => {
    return await axios.post('/depots/companies', data);
  },
  locations: async (id:string|undefined, pageNumber:number) => {
    return await axios.get(`depots/companies/${id}/locations?pageNumber=${pageNumber}`);
  },
  locationsRates: async (depotLocationId:string|undefined, pageNumber:number) => {
    return await axios.get(`/depots/productPrices?pageNumber=${pageNumber}&depotLocationId=${depotLocationId}`);
  }
 
}

export default companyService
