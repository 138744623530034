import React, { FC, useState } from 'react'
import './RespondToQuote.scss'
import { useForm } from 'react-hook-form'
import { RespondToQuoteType } from '../../../../../../Models/requestType' 
import { yupResolver } from '@hookform/resolvers/yup';  
import * as yup from 'yup';
import { ShowTGAlert } from '../../../../../../Helpers/ShowTGAlert';
import requestService from '../../../../../../Services/requestService';

interface propsType {
    toggleModal: ()=>void, 
    referenceId?: string | undefined,
    unitPrice?: number
}

const schema = yup.object().shape({
    deliveryFeePerUnit: yup.number().required('Delivery fee per unit Litre is required') 
  });

const RespondToQuote:FC<propsType> = ({toggleModal, referenceId, unitPrice}) => { 
    const [request, setRequest] = useState(false)
    const { showAlert } = ShowTGAlert() 
    
    const {handleSubmit, register} = useForm<RespondToQuoteType>({
        resolver: yupResolver(schema),
      })

    const submit = handleSubmit(async (data: RespondToQuoteType)=>{
        setRequest(true)
        await requestService.sendQuote(referenceId, data).then(()=>{
            setRequest(false) 
            toggleModal()
            showAlert({message: 'Quote sent successfully', show:true, title: 'Success', type: 'success'}) 
        }, error =>{ 
        setRequest(false)    
        toggleModal();  
        showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'}) 
    
       })
    })

    return (
        <div className='RespondToQuote'> 
            <div className="title">
            Fill in the details below to send a quote to the buyer
            </div>


            <form onSubmit={submit}>
                <div className='form-group mb-3 mt-4'>
                    <label htmlFor="price-per-lit">Unit price</label>
                    <input type="text" id="price-per-lit" className='form-control' value={`₦${unitPrice}`} disabled/>
                </div>
                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Delivery fee(Per unit Litre)</label>
                    <input type="text" id="price-per-lit" className='form-control' {...register('deliveryFeePerUnit')}/>
                </div>
                {/* <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Plan</label>
                    <input type="text" id="price-per-lit" className='form-control' disabled/>
                </div>   */}

                <div className='form-group mb-3'>
                    {!request && <button className='btn btn-default w-100'>Send Quote</button> }
                    {request && <button className='btn btn-default w-100 disabled'>Send Quote <span className='spinner-border spinner-border-sm'></span></button> }
                </div> 
            </form>
        </div>
    )
}

export default RespondToQuote
