import React, { FC, useState } from 'react'
import './AddUser.scss'

import customerService from '../../../../../Services/customerService';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';  
import * as yup from 'yup'; 
import { ShowTGAlert } from '../../../../../Helpers/ShowTGAlert'; 

interface propsType {
    toggleModal: ()=>void
}

 export type addCustomerFormType = { 
    firstName: string,
    lastName: string,
    msisdn: string, 
    tags?: string|undefined, 
    emailAddress?: string|undefined, 
}
  
  const schema = yup.object().shape({ 
    firstName: yup.string().required('FirstName field is required'), 
    lastName: yup.string().required('LastName field is required'), 
    msisdn: yup.string().required('Phone number field is required'),
    tags: yup.string(),
    emailAddress: yup.string(),
  });
  

const AddUser:FC<propsType> = ({toggleModal}) => { 
   const {handleSubmit, register, formState:{errors}} = useForm<addCustomerFormType>({
    resolver: yupResolver(schema),
  })

  const { showAlert } = ShowTGAlert()  
  const [request, setRequest] = useState(false) 

  const submitNewUserInformation = handleSubmit(async (data:addCustomerFormType) => { 

    await customerService.createNewCustomer(data).then(()=>{
        setRequest(false)  
        toggleModal()

        showAlert({message: 'Customer created successfully', show:true, title: 'Success', type: 'success'}) 

        setTimeout(()=>{ 
            window.location.reload()
        }, 200)

    }, (error: { response: { data: { error: string } } }) =>{ 

        setRequest(false)   
        toggleModal()  
        showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'}) 
   }) 
  }); 
    return (
        <div className='AddUser'>
            {/* <div className="row mb-3">
                <div className="col-3">
                    <div className="photo-default">
                        <i className='d-icon d-add text-white fw-bolder fs-3'></i>
                    </div>
                </div>
                <div className="col-9">
                    <div className="addPhoto">Add Photo</div>
                    <div className="addProfilePhoto">Upload profile photo</div>
                </div>
            </div> */}
             <form action='/product-scanner'>
                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">First Name</label>
                    <input type="text" id="newCustomerFirstName" className='form-control' {...register('firstName')}/>
                    {errors.firstName && <div className='text-danger'>{errors.firstName.message}</div>}
                </div>
                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Last Name</label>
                    <input type="text" id="newCustomerLastName" className='form-control' {...register('lastName')}/>
                    {errors.lastName && <div className='text-danger'>{errors.lastName.message}</div>}
                </div>
                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Msisdn </label>
                    <input type="text" id="newCustomerMsisdn" className='form-control' {...register('msisdn')} placeholder='+2348194033342'/>
                    {errors.msisdn && <div className='text-danger'>{errors.msisdn.message}</div>}
                </div>
                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Tags[optional)</label>
                    <input type="text" id="newCustomerTags" className='form-control' {...register('tags')}/>
                    {errors.tags && <div className='text-danger'>{errors.tags.message}</div>}
                </div>
                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Email(optional)</label>
                    <input type="text" id="newCustomerEmailAddress" className='form-control' {...register('emailAddress')}/>
                    {errors.emailAddress && <div className='text-danger'>{errors.emailAddress.message}</div>}
                </div>
                <div className='form-group mb-3'>
                    <button className='btn btn-default w-100' onClick={(e) => submitNewUserInformation(e)} disabled={request}>Add User {request && <span className='spinner-border spinner-border-sm'></span>}</button> 
                </div> 
            </form>
        </div>
    )
}

export default AddUser
