import './EditUsersDetails.scss'
import { BreadCrumb, HorizontalLoader, ModalWrapper } from '../../../../../Components'
import thumbnail from './../../../../../Assets/Images/thumbnail.jpeg' 
import { Link, useParams } from 'react-router-dom'
import { CustomerType } from '../../../../../Models'
import { useEffect, useState } from 'react' 
import customerService from '../../../../../Services/customerService'
import { TimeConverter } from '../../../../../Helpers/TimeConverter';
import loanService from 'Services/loanServices'
import { ShowTGAlert } from 'Helpers/ShowTGAlert'


const EditUsersDetails = () => {
    const { customerAccountId } = useParams()
    const [userData, setUserData] = useState<CustomerType>()
    const [request, setRequest] = useState(false)
    const [debitLoading, setDebitLoading] = useState(false)
    
    const getUser = async () => {
        setRequest(true)
        await customerService.getDetails(customerAccountId).then((response)=>{ 
          setUserData(response.data.data.dataset[0])
          setRequest(false)
        }, (error: { response: {data: { error:string } } }) =>{
            setRequest(false)
            console.log('orderService error', error.response)
        })
    }

    useEffect(() => {
        getUser()
    }, [])


     const [showDebitModal, setShowDebitModal] = useState(false);
 
      const toggleDebitModal = () => {
        setShowDebitModal((prevState) => !prevState);
      }; 


      const { showAlert } = ShowTGAlert() 

      const sendDebitRequest = async () => {
        setDebitLoading(true)  
        const msisdn = encodeURIComponent(userData?.msisdn ?? '');
        await loanService.vfdAllowFundDebit(msisdn).then(()=>{
        toggleDebitModal
            setDebitLoading(false)
            toggleDebitModal()
            showAlert({message: 'Debit approved succesfully', show:true, title: 'Approval Successful', type: 'success'}) 
          
        }, (error: { response: { data: { error: string } } }) =>{     
            showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'}) 
              setDebitLoading(false)
            toggleDebitModal()
        })
      }
  
    return (
        <div className='EditUsersDetails'>
            
           <div className="container">
            <BreadCrumb title={`#${customerAccountId}`} parent={'Users'} parentUrl={'users'} showTitle={false}></BreadCrumb>
                <div className="container mt-2">
                    
                    <div className="card border-0">
                        <div className="card-header border-0 px-0">
                            <div className="row">
                                <div className="col-12 col-md-8 my-auto">
                                {!request && <div className="title"> 
                                  {userData?.firstName} {userData?.lastName}
                                </div>}
                                {request && <div className="title"> 
                                  <span className='spinner-border  spinner-border-sm'></span>
                                </div>}
                                </div>
                                <div className="col-6 col-md-2"> 
                                </div>
                                <div className="col-6 col-md-2 text-md-end"> 
                                  <Link to="user/klklkl">
                                    <span className='btn btn-respond-req'>{userData?.isEnabled? 'Active': 'Inactive' }</span>
                                   </Link>
                                </div>
                            </div> 
                        </div>
                    </div>


                    <div className="row details-section">
                        <div className="col-md-12"> 
                            <div className='card border-0 mt-3 pt-4'> 
                                <div className='card-header bg-white border-0'>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-7 col-lg-8">
                                            <div className="title">
                                            Edit User Profile
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-3 col-lg-2">
                                            
                                        </div>
                                        <div className="col-3 col-sm-6 col-md-2  col-lg-2 mt-2 mt-sm-0">
                                            
                                        </div>
                                    </div> 
                                </div>
                                <div className="card-body">  
                                {request && <HorizontalLoader></HorizontalLoader>}
                                {!request && <div className="row mt-4">
                                        <div className="col-12 col-md-2 text-md-center mb-3"> 
                                            <div className="profile-image">
                                               <img src={userData?.photo.url? userData?.photo.url : thumbnail} className=''/>
                                            </div>
                                       </div>
                                        <div className="col-md-6 text-start"> 
                                         <div className='profile-item'>
                                            <div className='name'>First Name</div>
                                            <input type="text" defaultValue={userData?.firstName} className="form-control"/> 
                                         </div><br/>
                                         <div className='profile-item'>
                                            <div className='name'>Last Name</div>
                                            <input type="text" defaultValue={userData?.lastName} className="form-control"/> 
                                         </div><br/>
                                         <div className='profile-item'>
                                            <div className='name'>Email</div>
                                            <input type="email" defaultValue={userData?.emailAddress? userData?.emailAddress : '-'} className="form-control"/>  
                                         </div><br/>
                                         <div className='profile-item'>
                                            <div className='name'>Phone Number</div> 
                                            <input type="text" defaultValue={userData?.msisdn} className="form-control"/> 
                                         </div><br/>
                                         <div className='profile-item'>
                                            <div className='name'>BVN</div>
                                            <input type="text" defaultValue={''} className="form-control"/>  
                                         </div><br/>
                                         <div className='profile-item'>
                                            <div className='name'>Account Plan</div>
                                            <input type="text" defaultValue={userData?.accountPlan} className="form-control"/>  
                                         </div><br/>
                                         <div className='profile-item'>
                                            <div className='name'>PLP Ultra Local Government Area</div>
                                            <input type="text" defaultValue={userData?.plpUltraLocalGovernmentArea} className="form-control"/>  
                                         </div><br/>
                                         <div className='profile-item'>
                                            <div className='name'>PLP Ultra default order quantity</div>
                                            <input type="text" defaultValue={userData?.plpUltraDefaultQuoteRequestQuantity} className="form-control"/>  
                                         </div><br/>
                                         <div className='profile-item'>
                                            <div className='name'>Country</div>
                                            <input type="text" defaultValue={userData?.country} className="form-control"/>  
                                         </div><br/>
                                         
                                       </div> 

                                       <div className="col-md-4">  
                                          
                                       </div>
                                    </div> }  
                                </div>
                            </div> 
                        </div> 
                   
                    </div>
                </div>
            </div>
 
     
            <ModalWrapper showModal={showDebitModal} closeModal={toggleDebitModal} title="Confirmation!">
                 <small>Are you sure you want to authorize TradeGrid to debit the customer&apos;s VFD bank account?</small>
                 <div className='mt-4'>
                   <button className='btn btn-default' onClick={sendDebitRequest} disabled={debitLoading}>Continue {debitLoading && <span className='spinner-border spinner-border-sm'></span>}</button> 
                 </div>
             </ModalWrapper>
        </div>
    )
}

export default EditUsersDetails
