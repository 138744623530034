import React, { useEffect, useState } from 'react'
import './LogisticsDetails.scss'  
import { AsignedTrips, AsignTrip, Documentation, CustomizeTruck } from '../../../Components'
import { ModalWrapper } from '../../../../../Components'
import { truckTypes } from '../../../../../Models'
import logisticsService from '../../../../../Services/logisticsService'
import { useParams } from 'react-router-dom'
import { Logger } from '../../../../../Helpers/Logger'
import { ShowTGAlert } from '../../../../../Helpers/ShowTGAlert'

const LogisticsDetails = () => {

    const [tab, setTab] = useState(0) 
      
    const { truckId } = useParams<{truckId:string}>()
    const [truck, setTruck] = useState<truckTypes>();  
    const [loading, setLoading] = useState(false); 

    const getOrders = async () => {
        setLoading(true)
        await logisticsService.getOne(truckId)
          .then((response)=>{    
            setLoading(false)  
            setTruck(response?.data.data.dataset[0]) 

          }, (error: { response: object }) =>{     
            setLoading(false)
            Logger('response error', error.response) 
          })
    }

    useEffect(() => {
     getOrders()
    }, [])
    
    

    const [showStatusModal, setShowStatusModal] = useState(false);  
    const [statusRequest, setStatusRequest] = useState(false);  
    
    const toggleStatusModal = () => {
        setShowStatusModal((prevState) => !prevState);
    };

    const { showAlert } = ShowTGAlert() 

    const updateStatus = async (status:string) =>{
        setStatusRequest(true)
      await logisticsService.updateStatus(truckId, status).then(()=>{ 
         
        toggleStatusModal()
        showAlert({message: `Truck ${status} successfully`, show:true, title: 'Success', type: 'success'}) 
        
        setStatusRequest(false)
      }, error =>{
        setStatusRequest(false)
        toggleStatusModal()
        showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'}) 
        console.log('orderService error', error.response)
      })
    }

    return (
        <div className='requests'>
            <div className="container mt-4">
                <div className="container">
                    <div className="card border-0">
                        <div className="card-header border-0 px-0">
                            <div className="row">
                                <div className="col-6 col-md-6 my-auto">
                                <div className="title">
                                    {loading && <span className='spinner-border spinner-border-sm'></span>}
                                    Truck - {truck?.truckPlateNumber}
                                </div>
                                </div>
                                <div className="col-6 col-md-6 text-end">
                                   {truck?.status === 'PENDING_APPROVAL' && <button className='btn btn-add-company' onClick={()=>setShowStatusModal(true)}>Approve truck</button>}
                                   {truck?.status !== 'PENDING_APPROVAL' && <button className='btn btn-add-company bg-white text-danger' onClick={()=>setShowStatusModal(true)}>Disapprove truck</button>}
                                </div>
                            </div>
 

                            <div className="mt-2 bg-white">  
                                    <div className="head-tab"> 
                                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={()=>setTab(0)}>Assign a Trip</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false"onClick={()=>setTab(1)}>Assigned Trips</button>
                                            </li> 
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="pills-documentation-tab" data-bs-toggle="pill" data-bs-target="#pills-documentation" type="button" role="tab" aria-controls="pills-documentation" aria-selected="false"onClick={()=>setTab(2)}>Documentation</button>
                                            </li> 
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="pills-customize-tab" data-bs-toggle="pill" data-bs-target="#pills-customize" type="button" role="tab" aria-controls="pills-customize" aria-selected="false"onClick={()=>setTab(3)}>Customize Truck</button>
                                            </li>  
                                        </ul>
                                    </div>   
                            </div>
 
                        </div>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                              {tab ==0 && <AsignTrip></AsignTrip>}                 
                            </div>
                            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                {tab ==1 && <AsignedTrips></AsignedTrips>}
                            </div>
                            <div className="tab-pane fade" id="pills-documentation" role="tabpanel" aria-labelledby="pills-documentation-tab">
                                {tab ==2 && <Documentation></Documentation>}
                            </div>
                            <div className="tab-pane fade" id="pills-customize" role="tabpanel" aria-labelledby="pills-prcustomizeofile-tab">
                                {tab ==3 && <CustomizeTruck truck={truck}></CustomizeTruck>}
                            </div>
                        </div> 
                    </div> 
                </div> 
            </div>

 
            <ModalWrapper showModal={showStatusModal} closeModal={toggleStatusModal} title={truck?.status === 'PENDING_APPROVAL'? 'Approve Truck':'Disapprove Truck'}>
                <p>Are you sure you want to continue?</p> 
                <button className='btn btn-order-traking-confirm' onClick={()=>updateStatus(truck?.status === 'PENDING_APPROVAL'? 'approve':'reject' )} disabled={statusRequest}>Continue {statusRequest && <span className='spinner-border spinner-border-sm'></span>}</button>
            </ModalWrapper>
        </div>
    )
}

export default LogisticsDetails
