import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import './Tag.scss'
import smsService from 'Services/smsService' 
import Swal from 'sweetalert2'

interface tagType { 
    id: number,
    groupName: string,
    phoneNumbers: Array<string>,
    createdAt: string,
} 
  
interface propsType {
    closeModal: ()=>void,
    phones: string|undefined,
    setPhones: (val:string)=>void
}
const Tag:FC<propsType> = ({closeModal, phones, setPhones}) => {

    const [tags, setTag] = useState<Array<tagType>>()
    const [inputTag, setInputTag] = useState<string>()
    const [phoneError, setPhoneError] = useState<boolean>(false)
    const [groupNameError, setGroupNameError] = useState<boolean>(false)
    
    const [toggleResult, setToggleResult] = useState(false)
    const [phoneCount, setPhoneCount] = useState(0)
    
    const [searchResults, setSearchResults] = useState<tagType[] | undefined>();

    const searchTag = (tag:ChangeEvent<HTMLInputElement>) => {
        setInputTag(tag.target.value) 
        searchStringInArray(tag.target.value) 
    }
 
    function searchStringInArray(searchString: string) { 

        const filteredResults = tags?.filter(item =>
            item.groupName.toLowerCase().includes(searchString.toLowerCase())
          );

        setSearchResults(filteredResults)
    }
   
     
    const getTag = async () => {
     await smsService.index().then((response)=>{
        console.log(response.data.data)
        setTag(response.data.data)
        setToggleResult(true)
     })
    }

    useEffect(()=>{
        getTag()
    }, [])

    const chooseTag = (tag: tagType) =>{  
       getGroup(tag.id)
       setInputTag(tag.groupName) 
       setToggleResult(false) 
    }
 
    const [request, setRequest] = useState(false)
    
    const getGroup = async (groupId: number) =>{

        setRequest(true) 
        await smsService.getGroup(groupId)
        .then((response)=>{ 
            console.log('response', response.data.data.phoneNumbers)

            const commaSeparatedNumbers = response.data.data.phoneNumbers.join(', ');
            setPhones(commaSeparatedNumbers)
            setToggleResult(!toggleResult)
            setRequest(false)   

            Swal.fire({
                title: 'Completed!',
                text: 'Contacts loaded succesfully',
                icon: 'success',
                confirmButtonText: 'Ok',
              });  

        }, error => { 
            setRequest(false)  
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: { 
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
                });

                swalWithBootstrapButtons.fire({
                    title: 'Oops!',
                    text: error.response.data.error,
                    icon: 'warning',
                    showCancelButton: true, 
                    showConfirmButton: false, 
                    cancelButtonText: 'close',
                    reverseButtons: true
                });
        })
    }


    const addGroup = async () => {

        if(!phones || !inputTag){
            setPhoneError(true)
            setGroupNameError(true)
            return 
        } 

        setPhoneError(false)
        setGroupNameError(false)


        const filteredResults = tags?.filter((item:tagType) => 
             item.groupName.toLowerCase()===inputTag.toLowerCase()
         );

          if(!filteredResults){
  
                await smsService.addGroup({groupName: inputTag, phoneNumbers: phones?.split(', ')}).then((response)=>{ 
                    setRequest(false)   
                    Swal.fire({
                        title: 'Success!',
                        text: 'New tag has been added succesfully',
                        icon: 'success',
                        confirmButtonText: 'Ok',
                    });  
                    closeModal()
                }, (error: { response: { data: { error: any } } }) => { 
                    setRequest(false)  
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: { 
                            cancelButton: 'btn btn-danger'
                        },
                        buttonsStyling: false
                        });

                        swalWithBootstrapButtons.fire({
                            title: 'Oops!',
                            text: error.response.data.error,
                            icon: 'warning',
                            showCancelButton: true, 
                            showConfirmButton: false, 
                            cancelButtonText: 'close',
                            reverseButtons: true
                        });
                })
            }else{
                console.log('filteredResults', filteredResults[0].id)
                await smsService.updateGroup({groupId: filteredResults[0].id, phoneNumbers: phones?.split(', ')}).then((response)=>{ 
                    setRequest(false)   
                    Swal.fire({
                        title: 'Success!',
                        text: 'Tag has been updated succesfully',
                        icon: 'success',
                        confirmButtonText: 'Ok',
                    });  
                    closeModal()
                }, (error: { response: { data: { error: any } } }) => { 
                    setRequest(false)  
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: { 
                            cancelButton: 'btn btn-danger'
                        },
                        buttonsStyling: false
                        });

                        swalWithBootstrapButtons.fire({
                            title: 'Oops!',
                            text: error.response.data.error,
                            icon: 'warning',
                            showCancelButton: true, 
                            showConfirmButton: false, 
                            cancelButtonText: 'close',
                            reverseButtons: true
                        });
                })
            }
        }

    useEffect(()=>{
        const numbersArray = phones?.split(',');

        // Count the number of elements in the array
        const count = numbersArray? numbersArray.length : 0;
        setPhoneCount(count)
    }, [phones])
 
   const [updating, setUpdating] = useState(false)

    useEffect(()=>{ 

        // check if the tag is to be updated or created
        if(inputTag){
            const filteredResults = tags?.filter((item:tagType) => 
               item.groupName.toLowerCase()===inputTag.toLowerCase());

            if(filteredResults && filteredResults.length >0){ 
                setUpdating(true)
            }else{ 
                setUpdating(false)
            }
        }
    }, [inputTag])

    return (
        <div className='Tag'>
            <form> 
                <div className='form-group'> 
                    <div className="row">
                        <div className="col-md-10"> 
                        <input type="text" className={groupNameError? 'form-control is-invalid':'form-control'} value={inputTag} placeholder='Search for tag' onChange={searchTag}/> 
                            {toggleResult && <div className="searchAutoComplete shadow">
                                <ul className="list-group"> 
                                    {!searchResults && tags && tags.map((tag:tagType, index:React.Key)=><li key={index} className="list-group-item" onClick={()=>chooseTag(tag)}>{tag.groupName}</li>)} 
                                    {searchResults && searchResults.map((tag:tagType, index:React.Key)=><li key={index} className="list-group-item" onClick={()=>chooseTag(tag)}>{tag.groupName}</li>)} 
                                </ul> 
                            </div>}
                        </div>
                        <div className="col-md-2 my-auto">
                            <button className='btn btn-dark' type="button" onClick={()=>setToggleResult(!toggleResult)}><i className={`d-icon ${toggleResult? 'd-arrowhead-up' :'d-arrowhead-down'}`}></i></button>
                        </div>
                    </div>
                </div>


                <div className="form-group mt-4">
                    <label>MSSIDN separated by commas e.g +23481432,+43481432 </label>
                    <textarea  className={phoneError? 'form-control is-invalid':'form-control'} onChange={(e)=>setPhones(e.target.value)} value={phones}></textarea> 
                    {phoneError && <span className='text-danger msisdn-error'>Add MSISDN to group</span>}
                </div>
                <div className='numOfContact'>Number of contacts: {phoneCount}</div>

                <div className="form-group mt-4"> 
                    <div className="row">
                        <div className="col-7 text-center p-0"> 
                            <button className='btn btn-dark fs-6' type='button' onClick={addGroup}> 
                                {request && <span className='spinner-border spinner-border-sm'></span>}
                                {updating? 'Update': 'Save tag'} & Import Contacts
                            </button>
                        </div>
                        <div className="col-5 text-center p-0"> 
                            <button className="btn btn-default text-dark fs-6" type='button' onClick={closeModal}>
                                Import Contacts
                            </button>
                        </div>
                    </div>
  
                </div>
            </form>
        </div>
    )
}

export default Tag
