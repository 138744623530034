import React, { ChangeEvent, Fragment, useEffect, useState } from 'react'
import './BulkSms.scss'
import { useForm } from 'react-hook-form'
import { ModalWrapper } from 'Components';
import { Tag, CsvFile, PreviewMessage, TestMessage } from '../../Components'; 
import smsService from 'Services/smsService';
import Swal from 'sweetalert2'; 
import { Link } from 'react-router-dom';


type formType = {
    phoneNumbers: string,
    message: string
}
const BulkSms = () => {
    const [phones, setPhones] = useState<string>()
    const [messageContent, setMessageContent] = useState<string>()
    const [messageWordCount, setMessageWordCount] = useState<number>(0)
    const [messagePageCount, setMessagePageCount] = useState<number>(0)
    const [phoneCount, setPhoneCount] = useState(0)
    
    const [showCsvPhoneModal, setShowCsvPhoneModal] = useState(false);  

    const toggleCsvPhoneModal = () => {
       setShowCsvPhoneModal((prevState:boolean) => !prevState);
    };

    const [showTagModal, setShowTagModal] = useState(false); 
 
    const toggleTagModal = () => {
       setShowTagModal((prevState:boolean) => !prevState);
    };

    const [showPreviewModal, setShowPreviewModal] = useState(false); 
 
    const togglePreviewModal = () => {
        setShowPreviewModal((prevState:boolean) => !prevState);
    };

    const [showTestModal, setShowTestModal] = useState(false); 
 
    const toggleTestModal = () => {
        setShowTestModal((prevState:boolean) => !prevState);
    };

    const messageContentElement = (e:ChangeEvent<HTMLTextAreaElement>) => {
        const text = e.target.value;
        const wordTotal = text.length;
        setMessageContent(text)
        setMessageWordCount(wordTotal)
        setMessagePageCount(Math.max(Math.ceil(wordTotal/160), 1));
    }

    const [request, setRequest] = useState(false)
    const { register, reset, handleSubmit, formState: {errors} } = useForm<formType>();

    const submit = handleSubmit(async (data:formType)=>{  
        setRequest(true) 
 
        const newPhone = phones?.split(',').map((data)=>{
            // remove leading space from each number
            return formatPhoneNumber(data.replace(/\s/g, ''))
        })  

        await smsService.send({...data, phoneNumbers: newPhone})
        .then(()=>{ 

            setRequest(false)  
            reset() 
            setPhones('') 

            Swal.fire({
                title: 'Completed!',
                text: 'Your sms has been sent succesfully',
                icon: 'success',
                confirmButtonText: 'Ok',
              });
  
        }, error => { 
            setRequest(false)  
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: { 
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
                });

                swalWithBootstrapButtons.fire({
                title: 'Oops!',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: true, 
                showConfirmButton: false, 
                cancelButtonText: 'close',
                reverseButtons: true
                });
        })
    })

    const extractedNumbers = (extractedNumbers:string[]) =>{
        
        const commaSeparatedNumbers = extractedNumbers.join(', ');

        console.log('commaSeparatedNumbers', commaSeparatedNumbers)
        setPhones(commaSeparatedNumbers)
    }

    useEffect(()=>{
        const numbersArray = phones?.split(',');

        // Count the number of elements in the array
        const count = numbersArray? numbersArray.length : 0;
        setPhoneCount(count) 
    }, [phones])

  
    function formatPhoneNumber(phoneNumber: string) {
        // Check if the phone number starts with '0'
        if (phoneNumber.startsWith('0')) {
          // Remove the leading '0' and add '+234' at the beginning
          phoneNumber = '+234' + phoneNumber.slice(1);
        } else if (phoneNumber.startsWith('+')) {
          // If the phone number starts with '+', it's already formatted, do nothing
          return phoneNumber;
        } else if (phoneNumber.startsWith('234')) {
          // If the phone number starts with '234', it's already formatted, do nothing
          return '+' + phoneNumber;
        } else {
          // Otherwise, add the country code '+234' at the beginning
          phoneNumber = '+234' + phoneNumber;
        }
        
        return phoneNumber;
      }

    return (
        <div className='BulkSms mb-4'>
             <div className="container mt-4">
                <div className="card border-0">
                    <div className="card-header border-0">
                        <div className="row">
                            <div className="col-12 col-md-8 my-auto">
                                <div className="title">
                                   Send Bulk SMS
                                </div>
                            </div>
                              <Fragment>  
                                <div className="col-6 col-md-4  text-md-end"> 
                                    <Link to={'/bulksms-history'}>
                                        <button className='btn btn-respond-req'>
                                            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.5 10.0625H4.5C4.1925 10.0625 3.9375 9.8075 3.9375 9.5C3.9375 9.1925 4.1925 8.9375 4.5 8.9375H13.5C13.8075 8.9375 14.0625 9.1925 14.0625 9.5C14.0625 9.8075 13.8075 10.0625 13.5 10.0625Z" fill="white"/>
                                                <path d="M9 14.5625C8.6925 14.5625 8.4375 14.3075 8.4375 14V5C8.4375 4.6925 8.6925 4.4375 9 4.4375C9.3075 4.4375 9.5625 4.6925 9.5625 5V14C9.5625 14.3075 9.3075 14.5625 9 14.5625Z" fill="white"/>
                                            </svg> 
                                            SMS History
                                        </button>
                                    </Link>
                                </div>
                            </Fragment>
                        </div> 
                    </div>
                </div>
            </div>
            <div className='container m-3'> 
                <div className="row w-100">
                    <div className="col-12 col-lg-8"> 
                        <form onSubmit={submit}>

                            <div className="form-group radio-list">
                                <div>Mode of upload:</div>
                                <label htmlFor="manual" className='mt-2'>
                                    <input type="radio" id="manual" name="numberMode" />
                                    Enter phone numbers manually
                                </label>
                                <label htmlFor="tag" className='mt-2'>
                                    <input type="radio" id="tag" name="numberMode" onClick={toggleTagModal}/>
                                    Use an existing tag
                                </label>

                                <label htmlFor="csvFile" className='mt-2'>
                                    <input type="radio" id="csvFile" name="numberMode" onClick={toggleCsvPhoneModal}/>
                                    Upload a CSV file
                                </label> 
                            </div>


                            <div className="form-group">
                                <label>Enter MSSIDN separated by commas e.g +23481432,+43481432 </label>
                                <textarea className="form-control" {...register('phoneNumbers', { required: 'MSSIDN number field required'})} onChange={(e)=>setPhones(e.target.value)} value={phones}></textarea> 
                                {errors.phoneNumbers && <span className='text-danger'>{errors.phoneNumbers.message}</span>}
                            </div>
                            <div className="form-group mt-2">
                                <div className="row">
                                    <div className="col-md-7">
                                        <label htmlFor="uAddTag"> 
                                            <input className='me-2' type="checkbox" id="uAddTag"  onClick={toggleTagModal}></input>
                                            Update/Add recipients to tags
                                        </label>
                                    </div>
                                    <div className="col-md-5 text-end">
                                        <div className="recipent">
                                        {phoneCount} recipients
                                        </div>
                                    </div> 
                                </div>
                            </div>
                            <div className="form-group mt-4"> 
                                    <div className='row'>
                                        <div className="col-md-7">
                                            <label htmlFor=''>
                                                Body of the message  
                                            </label>
                                        </div>
                                        <div className="col-md-5 text-end">
                                            <div className="preview" onClick={togglePreviewModal}>
                                               <i className='d-icon d-eye-open pt-1'></i> Text Preview
                                            </div>
                                        </div>
                                    </div>
                                <textarea className="form-control" {...register('message', { required: 'Message field required'})} onChange={(e)=>messageContentElement(e)}></textarea>
                                {errors.message && <span className='text-danger'>{errors.message.message}</span>}

                                <div className="text-center character-remaining">{messageWordCount} characters remaining | {messagePageCount} page</div>
                            </div>


                            <div className="form-group my-3">
                                <div className="text-start">
                                    <label htmlFor='testMessage'>
                                        <input type="checkbox" className="me-2" id="testMessage" onChange={toggleTestModal}/>
                                        Click here to send a test sms.
                                    </label>
                                </div>
                            </div>


                            <div className="form-group">
                                <button className="btn btn-default text-dark">
                                    {request && <span className='spinner-border spinner-border-sm'></span>}
                                    Send SMS
                                </button>
                            </div>
                        </form> 
                    </div>
                </div>
            </div> 
          

            <ModalWrapper showModal={showCsvPhoneModal} closeModal={toggleCsvPhoneModal} title="Upload CSV file">
             <CsvFile extractedNumbers={(data: string[])=>extractedNumbers(data)} closeModal={toggleCsvPhoneModal}></CsvFile>
            </ModalWrapper>

            <ModalWrapper showModal={showTagModal} closeModal={toggleTagModal} title="Tags">
             <Tag closeModal={toggleTagModal} phones={phones} setPhones={(value)=>setPhones(value)}></Tag>
            </ModalWrapper>

            <ModalWrapper showModal={showPreviewModal} closeModal={togglePreviewModal} title="Preview Message">
              <PreviewMessage messageContent={messageContent}></PreviewMessage>
            </ModalWrapper>

            <ModalWrapper showModal={showTestModal} closeModal={toggleTestModal} title="Test Message">
              <TestMessage messageContent={messageContent} closeModal={toggleTestModal}></TestMessage>
            </ModalWrapper>
        </div>
    )
}

export default BulkSms
