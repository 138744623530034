
import React, { FC, Key, useEffect, useState } from 'react' 
import { CustomerType } from 'Models' 
import { ShowTGAlert } from 'Helpers/ShowTGAlert'
import { yupResolver } from '@hookform/resolvers/yup';  
import * as yup from 'yup'; 
import { useForm } from 'react-hook-form'; 
import { urlencodePhoneNumber } from 'Helpers';
import loanService from 'Services/loanServices';
import customerService from 'Services/customerService';

interface propsType {
    toggleLoanProviderModal: ()=>void, 
    userData?: CustomerType
}


export type AssignProviderFormType = {   
    provider: string,
    unassign?:  undefined|string,
    isSecondary?: string
}
 

const schema = yup.object().shape({   
    provider: yup.string().required(),
    unassign: yup.string().optional(),
    isSecondary: yup.string().optional()
});


const LoanProviderModal:FC<propsType> = ({toggleLoanProviderModal, userData}) => {  
    
    const {handleSubmit, register, formState:{errors}} = useForm<AssignProviderFormType>({
        resolver: yupResolver(schema),
      })
    
      const { showAlert } = ShowTGAlert()  
      const [request, setRequest] = useState(false) 
      const [requestLp, setRequestLP] = useState(false) 
      const [providers, setProviders] = useState<Array<string>>([])
    
      const submitNewUserInformation = handleSubmit(async (data:AssignProviderFormType) => { 
        setRequest(true)  
        const encodedPhone = urlencodePhoneNumber(userData?.msisdn); 
        
        let endpoint;
        let message:string;
        console.log('data.unassign')
        console.log(data.unassign)

        if(data.provider && data.provider !=='--option--' && data.unassign ==='false'){
            message = 'Loan provider assigned successfully'
            endpoint = loanService.asignLoanProvider(encodedPhone, data.provider, data.isSecondary)
        } else {
            message =  'Loan provider unassigned successfully'
            endpoint = loanService.unAsignLoanProvider(encodedPhone)
        }

        await endpoint.then(()=>{
            setRequest(false)  
            toggleLoanProviderModal()
    
            showAlert({message, show:true, title: 'Success', type: 'success'}) 
     
    
        }, (error: { response: { data: { error: string } } }) =>{ 
    
            setRequest(false)   
            toggleLoanProviderModal()  
            showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'}) 
       }) 
      }); 



    const [userLp, setUserLp] = useState<{
        accountLoanProvider : string,
        isDefault :  boolean
    }[]>()
 

    const getUserlp = async (msisdn: string) => { 
        await customerService.getUserLPs(msisdn).then((response)=>{  
          console.log('getUser getUserLPs 00', response.data.data)
          setUserLp(response.data.data) 
        }, (error: { response: {data: { error:string } } }) =>{ 
            console.log('orderService error', error.response)
        })
    }




    useEffect(() => {
        if(userData)
        getUserlp(userData.msisdn)
    }, [userData])

    
      const getLoanProviders = async() => {
        setRequestLP(true)
        await loanService.loanProvider().then((response)=>{ 
            setProviders(response.data.data)
            console.log('getLoanProviders', response.data.data)
            setRequestLP(false)
        })
      }

      useEffect(()=>{
        getLoanProviders()
      }, [])



      const [newProviders, setNewProviders] = useState<{provider: string, isAvailable: boolean }[]>();

      useEffect(() => {
          if (providers && userLp) {
              // Create a map of providers from userLp for quick lookup
              const userLpProviders = new Set(userLp.map(lp => lp.accountLoanProvider));
              
              // Map through providers and add the availability indicator
              const updatedIndicators = providers.map(provider => ({
                  provider,
                  isAvailable: userLpProviders.has(provider)
              }));
              
              setNewProviders(updatedIndicators);
          }
      }, [providers, userLp]);
     

        return (
            <div className='AddUser'> 
               <form onSubmit={submitNewUserInformation}> 
                    <div className='form-group mb-3'>
                        <label htmlFor="price-per-lit">Loan Providers</label>{requestLp && <span className='spinner-border spinner-border-sm'></span>}
                        <select  id="enableOrDisable" className='form-control' {...register('provider')}>
                            <option>--option--</option>
                            {newProviders && newProviders.length > 0 && newProviders.map((res: {
                                    provider: string;
                                    isAvailable: boolean;
                                }, index:Key)=><option key={index} disabled={res.isAvailable}>{res.provider}</option>)}
                        </select>
                        {errors.provider && <div className='text-danger'>{errors.provider.message}</div>}
                    </div>

                    <div className='form-group mb-1'>
                        <label htmlFor="price-per-lit">Unassign Loan Providers? </label>
                        <input type="checkbox" value="unassigned" id="unassign" className='ms-3' {...register('unassign')}/> 
                        {errors.unassign && <div className='text-danger'>{errors.unassign.message}</div>}
                    </div> 
 
                    <div className='form-group mb-3'>
                        <label htmlFor="price-per-lit">Primary provider</label>
                        <input type="checkbox" value="1" id="isSecondary" className='ms-3' {...register('isSecondary')}/> 
                        {errors.isSecondary && <div className='text-danger'>{errors.isSecondary.message}</div>}
                    </div>  
    
                    <div className='form-group mb-3'>
                        <button type="submit" className='btn btn-default w-100' disabled={request}>Assign {request && <span className='spinner-border spinner-border-sm'></span>}</button> 
                    </div> 
                </form>
            </div>);
}

export default LoanProviderModal
