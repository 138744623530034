import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface AuthState {
  token: string | null;
  isAuthenticated: boolean | null;
  user: any | null;
}

const initialState: AuthState = {
  // token: localStorage.getItem('token'),
  token: typeof window !== 'undefined' ? localStorage.getItem('token') : null,
  isAuthenticated: null,
  // check for use in local storage or set to null
  // user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '{}') : null,
  user: typeof window !== 'undefined' ? localStorage.getItem('ko_user') ? JSON.parse(localStorage.getItem('ko_user') || '{}') : null : null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action:PayloadAction<AuthState['user']>) => {
      console.log('login action', action.payload)
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('ko_user', JSON.stringify(action.payload.user));
      state.token = action.payload.token;
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },
    logout: (state) => {
      localStorage.removeItem('token');
      localStorage.removeItem('ko_user');
      state.token = null;
      state.isAuthenticated = false;
      state.user = null;
    },
    // update only user profile info
    updateUser: (state, action:PayloadAction<AuthState['user']>) => {
      localStorage.setItem('ko_user', JSON.stringify(action.payload));
      state.user = action.payload;
    }
  },
});


export const { login, logout, updateUser } = authSlice.actions;

export const selectToken = (state: { auth: AuthState }) => state.auth.token;
export const selectIsAuthenticated = (state: { auth: AuthState }) => state.auth.isAuthenticated;
export const selectUser = (state: { auth: AuthState }) => state.auth.user;

export default authSlice.reducer;