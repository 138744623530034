
import { formatDistanceToNow, isAfter, subDays, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export function FormatTimeAgo(utcTimestamp: string | number | Date ) {
  const currentTime = new Date();
  const utcDate = new Date(utcTimestamp);
  
  const zonedUtcDate = utcToZonedTime(utcDate, 'UTC');

  if (isAfter(zonedUtcDate, subDays(currentTime, 5))) {
    // Less than 5 days, use time ago format
    return formatDistanceToNow(zonedUtcDate, { addSuffix: true });
  } else {
    // More than 5 days, display date and time
    return format(zonedUtcDate, 'MMM dd, yyyy HH:mm');
  }
}
 

 
