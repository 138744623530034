import React, { Key, useEffect, useState } from 'react'
import './RecentOrders.scss'
import orderService from 'Services/orderService'
import { Logger } from 'Helpers/Logger'
import { HorizontalLoader } from 'Components'
import { TimeConverter } from 'Helpers/TimeConverter'
import { OrderType } from 'Models/orderType'
import { CurrencyFormatter } from 'Helpers'
import { Link } from 'react-router-dom'

const RecentOrders = () => { 
   
    
    const [order, setOrder] = useState([]); 
    const [loading, setLoading] = useState(false);

    
    const getOrders = async () => {
        setLoading(true)
        await orderService.index(1, 'DESC', 'ALL')
          .then((response)=>{    
            setLoading(false)
            setOrder(response?.data.data.dataset)
            console.log('response', response) 

          }, (error: { response: object }) =>{     
            setLoading(false)
            Logger('response error', error.response) 
          })
    }

    useEffect(() => {
     getOrders()
    }, [])

    return (
        <div className='recentOrders'> 
            <div className="card">
                <div className="card-header bg-white border-0">
                    Recent Orders
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr> 
                                    <td>Order ID</td>
                                    <td>Customer</td>
                                    <td>Product</td>
                                    <td>Amount</td>
                                    <td>Status</td> 
                                    <td>Date</td> 
                                </tr>
                            </thead>
                            <tbody>
                              {order.length > 0 && order.map((data: OrderType, index:Key)=><tr key={index}>
                                    <td>#{data.referenceNumber}</td>
                                    <td>{data.quoteRequest.customer.firstName} {data.quoteRequest.customer.lastName}</td>
                                    <td> {data.product.fullName} </td>
                                    <td>₦{CurrencyFormatter(Number(data.quoteRequest.productPrice.unitSellingPrice) * Number(data.quantity))}</td>
                                    <td><span className='status'>{data.status}</span></td> 
                                    <td>{TimeConverter(data.createdAtUtc)}</td>  
                                </tr>)} 

                                {loading === false && order.length === 0 &&<tr>
                                    <td colSpan={8} className='text-center'>No order found</td>
                                </tr>}
                                {loading===true && order.length === 0 && <tr>
                                    <td colSpan={8} className='text-center'><HorizontalLoader></HorizontalLoader></td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-footer py-1 text-end bg-white">
                    <Link to="/orders" className='view-all'>View All</Link>
                </div>
             </div>
        </div>
    )
}

export default RecentOrders
