import React, { Key, useEffect, useState } from 'react'
import './AsignedTrips.scss' 
import { TimeConverter } from '../../../../../Helpers/TimeConverter';
import { HorizontalLoader } from '../../../../../Components';
import { Link, useParams } from 'react-router-dom'; 
import { Logger } from '../../../../../Helpers/Logger';
import logisticsService from '../../../../../Services/logisticsService'; 
import { truckOrderType } from '../../../../../Models/truckTypes';
 
const AsignedTrips = () => {  
    const { truckId } = useParams<{truckId:string}>() 
    const [orders, setOrders] = useState<Array<truckOrderType>>(); 
    const [loading, setLoading] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [pageNumber, setPageNumber] = useState<number>(1) 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [sortOrder, setSortOrder] = useState('DESC') 
   
  
    const getOrdersList = async () => {
        setLoading(true)
        await logisticsService.getOrders(truckId, pageNumber, sortOrder)
          .then((response)=>{    
            setLoading(false) 
            console.log('truck order response', response?.data.data.dataset)
            setOrders(response?.data.data.dataset) 

          }, (error: { response: object }) =>{     
            setLoading(false)
            Logger('truck order error', error.response) 
          })
    }

    useEffect(() => {
        getOrdersList()
    }, [])

    return (
        <div className='AsignedTrips'>
            <div className="card-body">
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr> 
                                <td>Order No.</td>
                                <td>Product</td>
                                <td>Volume</td>
                                <td>Destinations</td>
                                <td>Status</td>
                                <td>Date</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {orders && orders?.length > 0 && orders?.map((data: truckOrderType, index:Key)=><tr key={index}>
                                <td>#{data.referenceNumber}</td>
                                <td>{data.product.shortName}</td>
                                <td>{data.quantity} litrs</td>
                                <td>{data.quoteRequest.deliveryLocation?.address}, {data.quoteRequest.deliveryLocation?.state}</td>
                                <td><span className='status'>{data.status}</span></td>
                                <td>{TimeConverter(data.createdAtUtc)}</td> 
                                <td className='dropdown option-dropdown'>
                                    <button className='btn border-0 dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false" data-bs-popper-config='{"strategy":"fixed"}'>
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.49991 8.4705C7.49991 7.72933 8.0955 7.14697 8.82344 7.14697C9.55138 7.14697 10.147 7.74256 10.147 8.4705C10.147 9.19844 9.55138 9.79403 8.82344 9.79403C8.0955 9.79403 7.49991 9.21168 7.49991 8.4705Z" fill="#707070"/>
                                            <path d="M7.49991 14.1175C7.49991 13.3763 8.0955 12.7939 8.82344 12.7939C9.55138 12.7939 10.147 13.3895 10.147 14.1175C10.147 14.8454 9.55138 15.441 8.82344 15.441C8.0955 15.441 7.49991 14.8587 7.49991 14.1175Z" fill="#707070"/>
                                            <path d="M7.49991 2.82353C7.49991 2.08235 8.0955 1.5 8.82344 1.5C9.55138 1.5 10.147 2.09559 10.147 2.82353C10.147 3.55147 9.55138 4.14706 8.82344 4.14706C8.0955 4.14706 7.49991 3.56471 7.49991 2.82353Z" fill="#707070"/>
                                        </svg>

                                    </button>

                                    <ul className="dropdown-menu dropdown-menu-end export-dropdown border-0 shadow">
                                        <li className='dropdown-item'><Link to={`/logistic/truck/details/${data.referenceNumber}`}>Trip Details</Link></li> 
                                        <li className='dropdown-item'>Respond to Request</li> 
                                        <li className='dropdown-item text-danger'>Decline Request </li> 
                                    </ul> 
                                </td>
                                </tr>)}   

                                {loading === false && orders?.length === 0 &&<tr>
                                    <td colSpan={8} className='text-center'>No order found</td>
                                </tr>}
                                {loading===true && <tr>
                                    <td colSpan={8} className='text-center'><HorizontalLoader></HorizontalLoader></td>
                                </tr>}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default AsignedTrips
