export function TableSerialNumber(pageNumber: number, pageSize: number, index:number) {
    const start = (pageNumber - 1) * pageSize + 1;
    const end = pageNumber * pageSize;
    const multiples = [];

    for (let i = start; i <= end; i++) {
      multiples.push(i);
    }
  
    return multiples[index];
   
  }