import React, { Key, useEffect, useState } from 'react'
import './LoanProvidersDetails.scss'
import { Link, useNavigate, useParams } from 'react-router-dom'
import loanService from 'Services/loanServices';
import { Logger } from 'Helpers/Logger';
import { LoanProviderTransactionType } from 'Models/loanProviderTransactionType';
import { HorizontalLoader } from 'Components'; 
import { TimeConverter } from '../../../../../Helpers/TimeConverter'; 
import { ShowTGAlert } from 'Helpers/ShowTGAlert';
import { CustomerType } from 'Models'; 
import { CurrencyFormatter } from 'Helpers';
import { useAuth } from 'Components/AuthProvider/AuthProvider';
import { posTransactionType } from 'Models/posTransactionType';

interface balanceType { 
    loanProvide: string,
    totalLoanDisbursedMajor: number,
    portfolioAmountMajor: number ,
    cycleCount: number,
}
const LoanProvidersDetails = () => {

    const { loanProvider } = useParams<{loanProvider: string}>();
    const [transaction, setTransaction] = useState<Array<LoanProviderTransactionType>>()
    const [transactionRequest, setTransactionRequest] = useState(false)  
    const [pageNumber, ] = useState<number>(1)
    const { showAlert } = ShowTGAlert()  
    const { user } = useAuth()
    const navigate = useNavigate()

    const [sortOrder,] = useState('DESC')  
    const [customers, setCustomers] = useState([]); 
    const [loading, setLoading] = useState(false);   

    const [totalBalance, setTotalBalance] = useState<balanceType>();
    const [balanceLoading, setBalanceLoading] = useState(true);  

    
    const getTransaction = async () => {
        setTransactionRequest(true)
        await loanService.loanProvidersTransaction(pageNumber, loanProvider).then((response)=>{
            setTransaction(response.data.data.dataset) 
            setTransactionRequest(false)
        }, (error: { response: { data:{ error: string }}})=>{
            setTransactionRequest(false)
            Logger(error)
        })
    }

    useEffect(()=>{
        getTransaction()
    }, [])
 
    const getUsers = async () => {
        setLoading(true)
        await loanService.loanProvidersCustomers(pageNumber, loanProvider)
          .then((response: { data: { data: { dataset: React.SetStateAction<never[]>; pageNumber: number; pageSize: number; total: number } } })=>{    
            setLoading(false)
            setCustomers(response?.data.data.dataset)  

          }, (error: { response: { data: { error: string } } }) =>{     
            showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'}) 

            setLoading(false)
    
          })
    }

    useEffect(() => {
        getUsers()
    }, [pageNumber, sortOrder, loanProvider])
    
     
    const getLoanTotalBalance = async () => {
        setBalanceLoading(true)
        await loanService.loanProvidersCounterBalance(loanProvider).then((response:{data:{data:balanceType}})=> { 
           console.log('loanProvidersCounterBalance', response.data.data)
           setTotalBalance(response.data.data)
           setBalanceLoading(false)
        }, (error: { response: { data: { error: string } } }) =>{     
            showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'}) 

            setBalanceLoading(false)
            setLoading(false)
    
        })
    }

    useEffect(() => {
        getLoanTotalBalance()
    }, [])

    useEffect(() => {
        if(user.user.loanProvider !== loanProvider && user.loanProviderAdminRole === true){
           navigate('/', {replace: true})
        } 
    }, [loanProvider])



    const [transactionType, setTransactionType] = useState('bank');
    const [startDate, setStartDate] = useState<string | undefined>();
    const [endDate, setEndDate] = useState<string | undefined>();

    const [posTransaction, setPosTransaction] = useState<Array<posTransactionType>>()

    useEffect(() => {
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().slice(0, 19).replace('T', ' ');
        setEndDate(formattedDate)
       
       // calculate ten days ago
        const tenDaysAgoDate = new Date(currentDate);
        tenDaysAgoDate.setDate(currentDate.getDate() - 510);

        const formattedNewDate = tenDaysAgoDate.toISOString().slice(0, 19).replace('T', ' ');
        setStartDate(formattedNewDate);

      }, []);


    const getPosTransaction = async () => {
        setLoading(true)
 
         // startDate=2022-07-28 00:00:00&endDate=2023-12-20 00:00:00
        await loanService.loanProvidersAllPOSTransaction(loanProvider?.toLowerCase(), pageNumber, startDate, endDate, transactionType)
          .then((response)=>{    
            setLoading(false)

            if(response?.data.data.dataset.length > 0){
               setPosTransaction(response?.data.data.dataset)
 
            }

            console.log('loanProvidersPOSTransaction response ===', response)
   
          }, (error: { response: object }) =>{     
            setLoading(false) 
            Logger('response error 00', error.response) 
          })
    }

    useEffect(() => {
     if(startDate && endDate && loanProvider?.toLowerCase() !=='zedvance'){
        getPosTransaction()
     }
    }, [startDate, endDate, transactionType])
    
    return (
        <div className='LoanProvidersDetails mb-5'>
            <div className="container pe-4"> 
                <div className="row mt-3"> 
                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <div className="card box_one mt-3 ms-1 w-100"> 
                        <div className="card-body"> 
                            <div className="title">
                            Total Amount Disbursed
                            </div>
                            <div className='balance'>
                                {balanceLoading === false ? `₦${totalBalance ? CurrencyFormatter(totalBalance.totalLoanDisbursedMajor) : '0.00'}` : <HorizontalLoader />}  
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-lg-4 col-xl-4">
                   <div className="card box_three mt-3 ms-1 w-100"> 
                        <div className="card-body"> 
                            <div className="title">
                            Cycle Count
                            </div>
                            <div className='balance'>
                                {balanceLoading === false ? `${totalBalance ? totalBalance.cycleCount : '0'}` : <HorizontalLoader />}  
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <div className="card box_two mt-3 ms-1 w-100"> 
                        <div className="card-body"> 
                            <div className="title">
                                    Portfolio Balance
                            </div>
                            <div className='balance'>
                                {balanceLoading === false ? `₦${totalBalance ? CurrencyFormatter(totalBalance.portfolioAmountMajor) : '0.00'}` : <HorizontalLoader />}  
                            </div>
                        </div>
                        </div>
                </div>
            </div> 

                <div className="card bg-white mt-4 border-0 shadow-sm">
                    <div className="card-header bg-white border-0">
                        <div className="container">
                            <div className="row">
                                <div className="col-8">
                                    <div className="card-table-title">
                                        Users
                                    </div>
                                </div>
                                <div className="col-4 text-end">
                                    <Link to={`/loan-providers/users/${loanProvider}`} className="card-table-see">
                                        See all
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        <div className="table-responsive">
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <td>S/N</td>
                                        {/* <td>Date</td> */}
                                        <td>Fullname</td>
                                        {loanProvider?.toLowerCase() !=='zedvance' && <td>Account Number</td>}
                                        <td>Phone Number</td>
                                        <td>Status</td>
                                        {loanProvider?.toLowerCase() !=='zedvance' && <td>Account Balance</td>}
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                {customers.length > 0 && customers.map((data: CustomerType, index:Key)=>{
                                    return <TableRole data={data} lp={loanProvider?.toLowerCase()} serialNumber={Number(index) +1} key={index} loanProvider={loanProvider}></TableRole>
                                    })}

                                    {loading === false && customers.length === 0 &&<tr>
                                        <td colSpan={6} className='text-center'>No user found</td>
                                    </tr>}

                                    {loading && <tr>
                                        <td colSpan={6}>
                                            <HorizontalLoader></HorizontalLoader>
                                        </td>
                                    </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>


                <div className="card bg-white mt-4 border-0 shadow-sm">
                    <div className="card-header bg-white border-0">
                        <div className="container">
                            <div className="row">
                                <div className="col-8">
                                    <div className="card-table-title">
                                        Transactions
                                    </div>
                                </div>
                                <div className="col-4 text-end">
                                    <Link to={`/loan-providers/transaction/${loanProvider}`} className="card-table-see">
                                        See all
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        <div className="table-responsive">
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <td>Customer</td>
                                        <td>MSISDN</td>
                                        <td>Amount</td>
                                        <td>Description</td>
                                        <td>Status</td>
                                        <td>Flow</td> 
                                        <td>Date</td>
                                    </tr>
                                </thead>
                                <tbody>
                                {transaction && transaction.length > 0 && transaction.map((res:LoanProviderTransactionType, index:Key)=>{

                                 const firstName = res.customer.firstName.charAt(0).toUpperCase() + res.customer.firstName.slice(1);
                                 const lastName = res.customer.lastName.charAt(0).toUpperCase() + res.customer.lastName.slice(1);

                                 return <tr key={index}>
                                        <td>{firstName} {lastName}</td>
                                        <td>{res.customer.msisdn}</td>
                                        <td>{res.currency}{CurrencyFormatter(res.amountMajor)}</td>
                                        <td>{res.description}</td>
                                        <td>{res.paidStatus}</td>
                                        <td>{res.flowType} flow</td> 
                                        <td>{TimeConverter(res.createdAt)}</td> 
                                    </tr>})}
                                    {transactionRequest === false && !transaction &&<tr>
                                        <td colSpan={7} className='text-center'>No transaction found</td>
                                    </tr>}
                                    {transactionRequest && <tr>
                                        <td colSpan={7}>
                                            <HorizontalLoader></HorizontalLoader>
                                        </td>
                                    </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>





                {loanProvider?.toLowerCase() !=='zedvance' && <div className="card bg-white mt-4 border-0 shadow-sm">
                    <div className="card-header bg-white border-0">
                        <div className="container">
                            <div className="row">
                                <div className="col-8">
                                    <div className="card-table-title">
                                       POS Transactions
                                    </div>
                                </div>
                                <div className="col-4 text-end">
                                    <Link to={`/loan-providers/users/post-transaction/${loanProvider}`} className="card-table-see">
                                        See all
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        <div className="table-responsive">
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <td>Acount No.</td>
                                        <td>Amount</td>
                                        <td>Receipt No.</td>
                                        <td>Running Bal.</td>
                                        <td>Remark</td> 
                                        <td>Date</td>
                                    </tr>
                                </thead>
                                <tbody>
                                {posTransaction && posTransaction.length > 0 && posTransaction.map((res:posTransactionType, index:Key)=>{
 
                                 return <tr key={index}> 
                                        <td>{res.accountNo??''}</td>
                                        <td>₦{CurrencyFormatter(Number(res.amount)??0)}</td>
                                        <td>{res.receiptNumber??''}</td>
                                        <td>₦{CurrencyFormatter(Number(res.runningBalance)??0)}</td>
                                        <td>{res.remarks??''}</td> 
                                        <td>{res.createdDate? TimeConverter(res.createdDate):''}</td> 
                                    </tr>})}
                                    {transactionRequest === false && !transaction &&<tr>
                                        <td colSpan={7} className='text-center'>No transaction found</td>
                                    </tr>}
                                    {transactionRequest && <tr>
                                        <td colSpan={7}>
                                            <HorizontalLoader></HorizontalLoader>
                                        </td>
                                    </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default LoanProvidersDetails



 
export const TableRole = ({ data, lp, serialNumber, loanProvider }: { data: CustomerType, lp:string|undefined, serialNumber: number, loanProvider: string|undefined }) => {
    const { showAlert } = ShowTGAlert()  
    const [loading, setLoading] = useState(false)
    const [accountBalance, setAccountBalance] = useState(0)
   

    const getBalance = async (msisdn:string) => {
        setLoading(true)
        setAccountBalance(0)
        await loanService.loanProvidersCustomerBalance(loanProvider, msisdn).then((response)=>{
          console.log('refreshed blance', response.data.data)
          setAccountBalance(response.data.data.bankAccountBalance)
          setLoading(false)
        }, (error) => {
            setLoading(false)
            showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'})  
        })
    }
    const firstName = data.firstName.charAt(0).toUpperCase() + data.firstName.slice(1);
    const lastName = data.lastName.charAt(0).toUpperCase() + data.lastName.slice(1);
    const navigate = useNavigate()
    return (
      <tr>
        <td>{serialNumber}</td>
        {/* <td>{TimeConverter(data.createdAt)}</td> */}
        <td>{firstName} {lastName}</td>
       {lp !=='zedvance' &&  <td>{data.loanProviderAccount?.VFD.beneficiaryAccountEnquiry.account.number}</td> }
        <td>{data.msisdn}</td>
       <td><span className='status'>{data.isEnabled ? 'Active' : 'Inactive'}</span></td> 
       {lp !=='zedvance' &&  <td className={Number(data.vfdBankAccountBalance) < 0? 'text-danger': 'text-success'}>₦{accountBalance? CurrencyFormatter(Number(accountBalance)): CurrencyFormatter(Number(data.vfdBankAccountBalance))}</td>}
        <td> 
            <div className="btn-group" role="group"> 
                <button className='btn btn-refresh me-1' onClick={()=>getBalance(data.msisdn)} disabled={loading}> {loading? <span className='spinner-border spinner-border-sm'></span>: 'Refresh'}</button>
                <button className='btn btn-refresh' onClick={()=>navigate(`/user/${data.accountId}`, { state: { from: location.pathname }})}><i className='d-icon d-eye-open'></i></button> 
            </div>
         </td>
      </tr>
    );
  };