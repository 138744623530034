 
import axios from 'axios'

import { AxiosInterceptor } from '../Config/AxiosInterceptor'; 
import { AddDeliveryFeeFormType } from '../Pages/Frontend/Components/DeliveryUltra/AddDeliveryUltra/AddDeliveryFee';
 
AxiosInterceptor()

const deliveryService = {

  index: async (pageNumber:number, country:string | undefined, state:string | undefined, localGovernmentArea:string) => {
    if(country && state && state !=='--option--' && country !=='--select country--'){
      return await axios.get(`/ultra/deliveryFees?pageNumber=${pageNumber}&country=${country}&state=${state}&localGovernmentArea=${localGovernmentArea}`);
    }else{
      return await axios.get(`/ultra/deliveryFees?pageNumber=${pageNumber}`);
    } 
  },

  createNewDelivery: async (data: AddDeliveryFeeFormType) => {
    return await axios.put('/ultra/deliveryFees', data);
  },

  customerDeliveryLocation: async (msidn:string) => {
    return await axios.get(`/deliverylocations?pageNumber=1&customerMsisdn=${msidn}`);
  }

}

export default deliveryService
