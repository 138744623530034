import React, {FC} from 'react';
import './OrderTrackingCard.scss';

type propTypes = {
    orderId?:number,
    children?:React.ReactNode | React.ReactNode[],
    isLoading:boolean,
    isUpdatingOrderStatus:boolean,
    title?:string
}

const OrderTrackingCard:FC<propTypes> = ({orderId, isLoading, isUpdatingOrderStatus,title,  children})=> {
    return (
        <div className="card order-tracking-card border-0">
            <div className="card-header order-tracking-card-header bg-white">
            {title} - #{orderId}
            </div>
            <div className="card-body">
                    { 
                        (!isLoading && !isUpdatingOrderStatus) &&
                        <ul className="list-group list-group-flush">
                            {children}
                        </ul>
                    }
                    {   
                        (isLoading || isUpdatingOrderStatus) &&
                        <div className='text-secondary text-center fw-bold'>
                            <span className='spinner-border spinner-border-lg'></span>
                        </div>
                    }
            </div>
        </div>
    )
}

export default OrderTrackingCard;
