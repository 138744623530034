import React, { useState } from 'react'
import './Requests.scss'  
import { AdminRequest, CreateRequest, UserRequest } from '../../Components'
import { ModalWrapper } from '../../../../Components'

const Requests = () => {

    const [tab, setTab] = useState(0) 
     
    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => {
      setShowModal((prevState) => !prevState);
    };
    
    return (
        <div className='requests'>
            <div className="container mt-4">
                <div className="container">
                    <div className="card border-0">
                        <div className="card-header border-0 px-0">
                            <div className="row">
                                <div className="col-6 col-md-6 my-auto">
                                <div className="title">
                                All Quote Requests
                                </div>
                                </div>
                                <div className="col-6 col-md-6 text-end">
                               
                                </div>
                            </div>
 

                            <div className="row mt-2">
                                <div className="col-12 col-md-8">   
                                    <div className="head-tab"> 
                                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={()=>setTab(0)}>Users Requests</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false"onClick={()=>setTab(1)}>Admin Created</button>
                                            </li> 
                                        </ul>
                                    </div>  
                                </div>

                                <div className="col-12 col-md-4 text-end"> 
                                    <button className='btn btn-add-company'  onClick={toggleModal}> 
                                        Create New
                                    </button>
                                </div>
                            </div>
 
                        </div>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                              {tab ==0 && <UserRequest></UserRequest>}                 
                            </div>
                            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                {tab ==1 && <AdminRequest></AdminRequest>}
                            </div>
                        </div> 
                    </div> 
                </div> 
            </div>


            <ModalWrapper showModal={showModal} closeModal={toggleModal} title="Create Request">
                <CreateRequest toggleModal={ toggleModal }></CreateRequest>
            </ModalWrapper>
        </div>
    )
}

export default Requests
