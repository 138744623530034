
import React, { Key, useEffect, useState } from 'react' 
import { Logger } from 'Helpers/Logger' 
import { TimeConverter } from 'Helpers/TimeConverter'
import { HorizontalLoader, Pagination } from 'Components'  
import loanService from 'Services/loanServices'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from 'Components/AuthProvider/AuthProvider'
import { posTransactionType } from 'Models/posTransactionType' 
import { CurrencyFormatter } from '../../../../../Helpers/CurrencyFormatter';


const LoanProviderAllPOSTransaction = () => {   
       const { loanProvider } = useParams<{loanProvider:string}>();
       const [pageNumber, setPageNumber] = useState(0)   

       const [paginationData, setPaginationData] = useState<{
        pageNumber: number,
        pageSize: number,
        total:number
      }>({
        pageNumber: 1,
        pageSize: 20,
        total: 1
      })

       const { user } = useAuth()
       const navigate = useNavigate()
       
       const [transaction, setTransaction] = useState<Array<posTransactionType>>()
       
       const [transactionType, setTransactionType] = useState('bank');
       const [startDate, setStartDate] = useState<string | undefined>();
       const [endDate, setEndDate] = useState<string | undefined>();
       const [loading, setLoading] = useState(false); 
        
   
        const [searching, setSearching] = useState(false);
       

        useEffect(() => {
            const currentDate = new Date();
            const formattedDate = currentDate.toISOString().slice(0, 19).replace('T', ' ');
            setEndDate(formattedDate)
           
           // calculate ten days ago
            const tenDaysAgoDate = new Date(currentDate);
            tenDaysAgoDate.setDate(currentDate.getDate() - 510);

            const formattedNewDate = tenDaysAgoDate.toISOString().slice(0, 19).replace('T', ' ');
            setStartDate(formattedNewDate);
 
          }, []);

       const getTransaction = async () => {
           setLoading(true)

           setSearching(true)
            // Check if a search operation is already in progress
            if (searching) {
                return;
            } 

            console.log({startDate, endDate})
            // startDate=2022-07-28 00:00:00&endDate=2023-12-20 00:00:00
           await loanService.loanProvidersAllPOSTransaction(loanProvider?.toLowerCase(), pageNumber, startDate, endDate, transactionType)
             .then((response)=>{    
               setLoading(false)

               if(response?.data.data.dataset.length > 0){
                  setTransaction(response?.data.data.dataset)

                  setPaginationData({
                    pageNumber: response?.data.data.pageNumber,
                    pageSize: response?.data.data.pageSize,
                    total: response?.data.data.total
                  })
               }

               console.log('loanProvidersPOSTransaction response ===', response)
     
                setSearching(false)
             }, (error: { response: object }) =>{     
               setLoading(false)
               setSearching(false)
               Logger('response error', error.response) 
             })
       }
   
       useEffect(() => {
        if(startDate && endDate){
            getTransaction()
        }
       }, [startDate, endDate, transactionType])

           
    

    useEffect(() => {
        if(user.user.loanProvider !== loanProvider && user.loanProviderAdminRole === true){
           navigate('/', {replace: true})
        } 
    }, [loanProvider])

    return (
        <div className='Transactions'> 
            <div className="container mt-4"> 
             
               <div className="card border-0">
                <div className="card-header border-0 px-2 px-md-2"> 

                    <div className="row mt-2">   
                        <div className="col-12 col-md-8"> 
                            <div className="title">
                              POS Transactions
                            </div>
                        </div> 
                        <div className="col-6 col-md-2 dropdown">
                            <button className='btn btn-filter dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false">
                            <svg width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.94 22.65C10.46 22.65 9.99002 22.53 9.55002 22.29C8.67002 21.8 8.14002 20.91 8.14002 19.91V14.61C8.14002 14.11 7.81002 13.36 7.50002 12.98L3.76002 9.02001C3.13002 8.39001 2.65002 7.31001 2.65002 6.50001V4.20001C2.65002 2.60001 3.86002 1.35001 5.40002 1.35001H18.6C20.12 1.35001 21.35 2.58001 21.35 4.10001V6.30001C21.35 7.35001 20.72 8.54001 20.13 9.13001L15.8 12.96C15.38 13.31 15.05 14.08 15.05 14.7V19C15.05 19.89 14.49 20.92 13.79 21.34L12.41 22.23C11.96 22.51 11.45 22.65 10.94 22.65ZM5.40002 2.85001C4.70002 2.85001 4.15002 3.44001 4.15002 4.20001V6.50001C4.15002 6.87001 4.45002 7.59001 4.83002 7.97001L8.64002 11.98C9.15002 12.61 9.65002 13.66 9.65002 14.6V19.9C9.65002 20.55 10.1 20.87 10.29 20.97C10.71 21.2 11.22 21.2 11.61 20.96L13 20.07C13.28 19.9 13.56 19.36 13.56 19V14.7C13.56 13.63 14.08 12.45 14.83 11.82L19.11 8.03001C19.45 7.69001 19.86 6.88001 19.86 6.29001V4.10001C19.86 3.41001 19.3 2.85001 18.61 2.85001H5.40002Z" fill="#868484"/>
                                <path d="M6.00004 10.75C5.86004 10.75 5.73004 10.71 5.60004 10.64C5.25004 10.42 5.14004 9.94999 5.36004 9.59999L10.29 1.69999C10.51 1.34999 10.97 1.23999 11.32 1.45999C11.67 1.67999 11.78 2.13999 11.56 2.48999L6.63004 10.39C6.49004 10.62 6.25004 10.75 6.00004 10.75Z" fill="#868484"/>
                            </svg>Filters</button> 
                            <div className="dropdown-menu dropdown-menu-end border-0 shadow filter-dropdown">
                               <div className='dropdown-item'> 
                                   <form action=""> 
                                     <div className="form-group">
                                        <label htmlFor=''>Type</label>
                                        <select id="" className='form-control' onChange={(e)=>{setTransactionType(e.target.value)}}>
                                            <option value="bank">--Choose--</option>
                                            <option value="bank">Bank</option>
                                            <option value="wallet">Wallet</option>
                                        </select>
                                     </div> 
                                     <div className="row mt-3">
                                        <div className="col-md-6">
                                            <button type="button"  className="btn btn-reset"  onClick={()=>setTransactionType('bank')}>Reset</button>
                                        </div>
                                        <div className="col-md-6 text-end">
                                            <button type="button" className="btn btn-filter" onClick={()=>setTransactionType('bank')}>Filter</button>
                                        </div>
                                     </div>
                                   </form>
                                </div> 
                            </div> 
                        </div>
                        <div className="col-6 col-md-2 dropdown">
                            <button className='btn btn-export dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false">
                            <svg  width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 11V17L11 15" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M9 17L7 15" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M22 10H18C15 10 14 9 14 6V2L22 10Z" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg> Export</button>

                            <ul className="dropdown-menu dropdown-menu-end export-dropdown border-0 shadow">
                               <li className='dropdown-item'> Export as .pdf</li> 
                               <li className='dropdown-item'> Export as .xlsx</li>
                               <li className='dropdown-item'> Export as .doc </li> 
                            </ul> 
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr> 
                                    <td>Acount No.</td>
                                    <td>Amount</td>
                                    <td>Receipt No.</td>
                                    <td>Running Bal.</td>
                                    <td>Remark</td> 
                                    <td>Date</td>
                                </tr>
                            </thead>
                            <tbody>
 
                               {loading===true && !transaction && <tr>
                                    <td colSpan={7} className='text-center'><HorizontalLoader></HorizontalLoader></td>
                                </tr>}
                                {transaction && transaction.length > 0 && transaction.map((res:posTransactionType, index:Key)=>{
                  
                                return <tr key={index}> 
                                        <td>{res.accountNo??''}</td>
                                        <td>₦{CurrencyFormatter(Number(res.amount)??0)}</td>
                                        <td>{res.receiptNumber??''}</td>
                                        <td>₦{CurrencyFormatter(Number(res.runningBalance)??0)}</td>
                                        <td>{res.remarks??''}</td>
                                        {/* <td>{res.fromAccountNo??''}</td>
                                        <td>{res.fromBank??''}</td>  */}
                                        <td>{res.createdDate? TimeConverter(res.createdDate):''}</td> 
                                    </tr>})}

                                    {loading === false && !transaction &&<tr>
                                    <td colSpan={7} className='text-center'>No transaction found</td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>

                    {paginationData.total >= paginationData.pageSize && <div className="pagination w-100 d-flex justify-content-center">
                    {paginationData.total && <Pagination
                        className="pagination-bar ps-0"
                        currentPage={Number(paginationData.pageNumber)}
                        totalCount={paginationData.total}
                        pageSize={paginationData.pageSize}
                        onPageChange={(page: React.SetStateAction<number>) => setPageNumber(page)}/>
                        }
                   </div>}
                    
                 </div>
               </div> 
            </div>  
    </div>
    )
}

export default LoanProviderAllPOSTransaction
