import { AllowedAlertType } from '../../Components/TGModalAlert/TGModalAlert';
import { SHOW_ALERT } from '../../Constants/AppAlertConstants'; 

export interface appAlertType { 
  title:string, 
  message: string, 
  show: boolean 
  type:AllowedAlertType
};

export function AppAlertsReducer(state: appAlertType, action: { type: string; payload: appAlertType }) {
  switch (action.type) {
    case SHOW_ALERT:
      return action.payload
    default:
      return {
        title: '',
        message: '',
        show: false,
        type: ''
      }
  }
}
 
