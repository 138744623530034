import axios from 'axios'

import { AxiosInterceptor } from '../Config/AxiosInterceptor';

AxiosInterceptor()

const logisticsService = {
  async index (pageNumber: number, sortOrder: string, status: string) {
    return axios.get(`/deliverytrucks?pageNumber=${pageNumber}&sortOrder=${sortOrder}&status=${status}`);
  }, 
  async getOne (id: string|undefined) {
    return axios.get(`/deliverytrucks?id=${id}&pageNumber=1`);
  },  
  async getOrders (id: string|undefined, pageNumber:number, sortOrder: string) {
    return axios.get(`/deliverytrucks/${id}/ordersAssignedTo?pageNumber=${pageNumber}&sortOrder=${sortOrder}`);
  }, 
  asign: async (orderReferenceNumber:string, truckId:string|undefined) => {
    return await axios.put(`deliverytrucks/${truckId}/assignToOrder/${orderReferenceNumber}`)
  },
  updateStatus: async (truckId:string|undefined, status:string, ) => {
    return await axios.put(`deliverytrucks/${truckId}/${status}`)
  },
  async getDetails (referenceId: string | undefined) {
    return axios.get(`/orders?pageNumber=1&referenceNumber=${referenceId}`);
  },
  
}
export default logisticsService
