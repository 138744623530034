import React, { ChangeEvent, FC, useState } from 'react'
import './CsvFile.scss' 
import Papa from 'papaparse';
import { HorizontalLoader } from 'Components';

interface Props {
    extractedNumbers: (numbers: string[]) => void;
    closeModal: () =>void
}

const CsvFile: FC<Props> = ({ extractedNumbers, closeModal }) => {

    const [loading, setLoading] = useState(false)
    
    const fileAttachment = async (event: ChangeEvent<HTMLInputElement>) => {
       console.log('fileAttachment', event)
       setLoading(true)
       const file = event.target.files && event.target.files[0];

       if (file) {
       await Papa.parse(file, {
            complete: (result: { data: any[]; }) => {
                // Assuming the CSV has a header and the phone column is named 'phone'
                const phoneNumbers = result.data.map((row) => row.Phone);
                
                setTimeout(()=>{
                    setLoading(false)
                    extractedNumbers(phoneNumbers)
                    closeModal()
                }, 4000)
                
            },
            header: true, // if CSV has a header row
            dynamicTyping: true, // Automatically convert strings to numbers if possible
        });
        
        }
    }

    return (
        <div className='CsvFile'> 
          {loading && <HorizontalLoader></HorizontalLoader>}
          {!loading && <>
            <div className='form-group mt-2'>
                <input type="file" name="attachment" onChange={fileAttachment}></input>
            </div> 
            
            <a href='https://res.cloudinary.com/trade-grid/raw/upload/v1712310883/tradegrid_contact_template_v1.0_ljryc3.csv' download="tradegride_contact_template.csv">Download template</a> 
            </>}
        </div>
    )
}

export default CsvFile
