import { ACTIVEMENU } from '../../Constants/MobileMenuConstants';
 
export function ActiveMenuReducer(state: string, action: { type: string; payload: {user: { loanProviderAdminRole: boolean, loanProvider: string }, name: string }; }) {
  const userPayload = action.payload?.user

  switch (action.type) {
    case ACTIVEMENU:
      return action.payload.name
    default:
      return userPayload && userPayload.loanProviderAdminRole? `${userPayload.loanProvider} Loan Provider`:'Dashboard';
  }
}
 
