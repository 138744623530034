import axios from 'axios'

import { AxiosInterceptor } from '../Config/AxiosInterceptor';
import { AddTransactionType } from '../Pages/Frontend/Components/Transactions/AddTransaction/AddTransaction';
import { AddTransactionTransferFormType } from '../Pages/Frontend/Components/Transactions/InternalTransferTransaction/InternalTransferTransaction';

AxiosInterceptor()

const transactionService = {
  async index (pageNumber: number, sortOrder: string, status: string, transactionFlowType:string|undefined) {
    if(transactionFlowType){
      return axios.get(`/financialtransactions?pageNumber=${pageNumber}&sortOrder=${sortOrder}&status=${status}&transactionFlowType=${transactionFlowType}`);
    }else{
      return axios.get(`/financialtransactions?pageNumber=${pageNumber}&sortOrder=${sortOrder}&status=${status}`);
    }
  },

  async single (pageNumber: number, id:string|undefined) {
    return axios.get(`/financialtransactions?pageNumber=${pageNumber}&id=${id}`); 
  },

  async create (data: AddTransactionType) {
    return axios.post('/financialtransactions', data);
  },
  
  searchTransaction: async (endpoint:string, pageNumber:number, sortOrder:string) => {
    const queryParams = `${endpoint}&pageNumber=${pageNumber ?? 1}&sortOrder=${sortOrder}`
    return await axios.get(`financialtransactions?${queryParams}`);
  },  

  async exportTransaction (pageNumber:number, sortOrder: string, transactionFlowType: string | undefined) {

    if(transactionFlowType){
       return axios.get(`/financialtransactions/export?pageNumber=${pageNumber}&sortOrder=${sortOrder}&transactionFlowType=${transactionFlowType}`); 
    }else{
       return axios.get(`/financialtransactions/export?pageNumber=${pageNumber}&sortOrder=${sortOrder}`); 
    }

  },

  async debitTransfer (data: AddTransactionTransferFormType) {
    return axios.post('/orderDebtTransfer', data);
  },
  
}
export default transactionService
