import React, { FC, useEffect, useState } from 'react'
import './AddCompany.scss' 
import { ShowTGAlert } from '../../../../../Helpers/ShowTGAlert';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';  
import * as yup from 'yup';
import companyService from '../../../../../Services/companyService';
import country from '../../../../../Data/country_full.json'
import state from '../../../../../Data/state.json'
import { locationService } from '../../../../../Services/locationService';
import { countryType, stateType } from '../../DeliveryUltra/AddDeliveryUltra/AddDeliveryFee';



interface propsType {
    toggleModal: () => void
}

export interface CreateCompanyFormData { 
    name: string; 
    country: string; 
    address: string; 
    state: string; 
}
 

  const schema = yup.object().shape({
    name: yup.string().required('Name is required'),
    country: yup.string().required('Country is required'),
    address: yup.string().required('Address is required'),
    state: yup.string().required('State is required'),
  });
  

const AddCompany:FC<propsType> = ({toggleModal}) => { 
    const [request, setRequest] = useState(false)

    const [countries, setCountries] = useState<Array<countryType>>([])
    const [states, setStates] = useState<Array<stateType>>([])
    const { showAlert } = ShowTGAlert() 
    
   
      const { register, handleSubmit, formState: { errors } } =  useForm<CreateCompanyFormData>({
        resolver: yupResolver(schema),
      });
      
      const onSubmit = handleSubmit(async (data: CreateCompanyFormData) => { 
        const newData = {
            ...data,  
        }
        console.log('onSubmit', newData)
        setRequest(true)
        await companyService.create(newData).then(()=>{
            setRequest(false)
            toggleModal();  
            showAlert({message: 'Company created successfully', show:true, title: 'Success', type: 'success'})
         
            setTimeout(()=>{
                window.location.reload()
            }, 3000)
        }, error => { 
            setRequest(false)
            toggleModal();  
            showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'}) 
        })
      });

 
      const getLocations = async () =>{
        await locationService.index().then((response)=>{
          console.log('response country', response)

          setCountries(response.data.data)
        }, (error) => {
            console.log('error country', error)
        })
    }

    useEffect(() => { 
        getLocations()
    }, [])


    const selectState = (countryName:{target: { value: string }})=>{ 
        console.log(countryName.target.value)
         let country:countryType|undefined;
         if(countryName.target.value){ 
             country = countries.find((res)=>res.name === countryName.target.value)
             if(country)setStates(country.states)
         }
     }

    return (
        <div className='AddCompany'>
            <div className="row mb-3">
                <div className="col-3">
                    <div className="photo-default">
                        <i className='d-icon d-add text-white fw-bolder fs-3'></i>
                    </div>
                </div>
                <div className="col-9">
                    <div className="addPhoto">Add Photo</div>
                    <div className="addProfilePhoto">Upload company logo</div>
                </div> 
            </div> 
            <form onSubmit={onSubmit}>
                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Company Name</label>
                    <input type="text" id="price-per-lit" className={errors.name? 'form-control is-invalid':'form-control'} {...register('name')}/>
                    {errors.name && <div className='error text-danger'>{errors.name.message}</div>}
                </div>
                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Country</label> 
                    <select className={errors.country? 'form-control is-invalid':'form-control'}  id="price-per-lit" 
                        {...register('country', { required: 'field is required',  onChange: selectState})}>
                        <option>--Country--</option>
                        {countries.length > 0 && countries.map((res:countryType, index:React.Key) => {
                            return <option key={index}>{res.name}</option>
                        })}
                    </select>
                    {errors.country && <div className='error text-danger'>{errors.country.message}</div>}

                </div> 
               
                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">State</label>  
                    <select id="price-per-lit" className={errors.state? 'form-control is-invalid':'form-control'}  {...register('state')}>
                        <option>--State--</option>
                        {states.map((result: stateType, index: React.Key) => {
                            return  <option key={index}>{result.name}</option>
                        })}
                    </select>
                    {errors.state && <div className='error text-danger'>{errors.state.message}</div>}
                </div> 
                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Address</label>
                    <input type="text" id="price-per-lit" className={errors.address? 'form-control is-invalid':'form-control'}  {...register('address')}/>
                    {errors.address && <div className='error text-danger'>{errors.address.message}</div>}
                </div> 
                <div className='form-group mb-3'>
                    {!request && <button className='btn btn-default w-100'>Add Company</button> }
                    {request && <button className='btn btn-default w-100'>Add Company <span className='spinner-border spinner-border-sm'></span></button> }
                </div> 
            </form>

           
        </div>
    )
}

export default AddCompany
