import React, {FC} from 'react'; 
import './TriptrackingItem.scss'
import { OrderType } from '../../../../../../Models/orderType';
import StatusIcon from './../../../../../../Assets/Icons/truck.svg'; 


type propTypes = { 
    order: OrderType
}

const TriptrackingItem: FC<propTypes> = ({order}) => {
       
    
    return (
            <li className={order.quoteRequest.depotLocation.company.name ? 'list-group-item d-flex flex-row trip-tracking-item align-items-start' : 'list-group-item d-flex flex-row order-tracking-card-item-invalid align-items-start'}>
                <div className="d-flex flex-column">
                    <img src={StatusIcon} alt='' 
                        className={
                            order.quoteRequest.depotLocation.company.name==='Delivery confirmed' && order.quoteRequest.depotLocation.company.name ? 
                            'item-icon-green-bg' : 'item-icon'
                        }
                    />
                    <span className="dotted-vertical-line mt-4"></span>
                </div>
                <div className="d-flex flex-column ms-4">
                    <h4 className="item-title">{order.quoteRequest.depotLocation.company.name}</h4>
                    <div className="item-value"> 
                       <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.9999 14.1714C9.86988 14.1714 8.12988 12.4414 8.12988 10.3014C8.12988 8.16141 9.86988 6.44141 11.9999 6.44141C14.1299 6.44141 15.8699 8.17141 15.8699 10.3114C15.8699 12.4514 14.1299 14.1714 11.9999 14.1714ZM11.9999 7.94141C10.6999 7.94141 9.62988 9.00141 9.62988 10.3114C9.62988 11.6214 10.6899 12.6814 11.9999 12.6814C13.3099 12.6814 14.3699 11.6214 14.3699 10.3114C14.3699 9.00141 13.2999 7.94141 11.9999 7.94141Z" fill="#292D32"/>
                            <path d="M11.9997 22.76C10.5197 22.76 9.02969 22.2 7.86969 21.09C4.91969 18.25 1.65969 13.72 2.88969 8.33C3.99969 3.44 8.26969 1.25 11.9997 1.25C11.9997 1.25 11.9997 1.25 12.0097 1.25C15.7397 1.25 20.0097 3.44 21.1197 8.34C22.3397 13.73 19.0797 18.25 16.1297 21.09C14.9697 22.2 13.4797 22.76 11.9997 22.76ZM11.9997 2.75C9.08969 2.75 5.34969 4.3 4.35969 8.66C3.27969 13.37 6.23969 17.43 8.91969 20C10.6497 21.67 13.3597 21.67 15.0897 20C17.7597 17.43 20.7197 13.37 19.6597 8.66C18.6597 4.3 14.9097 2.75 11.9997 2.75Z" fill="#292D32"/>
                        </svg>
                        {order.quoteRequest?.deliveryLocation?.address}
                    </div>
                    <div className="item-value"> 
                        <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.1596 11.62C12.1296 11.62 12.1096 11.62 12.0796 11.62C12.0296 11.61 11.9596 11.61 11.8996 11.62C8.99957 11.53 6.80957 9.25 6.80957 6.44C6.80957 3.58 9.13957 1.25 11.9996 1.25C14.8596 1.25 17.1896 3.58 17.1896 6.44C17.1796 9.25 14.9796 11.53 12.1896 11.62C12.1796 11.62 12.1696 11.62 12.1596 11.62ZM11.9996 2.75C9.96957 2.75 8.30957 4.41 8.30957 6.44C8.30957 8.44 9.86957 10.05 11.8596 10.12C11.9096 10.11 12.0496 10.11 12.1796 10.12C14.1396 10.03 15.6796 8.42 15.6896 6.44C15.6896 4.41 14.0296 2.75 11.9996 2.75Z" fill="#333333"/>
                            <path d="M12.1696 22.55C10.2096 22.55 8.23961 22.05 6.74961 21.05C5.35961 20.13 4.59961 18.87 4.59961 17.5C4.59961 16.13 5.35961 14.86 6.74961 13.93C9.74961 11.94 14.6096 11.94 17.5896 13.93C18.9696 14.85 19.7396 16.11 19.7396 17.48C19.7396 18.85 18.9796 20.12 17.5896 21.05C16.0896 22.05 14.1296 22.55 12.1696 22.55ZM7.57961 15.19C6.61961 15.83 6.09961 16.65 6.09961 17.51C6.09961 18.36 6.62961 19.18 7.57961 19.81C10.0696 21.48 14.2696 21.48 16.7596 19.81C17.7196 19.17 18.2396 18.35 18.2396 17.49C18.2396 16.64 17.7096 15.82 16.7596 15.19C14.2696 13.53 10.0696 13.53 7.57961 15.19Z" fill="#333333"/>
                        </svg> 
                        {order.quoteRequest.customer.firstName} {order.quoteRequest.customer.lastName}, {order.quoteRequest.customer.msisdn}.
                    </div>
                </div>
            </li>
        )
}

export default TriptrackingItem;
