import AdminHeader from '../../../../Layout/Admin/Header/AdminHeader'
import AdminMobileHeader from '../../../../Layout/Admin/MobileHeader/AdminMobileHeader'
import AdminSidebar from '../../../../Layout/Admin/Sidebar/AdminSidebar' 
import React, { FC , ReactNode, useEffect } from 'react' 
import './AdminWrapper.scss'
import { TGModalAlert } from '../../../../Components'
import { useDispatch, useSelector } from 'react-redux' 
import { SHOW_ALERT } from '../../../../Constants/AppAlertConstants'
import { appAlertType } from 'Reducers/AppAlerts/AppAlertsReducer'

interface propTypes {
    children: ReactNode
  }
  
  const AdminWrapper: FC<propTypes> = ({ children }) => { 


     // MODAL ALERT 
     const dispatcher = useDispatch()

     const handleCloseAlertModal = () => { 
        dispatcher({type: SHOW_ALERT, payload: { message: '', title: '', show: false}})
     };

     const appAlert = useSelector((state: { appAlert: appAlertType})=>state?.appAlert)
    


    useEffect(() => {
      const handleUnload = () => {
        // Clear data from local storage
        // localStorage.removeItem('__TG');
      };

      const handleVisibilityChange = () => {
        if (document.visibilityState === 'hidden') {
          // User is inactive, clear data from local storage
          // localStorage.removeItem('__TG');
        }
      };

      window.addEventListener('beforeunload', handleUnload);
      document.addEventListener('visibilitychange', handleVisibilityChange);

      return () => {
        window.removeEventListener('beforeunload', handleUnload);
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }, []);
     
    return (
      <React.Fragment>
      <div className='adminWraper p-0 m-0 w-100'> 
        <div className="row">
          <div className="col-25 pe-0 ">
            <AdminSidebar></AdminSidebar>
          </div>
          <div className="col-85 p-0">
           <AdminMobileHeader/>
            <AdminHeader></AdminHeader>  
              { children } 
          </div>
        </div> 
      </div>

      <TGModalAlert 
          show={appAlert?.show}
          title={appAlert?.title}
          message={appAlert?.message}
          onClose={handleCloseAlertModal}
          type={appAlert?.type}
      />
    </React.Fragment>
    )
}
  

export default AdminWrapper

