import React, { FC, useEffect, useState } from 'react'
import './AnnouncementForm.scss'
import { useForm } from 'react-hook-form';
import marketControlService from 'Services/marketControlService';
import Swal from 'sweetalert2'; 

type formProps = {
    title: string;
    content: string;
    publishedStatus: string;
    file: File[]
  }

const AnnouncementForm:FC<{ toggle: ()=>void }> = ({toggle}) => {
    const [request, setRequest] = useState(false)

    const { register, handleSubmit, formState: { errors } } = useForm<formProps>()
 
    const submit = handleSubmit(async (data: formProps)=>{
       console.log({data})
       setRequest(true)  
       await marketControlService.createAnnouncement({ title: data.title, content: data.content, publishedStatus: 'PUBLISHED' }) 
        .then((response)=>{ 

            console.log('createAnnouncement', response)
            
            startUpload(response.data.data.id, data.file[0])

        }, error => { 
            console.log(error)
            setRequest(false)  
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: { 
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
            });

            swalWithBootstrapButtons.fire({
                title: 'Oops!',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: true, 
                showConfirmButton: false, 
                cancelButtonText: 'close',
                reverseButtons: true
            });
        })
    })

  
    const startUpload = async (annUuid:string, file:File) => {
         console.log({annUuid, file})
          const formData = new FormData();
       
          formData.append(
            'file',
             file
          ); 

          await marketControlService.file(annUuid, formData, 'ANNOUNCEMENT_IMAGE').then((response) => {  
            toggle()
            setRequest(false) 

            Swal.fire({ 
                text: 'Created succesfully',
                icon: 'success',
                confirmButtonText: 'Ok',
            }); 
            
          }, (error: { response: { data: { error: string; }; }; }) => { 
             
            console.log(error)
            setRequest(false)  
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: { 
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
            });

            swalWithBootstrapButtons.fire({
                title: 'Oops!',
                text: error.response.data.error,
                icon: 'warning',
                showCancelButton: true, 
                showConfirmButton: false, 
                cancelButtonText: 'close',
                reverseButtons: true
            });
          }) 
      }
    return (
        <div className='AnnouncementForm'>  
            <form onSubmit={submit}> 
                <div className="form-group mt-3">
                    <label  htmlFor='title'>Announcement Title</label>
                    <input type="text" id="title" className='form-control' {...register('title', { required: 'field is required'})}/>
                    {errors.title && <div className="error text-danger">{errors.title.message}</div>}
                </div>
                <div className="form-group mt-3">
                    <label htmlFor='content'>Announcement Content <small>(the link, e.g https://tradegrid.co/)</small></label>
                    <textarea id="content" className='form-control'  {...register('content', { required: 'field is required'})}/>
                    {errors.content && <div className="error text-danger">{errors.content.message}</div>}
                </div>
                
                <div className="form-group mt-3">   
                    <label htmlFor='formFile'>Announcement Attachment</label>
                    <input type="file" id="formFile" className='form-control'  {...register('file', { required: 'field is required'})}/>
                    {errors.file && <div className="error text-danger">{errors.file.message}</div>}
                </div>

                <div className="form-group mt-5">
                    <button className='btn btn-default w-100 create-ann-btn' disabled={request}>{request && <span className='spinner-border spinner-border-sm'></span>} Create Announcement</button>
                </div> 
            </form>  
        </div>
    )
}

export default AnnouncementForm
