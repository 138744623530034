 
import React, { FC } from 'react'
import Button from 'react-bootstrap/esm/Button'
import Modal from 'react-bootstrap/esm/Modal' 
import './TGModalAlert.scss'

export type AllowedAlertType = 'success' | 'error';

interface propsType {
    show: boolean,
    title: string,
    message: string,
    onClose: ()=>void,
    type: AllowedAlertType
}
const TGModalAlert:FC<propsType> = ({ show, title, message, onClose, type }) => {
    return (
    <Modal show={show} onHide={onClose} contentClassName='TGModalAlert' centered>
        <Modal.Header>
        <div className="icon-box">
					{type === 'success' && <i className="d-icon d-check-circle"></i>}
					{type === 'error' && <i className="d-icon d-close-circle text-danger"></i>}
				</div>
         
        </Modal.Header>
       
        <Modal.Body className='text-center'> 
        <h4>{title}</h4>	
				<p>{message}</p>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-center'>
          <Button variant={type === 'success'? 'secondary' : 'danger'} onClick={onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default TGModalAlert

 
 
   