
export function TimeConverter (data: any) {
  const newDate = new Date(data);
  return newDate.toLocaleString();
}



export function formatUnixTimestamp(dateTimeString: string): string {
  const date = new Date(dateTimeString);

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'UTC',
  };

  const formattedDateTime = date.toLocaleString('en-US', options); 
  return formattedDateTime.replace(/(\d+)\/(\d+)\/(\d+), (\d+:\d+:\d+ [APMapm]+)/, '$2/$1/$3 $4');
}



export function CustomTimeFormat (data:string) {

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]

  const days = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat'
  ]

  const newDate = new Date(data);

  const year = newDate.getFullYear();
  const date = newDate.getDate();
  const month = newDate.getMonth();
  const day =  newDate.getDay()
  const hour = newDate.getHours();
  const minute = newDate.getMinutes();
  const second = newDate.getSeconds();
  const timeZone =Intl.DateTimeFormat().resolvedOptions().timeZone;


  return {
      year, 
      date, 
      month:months[month], 
      day:days[day],
      hour,
      minute,
      second,
      timeZone
  }
}