import { combineReducers } from 'redux';
import {  
  MobileMenuReducer,
  ActiveMenuReducer,
  AppAlertsReducer,
} from './'    
import authSlice from './authSlice';

export const rootReducer = combineReducers({  
  mobileMenu: MobileMenuReducer,
  activeMenu: ActiveMenuReducer,
  appAlert: AppAlertsReducer,
  auth: authSlice,
});
 