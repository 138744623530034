import { BreadCrumb, HorizontalLoader, ModalWrapper } from '../../../../../Components' 
import { Fragment, useEffect, useMemo, useState } from 'react'
import orderService from '../../../../../Services/orderService'
import { useParams } from 'react-router-dom'
import { OrderType } from '../../../../../Models/orderType' 
import StatusIcon from '../../../../../Assets/Icons/status.svg';
import CreatedIcon from '../../../../../Assets/Icons/created.svg';
import TruckIcon from '../../../../../Assets/Icons/truck.svg';
import './OrderTracking.scss'
import { OrderTrackingCard, OrderTrackingCardItem } from '../../../Components'
import { ShowTGAlert } from '../../../../../Helpers/ShowTGAlert'
import { useAuth } from 'Components/AuthProvider/AuthProvider'

const OrderTracking = () => {
    const { referenceId } = useParams()
    const [orderData, setOrderData] = useState<OrderType>()
 
    const [orderConfirmed, setOrderConfirmed] = useState<boolean>(false); 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loadingRequest, setLoadingRequest] = useState<boolean>(false)
    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loadingOrderStatusChange, setLoadingOrderStatusChange] = useState<boolean>(false)

    const { user } = useAuth()

    const getOrder = async () => {

        await orderService.getDetails(referenceId, user.loanProviderAdminRole, user.user.loanProvider).then((response)=>{
          console.log('orderService', response.data.data.dataset[0])
          setOrderData(response.data.data.dataset[0])
        }, error =>{
            console.log('orderService error', error.response)
        })
    }
     

    const showableStatus = ['Created', 'In progress', 'Completed','ENDED_WITH_DISPUTES', 'CONFIRMED_BY_BUYER', 'Completed successfully']

    useEffect(() => {
        getOrder()
    }, [])

    const terminatedStatus = ['ENDED_WITH_DISPUTES']
    
    const contructTrackingListItems = useMemo (()=>{
        const trackingList:any = [];
        type statusDataArrayFormat= [string,string,string,string]
  

        const allStatus:statusDataArrayFormat =[
          'Created',
          'In progress',
          'Ready for pickup',
          'Completed'
        ]

     
  
        const statusIcons:statusDataArrayFormat = [
          CreatedIcon,
          StatusIcon,
          StatusIcon,
          TruckIcon
  
        ]
       
        for ( let i=0; i < 4; i++) {
          if (orderData?.statusHistory[i]) {
 
           
            trackingList.push({
              status: i===2 && terminatedStatus.includes(orderData.statusHistory[i].status)? orderData.statusHistory[i].status: allStatus[i],
              dateTimeInISO8601:orderData.statusHistory[i].dateTimeInISO8601,
              icon:statusIcons[i]
            })
            
            if ((orderData?.statusHistory[i].status==='CONFIRMED' || orderData?.statusHistory[i].status==='COMPLETED' ||  orderData?.statusHistory[i].status==='CONFIRMED_BY_BUYER') && orderData?.statusHistory[i].dateTimeInISO8601) {
              setOrderConfirmed(true)
            }
            continue;
           }
          
           if(i===3 && orderData && orderData.statusHistory.length === 3 && (['CONFIRMED_BY_BUYER', 'COMPLETED'].includes(orderData.statusHistory[i-1].status))){
           
            trackingList.push(
              {
                status: allStatus[i],
                dateTimeInISO8601: orderData.statusHistory[i-1].dateTimeInISO8601,
                icon:statusIcons[i]
              }
            )
          }else{
 

            trackingList.push(
              {
                status: allStatus[i],
                dateTimeInISO8601: '',
                icon:statusIcons[i]
              }
             )
          }
           
        }  
        return trackingList;
  
    },[orderData])
    



    const contructTrackingDeliveryListItems = useMemo (()=>{
        const trackingList = [];
        type statusDataArrayFormat = [string,string,string,string]
   
        const allStatus:statusDataArrayFormat = [
          'Created',
          'In progress', 
          'Confirmed by buyer',
          'Completed'
        ]
  
        const statusIcons:statusDataArrayFormat = [
          CreatedIcon,
          StatusIcon,
          StatusIcon,
          TruckIcon
  
        ]
  
        for ( let i=0; i<4; i++) { 
          if (orderData?.statusHistory && orderData?.statusHistory[i]) {
  
            trackingList.push({
              status: i===2 && terminatedStatus.includes(orderData.statusHistory[i].status)? orderData.statusHistory[i].status: allStatus[i],
              dateTimeInISO8601:orderData.statusHistory[i].dateTimeInISO8601,
              icon:statusIcons[i]
            })  

            if ((orderData?.statusHistory[i].status==='CONFIRMED' || orderData?.statusHistory[i].status==='COMPLETED' ||  orderData?.statusHistory[i].status==='CONFIRMED_BY_BUYER') && orderData?.statusHistory[i].dateTimeInISO8601) {
                setOrderConfirmed(true)
              }
            continue;
           }
  
           if(i===3 && orderData && orderData.statusHistory.length === 3 && (['CONFIRMED_BY_BUYER', 'COMPLETED'].includes(orderData.statusHistory[i-1].status))){
            
            trackingList.push(
              {
                status: allStatus[i],
                dateTimeInISO8601: orderData.statusHistory[i-1].dateTimeInISO8601,
                icon:statusIcons[i]
              }
            )
          }else{
 
            trackingList.push(
              {
                status: allStatus[i],
                dateTimeInISO8601: '',
                icon:statusIcons[i]
              }
             )
          }
           
        }
        return trackingList;
  
    },[orderData])





    const contructTrackingPaymentListItems = useMemo (()=>{
        const trackingList:any = [];
        type statusDataArrayFormat= [string,string]
  
        //  CREATED -> AVAILABLE_FOR_PICKUP -> COMPLETED_SUCCESSFULLY
        const allStatus:statusDataArrayFormat =[
          'Buyer Payment Pending',
          'Buyer Payment In Escrow' 
        ]
  
        const statusIcons:statusDataArrayFormat = [
          CreatedIcon,
          StatusIcon  
        ]
  
        for ( let i=0; i<2; i++) {
          if (orderData?.paymentStatusHistory[i]) {
            trackingList.push({
              status:allStatus[i],
              dateTimeInISO8601:orderData.paymentStatusHistory[i].dateTimeInISO8601,
              icon:statusIcons[i]
            })
            
            if (orderData?.paymentStatusHistory[i].status==='CONFIRMED' &&orderData?.paymentStatusHistory[i].dateTimeInISO8601) {
              setOrderConfirmed(true)
            }
            continue;
           }
  
           trackingList.push(
            {
              status:allStatus[i],
              dateTimeInISO8601:'',
              icon:statusIcons[i]
            }
           )
           
        }
        return trackingList;
  
    },[orderData])


    const [showModal, setShowModal] = useState(false);  
    const [statusRequest, setStatusRequest] = useState(false);  
    
    const toggleModal = () => {
       setShowModal((prevState) => !prevState);
    };

    const { showAlert } = ShowTGAlert() 

    const confirmDelivery = async () =>{
      setStatusRequest(true)
      await orderService.orderStatus(orderData?.id, 'COMPLETED').then(()=>{ 
        
        toggleModal()
        showAlert({message: 'Order status changed to COMPLETED succesfully', show:true, title: 'Success', type: 'success'}) 
        
         setStatusRequest(false)

         setTimeout(()=>{
          getOrder()
         }, 300)

      }, error =>{
        setStatusRequest(false)
        toggleModal()
        showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'}) 
        console.log('orderService error', error.response)
      })
    }
     
    return (
        <div className='OrderTracking'> 
           <div className="container">
            <BreadCrumb title={'Order Tracking'} parent={'Orders'} parentUrl={`order/${orderData?.referenceNumber}`} showTitle={false}></BreadCrumb>
                <div className="container mt-2">
                    
                    <div className="card border-0">
                        <div className="card-header border-0 px-0">
                            <div className="row">
                                <div className="col-12 col-md-9 my-auto">
                                    <div className="title">
                                    Order Tracking
                                    </div>
                                </div>
                                {orderData && <Fragment> 
                                    <div className="col-6 col-md-3">
                                    <span className='order-status'>{orderData.status}</span>
                                    </div>
                                    {/* <div className="col-6 col-md-2"> 
                                    <button className='btn btn-respond-req'>Track Order</button>
                                    </div> */}
                                </Fragment>}
                            </div> 
                        </div>
                    </div>

                    {!orderData && <HorizontalLoader></HorizontalLoader>}
                    {orderData && <div className="row details-section">

                    {orderData.quoteRequest.orderReceiveType === 'PICKUP' &&  <div className="col-md-6">  
                       <OrderTrackingCard
                            orderId={orderData?.referenceNumber}
                            isLoading={loadingRequest}
                            isUpdatingOrderStatus={loadingOrderStatusChange}
                            title="Order Status"
                         > 
                            {
                              contructTrackingListItems?.map((item:any) => { 
                                  return (showableStatus.includes(item.status) && (<OrderTrackingCardItem
                                      itemIcon={item.icon}
                                      itemValue={item.dateTimeInISO8601}
                                      itemTitle={item.status}
                                      key={item.status}
                                  />)) 
                              })
                            }
                        </OrderTrackingCard> 
                        </div>}

                         {orderData.quoteRequest.orderReceiveType ==='DELIVERY' 
                           && <div className="col-md-6">  
                            <OrderTrackingCard
                                orderId={orderData?.referenceNumber}
                                isLoading={loadingRequest}
                                isUpdatingOrderStatus={loadingOrderStatusChange}
                                title="Order Delivery Status"
                            >
                                {
                                contructTrackingDeliveryListItems?.map((item:any) => {
                                    return (
                                      showableStatus.includes(item.status) &&  <OrderTrackingCardItem
                                        itemIcon={item.icon}
                                        itemValue={item.dateTimeInISO8601}
                                        itemTitle={item.status}
                                        key={item.status}
                                    />
                                    )
                                })
                                }
                            </OrderTrackingCard> 
                        </div>}

                        <div className="col-md-6"> 
 
                            <OrderTrackingCard
                                orderId={orderData?.referenceNumber}
                                isLoading={loadingRequest}
                                isUpdatingOrderStatus={loadingOrderStatusChange}
                                title="Order Payment Status"
                            >
                                {
                                contructTrackingPaymentListItems?.map((item:any) => {
                                    return (
                                      <OrderTrackingCardItem
                                        itemIcon={item.icon}
                                        itemValue={item.dateTimeInISO8601}
                                        itemTitle={item.status}
                                        key={item.status}
                                    />
                                    )
                                })
                                }
                            </OrderTrackingCard> 
                        </div> 

                        {!user.loanProviderAdminRole && orderData && orderData.quoteRequest.orderReceiveType ==='PICKUP' && <div className='col-6'>
                          <button className='btn btn-respond-req' onClick={()=>setShowModal(true) } disabled={orderConfirmed}>Confirm Pickup</button>
                        </div>}

                        {!user.loanProviderAdminRole && orderData && orderData.quoteRequest.orderReceiveType !=='PICKUP' && <div className='col-6'>
                          <button className='btn btn-respond-req' onClick={()=>setShowModal(true) } disabled={orderConfirmed}>Confirm Delivey</button>
                        </div>}
                   
                    </div>}

                    {orderData && <ModalWrapper showModal={showModal} closeModal={toggleModal} title={orderData.quoteRequest.orderReceiveType ==='PICKUP'? 'Confirm Pickup':'Confirm Delivery'}>
                      {/* <AddTransaction toggleModal={toggleModal}></AddTransaction> */}
                      <p>Are you sure you want to perform this irreversible action?</p> 
                      <button className='btn btn-order-traking-confirm' onClick={confirmDelivery} disabled={statusRequest}>Continue {statusRequest && <span className='spinner-border spinner-border-sm'></span>}</button>
                  </ModalWrapper>}
                </div>
            </div> 
        </div>
    )
}

export default OrderTracking
