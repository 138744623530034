import axios from 'axios'

import { AxiosInterceptor } from '../Config/AxiosInterceptor'

AxiosInterceptor()

const marketControlService = {
  async createAverageMarketPrice(payload: {
    productName: string
    marketPrice: number
    retailStation: string
    location: string
    displayInHeader: boolean
  }) {
    return await axios.post('marketPrice', { ...payload })
  },
  async createMarketPrice(payload: {
    productName: string
    marketPrice: number
    retailStation?: string
    location?: string
    displayInHeader: boolean
    priceType: string
    isTradeGridPrice?: boolean
  }) {
    return await axios.post('market-price', { ...payload })
  },

  deleteMarketPrice: async (mktPriceId: number) => {
    return await axios.delete(`market-price/${mktPriceId}`)
  },

  editMarketPrice: async (
    mktPriceId: number,
    marketPrice: number,
    productName: string,
    isTradeGridPrice?: boolean,
  ) => {
    return await axios.put(`market-price/${mktPriceId}`, {
      marketPrice,
      productName,
      isTradeGridPrice,
    })
  },

  editMarketPriceDisplay: async (
    mktPriceId: number,
    displayInHeader: boolean,
    marketPrice: number,
    productName: string,
  ) => {
    return await axios.put(`market-price/${mktPriceId}`, {
      displayInHeader,
      marketPrice,
      productName,
    })
  },

  async getMarketPrice(pageNumber: number, sortOrder: string, priceType: string) {
    return await axios.get(
      `market-price?pageNumber=${pageNumber}&sortOrder=${sortOrder}&priceType=${priceType}`,
    )
  },
  async getAverageMarketPrice() {
    return await axios.get('marketPrices')
  },
  async createAnnouncement(payload: { title: string; content: string; publishedStatus: string }) {
    return await axios.post('announcements', { ...payload })
  },

  async getAnnouncement() {
    return await axios.get('announcements')
  },

  file: async (anUuid: string | undefined, data: FormData, fileUploadCategory: string) => {
    return await axios.put(
      `/announcements/${anUuid}/fileUpload?fileUploadCategory=${fileUploadCategory}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
  },

  deleteAverageMarketPrice: async (mktPriceId: number) => {
    return await axios.delete(`marketPrice/${mktPriceId}`)
  },

  deleteAnnouncement: async (annId: number) => {
    return await axios.delete(`announcement/${annId}`)
  },
}

export default marketControlService
