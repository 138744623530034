import axios from 'axios'
import { AxiosInterceptor } from '../Config/AxiosInterceptor';
import { RespondToPriceMatrixType, RespondToQuoteType, UpdatePriceMatrixType } from '../Models/requestType';
import { CreateRequestType } from '../Pages/Frontend/Components/Request/CreateRequest/CreateRequest';

AxiosInterceptor()
 

const requestService = { 
  async index (pageNumber: number, sortOrder: string, status: string, isCreatedByAdmin = false) {
    return await axios.get(`/quoteRequests?pageNumber=${pageNumber}&sortOrder=${sortOrder}&status=${status}&isCreatedByAdmin=${isCreatedByAdmin}`);
  },

  async getDetails (referenceId: string | undefined) {
    return await axios.get(`/quoteRequests?pageNumber=1&referenceNumber=${referenceId}`);
  },

  searchQuoteRequests: async (endpoint:string, pageNumber:number, sortOrder:string) => {
    const queryParams = `${endpoint}&pageNumber=${pageNumber ?? 1}&sortOrder=${sortOrder}`
    return await axios.get(`quoteRequests?${queryParams}`);
  },  

  async sendQuote (referenceId: string | undefined, data:RespondToQuoteType) {
    return await axios.put(`/quoteRequests/${referenceId}/approve`, data);
  },

  async priceMatrixUpdate (referenceId: string | undefined, data:RespondToPriceMatrixType) {
    return await axios.put(`/quoteRequests/${referenceId}/priceMatrix`, data);
  },

  async create (data:CreateRequestType) {   
    return await axios.post('/quoteRequests', data);
  },

  async updateMatrix (data: UpdatePriceMatrixType, id: string|undefined) {
    return await axios.put(`/quoteRequests/${id}/priceMatrix/attach`, data)
  }

}
export default requestService
