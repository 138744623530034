import { CompanyType } from 'Models';
import { CompanyLocationType } from 'Models/companyType';
import React, { ChangeEvent, useState }  from 'react' 
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { countryType, stateType } from '../../DeliveryUltra/DeliveryUltra';
import { CreatePlpType } from './CreatePlp';
import './CreatePlp.scss'

type FormProps = {
  children?: React.ReactNode;
  errors: FieldErrors<CreatePlpType>;  
  register: UseFormRegister<CreatePlpType>;  
  companies: Array<CompanyType>;
  setDepotId: (value:string)=>void;
  setDepotLocationId: (value:string)=>void;
  locations: Array<CompanyLocationType>; 
  selectState: (value:{target:{value:string}})=>void;
  countries: Array<countryType>;
  states: Array<stateType>;
  onNext: ()=> void;
  handlePrev: ()=> void;
  publicAccess? :boolean;
};

const PlpCompanyDetailsStepTwo:React.FC<FormProps> = ({errors, register, onNext, handlePrev, publicAccess}) => {
   
    const [dailySalesError, setDailySalesError] = useState(true);
    const [documentTypeValidationError, setDocumentTypeValidationError] = useState(true);
  
    const dailySalesValidation = (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.trim();
      setDailySalesError(value === '');
    };
  
    const documentTypeValidation = (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.trim();
      setDocumentTypeValidationError(value === '');
    };

    return (
        <div className="row">  
            <div className="col-md-6 my-4"> 
                <div className="step-title">
                   Deferred Payment Details
                </div> 
            </div> 
            <div className="col-sm-6 text-sm-end my-4"> 
                <div className="step-page-number">
                Page 2 of 3
                </div> 
            </div> 

            <div className={publicAccess? 'col-md-10 col-lg-8' : 'col-md-10 col-lg-6'}>                            
                <div className='form-group mb-3'>
                    <label htmlFor="dailySalesCapacity">Daily Sales Capacity <span className='text-danger'>*</span></label>
                    <input type="text" id="dailySalesCapacity" className={errors.dailySalesCapacity? 'form-control is-invalid':'form-control'} {...register('dailySalesCapacity', { required: 'Daily sales capacity is required', onChange: dailySalesValidation})}/>
                    {errors.dailySalesCapacity && <div className='error text-danger'>{errors.dailySalesCapacity.message}</div>}  
                </div>   
    

                <div className='form-input mb-3'>

                    <label htmlFor="documentType">Document Type <span className='text-danger'>*</span></label> 

                    <div className='form-input mb-3'>
                        <label htmlFor="dprl">
                            <input type="radio" id="dprl" {...register('documentType', {onChange: documentTypeValidation})} value="DPR License"/>  DPR License
                        </label> 
                    </div>

                    <div className='form-input mb-3'>
                        <label htmlFor="laffs">
                            <input type="radio" id="laffs" {...register('documentType', {onChange: documentTypeValidation})} value="License Agreement for filling station"/>License Agreement for filling station
                        </label> 
                    </div>

                    <div className='form-input mb-3'>
                        <label htmlFor="other">
                            <input type="radio" id="other" {...register('documentType', {onChange: documentTypeValidation})} value="Other"/>Other
                        </label> 
                    </div>
                    {errors.documentType && <div className='error text-danger'>{errors.documentType.message}</div>}  
                </div>  
               
               <div className="row">  
                    <div className="col-12 col-sm-4 col-md-4 mt-1">  
                        <button onClick={handlePrev} className="btn btn-respond-req">Previous</button>
                    </div>
                    <div className="col-12 col-sm-4 col-md-4 mt-1"> 
                        <button onClick={onNext} className="btn btn-respond-req col" disabled={dailySalesError || documentTypeValidationError}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlpCompanyDetailsStepTwo
