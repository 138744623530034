
let auth0ClientId = 'ZQ5qz4b0s95BITUer534YPocGVh69aIZ'
if(process.env.REACT_APP_ENV === 'prod') {
  auth0ClientId =  'e8Q8lsLD8VBfek995GPFQF2OKcbvJoBF'
}

export const Auth0Config = {
  domain: 'tradegrid.us.auth0.com',
  clientId: auth0ClientId,
  redirectUri: window.location.origin,
  authorizationParams:  { 
    'redirect_uri': window.location.origin
  }
};
 