 
import { useDispatch } from 'react-redux';  
import { appAlertType } from '../Reducers/AppAlerts/AppAlertsReducer' 
import { SHOW_ALERT } from '../Constants/AppAlertConstants';

export const ShowTGAlert = () => {
  const dispatch = useDispatch();

  const showAlert = (payload:appAlertType) => { 
    dispatch({ 
            payload,
            type: SHOW_ALERT
        });
  };

  return {
    showAlert,
  };
};
 