 
  enum ValidationResult {
    PhoneNumber = 'PhoneNumber',
    Email = 'Email',
    AlphanumericWithUnderscore = 'AlphaNumeric',
    Numeric = 'Numeric',
    Unknown = 'Unknown',
  }
  
  export const checkSearchInputType = (inputValue: string): ValidationResult => {
    const isPhoneNumber = (value: string): boolean => {
      //   Check if the value matches a phone number pattern
      const phoneNumberPattern = /^\d{10}$/;
      return phoneNumberPattern.test(value);
    };

    function hasTenOrMoreConsecutiveNumbers(input: string): boolean {
        const consecutiveNumbersPattern = /\d{10,}/;
        return consecutiveNumbersPattern.test(input);
      }
  
    const isEmail = (value: string): boolean => { 
      //   Check if the value matches an email pattern
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailPattern.test(value);
    };
 
  
    const containsUnderscoreAndAlphanumeric = (value: string): boolean => {
      // Check if the value contains at least one underscore and alphanumeric characters
      return /.*\w+.*_.*\w+.*/.test(value);
    };

    const containsOnlyNumbers = (input: string): boolean => {
      return /^[0-9]+$/.test(input);
    };
  
    if (isPhoneNumber(inputValue) || hasTenOrMoreConsecutiveNumbers(inputValue)) {
      return ValidationResult.PhoneNumber;
    } else if (isEmail(inputValue)) {
      return ValidationResult.Email;
    } else if (containsUnderscoreAndAlphanumeric(inputValue)) {
      return ValidationResult.AlphanumericWithUnderscore;
    }else if (containsOnlyNumbers(inputValue)) {
      return ValidationResult.Numeric;
    } else {
      return ValidationResult.Unknown;
    }
  };