import React from 'react'

const Graph = () => {
    return (
        <svg width="113" height="44" viewBox="0 0 113 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_1350_17644)">
            <path d="M3 37C18.0825 20.381 38.6376 9.73693 60.9158 7.0094L110 1" stroke="#E873A8" strokeWidth="2"/>
            </g>
            <g filter="url(#filter1_d_1350_17644)">
            <circle cx="75" cy="5" r="4" fill="#E873A8"/>
            <circle cx="75" cy="5" r="3.5" stroke="white"/>
            </g>
            <defs>
            <filter id="filter0_d_1350_17644" x="0.259521" y="0.00744629" width="111.862" height="43.6646" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="1"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1350_17644"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1350_17644" result="shape"/>
            </filter>
            <filter id="filter1_d_1350_17644" x="67" y="1" width="16" height="16" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1350_17644"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1350_17644" result="shape"/>
            </filter>
            </defs>
        </svg>
    )
}

export default Graph
