import React, {Key, useEffect, useState} from 'react'
import './AsignTrip.scss'
import { HorizontalLoader, ImageSrc } from '../../../../../Components'
import orderService from '../../../../../Services/orderService'
import { OrderType } from '../../../../../Models/orderType'
import { Logger } from '../../../../../Helpers/Logger'
import { CurrencyFormatter } from '../../../../../Helpers'
import { useForm } from 'react-hook-form' 
import { yupResolver } from '@hookform/resolvers/yup';  
import * as yup from 'yup'; 
import { ShowTGAlert } from '../../../../../Helpers/ShowTGAlert'
import { useParams } from 'react-router-dom'
import logisticsService from '../../../../../Services/logisticsService'

export type AsignTruckType = {
  orderReferenceNumber: string
}
const AsignTrip = () => {
    const {truckId} = useParams()

    const [orderData, setOrderData] = useState<OrderType>()
    
    const [order, setOrder] = useState([]); 
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState(false);
    
    const { showAlert } = ShowTGAlert() 
    
    const [paginationData, setPaginationData] = useState<{
        pageNumber: number,
        pageSize: number,
        total:number
      }>({
        pageNumber: 1,
        pageSize: 20,
        total: 1
      })
 
       const [pageNumber,] = useState(1)  
       const [sortOrder, ] = useState('DESC')  
       const [status, ] = useState('ALL') 

    const getOrder = async (orderReferenceNumber:string) => {
        await orderService.getDetails(orderReferenceNumber).then((response)=>{
          console.log('orderService', response.data.data.dataset[0])
          setOrderData(response.data.data.dataset[0])
        }, error =>{
            console.log('orderService error', error.response)
        })
    }

    const getOrders = async () => {
        setLoading(true)
        await orderService.index(pageNumber, sortOrder, status)
          .then((response)=>{    
            setLoading(false)
            setOrder(response?.data.data.dataset)
            console.log('response', response)

            setPaginationData({
                pageNumber: response?.data.data.pageNumber,
                pageSize: response?.data.data.pageSize,
                total: response?.data.data.total
              })

          }, (error: { response: object }) =>{     
            setLoading(false)
            Logger('response error', error.response) 
          })
    }

    useEffect(() => {
     getOrders()
    }, [])

    const schema = yup.object().shape({
      orderReferenceNumber: yup.string().required('Order reference number field is required') 
    })
    
    const { register, handleSubmit, formState: { errors } } =  useForm<AsignTruckType>({
        resolver: yupResolver(schema),
      });
      
      const onSubmit = handleSubmit(async (data: AsignTruckType) => {  
        setRequest(true) 
        await logisticsService.asign(data.orderReferenceNumber, truckId).then(()=> {
            setRequest(false)  
            showAlert({message: 'Truck asigned successfully', show:true, title: 'Success', type: 'success'})
         
        }, (error: { response: { data: { error: string; }; }; }) => { 
            setRequest(false) 
            console.log('logisticsService', error.response)
            showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'}) 
        })
      });

    return (
        <div className='AsignTrip'>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                     <b>Assign a trip</b>

                     <form onSubmit={onSubmit}>
                        {/* <div className="form-group">
                            <label htmlFor="">Order no</label>
                            <select className='form-control' {...register('orderId', {  onChange: (e)=>getOrder(e.target.value)})}>
                                <option>--select--</option>
                                {order.length > 0 && order.map((data: OrderType, index:Key)=><option key={index} value={data.referenceNumber}>{data.referenceNumber} ({data.quoteRequest.product.shortName})  - {data.quoteRequest.customer.firstName} {data.quoteRequest.customer.lastName}</option>)}
                            </select> 
                            {errors.orderId && <small className='text-danger'>{errors.orderId.message}</small>}
                        </div> */}

                        <div className='form-group'>
                          <label htmlFor="">Order Reference Number</label>
                          <input type="number" id="orderReferenceNumber" className='form-control' {...register('orderReferenceNumber')} placeholder='' onChange={(e)=>getOrder(e.target.value)}/>
                          {errors.orderReferenceNumber && <div className='text-danger'>{errors.orderReferenceNumber.message}</div>}
                        </div>  
                        
                        <div className="form-group">
                            <button type="submit" className='btn btn-asign' disabled={request}>Asign Trip {request && <span className='spinner-border spinner-border-sm'></span>}</button> 
                        </div>
                     </form>
                    </div>


                    <div className="col-md-6 details-section">
                        {loading && <HorizontalLoader></HorizontalLoader>}
                        {!loading && orderData && <div className='card border-0 mt-3'>  
                            <div className='card-header bg-white border-0'>
                                <div className="title">
                                    Order Details
                                </div>
                            </div>
                            <div className="card-body">  
                                <div className="row">
                                    <div className="col-md-4"> 
                                        <div className="product-image">
                                        <ImageSrc src={orderData.depotLocation.company.logo? orderData.depotLocation.company.logo.url : ''} alt="" title="" width='w-100 rounded'/>
                                        </div>
                                    </div>
                                    <div className="col-md-4 text-start"> 
                                        <div className='seller-name'>{orderData.product.fullName}</div>
                                        <div className='quantity'>{orderData.quantity}Ltrs</div>
                                    </div>
                                    <div className="col-md-4 text-end"> 
                                        ₦{CurrencyFormatter(orderData.pricePerUnitMajor)}/Ltr
                                    </div>
                                </div> 

                                <div className="value-2 border-bottom pb-3"> 
                                    <div className="title-2 mt-4"> 
                                    Delivery Address
                                    </div>
                                    <div className="value-2"> 
                                        <svg width="24" height="24" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.9999 14.1714C9.86988 14.1714 8.12988 12.4414 8.12988 10.3014C8.12988 8.16141 9.86988 6.44141 11.9999 6.44141C14.1299 6.44141 15.8699 8.17141 15.8699 10.3114C15.8699 12.4514 14.1299 14.1714 11.9999 14.1714ZM11.9999 7.94141C10.6999 7.94141 9.62988 9.00141 9.62988 10.3114C9.62988 11.6214 10.6899 12.6814 11.9999 12.6814C13.3099 12.6814 14.3699 11.6214 14.3699 10.3114C14.3699 9.00141 13.2999 7.94141 11.9999 7.94141Z" fill="#292D32"/>
                                            <path d="M12.0002 22.76C10.5202 22.76 9.03018 22.2 7.87018 21.09C4.92018 18.25 1.66018 13.72 2.89018 8.33C4.00018 3.44 8.27018 1.25 12.0002 1.25C12.0002 1.25 12.0002 1.25 12.0102 1.25C15.7402 1.25 20.0102 3.44 21.1202 8.34C22.3402 13.73 19.0802 18.25 16.1302 21.09C14.9702 22.2 13.4802 22.76 12.0002 22.76ZM12.0002 2.75C9.09018 2.75 5.35018 4.3 4.36018 8.66C3.28018 13.37 6.24018 17.43 8.92018 20C10.6502 21.67 13.3602 21.67 15.0902 20C17.7602 17.43 20.7202 13.37 19.6602 8.66C18.6602 4.3 14.9102 2.75 12.0002 2.75Z" fill="#292D32"/>
                                        </svg>{orderData.quoteRequest.deliveryLocation?.address}
                                    </div>

                                    <div className="">
                                        <svg width="24" height="24" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.1596 11.62C12.1296 11.62 12.1096 11.62 12.0796 11.62C12.0296 11.61 11.9596 11.61 11.8996 11.62C8.99957 11.53 6.80957 9.25 6.80957 6.44C6.80957 3.58 9.13957 1.25 11.9996 1.25C14.8596 1.25 17.1896 3.58 17.1896 6.44C17.1796 9.25 14.9796 11.53 12.1896 11.62C12.1796 11.62 12.1696 11.62 12.1596 11.62ZM11.9996 2.75C9.96957 2.75 8.30957 4.41 8.30957 6.44C8.30957 8.44 9.86957 10.05 11.8596 10.12C11.9096 10.11 12.0496 10.11 12.1796 10.12C14.1396 10.03 15.6796 8.42 15.6896 6.44C15.6896 4.41 14.0296 2.75 11.9996 2.75Z" fill="#333333"/>
                                            <path d="M12.1696 22.55C10.2096 22.55 8.23961 22.05 6.74961 21.05C5.35961 20.13 4.59961 18.87 4.59961 17.5C4.59961 16.13 5.35961 14.86 6.74961 13.93C9.74961 11.94 14.6096 11.94 17.5896 13.93C18.9696 14.85 19.7396 16.11 19.7396 17.48C19.7396 18.85 18.9796 20.12 17.5896 21.05C16.0896 22.05 14.1296 22.55 12.1696 22.55ZM7.57961 15.19C6.61961 15.83 6.09961 16.65 6.09961 17.51C6.09961 18.36 6.62961 19.18 7.57961 19.81C10.0696 21.48 14.2696 21.48 16.7596 19.81C17.7196 19.17 18.2396 18.35 18.2396 17.49C18.2396 16.64 17.7096 15.82 16.7596 15.19C14.2696 13.53 10.0696 13.53 7.57961 15.19Z" fill="#333333"/>
                                        </svg>{orderData.quoteRequest.deliveryLocation?.contact.fullName}, {orderData.quoteRequest.deliveryLocation?.contact.phoneNumber}
                                    </div>
                                </div> 
                            </div>
                        </div> }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AsignTrip
