import React, { useEffect, useState } from 'react'
import './Statistics.scss'
import { Graph } from '../../../../../Assets/Icons'
import loanService from 'Services/loanServices'; 
import { Logger } from 'Helpers/Logger';
import statsService from 'Services/statsService';
import { useParams } from 'react-router-dom';

interface balanceType { 
    loanProvide: string,
    totalLoanDisbursedMajor: number,
    portfolioAmountMajor: number ,
    cycleCount: number,
}

const Statistics = () => {
    const { loanProvider } = useParams<{loanProvider: string}>();

    const [totalBalance, setTotalBalance] = useState<balanceType>();
    const [balanceLoading, setBalanceLoading] = useState(true);  

    const [providers, setProviders] = useState([]); 
    const [loading, setLoading] = useState(false);    
    
    const getLoanTotalBalance = async () => {
        setBalanceLoading(true)
        await loanService.loanProvidersCounterBalance(loanProvider).then((response:{data:{data:balanceType}})=> { 
           console.log('loanProvidersCounterBalance', response.data.data)
           setTotalBalance(response.data.data)
           setBalanceLoading(false)
        }, (error: { response: { data: { error: string } } }) =>{    
            console.log('get loan total', error)  
            setBalanceLoading(false) 
    
        })
    }

 
    const getLoanProviders = async () => {
      setLoading(true)
      await loanService.loanProvider()
        .then((response)=>{    
          setLoading(false) 
           const newData = response?.data.data
           setProviders(newData)  
  
        }, (error: { response: object }) =>{     
          setLoading(false)
          Logger('loanProvider response error', error.response) 
        })
  }
 
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   const [month, setMonth] = useState(new Date().toLocaleString('default', { month: 'short' }));
  const [month, setMonth] = useState(new Date().getMonth() + 1);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [year, setYear] = useState(new Date().getFullYear());
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [country, setCountry] = useState('Nigeria');
  const [orderCount, setOrderCount] = useState(0);

  const getOrderStats = async () => {
    setLoading(true)

    await statsService.getOrderStats(year, month, country).then((response)=>{ 
        setLoading(false)  
        console.log('order for this month', response.data.data.data, month)
        
        if(response.data.data.data){
            setOrderCount(response.data.data.data.totalOrders);
        } 

        }, error => {
            setLoading(false)
            console.log('getOrder for this month error -001', error.response)
    
        })
    }

    useEffect(() => {
        getOrderStats()
    }, [year, country, month])

  
    useEffect(() => {
        getLoanTotalBalance()
        getLoanProviders()
    }, [])
    
    
    return (
        <div className='statistics'>
            <div className="container">
                <div className="row mt-4">
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="card border-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-8 title">
                                    Total Loan Providers
                                    </div>
                                    <div className="col-4 subtitle d-none">
                                        All time total
                                    </div>
                                    <div className="col-7">
                                        <div className="col-12 value">
                                           {loading === false ?  providers.length: <span className="spinner-border spinner-border-sm"></span>}
                                        </div>

                                        <div className="col-12 d-none">
                                            <span className='bottom-percent'>
                                                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M14.0099 10.9851L11.6024 8.57755L10.1324 7.10005C9.50993 6.47755 8.49743 6.47755 7.87493 7.10005L3.98993 10.9851C3.47993 11.4951 3.84743 12.3651 4.55993 12.3651H8.76743H13.4399C14.1599 12.3651 14.5199 11.4951 14.0099 10.9851Z" fill="#6CC91A"/>
                                                </svg> 5%
                                            </span>
                                            <span className='bottom-week ms-4'>
                                            +5M  this week
                                            </span> 
                                        </div>
                                    </div>
                                    <div className="col-5 graph d-none"> 
                                        <Graph></Graph>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="card border-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-8 title">
                                    Total Number of Cycle count across Loan Providers
                                    </div>
                                    <div className="col-4 subtitle d-none">
                                        All time total
                                    </div>
                                    <div className="col-7">
                                        <div className="col-12 value"> 
                                            {balanceLoading === false ? `${totalBalance ? totalBalance.cycleCount : '0'}` :  <span className="spinner-border spinner-border-sm"></span>}  
                                        </div>

                                        <div className="col-12 d-none">
                                            <span className='bottom-percent text-danger'>
                                                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.0099 8.01495L11.6024 10.4224L10.1324 11.8999C9.50993 12.5224 8.49743 12.5224 7.87493 11.8999L3.98993 8.01495C3.47993 7.50495 3.84743 6.63495 4.55993 6.63495H8.76743H13.4399C14.1599 6.63495 14.5199 7.50495 14.0099 8.01495Z" fill="#EB2222"/>
                                                </svg>1%
                                            </span>
                                            <span className='bottom-week ms-4'>
                                            -1k  this month
                                            </span> 
                                        </div>
                                    </div>
                                    <div className="col-5 graph  d-none"> 
                                        <Graph></Graph>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="card border-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-8 title">
                                    Total Number of Orders for the Month
                                    </div>
                                    <div className="col-4 subtitle d-none">
                                    This month
                                    </div>
                                    <div className="col-7">
                                        <div className="col-12 value">
                                        {balanceLoading === false ? `${orderCount ? orderCount : '0'}` :  <span className="spinner-border spinner-border-sm"></span>}   
                                        </div>

                                        <div className="col-12 d-none">
                                            <span className='bottom-percent'>
                                                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M14.0099 10.9851L11.6024 8.57755L10.1324 7.10005C9.50993 6.47755 8.49743 6.47755 7.87493 7.10005L3.98993 10.9851C3.47993 11.4951 3.84743 12.3651 4.55993 12.3651H8.76743H13.4399C14.1599 12.3651 14.5199 11.4951 14.0099 10.9851Z" fill="#6CC91A"/>
                                                </svg>5%
                                            </span>
                                            <span className='bottom-week ms-4'>
                                            +5M  this week
                                            </span> 
                                        </div>
                                    </div>
                                    <div className="col-5 graph d-none"> 
                                        <Graph></Graph>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Statistics
