import axios from 'axios'

 
import { AxiosInterceptor } from '../Config/AxiosInterceptor';

AxiosInterceptor()
 
export const locationService = {
  index: async () => {
    return await axios.get('countries-and-states')
  }
}