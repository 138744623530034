import React from 'react'
import './Documentation.scss'

const Documentation = () => {
    return (
        <div className='CustomizeTruck text-center py-5'>
            <small className='text-center'>No documentation yet</small>
        </div>
    )
}

export default Documentation
