import axios from 'axios'
 
import { AxiosInterceptor } from '../Config/AxiosInterceptor';

AxiosInterceptor()
 
const smsService = {
  async index () {
    return await axios.get('/sms/group');
  },

  async getHistory (pageNumber:number, sortOrder: string) {
    return await axios.get(`/sms/history?pageNumber=${pageNumber}&sortOrder=${sortOrder}`);
  },
  async getHistoryDetail (smsId:string | undefined) {
    console.log('smsId', smsId)
    return await axios.get(`/sms/history/${smsId}?id=${smsId}`);
  },
 
    async send (data: { phoneNumbers: string[] | undefined; message: string; }) {
      return await axios.post('/sms/sendBulk', data);
    },

    async getGroup ( groupId: number ) {
      return await axios.get(`/sms/group/${groupId}?id=${groupId}`);
    },
    
    async addGroup (data: {groupName: string|undefined, phoneNumbers:  Array<string>|undefined}){ 
      return await axios.post('/sms/group', data);
    },

    async updateGroup (data: {groupId: number, phoneNumbers: Array<string>}) {
      return await axios.put(`/sms/group/${data.groupId}?id=${data.groupId}`, {phoneNumbers: data.phoneNumbers}); 
    }
  
}
export default smsService
