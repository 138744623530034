import React, { useEffect, useState } from 'react'
import './RequestDetails.scss'
import { BreadCrumb, HorizontalLoader, ModalWrapper } from '../../../../../Components' 
import RespondToQuote from './RespondToQuote/RespondToQuote'
import { useParams } from 'react-router-dom'
import requestService from '../../../../../Services/requestService'
import { RequestType } from '../../../../../Models/requestType'
import { CurrencyFormatter, FormatTimeAgo } from '../../../../../Helpers'
import ImageSrc from '../../../../../Components/ImageSrc/ImageSrc';
import EditRespondToQuote from './EditRespondToQuote/EditRespondToQuote'
import Swal from 'sweetalert2'
import orderService from 'Services/orderService'
import { UpdatePriceMatrix } from '../../Index'


const RequestDetails = () => {
     
  const [showModal, setShowModal] = useState(false);
  const [showPriceModal, setShowPriceModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showLoading, setLoading] = useState(false);

  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  const togglePriceModal = () => {
    setShowPriceModal((prevState) => !prevState);
  };

  const toggleEditModal = () => {
    setShowEditModal((prevState) => !prevState);
  };

  const { referenceId } = useParams()
  const [requestData, setRequestData] = useState<RequestType>()
  
  const getOrder = async () => {
      await requestService.getDetails(referenceId).then((response)=>{ 
        console.log('requestService',response.data.data.dataset[0])
        setRequestData(response.data.data.dataset[0])
      }, error =>{
          console.log('orderService error', error.response)
      })
  }

  useEffect(() => {
      getOrder()
  }, [])

 
  const reorder = () => { 
    Swal.fire({
        title: 'Are you sure?',
        text: 'Please note that initiating this order will deduct the cost of product from the user\'s wallet balance?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#186AFF',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, create!'
      }).then(async (result) => {

        if(result.isConfirmed){
            setLoading(true) 
            await orderService.reOrder(requestData?.id).then(()=>{
                setLoading(false) 
                Swal.fire('Congratulations!', 'Order has been created succesfully', 'success');
            }, error =>{
                console.log('error', error.response)
                setLoading(false) 
                Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    text: error.response.data.error,
                    showConfirmButton: false,
                    timer: 5000
                });
            })
        }
     })
}


  
    return (
        <div className='RequestDetails'> 
           <div className="container">
            <BreadCrumb title={'Quote request detail'} parent={'Quote request'} parentUrl={'requests'} showTitle={false}></BreadCrumb>
                <div className="container mt-2">
                    
                    <div className="card border-0">
                        <div className="card-header border-0 px-0">
                            <div className="row">
                                <div className="col-12 col-md-6 my-auto">
                                    <div className="title">
                                    Quote Request Details
                                    </div>
                                </div>

                                {requestData?.status === 'APPROVED' && <div className="col-6 col-md-2"></div>}

                                {!requestData?.priceMatrix &&<div className="col-6 col-md-2">
                                  <button className='btn btn-decline-req'>Decline Request</button>
                                </div>}

                                {!requestData?.priceMatrix && requestData?.status !== 'APPROVED' && <div className="col-6 col-md-2"> 
                                   <button className='btn btn-respond-req' onClick={toggleModal}>Respond to Request</button>
                                </div>}


                                {!requestData?.priceMatrix && requestData?.status !== 'APPROVED' && <div className="col-6 col-md-2"> 
                                   <button className='btn btn-respond-req' onClick={togglePriceModal}>Update Price</button>
                                </div>}

                                {/* {requestData?.priceMatrix && <div className="col-6 col-md-2"> </div>} */}
                                {requestData?.priceMatrix && <div className="col-6 col-md-3 col-lg-2"> 
                                   <button className='btn btn-respond-req' onClick={toggleEditModal}>Edit Request</button>
                                </div>}

                                {requestData?.status === 'APPROVED' && <div className="col-6 col-md-2">
                                     <button className='btn btn-respond-req' onClick={reorder}>Create Order {showLoading && <span className='spinner-border spinner-border-sm'></span>}</button>
                                </div>}
                            </div> 
                        </div>
                    </div>

                    {!requestData && <HorizontalLoader></HorizontalLoader>}
                    {requestData && <div className="row details-section">
                       <div className="col-md-6"> 
                            <div className='card border-0 mt-3'> 
                                <div className='card-header bg-white'>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="title">
                                            Order Summary
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="sub-title">
                                            {FormatTimeAgo(requestData?.createdAtUtc)}
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                                <div className="card-body">  
                                    <div className="row">
                                        <div className="col-md-4"> 
                                        <div className="product-image"> 
                                        <ImageSrc src={requestData.depotLocation.company.logo? requestData.depotLocation.company.logo.url : '' } width='w-100 rounded' alt={'company logo'} title={'company logo'}/>
                                        </div>
                                       </div>
                                        <div className="col-md-4 text-start"> 
                                         <div className='seller-name'>{requestData.product.fullName}</div>
                                         <div className='quantity'>{requestData.quantity}Ltrs</div>
                                       </div>
                                        <div className="col-md-4 text-end"> 
                                        ₦{requestData.productPrice.unitSellingPrice}/Ltr
                                       </div>
                                    </div>
                                    <ul className="list-group rounded-0 border-0">
                                        <li className="list-group-item border-start-0 border-end-0 px-0"> 
                                            <div className="row w-100 mx-0">
                                                <div className="col-6">
                                                Freight Cost Per Unit
                                                </div>
                                                {requestData.priceMatrix && <div className="col-6 text-end value px-0">
                                                ₦{CurrencyFormatter(requestData.priceMatrix.freightCostPerUnit)}
                                                </div>}
                                            </div>
                                        </li> 
                                        <li className="list-group-item border-start-0 border-end-0 px-0"> 
                                            <div className="row w-100 mx-0">
                                                <div className="col-6">
                                                Premium
                                                </div>
                                                {requestData.priceMatrix && <div className="col-6 text-end value px-0">
                                                ₦{CurrencyFormatter(requestData.priceMatrix.premium)}
                                                </div>}
                                            </div>
                                        </li> 
                                        <li className="list-group-item border-start-0 border-end-0 px-0"> 
                                            <div className="row w-100 mx-0">
                                                <div className="col-6">
                                                Unit Depot Price
                                                </div>
                                                {requestData.priceMatrix && <div className="col-6 text-end value px-0">
                                                ₦{CurrencyFormatter(requestData.priceMatrix.unitDepotPrice)}
                                                </div>}
                                            </div>
                                        </li> 
                                        <li className="list-group-item border-start-0 border-end-0 px-0"> 
                                            <div className="row w-100 mx-0">
                                                <div className="col-6">
                                                Unit Product Cost
                                                </div>
                                                {requestData.priceMatrix && <div className="col-6 text-end value px-0">
                                                ₦{CurrencyFormatter(requestData.priceMatrix.unitProductCost)}
                                                </div>}
                                            </div>
                                        </li> 
                                        <li className="list-group-item border-start-0 border-end-0 px-0"> 
                                            <div className="row w-100 mx-0">
                                                <div className="col-6">
                                                Retail Filling Station Name
                                                </div>
                                                {requestData.priceMatrix && <div className="col-6 text-end value px-0">
                                                 {requestData.priceMatrix.retailFillingStationName}
                                                </div>}
                                            </div>
                                        </li> 
                                        <li className="list-group-item border-start-0 border-end-0 px-0 mt-4"> 
                                            <div className="row w-100 mx-0">
                                                <div className="col-6 fw-bold">
                                                Subtotal
                                                </div>
                                                {requestData.subTotal && <div className="col-6 text-end value px-0">
                                                ₦{CurrencyFormatter(requestData.subTotal)}
                                                </div>}
                                            </div>
                                        </li> 
                                        <li className="list-group-item border-start-0 border-end-0 px-0"> 
                                            <div className="row w-100 mx-0">
                                                <div className="col-6 fw-bold">
                                                Delivery
                                                </div>
                                                {requestData.deliveryFee && <div className="col-6 value text-end px-0">
                                                ₦{CurrencyFormatter(requestData.deliveryFee)}
                                                </div>}
                                            </div>
                                        </li> 
                                        <li className="list-group-item border-start-0 border-end-0 border-bottom-0 px-0"> 
                                            <div className="row w-100 mx-0">
                                                <div className="col-6">
                                                Total
                                                </div>
                                                <div className="col-6 text-end value px-0">
                                                ₦{CurrencyFormatter((requestData.quantity * requestData.productPrice.unitSellingPrice) + requestData.deliveryFee)}
                                                </div>
                                            </div>
                                        </li> 
                                    </ul>   
                                </div>
                            </div> 
                        </div> 
                           <div className="col-md-6"> 
                            <div className='card border-0 mt-3'>  
                                <div className='card-header bg-white'>
                                    <div className="title">
                                    Payment and Delivery Information
                                    </div>
                                </div>
                                <div className="card-body">  
                                    <div className="title-2"> 
                                        Payment method
                                    </div>
                                    <div className="value-2 border-bottom pb-3"> 
                                        Wallet
                                    </div> 
                                    <div className="title-2 mt-4"> 
                                    Delivery Address
                                    </div>
                                    <div className="value-2"> 
                                        <svg width="24" height="24" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.9999 14.1714C9.86988 14.1714 8.12988 12.4414 8.12988 10.3014C8.12988 8.16141 9.86988 6.44141 11.9999 6.44141C14.1299 6.44141 15.8699 8.17141 15.8699 10.3114C15.8699 12.4514 14.1299 14.1714 11.9999 14.1714ZM11.9999 7.94141C10.6999 7.94141 9.62988 9.00141 9.62988 10.3114C9.62988 11.6214 10.6899 12.6814 11.9999 12.6814C13.3099 12.6814 14.3699 11.6214 14.3699 10.3114C14.3699 9.00141 13.2999 7.94141 11.9999 7.94141Z" fill="#292D32"/>
                                            <path d="M12.0002 22.76C10.5202 22.76 9.03018 22.2 7.87018 21.09C4.92018 18.25 1.66018 13.72 2.89018 8.33C4.00018 3.44 8.27018 1.25 12.0002 1.25C12.0002 1.25 12.0002 1.25 12.0102 1.25C15.7402 1.25 20.0102 3.44 21.1202 8.34C22.3402 13.73 19.0802 18.25 16.1302 21.09C14.9702 22.2 13.4802 22.76 12.0002 22.76ZM12.0002 2.75C9.09018 2.75 5.35018 4.3 4.36018 8.66C3.28018 13.37 6.24018 17.43 8.92018 20C10.6502 21.67 13.3602 21.67 15.0902 20C17.7602 17.43 20.7202 13.37 19.6602 8.66C18.6602 4.3 14.9102 2.75 12.0002 2.75Z" fill="#292D32"/>
                                        </svg>{requestData.deliveryLocation && <React.Fragment>{requestData.deliveryLocation?.address}, {requestData.deliveryLocation?.state},{requestData.deliveryLocation?.country}.</React.Fragment>}
                                    </div>

                                    <div className="">
                                        <svg width="24" height="24" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.1596 11.62C12.1296 11.62 12.1096 11.62 12.0796 11.62C12.0296 11.61 11.9596 11.61 11.8996 11.62C8.99957 11.53 6.80957 9.25 6.80957 6.44C6.80957 3.58 9.13957 1.25 11.9996 1.25C14.8596 1.25 17.1896 3.58 17.1896 6.44C17.1796 9.25 14.9796 11.53 12.1896 11.62C12.1796 11.62 12.1696 11.62 12.1596 11.62ZM11.9996 2.75C9.96957 2.75 8.30957 4.41 8.30957 6.44C8.30957 8.44 9.86957 10.05 11.8596 10.12C11.9096 10.11 12.0496 10.11 12.1796 10.12C14.1396 10.03 15.6796 8.42 15.6896 6.44C15.6896 4.41 14.0296 2.75 11.9996 2.75Z" fill="#333333"/>
                                            <path d="M12.1696 22.55C10.2096 22.55 8.23961 22.05 6.74961 21.05C5.35961 20.13 4.59961 18.87 4.59961 17.5C4.59961 16.13 5.35961 14.86 6.74961 13.93C9.74961 11.94 14.6096 11.94 17.5896 13.93C18.9696 14.85 19.7396 16.11 19.7396 17.48C19.7396 18.85 18.9796 20.12 17.5896 21.05C16.0896 22.05 14.1296 22.55 12.1696 22.55ZM7.57961 15.19C6.61961 15.83 6.09961 16.65 6.09961 17.51C6.09961 18.36 6.62961 19.18 7.57961 19.81C10.0696 21.48 14.2696 21.48 16.7596 19.81C17.7196 19.17 18.2396 18.35 18.2396 17.49C18.2396 16.64 17.7096 15.82 16.7596 15.19C14.2696 13.53 10.0696 13.53 7.57961 15.19Z" fill="#333333"/>
                                        </svg>
                                        {requestData.deliveryLocation && <React.Fragment>{requestData.deliveryLocation.contact.fullName}, {requestData.deliveryLocation.contact.phoneNumber}</React.Fragment>}
                                    </div>
                                </div>
                            </div> 
                        </div> 
                   
                    </div>} 
                </div>
            </div>

            <ModalWrapper showModal={showModal} closeModal={toggleModal} title="Respond to Quote Request">
               <RespondToQuote toggleModal={ toggleModal } referenceId={requestData?.id} unitPrice={requestData?.productPrice.unitSellingPrice}></RespondToQuote>
            </ModalWrapper>


            <ModalWrapper showModal={showPriceModal} closeModal={togglePriceModal} title="Update Price Metrix">
               <UpdatePriceMatrix toggleModal={ togglePriceModal } referenceId={requestData?.id}></UpdatePriceMatrix>
            </ModalWrapper>

            <ModalWrapper showModal={showEditModal} closeModal={toggleEditModal} title="Edit Quote Request">
               <EditRespondToQuote toggleModal={ toggleEditModal } referenceId={requestData?.id} priceMatrix={requestData?.priceMatrix}></EditRespondToQuote>
            </ModalWrapper>
        </div>
    )
}

export default RequestDetails
