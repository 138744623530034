import React, { FC, Key, useEffect, useState } from 'react'
import './AddProductRate.scss' 
import productService from '../../../../../Services/productService';
import { ProductRateType, productListType } from '../../../../../Models/productType';
import { useForm } from 'react-hook-form'; 
import { yupResolver } from '@hookform/resolvers/yup';  
import * as yup from 'yup';
import { ShowTGAlert } from '../../../../../Helpers/ShowTGAlert';
import { CompanyLocationType } from '../../../../../Models/companyType';

interface propsType {
    toggleModal: ()=>void;
    locations?: Array<CompanyLocationType>;
}

const schema = yup.object().shape({
    productId: yup.string().required('Product field is required'),
    unitCostPrice: yup.string().required('Unit cost price field is required'),
    unitSellingPrice: yup.string().required('Unit selling price is required'), 
    isHidden: yup.lazy((value) =>
        typeof value === 'boolean'
        ? yup.boolean().required('Required field') 
        : yup.string().required('Required field')
    ),
  }); 

const AddCompanyRates:FC<propsType> = ({toggleModal, locations}) => { 

    const [products, setProducts] = useState([])
    const [requests, setRequest] = useState(false)
    const { showAlert } = ShowTGAlert() 
    const [locationId, setLocationId] = useState<string>()
    
    const getCompanyProduct = async () => {
         
        await productService.getAll().then(
            (response)=>{
                setProducts(response.data.data) 
            })
    }

    useEffect(()=>{
        getCompanyProduct()
    }, [])

    const { register, handleSubmit, formState: { errors } } =  useForm<ProductRateType>({
        resolver: yupResolver(schema),
      });
      
 
    const submit = handleSubmit(async (data:ProductRateType)=>{
      console.log('ProductRateType', data)
      setRequest(true)

      if(!locationId){ 
        setRequest(false)
        showAlert({message: 'Select a depot location to continue', show:true, title: 'Error', type: 'error'})
        return false
      }

      await productService.setProductRate(locationId, data).then(
        ()=>{
            setRequest(false)
            showAlert({message: 'Rate updated successfully', show:true, title: 'Success', type: 'success'})
           
            setTimeout(()=>{
                window.location.reload() 
            }, 3000)
        }, error =>{       
            showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'})  
            setRequest(false)

            setTimeout(()=>{
                toggleModal();
            }, 1000)
           
        }
      )
      toggleModal()
    })
 
    return (
        <div className='AddProductRate'> 
             <form onSubmit={submit}>
                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Select Depot Location</label>
                    <select id="price-per-lit" className='form-control' onChange={(e)=>setLocationId(e.target.value)}>
                        <option>--select--</option>
                        {locations && locations?.map((data: CompanyLocationType, index:Key)=><option key={index} value={data.id}>{data.state}-{data.address}</option>)}
                    </select>
                    {errors.productId && <div className='error-message text-danger'>{errors.productId?.message}</div>}
                </div>
                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Select Product</label>
                    <select id="price-per-lit" className='form-control' {...register('productId',  {required: 'Product field is required'})}>
                        <option>--select--</option>
                        {products.length > 0 && products.map((data: productListType, index:Key)=>{
                           return <option key={index} value={data.id}>{data.shortName}</option> 
                        })}
                    </select>
                    {errors.productId && <div className='error-message text-danger'>{errors.productId?.message}</div>}
                </div>
                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Set Cost Price</label>
                    <input type="number" step="any" id="price-per-lit" className='form-control' {...register('unitCostPrice',  {required: 'Price field is required'})} />
                    {errors.unitCostPrice && <div className='error-message text-danger'>{errors.unitCostPrice?.message}</div>}
                </div> 
                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Set Selling Price</label>
                    <input type="number" step="any" id="price-per-lit" className='form-control' {...register('unitSellingPrice', {required: 'This field is required'})}/>
                    {errors.unitSellingPrice && <div className='error-message text-danger'>{errors.unitSellingPrice?.message}</div>}
                </div>


                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Hide Price</label>
                    <select id="price-per-lit" className='form-control' {...register('isHidden',  {required: 'Field is required'})}>
                        <option>--select--</option> 
                        <option value={'true'}>Hide</option> 
                        <option value={'false'}>Show</option> 
                    </select>
                    {errors.isHidden && <div className='error-message text-danger'>{errors.isHidden?.message}</div>}
                </div>

                <div className='form-group mb-3'>
                    {!requests && <button className='btn btn-default w-100'>Add Rate</button> }
                    {requests && <button className='btn btn-default w-100'>Add Rate <span className='spinner-border spinner-border-sm'></span></button> }
                </div>
            </form>
        </div>
    )
}

export default AddCompanyRates
