import './EditOrderDetails.scss'
import { BreadCrumb, HorizontalLoader, ImageSrc } from '../../../../../Components' 
import { Fragment, useEffect, useState } from 'react'
import orderService from '../../../../../Services/orderService'
import {  useParams } from 'react-router-dom'
import { EditOrderType, OrderType } from '../../../../../Models/orderType' 
import selectedStates from '../../../../../Data/stateNG.json' 
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup';  
import * as yup from 'yup';
 

const schema = yup.object().shape({
    address: yup.string().required('Address is required'),
    contactPhone: yup.string().required('Phone is required'),
    state: yup.string().required('State is required'),
    contactName: yup.string().required('Name is required'),
    quantity: yup.number().required('Quantity is required'),
    deliveryCost: yup.number().required('Delivery Cost is required'),
    paymentPlan: yup.string().required('Payment Plan is required'),
    unitPrice: yup.number().required('Unit Price is required'), 
  });
 
 
const EditOrderDetails = () => {
    const { referenceId } = useParams()
    const [orderData, setOrderData] = useState<OrderType>()
    const [request, setRequest] = useState(false)
   
    const getOrder = async () => {
        setRequest(true)
        await orderService.getDetails(referenceId).then((response)=>{
          setRequest(false)
          console.log('orderService', response.data.data.dataset[0])
          setOrderData(response.data.data.dataset[0])
        }, error =>{
            setRequest(false)
            console.log('orderService error', error.response)
        })
    }

    useEffect(() => {
        getOrder()
    }, [])

    const { register, setValue, formState: { errors } } =  useForm<EditOrderType>({
        resolver: yupResolver(schema),
      });

      useEffect(() => {

        if(orderData){
            setValue('address', '')
            setValue('contactName', orderData?.depotLocation.name)
            setValue('contactPhone', orderData.quoteRequest.customer.msisdn)
            setValue('deliveryCost', 0)
            setValue('paymentPlan', '')
            setValue('quantity', orderData?.quoteRequest.quantity)
            setValue('state', orderData?.depotLocation.state)
            setValue('unitPrice', orderData?.pricePerUnitMajor)
        }
 
      }, [orderData])
      
    return (
        <div className='EditOrderDetails'>
            
           <div className="container">
            <BreadCrumb title={'Edit Order'} parent={'Orders'} parentUrl={'orders'} showTitle={false}></BreadCrumb>
                <div className="container mt-2">
                    
                    <div className="card border-0">
                        <div className="card-header border-0 px-0">
                            <div className="row">
                                <div className="col-12 col-md-10 my-auto">
                                    <div className="title">
                                    Edit Order #{ orderData && orderData?.referenceNumber}
                                    </div>
                                </div>
                                {orderData && <Fragment> 
                                    <div className="col-6 col-md-2">
                                    <span className='order-status'>{orderData.status}</span>
                                    </div> 
                                </Fragment>}
                            </div> 
                        </div>
                    </div>

                    {!orderData && <HorizontalLoader></HorizontalLoader>} 

                    <form action="">
                        <div className="card border-0 mt-2">
                            <div className='card-body'> 
                                <div className="container bg-light mb-3 p-3">
                                    <div className='row'>
                                        <div className="col-6 col-sm-3 col-md-1">
                                            <ImageSrc src={orderData?.depotLocation.company.logo? orderData?.depotLocation.company.logo.url : ''} alt={'company logo'} title={'company logo'} width={'w-100 rounded'}></ImageSrc>
                                        </div>

                                        <div className="col-6 col-sm-3 col-md-2">
                                            <div className="title">Order ID</div>
                                            <div className="value fw-bold">{orderData?.referenceNumber}</div>

                                            <div className="col-sm-3 col-md-3 mt-3 d-sm-none">
                                                <div className="title"> Product</div>
                                                <div className="value fw-bold">{orderData?.product.fullName}</div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-sm-3 col-md-3 d-none d-sm-block">
                                            <div className="title"> Product</div>
                                            <div className="value fw-bold">{orderData?.product.fullName}</div>
                                        </div>

                                        <div className="col-sm-3 col-md-2">
                                           <div className="title"> Customer</div>
                                            <div className="value fw-bold">{orderData?.quoteRequest.customer.firstName} {orderData?.quoteRequest.customer.firstName}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="quantity">Quantity</label>
                                            <input type="text" id="quantity" className="form-control" {...register('quantity')}/>
                                            {errors.quantity && <div className='error text-danger'>{errors.quantity?.message}</div>}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="deliverycost">Delivery Cost</label>
                                            <input type="text" id="deliverycost" className="form-control" {...register('deliveryCost')} />
                                            {errors.deliveryCost && <div className='error text-danger'>{errors.deliveryCost.message}</div>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">

                                        <div className="form-group">
                                            <label htmlFor="unitprice">Unit Price</label>
                                            <input type="text" id="unitprice" className="form-control" {...register('unitPrice')} />
                                            {errors.unitPrice && <div className='error text-danger'>{errors.unitPrice.message}</div>}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="paymentplan">Payment Plan</label>
                                            <input type="text" id="paymentplan" className="form-control" {...register('paymentPlan')} />
                                            {errors.paymentPlan && <div className='error text-danger'>{errors.paymentPlan.message}</div>}
                                        </div>
                                    </div>
                                </div> 
  
                                <div className="row mt-4">
                                    <b>Delivery Information</b> 
                                    <div className="col-md-6"> 
                                        <div className='form-group mb-3'>
                                            <label htmlFor="price-per-lit">State</label>  
                                            <select id="price-per-lit" className={'form-control'} {...register('state')} >
                                                <option>--State--</option>
                                                {selectedStates.map((result: { name: string }, index: React.Key) => {
                                                    return result.name !=='Nationwide' && <option key={index} value={JSON.stringify(result)}>{result.name}</option>
                                                })}
                                            </select>
                                            {errors.state && <div className='error text-danger'>{errors.state.message}</div>}
                                        </div> 
                                        <div className='form-group mb-3'>
                                            <label htmlFor="price-per-lit">Address</label>
                                            <input type="text" id="price-per-lit" className={'form-control'} {...register('address')} />
                                            {errors.address && <div className='error text-danger'>{errors.address.message}</div>}
                                        </div> 
                                    </div>

                                    <div className="col-md-6">
                                        <div className='form-group mb-3'>
                                            <label htmlFor="price-per-lit">Contact Name</label>
                                            <input type="text" id="price-per-lit" className={'form-control'} {...register('contactName')} />
                                            {errors.contactName && <div className='error text-danger'>{errors.contactName.message}</div>}
                                        </div> 
                                        <div className='form-group mb-3'>
                                            <label htmlFor="price-per-lit">Contact Phone Number</label>
                                            <input type="text" id="price-per-lit" className={'form-control'}  {...register('contactPhone')} />
                                            {errors.contactPhone && <div className='error text-danger'>{errors.contactPhone.message}</div>}
                                        </div> 
                                    </div>
 
                                    <div className="col-md-6"> 
                                        <div className='form-group mb-3'>
                                            {!request && <button className='btn btn-save btn-default w-100'>Save Changes</button> }
                                            {request && <button className='btn btn-save btn-default w-100'>Save Changes <span className='spinner-border spinner-border-sm'></span></button> }
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div> 
        </div>
    )
}

export default EditOrderDetails
