import axios from 'axios'
import { AxiosInterceptor } from '../Config/AxiosInterceptor';

AxiosInterceptor()

const authService = {
  
  login: async (domainProvider: string | undefined, data: {  username: string;  password: string; }) => {
    return await axios.post(`/access/loanprovider/${domainProvider}/login/password`, data)
  },

  resetPassword: async (data: any) => { 
    return await axios.post('/access/login/phonenumber', data);
  },
  changePassword: async (data: any) => {
    return await axios.put('/profile/newpassword', data);
  },
  verifyPin: async (data: any) => { 
    return axios.post('/access/login/phonenumber/verify/otp', data);
  }, 
  register: (user: any) => {
    return axios.post('/onboarding/signup', user);
  },

  registerEnterprise: (user: any) => {
    return axios.post('/onboarding/signup/cooperate', user);
  },

  otpReg: (data: any) => {
    return axios.post('/onboarding/verify', data);
  },

  saveBusiness: async (data: any) => {
    return await axios.put('/onboarding/businessinfo', data);
  },

  getAll: () => {
    return axios.get('/users');
  },

  getById: (id: any) => {
    return axios.get(`/users/${id}`);
  },
  
  getCountry: async () => {
    return await axios.get('/miscellaneous/supported-countries')
  },

  getPrivileges: async () => {
    return await axios.get('/loanProviders/adminAllowedPages')
  }
}
 
export default authService
