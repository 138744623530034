 import React, { useEffect, useState } from 'react'
import './ProductDetails.scss' 
import { CustomizeProduct, ProductPricing, PurchaseOrder } from '../../../Components'
import productService from '../../../../../Services/productService'
import { useParams } from 'react-router-dom'
import { ProductType } from 'Models/productType'
 
const ProductDetails = () => {
    const { productId } = useParams<{productId:string}>()
    const [productInformation, setProductInformation] = useState<{name: string, id: string}>()
    const [productPrices, setProductPrices] = useState<Array<ProductType>>()

    const getProduct =  async () => {
        await productService.getOneDepoPrice(productId).then((res)=>{ 
            setProductPrices(res.data.data.dataset)
        }, error => {
            console.log('productService error',error.response)
        })
    }

    useEffect(() => {
        getProduct()
    }, [])

    return (
    <div className='productDetails'>
        <div className="container mt-4">
            <div className="container">
               <div className="card border-0">
                <div className="card-header border-0 px-0 mb-0 pb-0">
                    <div className="row">
                        <div className="col-md-6 my-auto">
                          <div className="title">
                              {productPrices && productPrices[productPrices.length-1].depotLocation.company.name} - {productPrices && productPrices[productPrices.length-1].product.fullName}
                           </div>
                        </div> 
                    </div>

                    <div className="head-tab">
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Purchase Orders</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Customize Product</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Pricing</button>
                            </li>
                        </ul>
                    </div> 
                </div> 
             

                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <PurchaseOrder setProductInformation={setProductInformation}></PurchaseOrder>
                    </div>
                    <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                       <CustomizeProduct productPrices={productPrices}></CustomizeProduct>
                    </div>
                    <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                       <ProductPricing productPrices={productPrices}></ProductPricing>
                    </div>
                </div> 
            </div>
 
            </div> 
        </div> 
    </div>
    )
}

export default ProductDetails
