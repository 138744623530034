
import React, { FC, Key, useEffect, useState } from 'react' 
import { CustomerType } from '../../../../../Models' 
import { ShowTGAlert } from '../../../../../Helpers/ShowTGAlert'
import { yupResolver } from '@hookform/resolvers/yup';  
import * as yup from 'yup'; 
import { useForm } from 'react-hook-form';
import customerService from '../../../../../Services/customerService';
import { urlencodePhoneNumber } from '../../../../../Helpers';

interface propsType {
    toggleEditModal: ()=>void,
    countries: Array<countryType>,
    editData?: CustomerType
}


export type UpdateUserPlanFormType = {  
  newAccountPlan: string,
  country: string,
  state: string,
  localGovernmentArea: string,
  plpUltraDefaultQuoteRequestQuantity?: yup.Maybe<number>
}

export type countryType = {
    name: string,
    iso2: string,
    phoneCode: string,
    currency: string,
    currencySymbol: string,
    states: [
      {
        name: string,
        localGovernmentAreas: [ string ]
      }
    ]
}

export type stateType = {  
    name: string,
    localGovernmentAreas: [ string ] 
}
 
const schema = yup.object().shape({ 
    newAccountPlan: yup.string().required('Account plan field is required'), 
    country: yup.string().required('country field is required'), 
    state: yup.string().required('state field is required'),
    localGovernmentArea: yup.string().required('LGA field is required'), 
    plpUltraDefaultQuoteRequestQuantity: yup.number().notRequired(), 
});


const EditUserPlan:FC<propsType> = ({toggleEditModal, editData, countries}) => { 
    const [states, setStates] = useState<Array<stateType>>([]);
    const [lgas, setLgas] = useState<Array<string>>([]); 
    const [selectedCountry, setSelectedCountry] = useState('');
    const [multiPlp, setMultiPlp] = useState<string>('0');
    
    const {handleSubmit, setValue, register, formState:{errors}} = useForm<UpdateUserPlanFormType>({
        resolver: yupResolver(schema),
      })
    
      const { showAlert } = ShowTGAlert()  
      const [request, setRequest] = useState(false) 
    
      const submitNewUserInformation = handleSubmit(async (data:UpdateUserPlanFormType) => { 
        setRequest(true)  
        const encodedPhone = urlencodePhoneNumber(editData?.msisdn); 


        await customerService.updatePlan(encodedPhone, data).then(()=>{
           if(multiPlp === '0'){
                setRequest(false)  
                toggleEditModal()
        
                showAlert({message: 'User profile updated successfully', show:true, title: 'Success', type: 'success'}) 
        
                setTimeout(()=>{ 
                    window.location.reload()
                }, 2000)
            }else{
                submitMultiPlp()
            }
    
        }, (error: { response: { data: { error: string } } }) =>{ 
    
            setRequest(false)   
            toggleEditModal()  
            showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'}) 
       }) 
      }); 
      


      const submitMultiPlp =  async ( ) => { 
        setRequest(true)  
        const encodedPhone = urlencodePhoneNumber(editData?.msisdn); 
 
        await customerService.updateMultiPlp(encodedPhone, multiPlp).then(()=>{
            setRequest(false)  
           
            toggleEditModal()
    
            showAlert({message: 'Customer profile updated successfully and enabled for multiple PLP order.', show:true, title: 'Success', type: 'success'}) 
    
            setTimeout(()=>{ 
                window.location.reload()
            }, 2000)
    
        }, (error: { response: { data: { error: string } } }) =>{ 
    
            setRequest(false)   
            toggleEditModal()  
            showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'}) 
       }) 
      }



      const selectState = (countryName:{target: { value: string }})=>{
        setSelectedCountry(countryName.target.value)
        console.log(countryName.target.value)
         let country:countryType|undefined;
         if(countryName.target.value){ 
             country = countries.find((res)=>res.name === countryName.target.value)
             if(country)setStates(country.states)
         }
     }
 
     const selectLga = (stateName:{target: { value: string }})=>{ 
         console.log(stateName.target.value)
         let country:countryType|undefined;
         if(stateName){ 
             country = countries.find((res)=>res.name === selectedCountry)
              const state:stateType|undefined = country?.states.find((res)=>res.name === stateName.target.value)
 
               console.log(state)
             if(state)setLgas(state?.localGovernmentAreas)
         }
     }


     const selectDefaultState = (countryName: string )=>{
         setSelectedCountry(countryName)
         let country:countryType|undefined;
         if(countryName){ 
             country = countries.find((res)=>res.name === countryName)
             if(country)setStates(country.states)
         }
     }
      
        useEffect(()=>{
            if(editData){
                setValue('country', editData.country)
                setValue('newAccountPlan', editData?.accountPlan)
                selectDefaultState(editData.country) 
            }
            console.log('countries', countries)
        }, [editData])




   

        return (
            <div className='AddUser'> 
            <form action='/product-scanner'>
                 
                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Account Plan</label>
                    <select  id="newCustomerLastName" className='form-control' {...register('newAccountPlan')}>
                        <option>--option--</option>
                        <option>REGULAR</option>
                        <option>PLP_STANDARD</option> 
                        <option>PLP_ULTRA_EXCLUSIVE</option> 
                        <option>PLP_ULTRA_UNLIMITED</option>  
                      </select>
                    {errors.newAccountPlan && <div className='text-danger'>{errors.newAccountPlan.message}</div>}
                </div>

                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Country</label>
                    <select  id="newCustomerLastName" className='form-control' {...register('country', { onChange: selectState})}>
                        <option>--option--</option>
                        {countries.length > 0 && countries.map((country:countryType, index:Key)=><option key={index}>{country.name}</option>)}
                    </select>
                    {errors.country && <div className='text-danger'>{errors.country.message}</div>}
                </div>
                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">State </label>
                    <select  id="newCustomerLastName" className='form-control' {...register('state', {onChange:selectLga})}>
                        <option>--option--</option>
                        {states.length > 0 && states.map((state:stateType, index:Key)=><option key={index}>{state.name}</option>)} 
                    </select> 
                    {errors.state && <div className='text-danger'>{errors.state.message}</div>}
                </div>  
                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">LGA </label>
                    <select  id="newCustomerLastName" className='form-control' {...register('localGovernmentArea')}>
                        <option>--option--</option>
                        {lgas.length > 0 && lgas.map((lga:string, index:Key)=><option key={index}>{lga}</option>)}  
                  </select> 
                    {errors.localGovernmentArea && <div className='text-danger'>{errors.localGovernmentArea.message}</div>}
                </div>  

                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">PLP Ultra Default Order quantity</label>
                    <input type="number" id="price-per-lit" className='form-control' {...register('plpUltraDefaultQuoteRequestQuantity')}/>
                </div>

                <div className='form-group mb-3'>
                        <label htmlFor="price-per-lit">Enable Multiple PLP</label>
                        <select  id="enableOrDisable" className='form-control' onChange={(e)=>setMultiPlp(e.target.value)}>
                            <option value={'0'}>--option--</option>
                            <option value="enable">Enable</option>
                            <option value="disable">Disable</option>  
                        </select> 
                    </div>

                    <div className='form-group mb-3'>
                        <button className='btn btn-default w-100' onClick={(e) => submitNewUserInformation(e)} disabled={request}>Update {request && <span className='spinner-border spinner-border-sm'></span>}</button> 
                    </div> 
                </form>
            </div>);
}

export default EditUserPlan
