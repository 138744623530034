import React, { Key, useEffect, useState } from 'react'
import './UserRequest.scss'
import { HorizontalLoader } from '../../../../../Components'
import { TimeConverter } from '../../../../../Helpers/TimeConverter'
import { Link, useNavigate } from 'react-router-dom'
import { RequestType } from '../../../../../Models/requestType'
import requestService from '../../../../../Services/requestService'
import { Logger } from '../../../../../Helpers/Logger'  
import { ShowTGAlert } from '../../../../../Helpers/ShowTGAlert'
import { checkSearchInputType } from '../../../../../Helpers/checkSearchInputType';
import SearchInput from '../../../../../Components/SearchInput/SearchInput';
import Pagination from '../../../../../Components/Pagination/Pagination';

const UserRequest = () => { 
      const [pageNumber, setPage] = useState(1) 
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [sortOrder, setSortOrder] = useState('DESC') 
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [status, setStatus] = useState('ALL') 
      
      const [request, setRequest] = useState([]); 
      const [loading, setLoading] = useState(false);
      const { showAlert } = ShowTGAlert() 

      const [paginationData, setPaginationData] = useState<{
          pageNumber: number,
          pageSize: number,
          total:number
        }>({
          pageNumber: 1,
          pageSize: 20,
          total: 1
        })
  
      const getQuoteRequests = async () => {
          setLoading(true)
          await requestService.index(pageNumber, sortOrder, status)
            .then((response)=>{    
              setLoading(false)
              setRequest(response?.data.data.dataset)
              console.log('response', response)
  
              setPaginationData({
                  pageNumber: response?.data.data.pageNumber,
                  pageSize: response?.data.data.pageSize,
                  total: response?.data.data.total
                })
  
            }, (error: { response: object }) =>{     
              setLoading(false)
              Logger('response error', error.response) 
            })
      }
  
      useEffect(() => {
        getQuoteRequests()
      }, [pageNumber])

      const handleSearch = async (searchTerm: string) => {
        console.log('Searching for:', searchTerm);
        const searchTermRes = checkSearchInputType(searchTerm).trim()
        
        let endpoint = '';
        if (searchTermRes === 'PhoneNumber') { 
            endpoint = `customerMsisdn=${encodeURIComponent(searchTerm.trim())}`;
        }else if (searchTermRes === 'AlphaNumeric') {  
            endpoint = `customerAccountId=${encodeURIComponent(searchTerm.trim())}`;
        }
        if(endpoint === '') {
          return
        }

        setLoading(true)
         await requestService.searchQuoteRequests(endpoint, pageNumber, sortOrder) 
             .then((response: { data: { data: { dataset: React.SetStateAction<never[]>; pageNumber: number; pageSize: number; total: number } } })=>{    
            setLoading(false)
            setRequest(response?.data.data.dataset) 

            setPaginationData({
                pageNumber: response?.data.data.pageNumber,
                pageSize: response?.data.data.pageSize,
                total: response?.data.data.total
              })

          }, (error: { response: any }) =>{ 
            setRequest([])

            setTimeout(()=>{
              getQuoteRequests()
            }, 3000) 
    
            setLoading(false)

            showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'}) 
            Logger('response error', error.response) 
          }) 
        };


    const navigate = useNavigate()

    return (
        <div className='UserRequest'>
               <div className="card-body">
                            <div className="table-responsive">
                              <div className="row mt-2">
                                <div className="col-12 col-md-8"> 
                                  <SearchInput onSearch={handleSearch} placeholder={'Search email, phone or user ID'}></SearchInput>
                                </div>
                                </div>
                                <table className="table">
                                    <thead>
                                        <tr> 
                                            <td>Request Number</td>
                                            <td>User</td>
                                            <td>Product</td>
                                            <td>Quantity</td>
                                            <td>Status</td>
                                            <td>Date</td>
                                            <td></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {request.length > 0 && request.map((data: RequestType, index:Key)=><tr key={index} onClick={()=>{ navigate(`/request/${data.referenceNumber}`, {replace:true})}}>
                                            <td>#{data.referenceNumber}</td>
                                            <td>{data.customer?.firstName} {data.customer?.lastName}</td>
                                            <td>{data.product.fullName}</td>
                                            <td>{data.quantity}Ltrs</td>
                                            <td><span className='status'>{data.status}</span></td>
                                            <td>{TimeConverter(data.createdAtUtc)}</td> 
                                            <td><Link to={`/request/${data.referenceNumber}`}><button className='btn btn-sm btn-transaction-detail'>Details</button></Link></td>
                                            {/* <td className='dropdown option-dropdown'>
                                                <button className='btn border-0 dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false" data-bs-popper-config='{"strategy":"fixed"}'>
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7.49991 8.4705C7.49991 7.72933 8.0955 7.14697 8.82344 7.14697C9.55138 7.14697 10.147 7.74256 10.147 8.4705C10.147 9.19844 9.55138 9.79403 8.82344 9.79403C8.0955 9.79403 7.49991 9.21168 7.49991 8.4705Z" fill="#707070"/>
                                                        <path d="M7.49991 14.1175C7.49991 13.3763 8.0955 12.7939 8.82344 12.7939C9.55138 12.7939 10.147 13.3895 10.147 14.1175C10.147 14.8454 9.55138 15.441 8.82344 15.441C8.0955 15.441 7.49991 14.8587 7.49991 14.1175Z" fill="#707070"/>
                                                        <path d="M7.49991 2.82353C7.49991 2.08235 8.0955 1.5 8.82344 1.5C9.55138 1.5 10.147 2.09559 10.147 2.82353C10.147 3.55147 9.55138 4.14706 8.82344 4.14706C8.0955 4.14706 7.49991 3.56471 7.49991 2.82353Z" fill="#707070"/>
                                                    </svg>

                                                </button>

                                                <ul className="dropdown-menu dropdown-menu-end export-dropdown border-0 shadow">
                                                    <li className='dropdown-item'><Link to={`/request/${data.referenceNumber}`}>Quote Request Details</Link></li> 
                                                    <li className='dropdown-item'>Respond to Request</li> 
                                                    <li className='dropdown-item text-danger'>Decline Request </li> 
                                                </ul> 
                                            </td> */}
                                            </tr>)} 

                                            {loading === false && request.length === 0 &&<tr>
                                                <td colSpan={8} className='text-center'>No user found</td>
                                            </tr>}
                                            {loading===true && request.length === 0 && <tr>
                                                <td colSpan={8} className='text-center'><HorizontalLoader></HorizontalLoader></td>
                                            </tr>}
                                    </tbody>
                                </table>
                            </div>

                            {paginationData.total >= paginationData.pageSize && <div className="pagination w-100 d-flex justify-content-center">
                              {paginationData.total && <Pagination
                                  className="pagination-bar ps-0"
                                  currentPage={Number(paginationData.pageNumber)}
                                  totalCount={paginationData.total}
                                  pageSize={paginationData.pageSize}
                                  onPageChange={(page: React.SetStateAction<number>) => setPage(page)}/>
                                  }
                            </div>}
                        </div>
        </div>
    )
}

export default UserRequest
