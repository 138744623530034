import React, { Key, useEffect, useState } from 'react'
import './Products.scss'
import { Link } from 'react-router-dom'
import { Logger } from '../../../../Helpers/Logger'
import productService from '../../../../Services/productService'
import { ProductType } from '../../../../Models'
import { HorizontalLoader, ImageSrc, ModalWrapper } from '../../../../Components'
import Pagination from '../../../../Components/Pagination/Pagination';
import { HideProductPrice } from '../../Components'
import { CompanyLocationType } from '../../../../Models/companyType'
import { TableSerialNumber } from 'Helpers'
import { ShowTGAlert } from 'Helpers/ShowTGAlert'

const Products = () => {  
      const [pageNumber, setPage] = useState(1)   
      
      const [products, setProducts] = useState([]); 
      const [loading, setLoading] = useState(false);
      const [productId, setProductId] = useState<string>('');
      

      const [paginationData, setPaginationData] = useState<{
          pageNumber: number,
          pageSize: number,
          total:number
        }>({
          pageNumber: 1,
          pageSize: 20,
          total: 1
        })
  
      const getProducts = async () => {
          setLoading(true)
          await productService.index(pageNumber)
            .then((response)=>{    
              setLoading(false)
              setProducts(response?.data.data.dataset)
              console.log('response', response)
  
              setPaginationData({
                  pageNumber: response?.data.data.pageNumber,
                  pageSize: response?.data.data.pageSize,
                  total: response?.data.data.total
                })
  
            }, (error: { response: object }) =>{     
              setLoading(false)
              Logger('response error', error.response) 
            })
      }
  
      useEffect(() => {
        getProducts()
      }, [pageNumber])




      const [selectAll, setSelectAll] = useState(false); 
      const [selected, setSelected] = useState(0); 
      const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>({});
  
      const handleCheckAll = () => { 
        const newCheckedItems: { [key: string]: boolean } = {};
        if (!selectAll) {
            products.forEach((item:{id:string}) => {
            newCheckedItems[item.id] = true;
          });
        }
        setCheckedItems(newCheckedItems);
        setSelectAll(!selectAll);
      };


      const handleCheckSingle = (id: string) => {
        setCheckedItems((prevCheckedItems) => ({
          ...prevCheckedItems,
          [id]: !prevCheckedItems[id],
        }));
      };

      useEffect(()=>{ 
        setSelected(Object.keys(checkedItems).length)
 
      }, [checkedItems])

      const deleteProduct = () =>{
        return true
      }


      const [showRateModal, setShowRateModal] = useState(false);
      const [rateDate, setRateData] = useState<CompanyLocationType>();
 
      const toggleRateModal = () => {
         setShowRateModal((prevState) => !prevState);
      };
 
      const toggleRateData = (data:CompanyLocationType) => {
         setRateData(data) 
      };

      const [request, setRequest] = useState(false);
      const { showAlert } = ShowTGAlert() 
    
      const hideBulk = async () =>{
        const isChecked = true
        const selectedItems: string[] = Object.keys(checkedItems);


        if(selectedItems.length > 0){
            const selectedItems: string[] = Object.keys(checkedItems);

            for(const [index, element] of selectedItems.entries()){
                setRequest(true);
               
                // Get the details for all the product IDs
                const rateDateId = products.find((data: ProductType)=>data.id === element) as ProductType | undefined;
               
                if (rateDateId) {
                    const newData = { isHidden: 'true', productId: rateDateId.product.id };
                    
                    await productService.setProductHidden(rateDateId.depotLocation.id, newData).then(
                        () => {
                            setRequest(false);
                            if (index === selectedItems.length - 1) {
                                showAlert({ message: `Price set to ${isChecked ? 'hidden' : 'show'} successfully`, show: true, title: 'Success', type: 'success' });
                            }
                        },
                        error => {     
                            console.log('bulk hisde price error', error.response)  
                            showAlert({ message: error.response.data.error, show: true, title: 'Error', type: 'error' });
                            setRequest(false);
                        }
                    );
                }
            }
            
        }

    }


    return (
    <div className='products'>
        <div className="container mt-4">
            <div className="container">
               <div className="card border-0">
                <div className="card-header border-0 px-0">
                    <div className="row">
                        <div className="col-6 col-md-6 my-auto">
                          <div className="title">
                            {paginationData.total} products available  
                           </div>
                        </div>
                        <div className="col-6 col-md-6 text-end">
                         <button className='btn btn-add-company'> 
                            Source Product
                        </button>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-12 col-md-8">
                            <div  className='search-field'>
                                <span>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.675 11.5661C9.34155 13.8995 5.55835 13.8995 3.22493 11.5661C0.891523 9.23269 0.891523 5.44948 3.22493 3.11607C5.55835 0.782656 9.34156 0.782656 11.675 3.11607C14.0084 5.44948 14.0084 9.23269 11.675 11.5661ZM11.675 11.5661L16.4126 16.3037" stroke="#868484" strokeWidth="1.5" strokeLinecap="round"/>
                                    </svg>
                                </span>

                                <input type="text"  className='form-control' placeholder='Search product or product ID'/>
                            </div>
                        </div>
                        <div className="col-6 col-md-2 dropdown">
                            <button className='btn btn-filter dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false">
                            <svg width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.94 22.65C10.46 22.65 9.99002 22.53 9.55002 22.29C8.67002 21.8 8.14002 20.91 8.14002 19.91V14.61C8.14002 14.11 7.81002 13.36 7.50002 12.98L3.76002 9.02001C3.13002 8.39001 2.65002 7.31001 2.65002 6.50001V4.20001C2.65002 2.60001 3.86002 1.35001 5.40002 1.35001H18.6C20.12 1.35001 21.35 2.58001 21.35 4.10001V6.30001C21.35 7.35001 20.72 8.54001 20.13 9.13001L15.8 12.96C15.38 13.31 15.05 14.08 15.05 14.7V19C15.05 19.89 14.49 20.92 13.79 21.34L12.41 22.23C11.96 22.51 11.45 22.65 10.94 22.65ZM5.40002 2.85001C4.70002 2.85001 4.15002 3.44001 4.15002 4.20001V6.50001C4.15002 6.87001 4.45002 7.59001 4.83002 7.97001L8.64002 11.98C9.15002 12.61 9.65002 13.66 9.65002 14.6V19.9C9.65002 20.55 10.1 20.87 10.29 20.97C10.71 21.2 11.22 21.2 11.61 20.96L13 20.07C13.28 19.9 13.56 19.36 13.56 19V14.7C13.56 13.63 14.08 12.45 14.83 11.82L19.11 8.03001C19.45 7.69001 19.86 6.88001 19.86 6.29001V4.10001C19.86 3.41001 19.3 2.85001 18.61 2.85001H5.40002Z" fill="#868484"/>
                                <path d="M6.00004 10.75C5.86004 10.75 5.73004 10.71 5.60004 10.64C5.25004 10.42 5.14004 9.94999 5.36004 9.59999L10.29 1.69999C10.51 1.34999 10.97 1.23999 11.32 1.45999C11.67 1.67999 11.78 2.13999 11.56 2.48999L6.63004 10.39C6.49004 10.62 6.25004 10.75 6.00004 10.75Z" fill="#868484"/>
                            </svg>Filters</button> 
                            <div className="dropdown-menu dropdown-menu-end border-0 shadow filter-dropdown">
                               <div className='dropdown-item'> 
                                   <form action="">
                                     <div className="form-group">
                                        <label htmlFor=''>Status</label>
                                        <select id="" className='form-control'>
                                            <option>Active</option>
                                        </select>
                                     </div>
                                     <div className="form-group mt-3">
                                        <label htmlFor=''>Depot</label>
                                        <select id="" className='form-control'>
                                            <option>Wari</option>
                                        </select>
                                     </div>
                                     <div className="form-group mt-3">
                                        <label htmlFor=''>Avail. Volume</label>
                                        <select id="" className='form-control'>
                                            <option>All</option>
                                        </select>
                                     </div>

                                     <div className="row mt-3">
                                        <div className="col-md-6">
                                            <button className="btn btn-reset">Reset</button>
                                        </div>
                                        <div className="col-md-6 text-end">
                                            <button className="btn btn-filter">Filter</button>
                                        </div>
                                     </div>
                                   </form>
                                </div> 
                            </div> 
                        </div>
                        <div className="col-6 col-md-2 dropdown">
                            <button className='btn btn-export dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false">
                            <svg  width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 11V17L11 15" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M9 17L7 15" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M22 10H18C15 10 14 9 14 6V2L22 10Z" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg> Export</button>

                            <ul className="dropdown-menu dropdown-menu-end export-dropdown border-0 shadow">
                               <li className='dropdown-item'> Export as .pdf</li> 
                               <li className='dropdown-item'> Export as .xlsx</li>
                               <li className='dropdown-item'> Export as .doc </li> 
                            </ul> 
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <td><input type="checkbox" checked={selectAll} onChange={handleCheckAll}></input></td>
                                    <td>S/N</td>
                                    <td>Company</td>
                                    <td>Photo</td>
                                    <td>Product</td>
                                    <td>Depot</td>
                                    <td>Price</td>
                                    <td>Selling Price</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                {products.length > 0 && products.map((data: ProductType, index:Key)=><tr key={index}>
                                    <td><input type="checkbox" id={`checkbox-${data.id}`} checked={checkedItems[data.id] || false} onChange={() => handleCheckSingle(data.id)}></input></td>
                                    <td>{ TableSerialNumber(pageNumber, paginationData.pageSize, parseInt(index.toString()))}</td>
                                    <td>{data.depotLocation.company.name}</td>
                                    <td>
                                       <ImageSrc src={data.depotLocation.company.logo?.url} alt={data.depotLocation.company.name} title={data.depotLocation.company.name} width={'company-logo'}></ImageSrc>
                                    </td>
                                    <td>{data.product.shortName}</td>
                                    <td>{data.depotLocation.state}</td>
                                    <td>₦{data.unitCostPrice}/{data.product.shortUnit}</td> 
                                    <td>₦{data.unitSellingPrice}/{data.product.shortUnit} {data.isHidden && <span className='d-icon d-eye-close text-danger'></span>}</td> 
                                    <td className='dropdown option-dropdown'>
                                        <button className='btn border-0 dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false" data-bs-popper-config='{"strategy":"fixed"}'>
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.49991 8.4705C7.49991 7.72933 8.0955 7.14697 8.82344 7.14697C9.55138 7.14697 10.147 7.74256 10.147 8.4705C10.147 9.19844 9.55138 9.79403 8.82344 9.79403C8.0955 9.79403 7.49991 9.21168 7.49991 8.4705Z" fill="#707070"/>
                                                <path d="M7.49991 14.1175C7.49991 13.3763 8.0955 12.7939 8.82344 12.7939C9.55138 12.7939 10.147 13.3895 10.147 14.1175C10.147 14.8454 9.55138 15.441 8.82344 15.441C8.0955 15.441 7.49991 14.8587 7.49991 14.1175Z" fill="#707070"/>
                                                <path d="M7.49991 2.82353C7.49991 2.08235 8.0955 1.5 8.82344 1.5C9.55138 1.5 10.147 2.09559 10.147 2.82353C10.147 3.55147 9.55138 4.14706 8.82344 4.14706C8.0955 4.14706 7.49991 3.56471 7.49991 2.82353Z" fill="#707070"/>
                                            </svg>

                                        </button>

                                        <ul className="dropdown-menu dropdown-menu-end export-dropdown border-0 shadow">
                                            <li className='dropdown-item'><Link to={`/product/${data.product.id}`}>View Details</Link></li> 
                                            <li className='dropdown-item' onClick={()=>{ toggleRateModal(); toggleRateData(data.depotLocation); setProductId(data.product.id)}}>Hide Price</li>
                                            <li className='dropdown-item d-none'>Hide Product </li> 
                                            <li className='dropdown-item text-danger d-none'>Delete </li> 
                                        </ul> 
                                    </td>
                                </tr>)}


                                {loading === false && products.length === 0 &&<tr>
                                    <td colSpan={8} className='text-center'>No product found</td>
                                </tr>}
                                {loading===true && products.length === 0 && <tr>
                                    <td colSpan={8} className='text-center'><HorizontalLoader></HorizontalLoader></td>
                                </tr>}

                            </tbody>
                        </table>
                    </div>

                     {paginationData.total >= paginationData.pageSize && <div className="pagination w-100 d-flex justify-content-center">
                    {paginationData.total && <Pagination
                        className="pagination-bar ps-0"
                        currentPage={Number(paginationData.pageNumber)}
                        totalCount={paginationData.total}
                        pageSize={paginationData.pageSize}
                        onPageChange={(page: React.SetStateAction<number>) => setPage(page)}/>
                        }
                </div>}
                 </div>
               </div>


               <div className="card mt-4 border-0 card-bottom">
                    <div className="card-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12  col-lg-6 my-auto">
                                    <div className="item-delected">
                                       <input type='checkbox'></input>
                                       <div className='text'>{selected} Items Selected</div>
                                    </div>
                                </div>
                                <div className="col-6  col-md-4 col-lg-2">
                                    <button className="btn" onClick={hideBulk} disabled={Object.keys(checkedItems).length === 0}>
                                        <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21.27 9.18005C20.98 8.72005 20.67 8.29005 20.35 7.89005C19.98 7.42005 19.28 7.38005 18.86 7.80005L15.86 10.8001C16.08 11.4601 16.12 12.2201 15.92 13.0101C15.57 14.4201 14.43 15.5601 13.02 15.9101C12.23 16.1101 11.47 16.0701 10.81 15.8501C10.81 15.8501 9.38001 17.2801 8.35001 18.3101C7.85001 18.8101 8.01001 19.6901 8.68001 19.9501C9.75001 20.3601 10.86 20.5701 12 20.5701C13.78 20.5701 15.51 20.0501 17.09 19.0801C18.7 18.0801 20.15 16.6101 21.32 14.74C22.27 13.2301 22.22 10.6901 21.27 9.18005Z" fill="#E873A8"/>
                                            <path d="M14.02 9.98001L9.98001 14.02C9.47001 13.5 9.14001 12.78 9.14001 12C9.14001 10.43 10.42 9.14001 12 9.14001C12.78 9.14001 13.5 9.47001 14.02 9.98001Z" fill="#E873A8"/>
                                            <path d="M18.25 5.75005L14.86 9.14005C14.13 8.40005 13.12 7.96005 12 7.96005C9.76 7.96005 7.96 9.77005 7.96 12.0001C7.96 13.1201 8.41 14.1301 9.14 14.8601L5.76 18.2501H5.75C4.64 17.3501 3.62 16.2001 2.75 14.8401C1.75 13.2701 1.75 10.7201 2.75 9.15005C3.91 7.33005 5.33 5.90005 6.91 4.92005C8.49 3.96005 10.22 3.43005 12 3.43005C14.23 3.43005 16.39 4.25005 18.25 5.75005Z" fill="#E873A8"/>
                                            <path d="M14.86 12C14.86 13.57 13.58 14.86 12 14.86C11.94 14.86 11.89 14.86 11.83 14.84L14.84 11.83C14.86 11.89 14.86 11.94 14.86 12Z" fill="#E873A8"/>
                                            <path d="M21.77 2.23C21.47 1.93 20.98 1.93 20.68 2.23L2.23 20.69C1.93 20.99 1.93 21.48 2.23 21.78C2.38 21.92 2.57 22 2.77 22C2.97 22 3.16 21.92 3.31 21.77L21.77 3.31C22.08 3.01 22.08 2.53 21.77 2.23Z" fill="#E873A8"/>
                                        </svg>
                                        Hide Price  {request && <span className='spinner-border spinner-border-sm'></span>}
                                    </button>
                                </div>
                                <div className="col-6  col-md-4 col-lg-2">
                                    <button className="btn" disabled={true}>
                                        <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.75 12C2.75 6.89421 6.89421 2.75 12 2.75C17.1058 2.75 21.25 6.89421 21.25 12C21.25 17.1058 17.1058 21.25 12 21.25C6.89421 21.25 2.75 17.1058 2.75 12Z" fill="#E873A8" stroke="#E873A8" strokeWidth="1.5"/>
                                            <path d="M22 2L2 22" stroke="#E873A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M23 3L3 23" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        Deactivate
                                    </button>
                                </div>
                                <div className="col-6  col-md-4 col-lg-2">
                                    <button className="btn text-danger" onClick={deleteProduct} disabled={true}>
                                        <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21.07 5.23C19.46 5.07 17.85 4.95 16.23 4.86V4.85L16.01 3.55C15.86 2.63 15.64 1.25 13.3 1.25H10.68C8.35004 1.25 8.13003 2.57 7.97004 3.54L7.76004 4.82C6.83004 4.88 5.90004 4.94 4.97004 5.03L2.93004 5.23C2.51004 5.27 2.21004 5.64 2.25004 6.05C2.29004 6.46 2.65004 6.76 3.07004 6.72L5.11004 6.52C10.35 6 15.63 6.2 20.93 6.73C20.96 6.73 20.98 6.73 21.01 6.73C21.39 6.73 21.72 6.44 21.76 6.05C21.79 5.64 21.49 5.27 21.07 5.23Z" fill="#FF5050"/>
                                            <path d="M19.23 8.14C18.99 7.89 18.66 7.75 18.32 7.75H5.67999C5.33999 7.75 4.99999 7.89 4.76999 8.14C4.53999 8.39 4.40999 8.73 4.42999 9.08L5.04999 19.34C5.15999 20.86 5.29999 22.76 8.78999 22.76H15.21C18.7 22.76 18.84 20.87 18.95 19.34L19.57 9.09C19.59 8.73 19.46 8.39 19.23 8.14ZM13.66 17.75H10.33C9.91999 17.75 9.57999 17.41 9.57999 17C9.57999 16.59 9.91999 16.25 10.33 16.25H13.66C14.07 16.25 14.41 16.59 14.41 17C14.41 17.41 14.07 17.75 13.66 17.75ZM14.5 13.75H9.49999C9.08999 13.75 8.74999 13.41 8.74999 13C8.74999 12.59 9.08999 12.25 9.49999 12.25H14.5C14.91 12.25 15.25 12.59 15.25 13C15.25 13.41 14.91 13.75 14.5 13.75Z" fill="#FF5050"/>
                                        </svg>
                                        Delete All
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
               </div>
            </div> 
        </div> 

        <ModalWrapper showModal={showRateModal} closeModal={toggleRateModal} title="Hide Product Price">
            <HideProductPrice rateDate={rateDate} toggleModal={toggleRateModal} productId={productId}></HideProductPrice>
        </ModalWrapper>
    </div>
    )
}

export default Products
