import axios from 'axios'

import { AxiosInterceptor } from '../Config/AxiosInterceptor';
 
AxiosInterceptor()

const loanService = { 

  loanProvider: () => { 
    return axios.get('/loanProviders')
  },
  
  asignLoanProvider: (msisdn:string|undefined, provider:string|null|undefined, isSecondary: string|undefined) => { 
    return axios.put(`/customers/${msisdn}/assignLoanProvider/${provider}?isSecondary=${isSecondary && isSecondary ==='1'? false : true}`)
  }, 

  unAsignLoanProvider: (msisdn:string|undefined) => { 
    return axios.put(`/customers/${msisdn}/unassignLoanProvider`)
  },

  tagCustomer: (msisdn:string|undefined, tag:string|null|undefined) => { 
    return axios.put(`/customers/${msisdn}/tag/${tag}`)
  }, 

  vfdAllowFundDebit: (msisdn:string|undefined) => { 
    return axios.put(`/customers/${msisdn}/loanprovider/vfd/vfdAllowFundDebit`)
  },

  loanProvidersCustomers: (pageNumber:number, loanProvider:string | undefined) =>{
    return axios.get(`/loanProviders/${loanProvider}/customers?pageNumber=${pageNumber}`)
  },
  
  loanProvidersTransaction: (pageNumber:number, loanProvider:string|undefined) =>{
    return axios.get(`/loanProviders/${loanProvider}/customers/transactions?pageNumber=${pageNumber}`)
  }, 
  
  searchLPTransaction: async (loanProvider:string| undefined, msisdn:string, pageNumber:number, sortOrder:string) => { 
    return await axios.get(`/loanProviders/${loanProvider}/customers/transactions?customerMsisdn=${msisdn}&pageNumber=${pageNumber ?? 1}&sortOrder=${sortOrder}`);
  },  
  loanProvidersCustomerBalance: (loanProvider:string| undefined, msisdn:string) =>{
    return axios.get(`/loanProviders/${loanProvider}/customers/${msisdn}/balance`)
  },  
  loanProvidersCounterBalance: (loanProvider:string| undefined) =>{
    return axios.get(`/loanProviders/${loanProvider}/loanCounterBalance`)
  },

  loanProvidersCustomerOrders: (pageNumber:number, loanProvider:string|undefined, customerAccountId: string| undefined) =>{
    return axios.get(`/loanProviders/${loanProvider}/customers/orders?pageNumber=${pageNumber}&customerAccountId=${customerAccountId}`)
  }, 

  loanProvidersPOSTransaction: async (loanProvider:string|undefined, pageNumber:number, accountNumber:string|undefined, startDate: string| undefined, endDate: string|undefined, transactionType: string) =>{
    return await axios.get(`/${loanProvider?.toLowerCase()}/account/transactions?accountNumber=${accountNumber}&startDate=${startDate}&endDate=${endDate}&transactionType=${transactionType}&page=0`);
   }, 

   loanProvidersAllPOSTransaction: async (loanProvider:string|undefined, pageNumber:number, startDate: string| undefined, endDate: string|undefined, transactionType: string) =>{
     return await axios.get(`/${loanProvider?.toLowerCase()}/account/transactions?startDate=${startDate}&endDate=${endDate}&transactionType=${transactionType}&page=0`);
    }
}

export default loanService
