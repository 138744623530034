import {Key, useEffect, useState} from 'react'
import './BulkSmsHistory.scss'
import smsService from 'Services/smsService'
import { ShowTGAlert } from 'Helpers/ShowTGAlert'
import { Logger } from 'Helpers/Logger'
import { HorizontalLoader, Pagination } from 'Components'
import { TimeConverter } from 'Helpers/TimeConverter';
import { useNavigate } from 'react-router-dom'
 
export interface HistoryType {
    id: number,
    date: string,
    message: string,
    noOfRecipients: 0,
    info: string
  }

const BulkSmsHistory = () => {

    const [pageNumber, setPage] = useState(1) 
    // const [sortOrder, setSortOrder] = useState('DESC') 
    
    const [histories, setHistories] = useState([]); 
    const [loading, setLoading] = useState(false);

    const { showAlert } = ShowTGAlert() 
    
    const [paginationData, setPaginationData] = useState<{
        pageNumber: number,
        pageSize: number,
        total:number
      }>({
        pageNumber: 1,
        pageSize: 20,
        total: 1
      })
      
   const getHistory = async () => {
    setLoading(true)
     await smsService.getHistory(pageNumber, 'DESC').then((response)=>{    
        setLoading(false)
        setHistories(response?.data.data.dataset)
        console.log('response', response)

        setPaginationData({
            pageNumber: response?.data.data.pageNumber,
            pageSize: response?.data.data.pageSize,
            total: response?.data.data.total
          })

      }, (error: { response: {data: {error: string} }}) =>{     
        setLoading(false)
        showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'}) 
    
        Logger('response error', error.response) 
      })
   }

   useEffect(() => {
    getHistory()
   }, [pageNumber])


   const getSentStats = (inputString2:string) =>{ 
    const regex = /\b1\/1\b/;
    const match = inputString2.match(regex);
    
    if (match) {
      const extractedPattern = match[0];
      return extractedPattern 
    } else {
        return '0/0' 
    }  
   }

   const getSmsCost = (inputString:string)=>{ 
        // Use a regular expression to extract the country code and number(PRICE)
        const regex = /([A-Z]+) (\d+(\.\d+)?)/;
        const match = inputString.match(regex);

        if (match) {
            const countryCode = match[1];
            const phoneNumber = match[2];  
            return `${countryCode} ${phoneNumber}`
        } else {
            return ''
        }
   
   }

   const deliveryRate = (inputString:string) => { 

        // Split the string into two components
        const components = inputString.split('/');

        if (components.length === 2) {
            // Extract the numerator and denominator
            const numerator = parseInt(components[0]);
            const denominator = parseInt(components[1]);

            // Calculate the percentage
            const percentage = (numerator / denominator) * 100;
 
            return `${percentage}%`
        } else {
            return '0%'
        }
   }

   const navigate = useNavigate()

    return (
        <div className='BulkSmsHistory'>
            <div className="container mt-4">
                <div className="card border-0">
                    <div className="card-header border-0">
                        <div className="row">
                            <div className="col-12 col-md-8 my-auto">
                                <div className="title">
                                    Bulk SMS History
                                </div>
                            </div> 
                        </div> 
                    </div>


                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Date</th> 
                                    <th>Type</th>
                                    <th>Message</th>
                                    <th>No Of Recipients</th>
                                    <th>Delivery Rate</th>
                                    <th>Amount Spent</th> 
                                </tr>
                            </thead>
                            <tbody>
                            {histories.length > 0 && histories.map((data: HistoryType, index:Key)=>{
                              return data.info && <tr key={index} onClick={()=>navigate(`/bulksms-history/${data.id}`, {replace: true})
                              }>
                                    <td>{TimeConverter(data.date)}</td>
                                    <td>Bulk Send</td>
                                    <td><div>{data.message}</div></td>
                                    <td>{data.noOfRecipients}</td>  
                                    <td>{deliveryRate(getSentStats(data.info.toString()))} </td> 
                                    <td> {getSmsCost(data.info.toString())}</td>  
                                </tr>})}
                                

                                {loading === false && histories.length === 0 &&<tr>
                                    <td colSpan={4} className='text-center'>No sms history found</td>
                                </tr>}
                                {loading===true && histories.length === 0 && <tr>
                                    <td colSpan={4} className='text-center'><HorizontalLoader></HorizontalLoader></td>
                                </tr>}
                            </tbody> 
                        </table>
                                
                        {paginationData.total >= paginationData.pageSize && <div className="pagination w-100 d-flex justify-content-center">
                            {paginationData.total && <Pagination
                                className="pagination-bar ps-0"
                                currentPage={Number(paginationData.pageNumber)}
                                totalCount={paginationData.total}
                                pageSize={paginationData.pageSize}
                                onPageChange={(page: React.SetStateAction<number>) => setPage(page)}/>
                                }
                        </div>}
                    </div>
                </div>
                </div>
            </div> 
        </div>
    )
}

export default BulkSmsHistory
