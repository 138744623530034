import AdminHeader from 'Layout/Admin/Header/AdminHeader'
import AdminMobileHeader from 'Layout/Admin/MobileHeader/AdminMobileHeader' 
import React, { FC , ReactNode, useEffect } from 'react' 
import './PublicWrapper.scss'
import { TGModalAlert } from 'Components'
import { useDispatch, useSelector } from 'react-redux' 
import { SHOW_ALERT } from 'Constants/AppAlertConstants'
import { appAlertType } from 'Reducers/AppAlerts/AppAlertsReducer' 
import VFDImage from 'Assets/Images/VFD.png';

interface propTypes {
    children: ReactNode,
    external: boolean
  }
  
  const PublicWrapper: FC<propTypes> = ({ children , external}) => { 


     // MODAL ALERT 
     const dispatcher = useDispatch()

     const handleCloseAlertModal = () => { 
        dispatcher({type: SHOW_ALERT, payload: { message: '', title: '', show: false}})
     };

     const appAlert = useSelector((state: { appAlert: appAlertType})=>state?.appAlert)
    


    useEffect(() => {
      const handleUnload = () => {
        // Clear data from local storage
        // localStorage.removeItem('__TG');
      };

      const handleVisibilityChange = () => {
        if (document.visibilityState === 'hidden') {
          // User is inactive, clear data from local storage
          // localStorage.removeItem('__TG');
        }
      };

      window.addEventListener('beforeunload', handleUnload);
      document.addEventListener('visibilitychange', handleVisibilityChange);

      return () => {
        window.removeEventListener('beforeunload', handleUnload);
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }, []);
     
    return (
      <React.Fragment>
      <div className='adminWraper publicadminWraper p-0 m-0 w-100'> 
        <div className="row justify-content-center"> 
          <div className="col-md-8"> 
            {!external && <AdminMobileHeader publicAccess={true}/>}
            {!external && <AdminHeader publicAccess={true}></AdminHeader>} 
            {external && <div className='vfd-25'><img src={VFDImage} className='vfd-logo'></img></div>}
              { children } 
          </div>
        </div> 

        <div className="copyright text-center fs-6 text-secondary py-4">&copy; 2023 Tradegrid - All Rights Reserved.</div>
      </div>

      <TGModalAlert 
          show={appAlert?.show}
          title={appAlert?.title}
          message={appAlert?.message}
          onClose={handleCloseAlertModal}
          type={appAlert?.type}
      />
    </React.Fragment>
    )
}
  

export default PublicWrapper

