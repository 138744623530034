import { FC, ChangeEvent, useRef } from 'react';
import './DocumentUploadCard.scss';

interface propTypes { 
    description:string;
    value?:(value:ChangeEvent<HTMLInputElement>)=>void;
    changeHandler: (value:ChangeEvent<HTMLInputElement>, doc:string)=>void;
    name:string;
    doc:string; 
    checkIfExist:(value:string)=>boolean
}

const DocumentUploadCard:FC<propTypes> = ({ description, changeHandler, name, doc, checkIfExist}) => {
  
  const fileInputRef = useRef<HTMLInputElement | null>(null);
 
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };


    return (
        <div className='document-upload w-100'> 
            <div className="document-desc">{description}</div> 
            <label className="mb-3 file-upload  mt-1" htmlFor={name}>
                <button  type="button" className="btn btn-upload" onClick={handleButtonClick}>Add file</button> 
                <input 
                    id={name}
                    type="file" 
                    accept="image/*" 
                    className="form-control file-upload-input" 
                    onChange={(event)=>changeHandler(event, doc)} 
                    name={name}
                    ref={fileInputRef}
                />
            </label>
            {checkIfExist(doc) ===true && <span className="d-icon d-check text-success fs-6 ms-3"></span>}
        </div>
    )
}

export default DocumentUploadCard;