import React, { Key, useEffect, useState } from 'react'
import './RecentTransactions.scss'
import transactionService from 'Services/transactionService'
import { Logger } from 'Helpers/Logger'
import { Link } from 'react-router-dom'
import { formatUnixTimestamp } from 'Helpers/TimeConverter'
import { CurrencyFormatter } from 'Helpers'
import { HorizontalLoader } from 'Components'
import { TransactionType } from 'Models/trasactionType'

const RecentTransactions = () => {
    const [transactionFlowType, setTransactionFlowType] = useState<string>() 
    const [status, ] = useState('ALL') 
       
    const [order, setOrder] = useState([]); 
    const [loading, setLoading] = useState(false);
    
    const getTransaction = async () => {
        setLoading(true)
  
        await transactionService.index(1, 'DESC', status, transactionFlowType)
          .then((response)=>{    
            setLoading(false)
            setOrder(response?.data.data.dataset) 
          }, (error: { response: object }) =>{     
            setLoading(false) 
            Logger('response error', error.response) 
          })
    }

    useEffect(() => {
      getTransaction()
    }, [transactionFlowType])


    return (
        <div className='recentTransactions'> 
            <div className="card">
                <div className="card-header bg-white border-0">
                    <div className="row">
                        <div className="col-md-8">
                              Recent Transactions
                        </div>
                        <div className="col-md-4">
                            <select id="" className='form-control' onChange={(e)=>{setTransactionFlowType(e.target.value)}} style={{ height: '30px' }}>
                                <option value="IN">Filter</option>
                                <option value="IN">Credit</option>
                                <option value="OUT">Debit</option>
                            </select>
                        </div>
                    </div> 
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr> 
                                    <td>#</td>
                                    <td>Customer</td>  
                                    <td>Amount</td>  
                                    <td>Date</td>  
                                </tr>
                            </thead>
                            <tbody>

                               {loading===true && order.length === 0 && <tr>
                                    <td colSpan={8} className='text-center'><HorizontalLoader></HorizontalLoader></td>
                                </tr>}
                              {order.length > 0 && order.map((data: TransactionType, index:Key)=><tr key={index}>
                                    
                                    <td>{Number(index) + 1}</td>     
                                    <td>{data.customer.firstName} {data.customer.lastName}</td>   
                                    <td>{data.currency}{CurrencyFormatter(data.amountMajor)}</td>     
                                    <td>{formatUnixTimestamp(data.createdAt)}</td>  
                              </tr>)} 

                                {loading === false && order.length === 0 &&<tr>
                                    <td colSpan={8} className='text-center'>No transaction found</td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-footer bg-white text-end py-1">
                    <Link to="/transactions" className='view-all'>View all</Link>
                </div>
             </div>
        </div>
    )
}

export default RecentTransactions
