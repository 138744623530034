import React, { useEffect, useState, ChangeEvent, FC } from 'react'
import './CreatePlp.scss'
import { BreadCrumb } from 'Components'
import { useForm } from 'react-hook-form' 
import { yupResolver } from '@hookform/resolvers/yup';  
import * as yup from 'yup';
import { ShowTGAlert } from 'Helpers/ShowTGAlert';
import plpService from 'Services/plpService';
import companyService from 'Services/companyService';
import { Logger } from 'Helpers/Logger'; 
import { locationService } from 'Services/locationService';
import { countryType, stateType } from '../../DeliveryUltra/DeliveryUltra';
import PlpCompanyDetailsStep from './PlpCompanyDetailsStep';
import PlpCompanyDetailsStepTwo from './PlpCompanyDetailsStepTwo';
import PlpDocumentUpload from './PlpDocumentUpload';
import productService from 'Services/productService';
import { productListType } from 'Models/productType';
import { useNavigate } from 'react-router-dom';
import customerService from 'Services/customerService';
 

export interface CreatePlpType {   
       businessRegistrationType: string,
       cacNumber: string,
       companyName: string,
       companyAddress: string,
       companyState: string,
       companyLGA: string,
       productName: string,
       dailySalesCapacity?: number,
       documentType?: string,
      //  moniePointCustomerAccount: string,
      bvn: string,
      customerMsisdn: string,
      incorporationDateISO8601: string; 
}
  
  const schema = yup.object().shape({ 
    businessRegistrationType: yup.string().required('Business Registration Type field is required'),
    cacNumber: yup.string().required('CAC Number field is required'),
    companyName: yup.string().required('Company Name field is required'),
    companyAddress: yup.string().required('Company Address field is required'),
    companyState: yup.string().required('Company State field is required'),
    companyLGA: yup.string().required('Company LGA field is required'),
    productName: yup.string().required('Product field field is required'),
    // dailySalesCapacity: yup.number().notRequired('Daily Sales Capacity field is required'),  
    // documentType: yup.string().required('Document Type field is required'),  
    bvn: yup.string().required('BVN field is required'),  
    customerMsisdn: yup.string().required('Customer Msisdn field is required'),  
    incorporationDateISO8601: yup.string().required('Incorporation Date field is required'),  
  });

  interface propsType {
      publicAccess? :boolean
    }
const CreatePlp:FC<propsType> = ({ publicAccess }) => {

    const [request, setRequest] = useState(false)
    const { showAlert } = ShowTGAlert() 
    const [depotId, setDepotId] = useState<string>();  
    const [companies, setCompanies] = useState([]); 
    const [locations, setLocations] = useState([]); 
    const [depotLocationId, setDepotLocationId] = useState<string>();  
    const [locationsPrice, setLocationsPrice] = useState<Array<productListType>>([]); 
  
    const [errorMessages, setErrorMessage] = useState<string | null>()

    const [selectedCountry, setSelectedCountry] = useState('');

    const [images, setImages] = useState<Array<{file: File, name: string}>>([]) 
    const uploadable =  [
        'PLP_APPLICATION_CAC_7',
        'PLP_APPLICATION_COMPANY_BANK_STATEMENT_LAST_12_MONTHS', 
        'PLP_APPLICATION_FILLING_STATION_REAL_OWNER_LEASE_AGREEMENT',
        'PLP_APPLICATION_ID',
        'PLP_APPLICATION_FUEL_PUMP_MOTHERBOARD'
    ] 

    const navigate = useNavigate()

    const [lgas, setLgas] = useState<Array<string>>([]); 
    const [step, setStep] = useState(1);

    const handleNext = () => {
      if (step < 3) {
        setStep(step + 1);
      }
    };
  
    const handlePrev = () => {
      if (step > 1) {
        setStep(step - 1);
      }
    };



    const { register, handleSubmit, formState: { errors } } =  useForm<CreatePlpType>({
        resolver: yupResolver(schema),
      });
      
      const onSubmit = handleSubmit(async (data: CreatePlpType) => { 

        console.log('onSubmit', data)

        const errorMessage = checkDocumentExist(); 
        if(errorMessage !== null){
           return false
        }

        setRequest(true)
        try {
            const response = await plpService.create(data);
            
            if (response.data.data.id !== undefined && response.data.data.id !== null) {
              
                const imageSize = images.length

               if(imageSize > 0){
                  await startUpload(response.data.data.id);
                } else {
                  setRequest(false)  
                  showAlert({message: 'PLP created successfully', show:true, title: 'Success', type: 'success'})  
                
                  setTimeout(() => { 
                    window.location.reload()
                  }, 3000);
                }
             }
          } catch (error: unknown | object) {
            setRequest(false);
            
            if (typeof error === 'object' && error !== null) {
              const errorMessage = (error as { response: { data: { error: string } } });
          
              console.error('Response ERROR', errorMessage);
          
              if (errorMessage.response) {
                showAlert({ message: errorMessage.response.data.error, show: true, title: 'Error', type: 'error' });
              } else {
                showAlert({ message: 'An error occurred', show: true, title: 'Error', type: 'error' });
              }
            } else {
              showAlert({ message: 'An error occurred', show: true, title: 'Error', type: 'error' });
            }
          }  

          
      });



      const startUpload = async (plpUuid:string) => {
       
        const arraySize = images.length
        for (const [index, currentPlpImage] of images.entries()) {
          const formData = new FormData();
       
          formData.append(
            'file',
            currentPlpImage.file
          ); 

          await plpService.file(plpUuid, formData, currentPlpImage.name).then((response) => {  
             
            Logger('file upload success =============', response)

            if (index === arraySize - 1) {
                setRequest(false) 

                showAlert({message: 'PLP created successfully', show:true, title: 'Success', type: 'success'}) 
                  setTimeout(() => { 
                    window.location.reload()
                  }, 3000);
                    if(publicAccess){
                      setTimeout(() => { 
                        window.location.reload()
                      }, 3000);
                    }else{
                      navigate(`/plp/detail/${plpUuid}`, {replace: true})
                    }
            }

          }, (error: { response: { data: { error: string; }; }; }) => { 
            setErrorMessage( 'Error occured while uploading your documents:  ' + error.response.data.error);
               
            if (index === arraySize - 1) { 
                setRequest(false) 
                setErrorMessage( 'PLP was submitted successfully but an error occured while uploading your documents:  ' + error.response.data.error);
                showAlert({message: 'PLP was submitted successfully but an error occured while uploading your documents:  ' + error.response.data.error, show:true, title: 'Error', type: 'error'})
            }

          })
        }
      }
 
      // const startUpload = async (plpUuid: string) => {
      //   try {
      //     for (const [index, currentPlpImage] of images.entries()) {
      //       const formData = new FormData();
      
      //       formData.append('file', currentPlpImage.file);
      
      //       const response = await plpService.file(plpUuid, formData, currentPlpImage.name);
      
      //       if (response.status === 200) {
      //         console.log('File upload success:', response.data);
      
      //         if (index === images.length - 1) {
      //           setRequest(false);
      //           showAlert({
      //             message: 'PLP created successfully',
      //             show: true,
      //             title: 'Success',
      //             type: 'success',
      //           });

      //           if(publicAccess){
      //             setTimeout(() => { 
      //               window.location.reload()
      //             }, 3000);
      //           }else{
      //             navigate(`/plp/detail/${plpUuid}`, {replace: true})
      //           } 
                
      //         }
      //       } else {
      //         // Handle non-200 HTTP status codes
      //         console.error('File upload failed with status code:', response.status);
      //         setRequest(false);
      //         setErrorMessage('An error occurred while uploading files.');
      //         showAlert({
      //           message: 'An error occurred while uploading files.',
      //           show: true,
      //           title: 'Error',
      //           type: 'error',
      //         });
      //       }
      //     }
      //   } catch (error) {
      //     console.error('File upload error:', error);
      //     setRequest(false);
      //     setErrorMessage('An error occurred while uploading files.');
      //     showAlert({
      //       message: 'An error occurred while uploading files.',
      //       show: true,
      //       title: 'Error',
      //       type: 'error',
      //     });
      //   }
      // };

     const getCompany =  async () => { 
        await companyService.index(1)
          .then((response)=>{    
            setCompanies(response?.data.data.dataset)  
          }, (error: { response: object }) =>{      
            Logger('response error', error.response) 
          })
    }

    useEffect(() => {
        getCompany()
    }, [])


    const [states, setStates] = useState<Array<stateType>>([]);
    const [countries, setCountries] = useState<Array<countryType>>([]);
     
    const getLocation = async () =>{
        await locationService.index().then((response)=>{ 
          setCountries(response.data.data)
        }, (error) => {
            Logger('response error', error.response) 
        })
    }
    useEffect(() => { 
        getLocation()
    }, [])

   
 


  const selectState = (countryName:{target: { value: string }})=>{
      setSelectedCountry(countryName.target.value)
      if(countryName.target.value){ 
          const country = JSON.parse(countryName.target.value)
          setStates(country.states) 
      }

   }

   const selectLga = (stateName:{target: { value: string }})=>{ 
    
    if(stateName){  
         const state:stateType|undefined = JSON.parse(selectedCountry)?.states?.find((res: { name:string })=>res.name === stateName.target.value)
 
        if(state)setLgas(state?.localGovernmentAreas)
    }
}

   useEffect(  () => { 
    productService.getAll().then((response)=>{ 
      setLocationsPrice(response?.data.data) 
    },(error: { response: object }) =>{     
     Logger('response error', error.response) 
   })
 
},[depotLocationId])


useEffect(() => {  
    companyService.locations(depotId, 1)
    .then((response)=>{     
      setLocations(response?.data.data.dataset)  
    }, (error: { response: object }) =>{      
      Logger('response error', error.response) 
    })
},[depotId])



const handleFileUpload =  async (event: ChangeEvent<HTMLInputElement>, fileName:string) => { 
    Logger('fileName', fileName); 
    if(event.target.files){
        setImages([...images, { file: event.target.files[0], name: fileName }]) 
    }
   };

const checkIfExist = (fName:string) => { 
    const found = images.some((el: { name: string }) => el.name === fName);
    return found;
}

// Check all document are select
const checkDocumentExist = () => {
    const missingDocument = images.filter((item: {name:string}) => !uploadable.includes(item.name));

    if (missingDocument.length > 0) {
      setErrorMessage(`The following documents are missing: ${missingDocument.join(', ')}`); 
      return `The following documents are missing: ${missingDocument.join(', ')}`;
    }

    return null; // No error
  };
  
   const message = checkDocumentExist()

   useEffect(()=>{ 
      setErrorMessage(message); 
   }, [message])


   const [msisdnErrors, setMsisdnErrors] = useState<boolean>();
   const [msisdnDetails, setMsisdnDetails] = useState<string>();
   

   const verifyMsisdn = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
     const msisdn: string = e.target.value; 
     const endpoint = `customerMsisdn=${encodeURIComponent(msisdn)}`; 
     if (msisdn.length >= 10 && msisdn.length <= 15) {
           await customerService.searchCustomer(endpoint, 1, 'DESC').then((response)=>{ 
              // const customerDetails = response.data.data.datalist[0] as CustomerType
               console.log('searchCustomer response', response.data.data.dataset[0].firstName) 
              //  setValue("bvn", customerDetails.)
               setMsisdnErrors(false); 
               setMsisdnDetails(`${response.data.data.dataset[0].firstName} ${response.data.data.dataset[0].lastName}`); 

           }, (error: {response: {data: {error:string}}}) => { 
               setMsisdnErrors(true);
               Logger(error.response);
           });
       
       }
   }
   
   
    return (
        <div className='createPlp'>
                
           <div  className={!publicAccess? 'container': ''}>
              {!publicAccess && <BreadCrumb title={'PLP Applications'} parent={'PLP'} parentUrl={'plp'} showTitle={false}></BreadCrumb>}
                <div className={!publicAccess? 'container mt-2': ''}>
 
                    <div className="card border-0">
                        <div className="card-header border-0 px-0">
                            <div className="row">
                                <div className="col-12 col-md-8 my-auto">
                                {publicAccess && <div className="title px-2">
                                      PLP Application Form
                                    </div>} 
                                {!publicAccess && <div className="title">
                                    Add new PLP
                                    </div>}
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                           <div className="progress">
                                <div
                                    className={`progress-bar bg-success ${
                                        step === 1 ? 'w-0' : step === 2 ? 'w-50' : 'w-100'
                                    }`}
                                />
                              </div>
                            <div> 
                                {Object.keys(errors).length > 0 && (
                                    <div className="alert alert-danger mt-4">
                                        <ul>
                                            {Object.values(errors).map((error, index) => (
                                            <li key={index}>{error.message}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )}

                               {errorMessages && <div className="alert alert-danger mt-4">{errorMessages}</div>}

                                <div>
                                    <form onSubmit={onSubmit}> 
                                      {step === 1 && <PlpCompanyDetailsStep 
                                          errors={errors} 
                                          register={register}  
                                          companies={companies} 
                                          setDepotId={setDepotId} 
                                          setDepotLocationId={setDepotLocationId} 
                                          locations={locations} 
                                          locationsPrice={locationsPrice}  
                                          selectState={(e)=>selectState(e)}
                                          countries={countries} 
                                          states={states}
                                          selectLga={selectLga}
                                          lgas={lgas}
                                          onNext={handleNext} 
                                          publicAccess={publicAccess}
                                          verifyMsisdn={verifyMsisdn}
                                          msisdnErrors={msisdnErrors}
                                          msisdnDetails={msisdnDetails}> 
                                          </PlpCompanyDetailsStep>}

                                          {step === 2 && <PlpCompanyDetailsStepTwo
                                            errors={errors} 
                                            register={register}  
                                            companies={companies} 
                                            setDepotId={setDepotId} 
                                            setDepotLocationId={setDepotLocationId} 
                                            locations={locations}   
                                            selectState={(e)=>selectState(e)}
                                            countries={countries} 
                                            states={states}
                                            onNext={handleNext} 
                                            handlePrev={handlePrev}
                                            publicAccess={publicAccess}> 
                                          </PlpCompanyDetailsStepTwo>}

                                          {step === 3 && <PlpDocumentUpload 
                                            handleFileUpload={(event:ChangeEvent<HTMLInputElement>, filename:string)=>handleFileUpload(event, filename)} 
                                            checkIfExist={(value: string)=>checkIfExist(value)}
                                            publicAccess={publicAccess}>
                                            </PlpDocumentUpload>
                                          }

                                          {step === 3 && (
                                              <div className='d-inline'>
                                                  <div className="row">  
                                                      {!request && <div className="col-6 col-sm-3 col-md-2">  
                                                          <button type="button" className='btn btn-default' onClick={handlePrev}>Previous</button>
                                                      </div>}
                                                      {!request &&<div className="col-6 col-sm-3 col-md-2"> 
                                                          <button type="submit" className='btn btn-default'>Submit</button>
                                                      </div>}
                                                      {request &&<div className="col-6 col-sm-3 col-md-2"> 
                                                          <button type="submit" className='btn btn-default'>Submit <span className='spinner-border spinner-border-sm'></span></button>
                                                      </div>}
                                                  </div> 
                                              </div> 
                                          )}
                                      </form>
                                    
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreatePlp
