import React, { useEffect, useState, ChangeEvent, FC } from 'react'
import './CreatePlp.scss'
import { BreadCrumb } from 'Components'
import { useForm } from 'react-hook-form' 
import { yupResolver } from '@hookform/resolvers/yup';  
import * as yup from 'yup';
import { ShowTGAlert } from 'Helpers/ShowTGAlert';
import plpService from 'Services/plpService';
import companyService from 'Services/companyService';
import { Logger } from 'Helpers/Logger'; 
import { locationService } from 'Services/locationService';
import { countryType, stateType } from '../../DeliveryUltra/DeliveryUltra';
import PlpCompanyDetailsStep from './PlpCompanyDetailsStep'; 
import productService from 'Services/productService';
import { productListType } from 'Models/productType'; 
import customerService from 'Services/customerService';
 

export interface CreatePlpType {   
       businessRegistrationType: string,
       cacNumber: string,
       companyName: string,
       companyAddress: string,
       companyState: string,
       companyLGA: string,
       productName: string,  
      bvn: string,
      customerMsisdn: string,
      incorporationDateISO8601: string; 
}
  
  const schema = yup.object().shape({ 
    businessRegistrationType: yup.string().required('Business Registration Type field is required'),
    cacNumber: yup.string().required('CAC Number field is required'),
    companyName: yup.string().required('Company Name field is required'),
    companyAddress: yup.string().required('Company Address field is required'),
    companyState: yup.string().required('Company State field is required'),
    companyLGA: yup.string().required('Company LGA field is required'),
    productName: yup.string().required('Product field field is required'),   
    bvn: yup.string().required('BVN field is required'),  
    customerMsisdn: yup.string().required('Customer Msisdn field is required'),  
    incorporationDateISO8601: yup.string().required('Incorporation Date field is required'),  
  });

  interface propsType {
      publicAccess? :boolean
    }
const CreatePlpv2:FC<propsType> = ({ publicAccess }) => {

    const [request, setRequest] = useState(false)
    const { showAlert } = ShowTGAlert() 
    const [depotId, setDepotId] = useState<string>();  
    const [companies, setCompanies] = useState([]); 
    const [locations, setLocations] = useState([]); 
    const [depotLocationId, setDepotLocationId] = useState<string>();  
    const [locationsPrice, setLocationsPrice] = useState<Array<productListType>>([]); 
    
    const [selectedCountry, setSelectedCountry] = useState('');
  
    const [lgas, setLgas] = useState<Array<string>>([]); 
    const [step, setStep] = useState(1);

    const handleNext = () => {
      if (step < 3) {
        setStep(step + 1);
      }
    };
   
 
    const { register, handleSubmit, formState: { errors } } =  useForm<CreatePlpType>({
        resolver: yupResolver(schema),
      });
      
      const onSubmit = handleSubmit(async (data: CreatePlpType) => { 

        console.log('onSubmit', data)
 
        const msisdn: string = addCountryCode(data.customerMsisdn)

        const  newData =  {...data, customerMsisdn: msisdn} 
        console.log('newData', newData)
        setRequest(true)
        try {
            const response = await plpService.create(newData);
            
            if (response.data.data.id !== undefined && response.data.data.id !== null) {
               console.log('CREATE PLP UUID', response.data.data)
               
               setRequest(false) 

               showAlert({message: 'PLP created successfully', show:true, title: 'Success', type: 'success'}) 
                 
                   if(publicAccess){
                     setTimeout(() => { 
                       window.location.reload()
                     }, 3000);
                   }  
            }
          } catch (error: unknown | object) {
            setRequest(false);
            
            if (typeof error === 'object' && error !== null) {
              const errorMessage = (error as { response: { data: { error: string } } });
          
              console.error('Response ERROR', errorMessage);
          
              if (errorMessage.response) {
                showAlert({ message: errorMessage.response.data.error, show: true, title: 'Error', type: 'error' });
              } else {
                showAlert({ message: 'An error occurred', show: true, title: 'Error', type: 'error' });
              }
            } else {
              showAlert({ message: 'An error occurred', show: true, title: 'Error', type: 'error' });
            }
          }   
      });

 
   
     const getCompany =  async () => { 
        await companyService.index(1)
          .then((response)=>{    
            setCompanies(response?.data.data.dataset)  
          }, (error: { response: object }) =>{      
            Logger('response error', error.response) 
          })
    }

    useEffect(() => {
        getCompany()
    }, [])


    const [states, setStates] = useState<Array<stateType>>([]);
    const [countries, setCountries] = useState<Array<countryType>>([]);
     
    const getLocation = async () =>{
        await locationService.index().then((response)=>{ 
          setCountries(response.data.data)
        }, (error) => {
            Logger('response error', error.response) 
        })
    }
    useEffect(() => { 
        getLocation()
    }, [])

    
  const selectState = (countryName:{target: { value: string }})=>{
      setSelectedCountry(countryName.target.value)
      if(countryName.target.value){ 
          const country = JSON.parse(countryName.target.value)
          setStates(country.states) 
      }

   }

   const selectLga = (stateName:{target: { value: string }})=>{ 
    
    if(stateName){  
         const state:stateType|undefined = JSON.parse(selectedCountry)?.states?.find((res: { name:string })=>res.name === stateName.target.value)
 
        if(state)setLgas(state?.localGovernmentAreas)
    }
}

   useEffect(  () => { 
    productService.getAll().then((response)=>{ 
      setLocationsPrice(response?.data.data) 
    },(error: { response: object }) =>{     
     Logger('response error', error.response) 
   })
 
},[depotLocationId])


useEffect(() => {  
    companyService.locations(depotId, 1)
    .then((response)=>{     
      setLocations(response?.data.data.dataset)  
    }, (error: { response: object }) =>{      
      Logger('response error', error.response) 
    })
},[depotId])

  
   const [msisdnErrors, setMsisdnErrors] = useState<boolean>();
   const [msisdnDetails, setMsisdnDetails] = useState<string>();
   

   const verifyMsisdn = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
     const phone: string = e.target.value; 
     const msisdn: string = addCountryCode(phone)
     const endpoint = `customerMsisdn=${encodeURIComponent(msisdn)}`; 
     if (msisdn.length >= 10 && msisdn.length <= 15) {
           await customerService.searchCustomer(endpoint, 1, 'DESC').then((response)=>{ 
              // const customerDetails = response.data.data.datalist[0] as CustomerType
               console.log('searchCustomer response', response.data.data.dataset[0].firstName) 
              //  setValue("bvn", customerDetails.)
               setMsisdnErrors(false); 
               setMsisdnDetails(`${response.data.data.dataset[0].firstName} ${response.data.data.dataset[0].lastName}`); 

           }, (error: {response: {data: {error:string}}}) => { 
               setMsisdnErrors(true);
               Logger(error.response);
           });
       
       }
   }
   

  const addCountryCode = (phone:string):string =>{ 

    if (/^\+\d{11,}$/g.test(phone)) {
      // Skip formatting
      return phone; 
    }

    const trimmedNumber = phone.replace(/^0+/, ''); 
    const formattedNumber = `+234${trimmedNumber}`;
    return formattedNumber;  
  }
   
    return (
        <div className='createPlp'>
                
           <div className={!publicAccess? 'container': ''}>
              {!publicAccess && <BreadCrumb title={'PLP Applications'} parent={'PLP'} parentUrl={'plp'} showTitle={false}></BreadCrumb>}
                <div className={!publicAccess? 'container mt-2': ''}>
 
                    <div className="card border-0">
                        <div className="card-header border-0 px-0">
                            <div className="row">
                                <div className="col-12 col-md-8 my-auto">
                                {publicAccess && <div className="title px-2">
                                   VFD - PLP Application form
                                  </div>} 
                                {!publicAccess && <div className="title">
                                    Add new PLP
                                    </div>}
                                </div>
                            </div>
                        </div>
                        <div className='card-body'> 
                            <div> 
                                {Object.keys(errors).length > 0 && (
                                    <div className="alert alert-danger mt-4">
                                        <ul>
                                            {Object.values(errors).map((error, index) => (
                                            <li key={index}>{error.message}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
 
                                <div>
                                    <form onSubmit={onSubmit}> 
                                      {step === 1 && <PlpCompanyDetailsStep 
                                          errors={errors} 
                                          register={register}  
                                          companies={companies} 
                                          setDepotId={setDepotId} 
                                          setDepotLocationId={setDepotLocationId} 
                                          locations={locations} 
                                          locationsPrice={locationsPrice}  
                                          selectState={(e)=>selectState(e)}
                                          countries={countries} 
                                          states={states}
                                          selectLga={selectLga}
                                          lgas={lgas}
                                          onNext={handleNext} 
                                          hideNext={false}
                                          publicAccess={publicAccess}
                                          verifyMsisdn={verifyMsisdn}
                                          msisdnErrors={msisdnErrors}
                                          msisdnDetails={msisdnDetails}> 
                                        </PlpCompanyDetailsStep>}
  

                                          {step === 1 && (
                                              <div className='d-inline'>
                                                  <div className="row">   
                                                      {!request &&<div className="col-6 col-sm-3 col-md-2"> 
                                                          <button type="submit" className='btn btn-default'>Submit</button>
                                                      </div>}
                                                      {request &&<div className="col-6 col-sm-3 col-md-2"> 
                                                          <button type="submit" className='btn btn-default'>Submit <span className='spinner-border spinner-border-sm'></span></button>
                                                      </div>}
                                                  </div> 
                                              </div> 
                                          )}
                                      </form> 
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreatePlpv2
