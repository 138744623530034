import { createStore, compose, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { rootReducer } from '../Reducers/index';

// Create logger middleware
const loggerMiddleware:any = createLogger();

declare global {
    interface Window {
      __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

// Use Redux DevTools extension if available, otherwise fallback to Redux's compose
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Create the store with rootReducer and apply middleware (thunk and logger)
const Store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(
      thunkMiddleware,  // Thunk middleware does not need explicit typing
      loggerMiddleware  // Logger middleware now correctly typed
    )
  )
);

export default Store;
