import React, { ChangeEvent, FC, useState } from 'react'
import './AddTransaction.scss' 
import { useForm } from 'react-hook-form'; 
import { yupResolver } from '@hookform/resolvers/yup';  
import * as yup from 'yup'; 
import { ShowTGAlert } from '../../../../../Helpers/ShowTGAlert';  
import transactionService from '../../../../../Services/transactionService';
import customerService from 'Services/customerService';
import { Logger } from 'Helpers/Logger';
import { ConvertToCustomISO8601 } from 'Helpers';

    interface propsType {
        toggleModal: ()=>void, 
    }

    export type AddTransactionFormType = {   
        msisdn: string,
        amountMajor: number,
        description: string ,
        createdAtISO8601: string,
        transactionFlowType: string,
    } 
    
    export type AddTransactionType = {   
        msisdn: string,
        amountMajor: number,
        description: string ,
        createdAtISO8601: string
    }

    

    export type stateType = {  
            name: string,
            localGovernmentAreas: [ string ] 
    }
  
  const schema = yup.object().shape({ 
    msisdn: yup.string().required('Field is required'),  
    description: yup.string().required('Description field is required'),
    amountMajor: yup.number().required('Field is required'),
    createdAtISO8601: yup.string().required('Field is required'),
    transactionFlowType: yup.string().required('Field is required'),
  });
  

const AddTransaction:FC<propsType> = ({toggleModal}) => { 
   
   const {handleSubmit, register, formState:{errors}} = useForm<AddTransactionFormType>({
    resolver: yupResolver(schema),
  })

  const { showAlert } = ShowTGAlert()  
  const [request, setRequest] = useState(false) 
 
  const submitNewUserInformation = handleSubmit(async (data:AddTransactionFormType) => { 
    setRequest(true)  
      
  
    const newTransaction = ConvertToCustomISO8601(data.createdAtISO8601) 

    if(data.transactionFlowType==='OUT'){
        data.amountMajor = -Math.abs(data.amountMajor); 
    } 
  
    const newData = { 
        msisdn: data.msisdn,
        amountMajor: data.amountMajor,
        description: data.description
    }
    console.log('transactionFlowType 002', {newData })

    await transactionService.create({...newData, createdAtISO8601: newTransaction}).then(()=>{
        setRequest(false)  
        toggleModal() 
        showAlert({message: 'Transaction uploaded successfully', show:true, title: 'Success', type: 'success'}) 

        setTimeout(()=>{ 
            window.location.reload()
        }, 2000)

    }, (error: { response: { data: { error: string } } }) =>{ 

        setRequest(false)    
        showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'}) 
   }) 
  }); 

    


  const [msisdnErrors, setMsisdnErrors] = useState<boolean>();
  const [msisdnDetails, setMsisdnDetails] = useState<{firstName: string; lastName: string;}>();
   

  const verifyMsisdn = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const msisdn: string = e.target.value; 
    const endpoint = `customerMsisdn=${encodeURIComponent(msisdn)}`; 
    if (msisdn.length >= 10 && msisdn.length <= 15) {
        setMsisdnDetails(undefined)
          await customerService.searchCustomer(endpoint, 1, 'DESC').then((response)=>{  
              setMsisdnErrors(false); 
              setMsisdnDetails({
                firstName: response.data.data.dataset[0].firstName,
                lastName: response.data.data.dataset[0].lastName
             }) 
          }, (error: {response: {data: {error:string}}}) => { 
              setMsisdnErrors(true);
              Logger(error.response);
              setMsisdnDetails(undefined)
          });
      
      }
  }
    return (
        <div className='AddTransaction'> 
             <form onSubmit={submitNewUserInformation}>
               <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Customer Msisdn</label>
                    <input type="text" id="msisdn" className='form-control' {...register('msisdn', {onChange: (e)=>verifyMsisdn(e),})} placeholder='e.g +2347023321234'/>
                    {errors.msisdn && <div className='text-danger'>{errors.msisdn.message}</div>} 
                    {msisdnErrors && <div className='error text-danger'>Phone number does not exist</div>}  
                    {msisdnDetails && <small className='error text-success fw-bolder'>{msisdnDetails.firstName} {msisdnDetails.lastName}</small>}              
                </div>  

                <div className="form-group mb-3">
                    <label htmlFor='transactionFlowType'>Transaction Type</label>
                    <select id="transactionFlowType" className='form-control'  {...register('transactionFlowType', { required: 'this field is required'})}>
                        <option value="">select</option>
                        <option value="IN" className='text-success'>Inflow</option>
                        <option value="OUT" className='text-danger'>Outflow</option>
                    </select>            
                     {errors.transactionFlowType && <div className='text-danger'>{errors.transactionFlowType.message}</div>}
                 </div>  

                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Amount</label>
                    <input type="number" id="newCustomerFirstName" className='form-control' {...register('amountMajor')} placeholder='e.g 2000'/>
                    {errors.amountMajor && <div className='text-danger'>{errors.amountMajor.message}</div>}
                </div>  

                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Description</label>
                    <input type="text" id="newCustomerFirstName" className='form-control' {...register('description')} placeholder='e.g Order'/>
                    {errors.description && <div className='text-danger'>{errors.description.message}</div>}
                </div>  


                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Transaction Period</label>
                    <input type="datetime-local" id="createdAtISO8601" className='form-control' {...register('createdAtISO8601')} placeholder='e.g 01-10-2023 10:45am'/>
                    {errors.createdAtISO8601 && <div className='text-danger'>{errors.createdAtISO8601.message}</div>}
                </div>  
                
              

                <div className='form-group mb-3'>
                    <button className='btn btn-default w-100' onClick={(e) => submitNewUserInformation(e)} disabled={request}>Submit {request && <span className='spinner-border spinner-border-sm'></span>}</button> 
                </div> 
            </form>
        </div>
    )
}

export default AddTransaction
