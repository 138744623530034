import React, { FC } from 'react'
import './PreviewMessage.scss'
 
interface propTypes {
    messageContent: string|undefined
}
const PreviewMessage:FC<propTypes> = ({messageContent}) => {
    return (
        <div className='PreviewMessage'> 
            <div className='alert alert-light mt-4 print-content'> 
              {messageContent}
            </div>
        </div>
    )
}

export default PreviewMessage
