import React, { FC, useEffect, useState } from 'react'
import { ProductType } from 'Models/productType'
import './ProductPricing.scss'
import { useForm } from 'react-hook-form'

interface propsType{
    productPrices: Array<ProductType>|undefined
}

type formType = {
    unitCostPrice: number;
    unitSellingPrice: number;
}

const ProductPricing:FC<propsType> = ({productPrices}) => { 
    const [marginValue, setMargin] = useState(0)
    const [priceCount, setPriceCount] = useState(0)

    const { register, setValue} = useForm<formType>()
   
    useEffect(() => {  
        if(productPrices) {
            const indexValue = productPrices?.length - 1;
            const margin = productPrices[indexValue]?.unitSellingPrice - productPrices[indexValue]?.unitCostPrice
            setMargin(margin)
            setPriceCount(indexValue)
            setValue('unitCostPrice', productPrices[indexValue]?.unitCostPrice) 
            setValue('unitSellingPrice', margin) 
        }
    }, [productPrices])
    return (
        <div className='ProductPricing'>
            <div className="card border-0 bg-white">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6 pb-5">  
                            <div className="form-input mt-4">
                                <label htmlFor="">Last cost price</label>
                                <input type="text" className='form-control' {...register('unitCostPrice')} disabled/>
                            </div>

                            <div className="form-input mt-3">
                                <label htmlFor="">Price Margin</label>
                                <input type="text" className='form-control' {...register('unitSellingPrice')} disabled/>
                            </div>

                            {productPrices && <div className="price-summary mt-5">
                                Selling price:
                                  <div className="sellingAt">{productPrices[priceCount]?.currency}{productPrices[priceCount]?.unitSellingPrice}/L</div>
                            </div>}

                            <div className="form-input mt-3">
                                <button className='btn btn-default btn-pill'>Save</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductPricing
