import React, { Key, useEffect, useState } from 'react'
import './MarketPrice.scss'
import { useForm } from 'react-hook-form'
import marketControlService from 'Services/marketControlService'
import Swal from 'sweetalert2'
import { marketPriceType } from 'Models/marketPriceType'
import { RiDeleteBin5Line } from 'react-icons/ri'
import { HorizontalLoader } from 'Components'
import state from '../../../../../Data/stateNG.json'
import productService from 'Services/productService'
import { productListType } from 'Models/productType'
import { CurrencyFormatter } from 'Helpers'
import { TimeConverter } from 'Helpers/TimeConverter'

type formProps = {
  productName: string
  marketPrice: number
  retailStation: string
  location: string
  displayInHeader: boolean
  priceType: string
}

const MarketPrice = () => {
  const [request, setRequest] = useState(false)
  const [marketPrice, setMarketPrice] = useState<marketPriceType[]>()

  const [hideShow, setHideShow] = useState<boolean>(false)

  const toggleHideShow = () => {
    setHideShow(!hideShow)
  }

  const getMarketPrice = async () => {
    await marketControlService.getMarketPrice(1, 'DESC', 'RETAIL_STATION_PRICE').then(
      (response) => {
        console.log('getMarketPrice', response)
        setMarketPrice(response.data.data.dataset)
      },
      (error) => {
        console.log('getMarketPrice error', error)
        setRequest(false)
      },
    )
  }

  useEffect(() => {
    getMarketPrice()
  }, [])

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<formProps>()

  const submit = handleSubmit(async (data: formProps) => {
    console.log({ data })
    setRequest(true)
    await marketControlService
      .createMarketPrice({ ...data, displayInHeader: false, priceType: 'RETAIL_STATION_PRICE' })
      .then(
        (response) => {
          setRequest(false)
          reset()
          Swal.fire({
            text: 'Created succesfully',
            icon: 'success',
            confirmButtonText: 'Ok',
          })
          getMarketPrice()
        },
        (error) => {
          console.log(error)
          setRequest(false)
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              cancelButton: 'btn btn-danger',
            },
            buttonsStyling: false,
          })

          swalWithBootstrapButtons.fire({
            title: 'Oops!',
            text: error.response.data.error,
            icon: 'warning',
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'close',
            reverseButtons: true,
          })
        },
      )
  })

  const deleteMarketPrice = async (mktPriceId: number) => {
    Swal.showLoading()
    await marketControlService.deleteMarketPrice(mktPriceId).then(
      (response) => {
        Swal.fire({
          text: 'deleted succesfully',
          icon: 'success',
          confirmButtonText: 'Ok',
        })
        getMarketPrice()
        Swal.close()
      },
      (error: { response: { data: { error: string } } }) => {
        console.log(error)
        Swal.close()
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            cancelButton: 'btn btn-danger',
          },
          buttonsStyling: false,
        })

        swalWithBootstrapButtons.fire({
          title: 'Oops!',
          text: error.response.data.error,
          icon: 'warning',
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: 'close',
          reverseButtons: true,
        })
      },
    )
  }

  const [products, setProducts] = useState<productListType[]>()
  const getProduct = async () => {
    await productService.getAll().then((res) => {
      setProducts(res.data.data)
    })
  }
  useEffect(() => {
    getProduct()
  }, [])

  const newPrices = marketPrice?.filter((price) => price.displayInHeader === false)

  const [editingPriceId, setEditingPriceId] = useState<number | null>(null)
  const [newEditingPrice, setNewEditingPrice] = useState<number | null>(null)

  const handlePriceClick = (priceId: number, currentPrice: number) => {
    setEditingPriceId(priceId)
    setNewEditingPrice(currentPrice)
  }

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewEditingPrice(Number(e.target.value))
  }

  const handleBlur = async (id: number, productName: string) => {
    if (newEditingPrice !== null && newEditingPrice >= 0) {
      await marketControlService.editMarketPrice(id, newEditingPrice, productName).then(
        () => {
          setEditingPriceId(null)
          setNewEditingPrice(null)
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'price updated',
            showConfirmButton: false,
            timer: 1000,
          })
          getMarketPrice()
        },
        (error: { response: { data: { error: string } } }) => {
          getMarketPrice()
          Swal.fire({
            position: 'top-end',
            icon: 'warning',
            text: error.response.data.error,
            showConfirmButton: false,
            timer: 1500,
          })

          console.error('Error updating price', error)
        },
      )
    } else {
      // Reset editing if price is invalid
      setEditingPriceId(null)
      setNewEditingPrice(null)
    }
  }

  return (
    <div className='MarketPrice'>
      <div className='card border-0'>
        <div className='card-header bg-white text-dark'>Market Price</div>
        <div className='card-body market-price-list'>
          {request && <HorizontalLoader></HorizontalLoader>}

          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                  <td>#</td>
                  <td>Retail Station</td>
                  <td>Product</td>
                  <td>Price</td>
                  <td>Location</td>
                  <td>Date</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                {newPrices &&
                  newPrices.map((mktPrice: marketPriceType, index: Key) => {
                    return (
                      <tr key={index} className=''>
                        <td>{Number(index) + 1}</td>
                        <td>{mktPrice.retailStation.toUpperCase()} </td>
                        <td>{mktPrice.productName}</td>
                        <td>
                          {editingPriceId === mktPrice.id ? (
                            <input
                              type='number'
                              value={
                                newEditingPrice !== null ? newEditingPrice : mktPrice.marketPrice
                              }
                              onChange={handlePriceChange}
                              onBlur={() => handleBlur(mktPrice.id, mktPrice.productName)}
                              autoFocus
                              className='form-control'
                            />
                          ) : (
                            <span
                              onClick={() => handlePriceClick(mktPrice.id, mktPrice.marketPrice)}
                            >
                              ₦{CurrencyFormatter(mktPrice.marketPrice)}
                            </span>
                          )}
                        </td>
                        <td>{mktPrice.location}</td>
                        <td>{TimeConverter(mktPrice.createdAt)}</td>
                        <td>
                          <button
                            className='btn p-0 border-0 text-danger'
                            onClick={() => deleteMarketPrice(mktPrice.id)}
                          >
                            <RiDeleteBin5Line />
                          </button>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>

          <div className='text-center mt-4 mb-4'>
            <button className='btn btn-add' onClick={toggleHideShow}>
              {hideShow ? '- Hide New Form' : '+ Add New Field'}
            </button>
          </div>
          {hideShow && (
            <form onSubmit={submit}>
              <div className='row create-new-price-form mb-5'>
                <div className='form-group col-md-6 mt-3'>
                  <label htmlFor='productName'>Product name</label>
                  <select
                    id='productName'
                    className='form-control'
                    {...register('productName', { required: 'field is required' })}
                  >
                    <option value=''> select </option>
                    {products &&
                      products.map((product: productListType, index: Key) => (
                        <option key={index} value={product.shortName}>
                          {' '}
                          {product.shortName}{' '}
                        </option>
                      ))}
                  </select>
                  {errors.productName && (
                    <div className='error text-danger'>{errors.productName.message}</div>
                  )}
                </div>
                <div className='form-group col-md-6 mt-3'>
                  <label htmlFor='marketPrice'>Product Price</label>
                  <input
                    type='text'
                    id='marketPrice'
                    className='form-control'
                    {...register('marketPrice', { required: 'field is required' })}
                  />
                  {errors.marketPrice && (
                    <div className='error text-danger'>{errors.marketPrice.message}</div>
                  )}
                </div>

                <div className='form-group col-md-6 mt-3'>
                  <label htmlFor='retailStation'>Retail Station Name</label>
                  <input
                    type='text'
                    id='retailStation'
                    className='form-control'
                    {...register('retailStation', { required: 'field is required' })}
                  />
                  {errors.retailStation && (
                    <div className='error text-danger'>{errors.retailStation.message}</div>
                  )}
                </div>

                <div className='form-group col-md-6 mt-3'>
                  <label htmlFor='retailStation'>Retail Station Location</label>
                  <select
                    id='retailStation'
                    className='form-control'
                    {...register('location', { required: 'field is required' })}
                  >
                    <option value=''>select location</option>
                    {state.map((state: { name: string }, index: Key) => {
                      return <option key={index}>{state.name}</option>
                    })}
                  </select>
                  {errors.retailStation && (
                    <div className='error text-danger'>{errors.retailStation.message}</div>
                  )}
                </div>

                <div className='form-group col-md-12 mt-3'>
                  <button type='submit' className='btn btn-default w-25 mt-md-4' disabled={request}>
                    {request && <span className='spinner-border spinner-border-sm'></span>}Create
                    price{' '}
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  )
}

export default MarketPrice
