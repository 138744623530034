import React, { FC, useState } from 'react'
import './UpdatePriceMatrix.scss'
import { useForm } from 'react-hook-form'
import { UpdatePriceMatrixFormType } from '../../../../../../Models/requestType' 
import { yupResolver } from '@hookform/resolvers/yup';  
import * as yup from 'yup';
import { ShowTGAlert } from '../../../../../../Helpers/ShowTGAlert';
import requestService from '../../../../../../Services/requestService';

interface propsType {
    toggleModal: ()=>void, 
    referenceId?: string | undefined,
    unitPrice?: number
}

const schema = yup.object().shape({ 
    unitDepotPrice: yup.number().required('Field is required') ,
    premium: yup.number().required('Field is required') ,
    unitProductCost: yup.number().required('Field is required') ,
    freightCostPerUnit: yup.number().required('Field is required') ,
    margin: yup.number().required('Field is required') ,
    retailFillingStationName: yup.string().required('Field is required')  
  });

const UpdatePriceMatrix:FC<propsType> = ({toggleModal, referenceId}) => { 
    const [request, setRequest] = useState(false)
    const { showAlert } = ShowTGAlert() 
    
    const {handleSubmit, register} = useForm<UpdatePriceMatrixFormType>({
        resolver: yupResolver(schema),
      })

    const submit = handleSubmit(async (data: UpdatePriceMatrixFormType)=>{
        setRequest(true)
        await requestService.updateMatrix(data, referenceId).then(()=>{
            setRequest(false) 
            toggleModal()
            showAlert({message: 'Price updated successfully', show:true, title: 'Success', type: 'success'}) 
        }, error =>{ 
            setRequest(false)    
            toggleModal();  
            showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'})  
       })
    })

    return (
        <div className='UpdatePriceMatrix'> 
            <div className="title">
            Fill in the details below to update the price matrix
            </div>


            <form onSubmit={submit}>
                <div className='form-group mb-3 mt-4'>
                    <label htmlFor="price-per-lit">Unit Product Price</label>
                    <input type="number" id="price-per-lit" className='form-control'  {...register('unitProductCost')}/>
                </div>

                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Unit Depot Price(Per unit Litre)</label>
                    <input type="number" id="price-per-lit" className='form-control' {...register('unitDepotPrice')}/>
                </div>

                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Freight Cost(Per unit Litre)</label>
                    <input type="number" id="price-per-lit" className='form-control' {...register('freightCostPerUnit')}/>
                </div>

                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Margin</label>
                    <input type="number" id="price-per-lit" className='form-control' {...register('margin')}/>
                </div>
                
                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Premium</label>
                    <input type="number" id="price-per-lit" className='form-control' {...register('premium')}/>
                </div>
  
                
                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Retail Filling Station Name</label>
                    <input type="text" id="price-per-lit" className='form-control' {...register('retailFillingStationName')}/>
                </div>
  
                <div className='form-group mb-3'>
                    {!request && <button className='btn btn-default w-100'>Update Price</button> }
                    {request && <button className='btn btn-default w-100 disabled'>Update Price <span className='spinner-border spinner-border-sm'></span></button> }
                </div> 
            </form>
        </div>
    )
}

export default UpdatePriceMatrix
