import React from 'react' 
import { Navigate, Outlet } from 'react-router-dom'   
import { useAuth } from '../AuthProvider/AuthProvider';
import AdminWrapper from '../../Pages/Skeleton/WrapperAuth/AdminWrapper/AdminWrapper';


const ProtectedAdmin = () => {
  // const {isAuthenticated} = useAuth0();
  const { isLocalAuth } = useAuth()
  
  switch (isLocalAuth) {
    case false:
      return <Navigate to="/login" />
    case true:
      return <AdminWrapper><Outlet/></AdminWrapper>
    default:
      return <Navigate to="/login" />
  }
}

export default ProtectedAdmin;
