import React, { FC, Key, useEffect, useMemo, useState } from 'react'
import './CreateRequest.scss'
import { useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { ShowTGAlert } from '../../../../../Helpers/ShowTGAlert'
import requestService from '../../../../../Services/requestService'
import { Logger } from '../../../../../Helpers/Logger'
import companyService from '../../../../../Services/companyService'
import { CompanyLocationRatType, CompanyType } from '../../../../../Models/companyType'
import { countryType, stateType } from '../../DeliveryUltra/AddDeliveryUltra/AddDeliveryFee'
import { locationService } from '../../../../../Services/locationService'
import { CurrencyFormatter, urlencodePhoneNumber } from '../../../../../Helpers'
import deliveryService from '../../../../../Services/deliveryService'
import customerService from 'Services/customerService'

interface propsType {
  toggleModal: () => void
}

export type deliveryLocationType = {
  name: string
  address: string
  country: string
  state: string
  contact: {
    fullName: string
    phoneNumber: string
  }
  isDefault: boolean
  id: string
}

export interface CreateRequestType {
  msisdn: string
  productId: string
  quantity: number
  orderReceiveType: string
  deliveryLocationId?: string
  retailFillingStationName?: yup.Maybe<string>
  deliveryState?: yup.Maybe<string>
  deliveryAddress?: yup.Maybe<string>
  // customUnitSellingPrice?: number | undefined;
  contactDetails?: {
    fullName: string | undefined
    phoneNumber: string | undefined
  }
  depotLocationId: string
  unitDepotPrice: number
  premium: number
  unitProductCost: number
  freightCostPerUnit: number
  margin: number
  // negotiatedPrice: number
}

const schema = yup.object().shape({
  msisdn: yup.string().required('Phone field is required'),
  productId: yup.string().required('Product field is required'),
  quantity: yup.number().required('Quantity field is required'),
  orderReceiveType: yup.string().required('order recived type field is required'),
  retailFillingStationName: yup.string().notRequired(),
  deliveryState: yup.string().notRequired(),
  deliveryAddress: yup.string().notRequired(),
  depotLocationId: yup.string().required('Depot Location field is required'),
  unitDepotPrice: yup.number().required('Unit Depot Price field is required'),
  premium: yup.number().required('Premium field is required'),
  unitProductCost: yup.number().required('Unit Product Cost field is required'),
  freightCostPerUnit: yup.number().required('Freight Cost Per Unit field is required'),
  margin: yup.number().required('Margin field is required'),
  // negotiatedPrice: yup.number().required('Negotiated Price field is required'),
})

const CreateRequest: FC<propsType> = ({ toggleModal }) => {
  const [request, setRequest] = useState(false)
  const { showAlert } = ShowTGAlert()

  const [depotId, setDepotId] = useState<string>()
  const [locations, setLocations] = useState([])
  const [depotLocationId, setDepotLocationId] = useState<string>()
  const [locationsPrice, setLocationsPrice] = useState<Array<CompanyLocationRatType>>([])
  const [deliveryLocation, setDeliveryLocation] = useState<Array<deliveryLocationType>>([])

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<CreateRequestType>({
    resolver: yupResolver(schema),
  })

  const onSubmit = handleSubmit(async (data: CreateRequestType) => {
    const newData =
      deliveryLocation.length > 0
        ? {
            ...data,

            contactDetails: {
              fullName: '',
              phoneNumber: data?.msisdn,
            },
          }
        : {
            msisdn: data.msisdn,
            productId: data.productId,
            quantity: data.quantity,
            orderReceiveType: data.orderReceiveType,
            retailFillingStationName: data.retailFillingStationName,
            deliveryState: data.deliveryState,
            deliveryAddress: data.deliveryAddress,
            contactDetails: {
              fullName: '',
              phoneNumber: data.msisdn,
            },
            depotLocationId: data.depotLocationId,
            unitDepotPrice: data.unitDepotPrice,
            premium: data.premium,
            unitProductCost: data.unitProductCost,
            freightCostPerUnit: data.freightCostPerUnit,
            margin: data.margin,
          }

    console.log('onSubmit', newData)
    setRequest(true)

    await requestService.create(newData).then(
      () => {
        setRequest(false)
        toggleModal()
        showAlert({
          message: 'Quote created successfully',
          show: true,
          title: 'Success',
          type: 'success',
        })

        setTimeout(() => {
          window.location.reload()
        }, 3000)
      },
      (error: { response: { data: { error: string } } }) => {
        setRequest(false)
        toggleModal()
        showAlert({ message: error.response.data.error, show: true, title: 'Error', type: 'error' })
      },
    )
  })

  const [companies, setCompanies] = useState<Array<CompanyType>>([])
  const [companyPageNumber, setCompanyPageNumber] = useState(1)
  const [numberOfCompanyPage, setNumberOfCompanyPage] = useState(1)

  const getCompanies = async () => {
    const response = await companyService.index(companyPageNumber)

    const responseContent = response?.data.data
    const pageSize = responseContent.pageSize
    const total = responseContent.total

    // get the number of paginations
    setNumberOfCompanyPage(Math.ceil(total / pageSize))
    setCompanyPageNumber(responseContent.pageNumber)

    if (companyPageNumber === 1) {
      setCompanies(response?.data.data.dataset ?? [])
    } else {
      setCompanies((prevPage) => {
        return [...prevPage, ...(response?.data.data.dataset ?? [])]
      })
    }
  }

  useEffect(() => {
    getCompanies()
  }, [companyPageNumber])

  useMemo(() => {
    if (numberOfCompanyPage !== companyPageNumber && companyPageNumber < numberOfCompanyPage) {
      setCompanyPageNumber((prevPageNumber) => prevPageNumber + 1)
    }
  }, [numberOfCompanyPage, companyPageNumber])

  useEffect(() => {
    companyService.locations(depotId, 1).then(
      (response) => {
        setLocations(response?.data.data.dataset)
      },
      (error: { response: object }) => {
        Logger('response error', error.response)
      },
    )
  }, [depotId])

  useEffect(() => {
    companyService.locationsRates(depotLocationId, 1).then(
      (response) => {
        setLocationsPrice(response?.data.data.dataset)
      },
      (error: { response: object }) => {
        Logger('response error', error.response)
      },
    )
  }, [depotLocationId])

  const [states, setStates] = useState<Array<stateType>>([])
  const [countries, setCountries] = useState<Array<countryType>>([])

  const getLocations = async () => {
    await locationService.index().then(
      (response) => {
        setCountries(response.data.data)
      },
      (error) => {
        console.log('error country', error)
      },
    )
  }

  useEffect(() => {
    getLocations()
  }, [])

  const selectState = (countryName: { target: { value: string } }) => {
    if (countryName.target.value) {
      const country = JSON.parse(countryName.target.value)
      setStates(country.states)
    }
  }

  const getDeliveryLocations = async (msisdn: string) => {
    await deliveryService.customerDeliveryLocation(urlencodePhoneNumber(msisdn)).then(
      (response) => {
        setDeliveryLocation(response.data.data.dataset)
      },
      (error) => {
        console.log('error country', error)
      },
    )
  }

  const [msisdnErrors, setMsisdnErrors] = useState<boolean>()
  const [msisdnDetails, setMsisdnDetails] = useState<{ firstName: string; lastName: string }>()

  const verifyMsisdn = async (e: { target: { value: string } }): Promise<void> => {
    const msisdn: string = e.target.value
    setMsisdnDetails(undefined)
    const endpoint = `customerMsisdn=${encodeURIComponent(msisdn)}`
    if (msisdn.length >= 10 && msisdn.length <= 15) {
      await customerService.searchCustomer(endpoint, 1, 'DESC').then(
        (response) => {
          setMsisdnDetails({
            firstName: response.data.data.dataset[0].firstName,
            lastName: response.data.data.dataset[0].lastName,
          })

          setMsisdnErrors(false)
        },
        (error: { response: { data: { error: string } } }) => {
          setMsisdnErrors(true)
          Logger(error.response)
        },
      )
    }
  }

  const unitDepotPrice = useWatch({ control, name: 'unitDepotPrice', defaultValue: 0 })
  const freightCostPerUnit = useWatch({ control, name: 'freightCostPerUnit', defaultValue: 0 })
  const margin = useWatch({ control, name: 'margin', defaultValue: 0 })
  const quantity = useWatch({ control, name: 'quantity', defaultValue: 0 })
  const [negotiatedPrice, setNegotiatedPrice] = useState<number | string>(0)
  const premium = useWatch({ control, name: 'premium', defaultValue: 0 })
  const unitProductCost = useWatch({ control, name: 'unitProductCost', defaultValue: 0 })

  const [totalCost, setTotalCost] = useState(0)

  useEffect(() => {
    const total = Number(unitProductCost) + Number(freightCostPerUnit) + Number(margin)

    setValue(
      'margin',
      Number(negotiatedPrice) - (Number(freightCostPerUnit) + Number(unitProductCost)),
    )

    setValue('unitProductCost', Number(unitDepotPrice) + Number(premium))

    if (quantity) {
      setTotalCost(Number(quantity) * total)
    }
  }, [unitProductCost, freightCostPerUnit, margin, quantity, premium, negotiatedPrice])

  const [showNewLocation, setShowNewLocation] = useState(false)

  return (
    <div className='CreateRequest'>
      <form onSubmit={onSubmit}>
        <div className='form-group mb-3'>
          <label htmlFor='msisdn'>Customer msisdn</label>
          <input
            type='text'
            id='msisdn'
            className={errors.msisdn ? 'form-control is-invalid' : 'form-control'}
            {...register('msisdn', {
              onChange: (e: { target: { value: string } }) => {
                e.target.value.length > 10 && getDeliveryLocations(e.target.value)
                verifyMsisdn(e)
              },
            })}
          />
          {errors.msisdn && <div className='error text-danger'>{errors.msisdn.message}</div>}
          {msisdnErrors && <div className='error text-danger'>Phone number does not exist</div>}
          {msisdnDetails && (
            <small className='error text-success fw-bolder'>
              {msisdnDetails.firstName} {msisdnDetails.lastName}
            </small>
          )}
        </div>
        <div className='row'>
          <div className='col-6'>
            <div className='form-group mb-3'>
              <label htmlFor='depot'>Depot</label>
              <select
                id='depot'
                className='form-control'
                onChange={(e) => setDepotId(e.target.value)}
              >
                <option>--choose--</option>
                {companies.length > 0 &&
                  companies.map((response: { id: string; name: string }, index: Key) => {
                    return (
                      <option key={index} value={response.id}>
                        {response.name}
                      </option>
                    )
                  })}
              </select>
            </div>
          </div>
          <div className='col-6'>
            <div className='form-group mb-3'>
              <label htmlFor='depotlocation'>Depot Location</label>
              <select
                id='depotlocation'
                className='form-control'
                {...register('depotLocationId')}
                onChange={(e) => setDepotLocationId(e.target.value)}
              >
                <option>--choose--</option>
                {locations.length > 0 &&
                  locations.map((response: { id: string; address: string }, index: Key) => {
                    return (
                      <option key={index} value={response.id}>
                        {response.address}
                      </option>
                    )
                  })}
              </select>
              {errors.depotLocationId && (
                <div className='error text-danger'>{errors.depotLocationId.message}</div>
              )}
            </div>
          </div>
        </div>

        <div className='form-group mb-3'>
          <label htmlFor='product'>Product</label>
          <select
            id='product'
            className={errors.productId ? 'form-control is-invalid' : 'form-control'}
            {...register('productId')}
          >
            <option>--choose--</option>
            {locationsPrice.length > 0 &&
              locationsPrice.map((response: CompanyLocationRatType, index: Key) => {
                return (
                  <option key={index} value={response.product.id}>
                    {response.product.shortName}
                  </option>
                )
              })}
          </select>
          {errors.productId && <div className='error text-danger'>{errors.productId.message}</div>}
        </div>

        {/* <div className='form-group mb-3'>
                    <label htmlFor="custom-unit-selling-price">Product price (optional to set price for customer)</label>
                    <input type="text" id="custom-unit-selling-price" className={errors.customUnitSellingPrice? 'form-control is-invalid':'form-control'} {...register('customUnitSellingPrice')}/>
                    {errors.customUnitSellingPrice && <div className='error text-danger'>{errors.customUnitSellingPrice.message}</div>}  
                </div>  */}

        {deliveryLocation.length > 0 && (
          <div className='form-group mb-3'>
            <label htmlFor='user'>Delivery Location</label>
            <select
              id='user'
              className={errors.deliveryLocationId ? 'form-control is-invalid' : 'form-control'}
              {...register('deliveryLocationId')}
            >
              <option>--option--</option>
              {deliveryLocation.map((response: deliveryLocationType, index: Key) => {
                return (
                  <option key={index} value={response.id}>
                    {response.address}
                  </option>
                )
              })}
            </select>
            {errors.deliveryLocationId && (
              <div className='error text-danger'>{errors.deliveryLocationId.message}</div>
            )}
          </div>
        )}

        <div className='form-group mb-3'>
          <label htmlFor='retail-filling-station'>Retail Filling Station Name</label>
          <input
            type='text'
            id='retail-filling-station'
            className={errors.retailFillingStationName ? 'form-control is-invalid' : 'form-control'}
            {...register('retailFillingStationName')}
          />
          {errors.retailFillingStationName && (
            <div className='error text-danger'>{errors.retailFillingStationName.message}</div>
          )}
        </div>

        <fieldset className='border p-2 mb-4'>
          <legend className='float-none w-auto p-2'> Product Variable Cost</legend>
          <div className='row'>
            <div className='col-6'>
              <div className='form-group mb-3'>
                <label htmlFor='unitDepotPrice'>Ex/Depot Price</label>
                <input
                  type='text'
                  id='unitDepotPrice'
                  className={
                    errors.retailFillingStationName ? 'form-control is-invalid' : 'form-control'
                  }
                  {...register('unitDepotPrice')}
                />
                {errors.unitDepotPrice && (
                  <div className='error text-danger'>{errors.unitDepotPrice.message}</div>
                )}
              </div>
            </div>
            <div className='col-6'>
              <div className='form-group mb-3'>
                <label htmlFor='premium'>Premium</label>
                <input
                  type='text'
                  id='premium'
                  className={errors.premium ? 'form-control is-invalid' : 'form-control'}
                  {...register('premium')}
                />
                {errors.premium && (
                  <div className='error text-danger'>{errors.premium.message}</div>
                )}
              </div>
            </div>

            <div className='col-6'>
              <div className='form-group mb-3'>
                <label htmlFor='negotiatedPrice'>Negotiated Price</label>
                <input
                  type='text'
                  id='negotiatedPrice'
                  value={negotiatedPrice}
                  className={'form-control'}
                  onChange={(e) => setNegotiatedPrice(e.target.value)}
                />
              </div>
            </div>

            <div className='col-6'>
              <div className='form-group mb-3'>
                <label htmlFor='freightCostPerUnit'>Freight Cost Per Unit</label>
                <input
                  type='text'
                  id='freightCostPerUnit'
                  className={errors.freightCostPerUnit ? 'form-control is-invalid' : 'form-control'}
                  {...register('freightCostPerUnit')}
                />
                {errors.freightCostPerUnit && (
                  <div className='error text-danger'>{errors.freightCostPerUnit.message}</div>
                )}
              </div>
            </div>

            <div className='col-6'>
              <div className='form-group mb-3'>
                <label htmlFor='retail-filling-station'>Unit Product Cost</label>
                <input
                  type='text'
                  id='retail-filling-station'
                  className={errors.unitProductCost ? 'form-control is-invalid' : 'form-control'}
                  {...register('unitProductCost')}
                  disabled
                />
                {errors.unitProductCost && (
                  <div className='error text-danger'>{errors.unitProductCost.message}</div>
                )}
              </div>
            </div>

            <div className='col-6'>
              <div className='form-group mb-3'>
                <label htmlFor='margin'>Margin</label>
                <input
                  type='text'
                  id='margin'
                  className={errors.margin ? 'form-control is-invalid' : 'form-control'}
                  {...register('margin')}
                  disabled
                />
                {errors.margin && <div className='error text-danger'>{errors.margin.message}</div>}
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset className='border p-2 mb-4'>
          <legend className='float-none w-auto p-2'> Product Esentials</legend>
          <div className='row'>
            <div className='col-6'>
              <div className='form-group mb-3'>
                <label htmlFor='deliverytype'>Delivery Type</label>
                <select
                  id='deliverytype'
                  className={errors.orderReceiveType ? 'form-control is-invalid' : 'form-control'}
                  {...register('orderReceiveType')}
                >
                  <option>--option--</option>
                  <option>DELIVERY</option>
                  <option>PICKUP</option>
                </select>
                {errors.orderReceiveType && (
                  <div className='error text-danger'>{errors.orderReceiveType.message}</div>
                )}
              </div>
            </div>

            <div className='col-6'>
              <div className='form-group mb-3'>
                <label htmlFor='quantity'>Quantity</label>
                <input
                  type='number'
                  id='quantity'
                  className={errors.quantity ? 'form-control is-invalid' : 'form-control'}
                  {...register('quantity')}
                />
                {errors.quantity && (
                  <div className='error text-danger'>{errors.quantity.message}</div>
                )}
              </div>
            </div>
          </div>
        </fieldset>

        <div className='form-group mb-3'>
          <label htmlFor='totalCost'>Total Cost</label>
          <input
            type='text'
            id='totalCost'
            value={CurrencyFormatter(totalCost)}
            className={'form-control'}
            disabled={true}
          />
        </div>

        <fieldset className={showNewLocation ? 'border p-2 mb-4' : ' p-2 mb-4'}>
          <legend
            className='float-none w-auto p-2'
            onClick={() => setShowNewLocation(!showNewLocation)}
          >
            {' '}
            Add new delivery location {showNewLocation ? <span>-</span> : <span>+</span>}
            {deliveryLocation.length > 0 && <>( Optional )</>}
          </legend>

          {showNewLocation && (
            <div className='row'>
              <div className='col-12'>
                <div className='form-group mb-3'>
                  <label htmlFor='address'>Delivery Address</label>
                  <input
                    type='text'
                    id='address'
                    className={errors.deliveryAddress ? 'form-control is-invalid' : 'form-control'}
                    {...register('deliveryAddress')}
                  />
                  {errors.deliveryAddress && (
                    <div className='error text-danger'>{errors.deliveryAddress.message}</div>
                  )}
                </div>
              </div>

              <div className='col-6'>
                <div className='form-group mb-3'>
                  <label htmlFor='country'>
                    Country <span className='text-danger'>*</span>
                  </label>
                  <select className={'form-control'} id='country' onChange={selectState}>
                    <option>--Country--</option>
                    {countries.length > 0 &&
                      countries.map((res: any, index: React.Key) => {
                        return (
                          <option key={index} value={JSON.stringify(res)}>
                            {res.name}
                          </option>
                        )
                      })}
                  </select>
                </div>
              </div>

              <div className='col-6'>
                <div className='form-group mb-3'>
                  <label htmlFor='state'>State</label>
                  <select
                    id='state'
                    className={errors.deliveryState ? 'form-control is-invalid' : 'form-control'}
                    {...register('deliveryState')}
                  >
                    <option>--State--</option>
                    {states.length > 0 &&
                      states.map((result: stateType, index: React.Key) => {
                        return (
                          result.name !== 'Nationwide' && <option key={index}>{result.name}</option>
                        )
                      })}
                  </select>
                  {errors.deliveryState && (
                    <div className='error text-danger'>{errors.deliveryState.message}</div>
                  )}
                </div>
              </div>
            </div>
          )}
        </fieldset>

        <div className='form-group mb-3'>
          {!request && <button className='btn btn-default w-100'>Submit</button>}
          {request && (
            <button className='btn btn-default w-100'>
              Submit <span className='spinner-border spinner-border-sm'></span>
            </button>
          )}
        </div>
      </form>
    </div>
  )
}

export default CreateRequest
