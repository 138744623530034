 export const useDeliveryCountdown = (createdDate: string) => { 
  const givenDate: Date = new Date(createdDate);

  const currentDate: Date = new Date();

  const timeDifference = currentDate.getTime() - givenDate.getTime() ;
  
  const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
 
  const remainingValue = 30 - dayDifference;

  return remainingValue; 
};
