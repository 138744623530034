import authService from 'Services/authService';
import { useAuth } from 'Components/AuthProvider/AuthProvider';

interface allowedRouteType { loanProvider: string,
        adminUiSubDomain: string,
        defaultAdminPageUrlPath: string,
        allowedAdminPageUrlPaths: Array<string> 
    }
export const LoanProviderRBAC = (element: string | null, allowRoute:Array<allowedRouteType>|undefined) => ({
  invoke: async () => {
    try {
      const response = await authService.getPrivileges();
      return response;
    } catch (error) {
      throw new Error('Error fetching privileges');
    }
  },

  privileges: (linkPath: string) => {
    const { user } = useAuth();  

    if(user && allowRoute){
     
        const privileges = allowRoute.filter(
            (res: { loanProvider: string }) => res.loanProvider === element
        ); 


        switch (true) {
            case user.loanProviderAdminRole && user.loanProviderAdminRole === true:
             return [...privileges[0].allowedAdminPageUrlPaths, 'loan-providers'].includes(linkPath);
            default:
            return true;
        } 
    } 
  },
});
