import React, { FC } from 'react'
import './ProductScanner.scss'

interface propsType {
    toggleModal: ()=>void
}
const ProductScanner:FC<propsType> = ({toggleModal}) => {
    return (
        <div className='ProductScanner'>
            <form action='/product-scanner'>
                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Price per litre (&#8358;)</label>
                    <input type="text" id="price-per-lit" className='form-control' />
                </div>
                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Depot name</label>
                    <input type="text" id="price-per-lit" className='form-control' />
                </div>
                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Address</label>
                    <input type="text" id="price-per-lit" className='form-control' />
                </div>
                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Location</label>
                    <input type="text" id="price-per-lit" className='form-control' />
                </div>
                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Local government</label>
                    <input type="text" id="price-per-lit" className='form-control' />
                </div>
                <div className='form-group mb-3'>
                    <button className='btn btn-default w-100' onClick={toggleModal}>Submit</button> 
                </div>
            </form>
        </div>
    )
}

export default ProductScanner
