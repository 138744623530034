import axios from 'axios'

import { AxiosInterceptor } from '../Config/AxiosInterceptor';

AxiosInterceptor()
 
const statsService = {
  async getAll () {
    return axios.get('/profile/seller/statistics');
  },
  async getStats (year: number, country: string) {
    return axios.get(`/statistics/order/year?year=${year}&country=${country}`);
  },
  async getOrderStats (year: number, month: number|string, country: string) {
    return axios.get(`/statistics/order/month?year=${year}&month=${month}&country=${country}`);
  }, 
}
export default statsService
