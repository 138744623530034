import React, { Fragment, useEffect, useState } from 'react'
import './TripDetails.scss' 
import logisticsService from '../../../../../Services/logisticsService'
import { useParams } from 'react-router-dom'
import { Logger } from '../../../../../Helpers/Logger'
import { OrderType } from '../../../../../Models/orderType'
import { BreadCrumb, HorizontalLoader, ImageSrc } from '../../../../../Components'
import { CurrencyFormatter, FormatTimeAgo } from '../../../../../Helpers'
import TriprackingItem from './TriptrackingItem/TriptrackingItem'
import { ShowTGAlert } from '../../../../../Helpers/ShowTGAlert'
import orderService from '../../../../../Services/orderService'
import ModalWrapper from '../../../../../Components/ModalWrapper/ModalWrapper';


const TripDetails = () => {  
    const {referenceId} = useParams<{referenceId:string}>() 
    
    const [orderData, setOrder] = useState<OrderType>(); 
    const [loading, setLoading] = useState(false);

    
    const getLogistics = async () => {
        setLoading(true)
        await logisticsService.getDetails(referenceId)
          .then((response)=>{    
            setLoading(false)
            setOrder(response?.data.data.dataset[0])
            console.log('response TripDetails', response)
 

          }, (error: { response: object }) =>{     
            setLoading(false) 
            Logger('TripDetails response error', error.response) 
          })
    }

    useEffect(() => {
     getLogistics()
    }, [])


    const [showModal, setShowModal] = useState(false);  
    const [statusRequest, setStatusRequest] = useState(false);  
    
    const toggleModal = () => {
       setShowModal((prevState) => !prevState);
    };

    const { showAlert } = ShowTGAlert() 

    const confirmDelivery = async () =>{
      setStatusRequest(true)
      await orderService.orderStatus(orderData?.id, 'COMPLETED').then(()=>{ 
        
        toggleModal()
        showAlert({message: 'Order status changed to COMPLETED succesfully', show:true, title: 'Success', type: 'success'}) 
        
         setStatusRequest(false)
      }, (error: { response: { data: { error: string } } }) =>{
        setStatusRequest(false)
        toggleModal()
        showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'}) 
        console.log('orderService error', error.response)
      })
    }


    return (
        <div className='TripDetails'>
             
           <div className="container">
            <BreadCrumb title={'Trip Details'} parent={'Trip'} parentUrl={'logistics'} showTitle={false}></BreadCrumb>
                <div className="container mt-2">
                    
                    <div className="card border-0">
                        <div className="card-header border-0 px-0">
                            <div className="row">
                                <div className="col-12 col-md-7 my-auto">
                                    <div className="title">
                                    Trip Details
                                    </div>
                                </div>
                                {orderData && <Fragment> 
                                    <div className="col-6 col-md-3">
                                      <span className='order-status'>{orderData.status}</span>
                                    </div>
                                    <div className="col-6 col-md-2"> 
                                      <button className='btn btn-respond-req' onClick={toggleModal} >Confirm Delivery</button>
                                    </div>
                                </Fragment>}
                            </div> 
                        </div>
                    </div>

                    {!orderData && <HorizontalLoader></HorizontalLoader>}
                    {orderData && <div className="row details-section">
                        <div className="col-md-6"> 
                            <div className='card border-0 mt-3'> 
                                <div className='card-header bg-white'>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="title">
                                            Order Summary
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="sub-title">
                                             { FormatTimeAgo(orderData.createdAtUtc)}
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                                <div className="card-body">  
                                    <div className="row">
                                        <div className="col-md-4"> 
                                        <div className="product-image w-100">
                                        <ImageSrc src={orderData.depotLocation.company.logo? orderData.depotLocation.company.logo.url : ''} alt="" title="" width='w-100 rounded'/>
                                        </div>
                                       </div>
                                        <div className="col-md-4 text-start"> 
                                         <div className='seller-name'>{orderData.product.fullName}</div>
                                         <div className='quantity'>{orderData.quantity}Ltrs</div>
                                       </div>
                                        <div className="col-md-4 text-end"> 
                                        ₦{CurrencyFormatter(orderData.pricePerUnitMajor)}/Ltr
                                       </div>
                                    </div>
                                    <ul className="list-group rounded-0 border-0">
                                        <li className="list-group-item border-start-0 border-end-0 px-0"> 
                                            <div className="row w-100 mx-0">
                                                <div className="col-6">
                                                Subtotal
                                                </div>
                                                <div className="col-6 text-end value px-0">
                                                ₦{CurrencyFormatter(orderData.totalCostMajor)}
                                                </div>
                                            </div>
                                        </li> 
                                        <li className="list-group-item border-start-0 border-end-0 px-0"> 
                                            <div className="row w-100 mx-0">
                                                <div className="col-6">
                                                Delivery
                                                </div>
                                                <div className="col-6 text-end value px-0">
                                                ₦0.0
                                                </div>
                                            </div>
                                        </li> 
                                        <li className="list-group-item border-start-0 border-end-0 border-bottom-0 px-0"> 
                                            <div className="row w-100 mx-0">
                                                <div className="col-6">
                                                Total
                                                </div>
                                                <div className="col-6 text-end value px-0">
                                                ₦{ CurrencyFormatter(orderData.totalCostMajor)}
                                                </div>
                                            </div>
                                        </li> 
                                    </ul>   
                                </div>
                            </div> 
                        </div>
                        <div className="col-md-6"> 
                            <div className='card border-0 mt-3'>  
                                <div className='card-header bg-white'>
                                    <div className="title">
                                    Track Delivery
                                    </div>
                                </div>
                                {orderData && <div className="card-body">  
                                   <TriprackingItem order={orderData}></TriprackingItem>
                                </div>}
                            </div> 
                        </div>
                   
                    </div>}
                </div>
            </div> 

            {orderData && <ModalWrapper showModal={showModal} closeModal={toggleModal} title={orderData.quoteRequest.orderReceiveType ==='PICKUP'? 'Confirm Pickup':'Confirm Delivery'}>
                      <p>Are you sure you want to perform this irreversible action?</p> 
                      <button className='btn btn-order-traking-confirm' onClick={confirmDelivery} disabled={statusRequest}>Continue {statusRequest && <span className='spinner-border spinner-border-sm'></span>}</button>
                  </ModalWrapper>}
        </div>
    )
}

export default TripDetails
