
import React, { FC, ReactNode } from 'react'; 
import Modal from 'react-bootstrap/esm/Modal';
import './ModalWrapper.scss'
type AllowedSize = 'lg' | 'sm' | 'xl' | undefined;

interface propsType {
    showModal:boolean,
    closeModal: () => void,
    title: string,
    size?: AllowedSize,
    children: ReactNode
}
const ModalWrapper:FC<propsType> = ({ showModal, closeModal, title, size, children }) => {  
    return ( 
        <Modal show={showModal} size={size} onHide={closeModal} dialogClassName="modalWrapper">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body> 
        </Modal>
    )
}

export default ModalWrapper 
