import React, { useEffect, useState } from 'react'
import './CompanyDetails.scss'
import { BreadCrumb, HorizontalLoader } from 'Components'
import { useParams } from 'react-router-dom'

const CompanyDetails = () => {
    const {uuid} = useParams<{uuid:string}>()
    const [request, setRequest] = useState(false)

    useEffect(()=>{
        setRequest(true)
    }, [uuid])

    return (
        <div className='CompanyDetails'>
            <div className="container">
                <BreadCrumb title={'Company Details'} parent={'Companies'} parentUrl={'company'} showTitle={false}></BreadCrumb>
                <div className="container mt-2">
                    <div className='card border-0'>
                        <div className='card-body'>
                            {request && <HorizontalLoader></HorizontalLoader>}
                              <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="company-item">
                                            <div className="name">
                                                Company name
                                            </div>
                                            <div className="value">
                                                TradeGride Technologies
                                            </div>
                                        </div>

                                        <div className="company-item">
                                            <div className="name">
                                                Country
                                            </div>
                                            <div className="value">
                                                Nigeria
                                            </div>
                                        </div>

                                        <div className="company-item">
                                            <div className="name">
                                            State
                                            </div>
                                            <div className="value">
                                                Lagos
                                            </div>
                                        </div>

                                        <div className="company-item">
                                            <div className="name">
                                                Industry
                                            </div>
                                            <div className="value">
                                                Oil & Gas
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              </div>
                        </div>
                    </div>
                </div> 
            </div> 
        </div>)
    
}

export default CompanyDetails
