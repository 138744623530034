import React, { useState, useEffect, FC, ReactNode, createContext } from 'react';

// Define the shape of the context
interface ThemeContextType {
  theme: string;
  toggleTheme: () => void;
}

// Create the ThemeContext with a default value
export const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

interface ThemeProviderProps {
  children: ReactNode;
}

const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState<string>('light');

  // Check localStorage for saved theme on mount
  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setTheme(savedTheme);
    }
  }, []);

  // Update the theme in localStorage whenever the theme changes
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);

     // Apply dark mode to all cards
     const allCards = document.querySelectorAll('.card *, .card');
     if (theme === 'dark') {
         allCards.forEach((card) => card.classList.add('bg-dark', 'text-secondary-default'));
     } else {
         allCards.forEach((card) => card.classList.remove('bg-dark', 'text-secondary-default'));
     }


     const cardheader = document.querySelectorAll('.card .card-header');
        if (theme === 'dark') {
            cardheader.forEach((card) => card.classList.add('card-border-bottom'));
        } else {
            cardheader.forEach((card) => card.classList.remove('card-border-bottom'));
        }

    const cardbutton = document.querySelectorAll('.card .btn');
        if (theme === 'dark') {
            cardbutton.forEach((card) => card.classList.add('border', 'border-light'));
        } else {
            cardbutton.forEach((card) => card.classList.remove('border', 'border-light'));
        }

     const topnavbarChildren = document.querySelectorAll('.admin-header *, .admin-header');
     if (topnavbarChildren.length > 0) {
         topnavbarChildren.forEach((child) => {
             if (theme === 'dark') {
                 child.classList.add('bg-top-dark', 'text-secondary-default');
             } else {
                 child.classList.remove('bg-top-dark', 'text-secondary-default');
             }
         });
     }
     
     const text = document.querySelector('.welcome-message, .Resource .title');
        if (text) {  // Check if the element exists
            if (theme === 'dark') {
                text.classList.add('text-secondary-default');
            } else {
                text.classList.remove('text-secondary-default');
            }
        }

        const sidebar = document.querySelectorAll('.EmployeeSideBar');
        if (theme === 'dark') {
            sidebar.forEach((card) => card.classList.add('bg-dark', 'text-secondary-default', 'emp-border-end-none'));
        } else {
            sidebar.forEach((card) => card.classList.remove('bg-dark', 'text-secondary-default', 'emp-border-end-none'));
        }

         
        const border = document.querySelectorAll('.card .items .row');
        if (theme === 'dark') {
            border.forEach((card) => card.classList.add('card-border-bottom'));
        } else {
            border.forEach((card) => card.classList.remove('card-border-bottom'));
        }


        const cardborder = document.querySelectorAll('.Event .card .items .row .md-col');
        if (theme === 'dark') {
            cardborder.forEach((card) => card.classList.add('card-inner-border-none'));
        } else {
            cardborder.forEach((card) => card.classList.remove('card-inner-border-none'));
        }

        const formControl = document.querySelectorAll('.employeeWrapper .form-control');
        if (theme === 'dark') {
            formControl.forEach((card) => card.classList.add('employee-dark-form'));
        } else {
            formControl.forEach((card) => card.classList.remove('employee-dark-form'));
        }
        

  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
