import React, { FC, useEffect } from 'react'
import { ProductType } from 'Models/productType' 
import './CustomizeProduct.scss'
import { useForm } from 'react-hook-form'

interface propsType{
    productPrices: Array<ProductType>|undefined
}

type formType = {
    company: string;
    product: string;
}
const CustomizeProduct:FC<propsType> = ({productPrices}) => {

    const { register, setValue} = useForm<formType>()

    useEffect(() => {  
        if(productPrices) {
            const indexValue = productPrices?.length - 1;
            setValue('company', productPrices[indexValue]?.depotLocation.company.name) 
            setValue('product', productPrices[indexValue]?.product.shortName) 
        }
    }, [productPrices])
    return (
        <div className='CustomizeProduct'>
            <div className="card border-0 bg-white">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6 pb-5">
                            <div className="row ">
                                <div className="col-4 col-md-2">
                                  <div className="addPhoto">
                                    +
                                  </div>
                                </div>
                                <div className="col-8 col-md-10 my-auto">
                                   <div className='fw-bold'>Add photo</div>
                                   <div>Click to upload product image or brand logo</div>
                                </div>
                            </div>


                            <form>
                                <div className="form-input mt-4">
                                    <label htmlFor="">Company</label>
                                    <input type="text" {...register('company')} className='form-control' disabled/>
                                </div>

                                <div className="form-input mt-3">
                                    <label htmlFor="">Product</label>
                                    <input type="text" {...register('product')} className='form-control' disabled/>
                                </div>
                                <div className="form-input mt-3">
                                    <button className='btn btn-default btn-pill'>Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomizeProduct
