import axios from 'axios' 
import { AxiosInterceptor } from '../Config/AxiosInterceptor';

AxiosInterceptor()
 
export const walletService = {

    initiateTransfer: async (loanProvider:string, msisdn:string, data: { amount: number}) => {
        return  await axios.post(`/${loanProvider}/transferRequest/${msisdn}`, data)
    },

    validateOtp: async (loanProvider:string | undefined, transferRequestId: string | undefined) => {
       return  await axios.post(`/${loanProvider}/transferRequest/${transferRequestId}/approve`)
    },

    getTransferDetails: async (loanProvider:string | undefined, transferRequestId: string | undefined) => {
       return  await axios.get(`/${loanProvider}/transferRequest/${transferRequestId}`)
    } 
}

