import React, { FC } from 'react'
import './CustomizeTruck.scss'
import { truckTypes } from '../../../../../Models'; 
type propsType = {
    truck: truckTypes|undefined
}
const CustomizeTruck:FC<propsType> = ({truck}) => {
    

    return (
        <div className='CustomizeTruck'> 
           { truck && <div className="container py-5">
            <div className="row">
                <div className="col-md-6"> 
                    <form>
                        <div className="form-group">
                            <label htmlFor="">License plate</label>
                            <input type="text" className='form-control' defaultValue={truck.truckPlateNumber}/>
                        </div>
                        
                        <div className="form-group mt-3">
                            <label htmlFor="">Truck Manufacturer</label>
                            <input type="text" className='form-control' defaultValue={truck.truckManufacturer}/>
                        </div>
                        
                        <div className="form-group mt-3">
                            <label htmlFor="">Truck Capacity In Litres</label>
                            <input type="text" className='form-control' defaultValue={truck.capacityInLitres}/>
                        </div>
                        
                        <div className="form-group mt-3">
                            <label htmlFor="">Truck Contact Name</label>
                            <input type="text" className='form-control' defaultValue={truck.contactName}/>
                        </div>
                        
                        <div className="form-group mt-3">
                            <label htmlFor="">Truck Contact Phone Number</label>
                            <input type="text" className='form-control' defaultValue={truck.contactPhoneNumber}/>
                        </div>

                        <div className="form-group mt-3">
                            <label htmlFor="">Truck Source State</label>
                            <input type="text" className='form-control' defaultValue={truck.sourceState}/>
                        </div>

                        <div className="form-group mt-3">
                            <label htmlFor="">Truck Status</label>
                            <input type="text" className='form-control' defaultValue={truck.status}/>
                        </div>


                        <div className="form-group mt-3">
                            <label htmlFor="">Truck Destination States</label>
                            <div>
                                {truck.destinationStates.map((res, index)=>{
                                return <span key={index}> 
                                    {res.state}   
                                    <svg width="10" height="10" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 19C14.95 19 19 14.95 19 10C19 5.05 14.95 1 10 1C5.05 1 1 5.05 1 10C1 14.95 5.05 19 10 19Z" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M7.45312 12.5471L12.5471 7.45312" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M12.5471 12.5471L7.45312 7.45312" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </span>
                                })}
                            </div>
                        </div>


                        <div className="form-group mt-3">
                            <button className='btn btn-default disabled'>Save</button>
                        </div>
                    </form>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default CustomizeTruck
