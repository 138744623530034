import axios from 'axios'
import { AxiosInterceptor } from '../Config/AxiosInterceptor'; 
import { CreatePlpType } from 'Pages/Frontend/Interface/Plp/CreatePlp/CreatePlp';
AxiosInterceptor()
 

const plpService = { 
  async index (pageNumber: number, sortOrder: string, status: string) {
    return axios.get(`/plpApplications?pageNumber=${pageNumber}&sortOrder=${sortOrder}&status=${status}`);
  },

  async getDetails (referenceId: string | undefined) {
    return axios.get(`/plpApplications?id=${referenceId}&pageNumber=1`);
  },
  async aproval (referenceId: string | undefined, aproval:string) {
    return axios.put(`/plpApplications/${referenceId}/approveOrReject/${aproval}`);
  },
  async create (data: CreatePlpType){
    return axios.put('/plpApplications', data);
  },

  file: async (plpUuid:string|undefined, data:FormData, fileUploadCategory:string) => {

    console.log('PLP SERVICE DATA', { plpUuid, file: data, name:fileUploadCategory })
  
    return await axios.put(`/plpApplications/${plpUuid}/fileUpload?fileUploadCategory=${fileUploadCategory}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  
}
export default plpService
