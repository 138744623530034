 export function ExtractSubdomain(domain: string): string | null {

    const localDevHost = 'localhost:2024';
   
    const domainHost = domain === localDevHost ? 'vfd-admin-dev.tradegrid.co' : domain;
   
    const subdomainRegex = /^(https?:\/\/)?([a-z0-9-]+)\.[a-z0-9-]+\.[a-z]{2,}(\.[a-z]{2,})?$/;
    const matches = domainHost.match(subdomainRegex);
  
    if (matches && matches[2]) { 
      const subdomainParts = matches[2].split('-');
      if (subdomainParts.length >= 1) { 
        return subdomainParts[0];
      }
    }
  
    return null;
  }

  
 
  export function ExtractLoanProvider() {
    const { host } = window.location;
    
    const isLocalDev = host === 'localhost:2024';
    const domainHost = isLocalDev ? 'vfd-admin-dev.tradegrid.co' : host;
  
    const parts = domainHost.split('.');
    if (parts.length >= 3) {
      const [loanProvider] = parts[0].split('-');
      return loanProvider.toUpperCase();
    }
  
    return null;
  }