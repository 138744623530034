import React, { FC } from 'react' 
import './AdminMobileHeader.scss' 
import logo from '../../../Assets/Images/logo-white.svg'
import { Link } from 'react-router-dom';  
import AdminSideBarContent from '../Sidebar/AdminSideBarContent/AdminSideBarContent'; 
import message from '../../../Assets/Images/message.png'
import bell from '../../../Assets/Images/bell.png' 

interface propsType {
    publicAccess?: boolean
}
const AdminMobileHeader:FC<propsType> = ({ publicAccess }) => { 
 
  return (
    <header className='admin-mobile-header'> 
    <div className="container h-100 px-4">
        <div className="row h-100">
            {!publicAccess && <div className="col-2 my-auto">
                <button className="btn d-flex justify-content align-item-center"  type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                  <i className="d-icon d-menu-hamburger" aria-hidden="true"></i>
                </button>
            </div>}
            <div className={!publicAccess? 'col-5 my-auto':'col-12 text-center my-auto'}>
                <Link className="navbar-brand" to="/">
                    <img src={logo} className="logo mb-1"/>
                </Link>
            </div>

            {!publicAccess && <div className="col-5 px-0 my-auto">
                <ul className="nav float-end">
                    <li className="nav-item">
                        <a href="" className="nav-link">
                        <img src={message} className='notice-icon'></img>
                        </a>
                    </li> 

                    <li className="nav-item">
                        <a href="" className="nav-link">
                        <img src={bell} className='notice-icon'></img>
                        </a>
                    </li> 
                </ul>
            </div>}
        </div> 
        <div className="offcanvas offcanvas-start" tabIndex={-1} id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-header">
                <h5 className="offcanvas-title text-light" id="offcanvasExampleLabel">Menu</h5>
                <button type="button" className="btn-close bg-white text-dark" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body px-0">
                <AdminSideBarContent></AdminSideBarContent> 
            </div>
        </div>
    </div>
 
    </header>
  )
}

export default AdminMobileHeader
