import { CompanyType } from 'Models';
import { CompanyLocationType } from 'Models/companyType';
import React, { Key, ChangeEvent, useState }  from 'react' 
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { countryType, stateType } from '../../DeliveryUltra/DeliveryUltra';
import { CreatePlpType } from './CreatePlp';
import './CreatePlp.scss'
import { productListType } from 'Models/productType'; 

type FormProps = {
  children?: React.ReactNode;
  errors: FieldErrors<CreatePlpType>;  
  register: UseFormRegister<CreatePlpType>;  
  companies: Array<CompanyType>;
  setDepotId: (value:string)=>void;
  setDepotLocationId: (value:string)=>void;
  locations: Array<CompanyLocationType>;
  locationsPrice: Array<productListType>; 
  selectState: (value:{target:{value:string}})=>void;
  countries: Array<countryType>;
  states: Array<stateType>;
  onNext: ()=> void;
  selectLga: (e:ChangeEvent<HTMLInputElement>)=> void;
  lgas: Array<string>; 
  publicAccess? :boolean;
  verifyMsisdn: (e:ChangeEvent<HTMLInputElement>)=> void;
  msisdnErrors: boolean|undefined; 
  msisdnDetails?: string;
  hideNext?:boolean;
};
 
const PlpCompanyDetailsStep:React.FC<FormProps> = ({errors, register,  locationsPrice, selectState, countries, states, onNext, selectLga, lgas, publicAccess, verifyMsisdn, msisdnErrors, msisdnDetails, hideNext}) => {

    const [fieldErrors, setFieldErrors] = useState({
        companyName: false,
        businessRegistrationType: false,
        cacNumber: false,
        incorporationDateISO8601: false,
        bvn: false,
        companyAddress: false,
        productName: false,
      });
    
      const validateNotEmpty = (value: string) => value.trim() !== '';
    
      const updateFieldError = (field: string, value: string) => { 
        setFieldErrors((prev) => ({
          ...prev,
          [field]: validateNotEmpty(value),
        }));
      };
    
      const handleInputChange = (field: string) => (e: ChangeEvent<HTMLInputElement>) => { 
        const value = e.target.value;
        updateFieldError(field, value);
      }; 
 
      const isNextButtonDisabled = Object.values(fieldErrors).every((error) => error === true);
    
         
    return (
        <div className="row"> 

            <div className="col-sm-6 my-4"> 
                <div className="step-title">
                    Fill the form below
                </div> 
            </div> 
            {(hideNext === true || hideNext ===undefined) && <div className="col-sm-6 text-sm-end my-4"> 
                <div className="step-page-number">
                   Page 1 of 3
                </div> 
            </div> }

            <div className={publicAccess? 'col-md-8' : 'col-md-6'}>   
             <div className='form-group mb-3'>
               {(hideNext === true || hideNext ===undefined) && <label htmlFor="customerMsisdn">Customer Msisdn or Phone</label>}
                <input type={(hideNext === true || hideNext ===undefined) ? 'text':'hidden'}
                  defaultValue={(hideNext !== undefined && hideNext === false )? '+2348022471355': ''}
                  id='customerMsisdn' className={errors.customerMsisdn? 'form-control is-invalid':'form-control'} {...register('customerMsisdn', { onChange:verifyMsisdn })} placeholder=""/>
                {errors.customerMsisdn && <div className='error text-danger'>{errors.customerMsisdn.message}</div>}  
                {msisdnErrors && <div className='error text-danger'>Phone number does not exist</div>}
                {msisdnDetails && <div className='error text-success'>{msisdnDetails}</div>}
                {(hideNext === undefined || hideNext === true ) && publicAccess && <small className="text-secondary">Customer phone number must contain contry code.</small>}
            </div>   

            <div className='form-group mb-3'>
                <label htmlFor="companyName">Company Name</label>
                <input type="text" id="companyName" className={errors.companyName? 'form-control is-invalid':'form-control'} {...register('companyName', { onChange: handleInputChange('companyName') })}/>
                {errors.companyName && <div className='error text-danger'>{errors.companyName.message}</div>}  
            </div>
 
            <div className='form-input mb-3'>

                    <label htmlFor="documentType">How is your business registered?</label> 

                    <div className='form-input'>
                        <label htmlFor="dprl">
                            <input type="radio" id="dprl" {...register('businessRegistrationType', { onChange: handleInputChange('businessRegistrationType') })} value="Business Name "/> Business Name 
                        </label> 
                    </div>

                    <div className='form-input mb-3'>
                        <label htmlFor="laffs">
                            <input type="radio" id="laffs" {...register('businessRegistrationType', {onChange:  handleInputChange('businessRegistrationType')})} value="Registered Company"/> Registered Company
                        </label> 
                    </div> 
                    {errors.businessRegistrationType && <div className='error text-danger'>{errors.businessRegistrationType.message}</div>}  
                </div>  

            <div className='form-group mb-3'>
                <label htmlFor="cacNumber">Provide your BN or RC number (CAC number)</label>
                <input type="text" id="cacNumber" className={errors.cacNumber? 'form-control is-invalid':'form-control'} {...register('cacNumber', {onChange:  handleInputChange('cacNumber')})}/>
                {errors.cacNumber && <div className='error text-danger'>{errors.cacNumber.message}</div>}  
            </div>  

            <div className='form-group mb-3'>
                <label htmlFor="incorporationDateISO8601">Company Incorporation Date</label>
                <input type="date" id="incorporationDateISO8601" className={errors.incorporationDateISO8601? 'form-control is-invalid':'form-control'} {...register('incorporationDateISO8601', {onChange: handleInputChange('incorporationDateISO8601')})}/>
                {errors.incorporationDateISO8601 && <div className='error text-danger'>{errors.incorporationDateISO8601.message}</div>}  
            </div>
          

            <div className='form-group mb-3'> 
                <label htmlFor="bvn">Customer&apos;s BVN </label>
                <input type="text" id="bvn" className={errors.bvn? 'form-control is-invalid':'form-control'} {...register('bvn', {onChange: handleInputChange('bvn')})} placeholder='22278379533'/>
                {errors.bvn && <div className='error text-danger'>{errors.bvn.message}</div>}  
            </div>   
             
            <div className='form-group mb-3'>
                <label htmlFor="product">Product</label>
                <select  id="product" className={errors.productName? 'form-control is-invalid':'form-control'} {...register('productName', {onChange: handleInputChange('productName')})}>
                    <option>--choose--</option>
                    {locationsPrice.length > 0 && locationsPrice.map(( response: productListType, index:Key)=>{
                        return <option key={index} value={response.shortName}>{response.shortName}</option>
                    })}
                </select>
                {errors.productName && <div className='error text-danger'>{errors.productName.message}</div>}
            </div> 
 
                <div className="row">   
                    <div className="col-12"> 
                        <div className='form-group mb-3'>
                            <label htmlFor="address">Filling Station Address</label>
                            <input type="text" id="address" className={errors.companyAddress? 'form-control is-invalid':'form-control'} {...register('companyAddress', {onChange: handleInputChange('companyAddress')})}/>
                            {errors.companyAddress && <div className='error text-danger'>{errors.companyAddress.message}</div>}  
                        </div> 
                    </div>

                    <div className="col-6"> 
                        <div className='form-group mb-3'>
                            <label htmlFor="country">Country <span className='text-danger'>*</span></label> 
                            <select className={'form-control'}  id="country"  
                                onChange={(e)=>selectState(e)}>
                                <option>--Country--</option>
                                {countries.length > 0 && countries.map((res:countryType, index:React.Key) => {
                                    return <option key={index} value={JSON.stringify(res)}>{res.name}</option>
                                })}
                            </select> 
                        </div> 
                    </div> 
            
                    <div className="col-6"> 
                        <div className='form-group mb-3'>
                            <label htmlFor="state">State <span className='text-danger'>*</span></label>  
                            <select id="state" className={errors.companyState? 'form-control is-invalid':'form-control'}  {...register('companyState', {onChange:selectLga})}>
                                <option>--State--</option>
                                {states.length > 0 && states.map((result: stateType, index: React.Key) => {
                                    return result.name !=='Nationwide' && <option key={index}>{result.name}</option>
                                })}
                            </select>
                            {errors.companyState && <div className='error text-danger'>{errors.companyState.message}</div>}
                        </div> 
                    </div>  
                    <div className="col-6"> 
                        <div className='form-group mb-3'>
                            <label htmlFor="price-per-lit">LGA <span className='text-danger'>*</span></label>
                            <select  id="newCustomerLastName" className='form-control' {...register('companyLGA')}>
                                <option>--option--</option>
                                {lgas.length > 0 && lgas.map((lga:string, index:Key)=><option key={index}>{lga}</option>)}  
                            </select> 
                            {errors.companyLGA && <div className='text-danger'>{errors.companyLGA.message}</div>}
                        </div>  
                    </div>  
                </div> 
                {(hideNext === true || hideNext ===undefined) && <button onClick={onNext} className="btn btn-respond-req" disabled={!isNextButtonDisabled}>Next</button>}
                             
            </div>
        </div>
    )
}

export default PlpCompanyDetailsStep
