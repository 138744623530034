import React, { Fragment, Key, useEffect, useState } from 'react'
import './OrderLogistics.scss' 
import { HorizontalLoader } from '../../../../../Components' 
import { truckTypes } from '../../../../../Models' 
import { Link, useParams } from 'react-router-dom';
import orderService from '../../../../../Services/orderService'
import { OrderType } from '../../../../../Models/orderType';

const OrderLogistics = () => {  
      
    const [orderData, setOrderData] = useState<OrderType>()   
       
       const [trucks, setTrucks] = useState<Array<truckTypes>>([]); 
       const [loading, setLoading] = useState(false);
   
       const { referenceId } = useParams()
       
       const getOrder = async () => {
        setLoading(true)
           await orderService.getDetails(referenceId).then((response)=>{
             console.log('orderService', response.data.data.dataset[0])
              
             setLoading(false)
             setOrderData(response.data.data.dataset[0])
             setTrucks(response.data.data.dataset[0].deliveryTrucks)
           }, (error) =>{
               setLoading(false)
               console.log('orderService error', error.response)
           })
       }
   
       useEffect(() => {
           getOrder()
       }, [])
       
    return (
        <div className='Logistics'> 
            <div className="container mt-4">
                <div className="card border-0">
                    <div className="card-header border-0">
                        <div className="row">
                            <div className="col-12 col-md-8 my-auto">
                                <div className="title">
                                   Trucks Assigned to Order {orderData? `#${orderData.referenceNumber}` : <span className='spinner-border spinner-border-sm'></span>}
                                </div>
                            </div> 
                        </div> 
                    </div>
                </div>
            <div className="container">
               <div className="card border-0">
                <div className="card-header border-0 px-0"> 
 
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr> 
                                    <td>Truck No.</td>
                                    <td>State</td> 
                                    <td>Contact Name</td> 
                                    <td>Contact Number</td> 
                                    <td>Volume</td> 
                                    <td> Status</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                              {trucks.length > 0 && trucks.map((data: truckTypes, index:Key)=>
                                <tr key={index} style={{cursor: 'pointer'}} onClick={() => window.location.href = `/logistic/${data.id}`}>                                    
                                    <td>{data.truckPlateNumber}</td>     
                                    <td>{data.sourceState}</td>        
                                    <td>{data.contactName}</td>  
                                    <td>{data.contactPhoneNumber}</td>  
                                    <td>{data.capacityInLitres}Litr</td>
                                    <td>{data.status === 'PENDING_APPROVAL'? <span className='bg-pending-0'>{data.status}</span>: <span className='bg-success-0'>{data.status}</span>}</td>  
                                    <td className='dropdown option-dropdown'>
                                        <button className='btn border-0 dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false" data-bs-popper-config='{"strategy":"fixed"}'>
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.49991 8.4705C7.49991 7.72933 8.0955 7.14697 8.82344 7.14697C9.55138 7.14697 10.147 7.74256 10.147 8.4705C10.147 9.19844 9.55138 9.79403 8.82344 9.79403C8.0955 9.79403 7.49991 9.21168 7.49991 8.4705Z" fill="#707070"/>
                                                <path d="M7.49991 14.1175C7.49991 13.3763 8.0955 12.7939 8.82344 12.7939C9.55138 12.7939 10.147 13.3895 10.147 14.1175C10.147 14.8454 9.55138 15.441 8.82344 15.441C8.0955 15.441 7.49991 14.8587 7.49991 14.1175Z" fill="#707070"/>
                                                <path d="M7.49991 2.82353C7.49991 2.08235 8.0955 1.5 8.82344 1.5C9.55138 1.5 10.147 2.09559 10.147 2.82353C10.147 3.55147 9.55138 4.14706 8.82344 4.14706C8.0955 4.14706 7.49991 3.56471 7.49991 2.82353Z" fill="#707070"/>
                                            </svg> 
                                        </button>

                                        <ul className="dropdown-menu dropdown-menu-end export-dropdown border-0 shadow">
                                            <li className='dropdown-item'><Link to={`/logistic/${data.id}`}>Truck Details</Link></li>  
                                             <li className='dropdown-item text-danger'>Delete</li> 
                                        </ul> 
                                    </td>   
                                </tr>)} 

                                {loading === false && trucks.length === 0 &&<tr>
                                    <td colSpan={7} className='text-center'>No truck assigned yet</td>
                                </tr>}
                                {loading===true && trucks.length === 0 && <tr>
                                    <td colSpan={7} className='text-center'><HorizontalLoader></HorizontalLoader></td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                 </div>
               </div>  
            </div> 
        </div> 
    </div>
    )
}

export default OrderLogistics
