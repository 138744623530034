type DateFormatOptions = {
    weekday: 'long' | 'short' | 'narrow';
    day: 'numeric' | '2-digit';
    month: 'long' | 'short' | 'narrow';
    year: 'numeric' | '2-digit';
};

export const todaysDateTime =  (date: Date): string => {

    const  getNumberSuffix = (number: number) => {
        if (number >= 11 && number <= 13) {
            return 'th';
        }
        switch (number % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    }
    
        const options: DateFormatOptions = {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        };

        const formattedDate = new Date(date).toLocaleDateString(undefined, options);
        
        const dayString: string = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(date);
        const day: number = parseInt(dayString, 10); // Parse the day string to a number
        const suffix = getNumberSuffix(day);

        return formattedDate.replace(/\d+/, day + suffix);
    }
    
 