import React, { FC, Key, useState } from 'react'
import './AddDeliveryFee.scss' 
import { useForm } from 'react-hook-form'; 
import { yupResolver } from '@hookform/resolvers/yup';  
import * as yup from 'yup'; 
import { ShowTGAlert } from '../../../../../Helpers/ShowTGAlert'; 
import deliveryService from '../../../../../Services/deliveryService';

    interface propsType {
        toggleModal: ()=>void,
        countries: Array<countryType>
    }

    export type AddDeliveryFeeFormType = {  
        country: string,
        state: string,
        localGovernmentArea: string,
        deliveryFeeMajor: number 
    }

    export type countryType = {
        name: string,
        iso2: string,
        phoneCode: string,
        currency: string,
        currencySymbol: string,
        states: [
          {
            name: string,
            localGovernmentAreas: [ string ]
          }
        ]
    }

    export type stateType = {  
            name: string,
            localGovernmentAreas: [ string ] 
    }
  
  const schema = yup.object().shape({ 
    country: yup.string().required('country field is required'), 
    state: yup.string().required('state field is required'),
    localGovernmentArea: yup.string().required('LGA field is required'),
    deliveryFeeMajor: yup.number().required('Delivery Fee field is required'),
  });
  

const AddDeliveryFee:FC<propsType> = ({toggleModal, countries}) => { 

    const [states, setStates] = useState<Array<stateType>>([]);
    const [lgas, setLgas] = useState<Array<string>>([]); 
    const [selectedCountry, setSelectedCountry] = useState('');
    
   const {handleSubmit, register, formState:{errors}} = useForm<AddDeliveryFeeFormType>({
    resolver: yupResolver(schema),
  })

  const { showAlert } = ShowTGAlert()  
  const [request, setRequest] = useState(false) 

  const submitNewUserInformation = handleSubmit(async (data:AddDeliveryFeeFormType) => { 
    setRequest(true)  
    await deliveryService.createNewDelivery(data).then(()=>{
        setRequest(false)  
        toggleModal()

        showAlert({message: 'Delivery created successfully', show:true, title: 'Success', type: 'success'}) 

        setTimeout(()=>{ 
            window.location.reload()
        }, 2000)

    }, (error: { response: { data: { error: any } } }) =>{ 

        setRequest(false)   
        toggleModal()  
        showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'}) 
   }) 
  }); 


    const selectState = (countryName:{target: { value: string }})=>{
       setSelectedCountry(countryName.target.value)
       console.log(countryName.target.value)
        let country:countryType|undefined;
        if(countryName.target.value){ 
            country = countries.find((res)=>res.name === countryName.target.value)
            if(country)setStates(country.states)
        }
    }

    const selectLga = (stateName:{target: { value: string }})=>{ 
        console.log(stateName.target.value)
        let country:countryType|undefined;
        if(stateName){ 
            country = countries.find((res)=>res.name === selectedCountry)
             const state:stateType|undefined = country?.states.find((res)=>res.name === stateName.target.value)

              console.log(state)
            if(state)setLgas(state?.localGovernmentAreas)
        }
    }
    return (
        <div className='AddUser'> 
             <form action='/product-scanner'>
                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Delivery Fee</label>
                    <input type="text" id="newCustomerFirstName" className='form-control' {...register('deliveryFeeMajor')}/>
                    {errors.deliveryFeeMajor && <div className='text-danger'>{errors.deliveryFeeMajor.message}</div>}
                </div> 
                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">Country</label>
                    <select  id="newCustomerLastName" className='form-control' {...register('country', { onChange: selectState})}>
                        <option>--option--</option>
                        {countries.length > 0 && countries.map((country:countryType, index:Key)=><option key={index}>{country.name}</option>)}
                    </select>
                    {errors.country && <div className='text-danger'>{errors.country.message}</div>}
                </div>
                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">State </label>
                    <select  id="newCustomerLastName" className='form-control' {...register('state', {onChange:selectLga})}>
                        <option>--option--</option>
                        {states.length > 0 && states.map((state:stateType, index:Key)=><option key={index}>{state.name}</option>)} 
                    </select> 
                    {errors.state && <div className='text-danger'>{errors.state.message}</div>}
                </div>  
                <div className='form-group mb-3'>
                    <label htmlFor="price-per-lit">LGA </label>
                    <select  id="newCustomerLastName" className='form-control' {...register('localGovernmentArea')}>
                        <option>--option--</option>
                        {lgas.length > 0 && lgas.map((lga:string, index:Key)=><option key={index}>{lga}</option>)}  
                  </select> 
                    {errors.localGovernmentArea && <div className='text-danger'>{errors.localGovernmentArea.message}</div>}
                </div>  
                <div className='form-group mb-3'>
                    <button className='btn btn-default w-100' onClick={(e) => submitNewUserInformation(e)} disabled={request}>Add Fee {request && <span className='spinner-border spinner-border-sm'></span>}</button> 
                </div> 
            </form>
        </div>
    )
}

export default AddDeliveryFee
