import React, { Key, useEffect, useState } from 'react'
import './AssignedCustomers.scss'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { HorizontalLoader, Pagination } from 'Components' 
import customerService from 'Services/customerService'
import { CustomerType } from 'Models'  
import { Logger } from 'Helpers/Logger'
import SearchInput from 'Components/SearchInput/SearchInput'   
import { ShowTGAlert } from 'Helpers/ShowTGAlert' 
import { CurrencyFormatter, checkSearchInputType } from 'Helpers'
import { TimeConverter } from 'Helpers/TimeConverter'
import loanService from 'Services/loanServices'
import { useAuth } from 'Components/AuthProvider/AuthProvider'
 
  
const AssignedCustomers = () => {  
    const { loanProvider } = useParams<{loanProvider: string}>();
  
    const { showAlert } = ShowTGAlert()  
 
    const [sortOrder, setSortOrder] = useState('DESC') 
    const [pageNumber, setPage] = useState(1) 
    const [customers, setCustomers] = useState([]); 
    const [loading, setLoading] = useState(false); 
    const [searching, setSearching] = useState(false);

    const { user } = useAuth()
    const navigate = useNavigate()
     
    const [paginationData, setPaginationData] = useState<{
        pageNumber: number,
        pageSize: number,
        total:number
      }>({
        pageNumber: 1,
        pageSize: 20,
        total: 1
      })

    
      const getUsers = async () => {
        setLoading(true)
        await loanService.loanProvidersCustomers(pageNumber, loanProvider!)
          .then((response: { data: { data: { dataset: React.SetStateAction<never[]>; pageNumber: number; pageSize: number; total: number } } })=>{    
            setLoading(false)
            setCustomers(response?.data.data.dataset) 
            setPaginationData({
                pageNumber: response?.data.data.pageNumber,
                pageSize: response?.data.data.pageSize,
                total: response?.data.data.total
              })

          }, (error: { response: { data: { error: string } } }) =>{     
            showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'}) 

            setLoading(false)
    
          })
    }

    useEffect(() => {
        getUsers()
    }, [pageNumber, sortOrder, loanProvider])



    const handleSearch = async (searchTerm: string) => {
        // If a search operation is already in progress, return early
        if (searching) {
            // return;
        }
    
        // Set the searching flag to true to indicate that a search is starting
        setSearching(true);

        // Perform search operation using the searchTerm
        console.log('Searching for:', searchTerm);
        const searchTermRes = checkSearchInputType(searchTerm)
        
        let endpoint = '';
        if (searchTermRes === 'Email') {
          endpoint = `customerEmailAddress=${encodeURIComponent(searchTerm)}`; 
        } else if (searchTermRes === 'PhoneNumber') { 
            endpoint = `customerMsisdn=${encodeURIComponent(searchTerm)}`;
        }else if (searchTermRes === 'AlphaNumeric') {  
            endpoint = `customerAccountId=${encodeURIComponent(searchTerm)}`;
        }else if (searchTermRes === 'Numeric') {  
            endpoint = `customerUserId=${encodeURIComponent(searchTerm)}`;
        }
        
        if(endpoint === '') {
            setLoading(false);
            setSearching(false);
            getUsers()
          return
        }

        setLoading(true) 
         await customerService.searchCustomer(endpoint, pageNumber, sortOrder) 
             .then((response: { data: { data: { dataset: React.SetStateAction<never[]>; pageNumber: number; pageSize: number; total: number } } })=>{    
            setLoading(false)
            setSearching(false);
            setCustomers(response?.data.data.dataset) 

            setPaginationData({
                pageNumber: response?.data.data.pageNumber,
                pageSize: response?.data.data.pageSize,
                total: response?.data.data.total
              })

          }, (error: { response: {data : {error : string }} }) =>{ 
            setCustomers([])
 
            setTimeout(()=>{  
                setLoading(false);
                setSearching(false);
                if (searchTerm.length < 9) {
                   getUsers()
                }
            }, 3000) 
    
            setLoading(false)

            // showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'}) 
            Logger('response error', error.response) 
          }) 
 
    };

    useEffect(() => {
        if(user.user.loanProvider !== loanProvider && user.loanProviderAdminRole === true){
           navigate('/', {replace: true})
        } 
    }, [loanProvider])

 
    return (
    <div className='AssignedCustomers'>
        <div className="container mt-4">
            <div className="container">
               <div className="card border-0">
                <div className="card-header border-0 px-0">
                    <div className="row">
                        <div className="col-6 col-md-6 my-auto">
                          <div className="title">
                          {paginationData.total} total customers assigned to {loanProvider} 
                           </div>
                        </div>
                        <div className="col-6 col-md-6 text-end"> 
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-12 col-md-8"> 
                            <SearchInput onSearch={handleSearch} placeholder={'Search email, phone or user ID'}></SearchInput>
                        </div> 
                        <div className="col-6 col-md-2 dropdown">
                            <button className='btn btn-filter dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false">
                            <svg width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.94 22.65C10.46 22.65 9.99002 22.53 9.55002 22.29C8.67002 21.8 8.14002 20.91 8.14002 19.91V14.61C8.14002 14.11 7.81002 13.36 7.50002 12.98L3.76002 9.02001C3.13002 8.39001 2.65002 7.31001 2.65002 6.50001V4.20001C2.65002 2.60001 3.86002 1.35001 5.40002 1.35001H18.6C20.12 1.35001 21.35 2.58001 21.35 4.10001V6.30001C21.35 7.35001 20.72 8.54001 20.13 9.13001L15.8 12.96C15.38 13.31 15.05 14.08 15.05 14.7V19C15.05 19.89 14.49 20.92 13.79 21.34L12.41 22.23C11.96 22.51 11.45 22.65 10.94 22.65ZM5.40002 2.85001C4.70002 2.85001 4.15002 3.44001 4.15002 4.20001V6.50001C4.15002 6.87001 4.45002 7.59001 4.83002 7.97001L8.64002 11.98C9.15002 12.61 9.65002 13.66 9.65002 14.6V19.9C9.65002 20.55 10.1 20.87 10.29 20.97C10.71 21.2 11.22 21.2 11.61 20.96L13 20.07C13.28 19.9 13.56 19.36 13.56 19V14.7C13.56 13.63 14.08 12.45 14.83 11.82L19.11 8.03001C19.45 7.69001 19.86 6.88001 19.86 6.29001V4.10001C19.86 3.41001 19.3 2.85001 18.61 2.85001H5.40002Z" fill="#868484"/>
                                <path d="M6.00004 10.75C5.86004 10.75 5.73004 10.71 5.60004 10.64C5.25004 10.42 5.14004 9.94999 5.36004 9.59999L10.29 1.69999C10.51 1.34999 10.97 1.23999 11.32 1.45999C11.67 1.67999 11.78 2.13999 11.56 2.48999L6.63004 10.39C6.49004 10.62 6.25004 10.75 6.00004 10.75Z" fill="#868484"/>
                            </svg>Filters</button> 
                            <div className="dropdown-menu dropdown-menu-end border-0 shadow filter-dropdown">
                               <div className='dropdown-item'> 
                                   <form action="">
                                     <div className="form-group">
                                        <label htmlFor=''>Sort List</label>
                                        <select id="" className='form-control' onChange={(e)=>setSortOrder(e.target.value)}>
                                            <option value="DESC">Descending Order</option>
                                            <option value="ASC">Ascending Order</option>
                                        </select>
                                     </div> 
                                     <div className="row mt-3">
                                        <div className="col-md-6">
                                            <button type='button' className="btn btn-reset"  onClick={()=>setSortOrder('DESC')}>Reset</button>
                                        </div>
                                        <div className="col-md-6 text-end">
                                            <button type='button' className="btn btn-filter">Filter</button>
                                        </div>
                                     </div>
                                   </form>
                                </div> 
                            </div> 
                        </div>
                        <div className="col-6 col-md-2">
                            <button className='btn btn-export'>
                            <svg  width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 11V17L11 15" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M9 17L7 15" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M22 10H18C15 10 14 9 14 6V2L22 10Z" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg> Export</button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                    <table className='table'>
                                <thead>
                                    <tr>
                                        <td>Date</td>
                                        <td>Fullname</td>
                                        <td>Account Number</td>
                                        <td>Phone Number</td>
                                        <td>Status</td>
                                        <td>Wallet Balance</td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                {customers.length > 0 && customers.map((data: CustomerType, index:Key)=>
                                 <TableRole data={data} key={index}></TableRole>
                                )} 
                                    {loading === false && customers.length === 0 &&<tr>
                                        <td colSpan={8} className='text-center'>No user found</td>
                                    </tr>}
                                    {loading===true && <tr>
                                        <td colSpan={8} className='text-center'><HorizontalLoader></HorizontalLoader></td>
                                     </tr>}
                                </tbody>
                            </table> 
                    </div>
 
                    {paginationData.total >= paginationData.pageSize && <div className="pagination w-100 d-flex justify-content-center">
                    {paginationData.total && <Pagination
                        className="pagination-bar ps-0"
                        currentPage={Number(paginationData.pageNumber)}
                        totalCount={paginationData.total}
                        pageSize={paginationData.pageSize}
                        onPageChange={(page: React.SetStateAction<number>) => setPage(page)}/>
                        }
                   </div>}
                </div>
               </div>
            </div> 
        </div>   
    </div>
    )
}

export default AssignedCustomers

 
 
export const TableRole = ({ data }: { data: CustomerType }) => {

    const { loanProvider } = useParams<{loanProvider: string}>();
    
    const { showAlert } = ShowTGAlert()  
    const [loading, setLoading] = useState(false)
    const [accountBalance, setAccountBalance] = useState(0)
    

    const getBalance = async (msisdn:string) => {
        setLoading(true)
        setAccountBalance(0)
        await loanService.loanProvidersCustomerBalance(loanProvider, msisdn).then((response)=>{
          console.log('refreshed blance', response.data.data)
          setAccountBalance(response.data.data.bankAccountBalance)
          setLoading(false)
        }, (error) => {
            setLoading(false)
            showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'})  
        })
    }

    const firstName = data.firstName.charAt(0).toUpperCase() + data.firstName.slice(1);
    const lastName = data.lastName.charAt(0).toUpperCase() + data.lastName.slice(1);
   
    return (
        <tr>
            <td>{TimeConverter(data.createdAt)}</td>
            <td>{firstName} {lastName}</td>
            <td>{data.loanProviderAccount?.VFD.beneficiaryAccountEnquiry.account.number}</td> 
            <td>{data.msisdn}</td>
            <td><span className='status'>{data.isEnabled ? 'Active' : 'Inactive'}</span></td>
            <td className={Number(data.vfdBankAccountBalance) < 0? 'text-danger': 'text-success'}>₦{accountBalance? CurrencyFormatter(Number(accountBalance)): CurrencyFormatter(Number(data.vfdBankAccountBalance))}</td>
            <td>
                <div className="btn-group" role="group"> 
                    <button className='btn btn-refresh me-1' onClick={()=>getBalance(data.msisdn)} disabled={loading}> {loading? <span className='spinner-border spinner-border-sm'></span>: 'Refresh'}</button>
                    <Link to={`/user/${data.accountId}`}><button className='btn btn-refresh'><i className='d-icon d-eye-open'></i></button></Link>
                </div>
            </td>
        </tr>
    );
  };
  