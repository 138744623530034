/* eslint-disable react/no-unescaped-entities */
import './UsersDetails.scss'
import { BreadCrumb, HorizontalLoader, ModalWrapper, Pagination } from '../../../../../Components'
import thumbnail from './../../../../../Assets/Images/thumbnail.jpeg'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { CustomerType } from '../../../../../Models'
import { Key, useEffect, useState } from 'react'
import customerService from '../../../../../Services/customerService'
import { TimeConverter } from '../../../../../Helpers/TimeConverter'
import loanService from 'Services/loanServices'
import { ShowTGAlert } from 'Helpers/ShowTGAlert'
import { useAuth } from 'Components/AuthProvider/AuthProvider'
import { Logger } from 'Helpers/Logger'
import { CurrencyFormatter } from 'Helpers'
import { OrderType } from 'Models/orderType'
import Swal from 'sweetalert2'
import { walletService } from 'Services/walletService'
import { useForm } from 'react-hook-form'
import { ExtractSubdomain } from 'Helpers/ExtractSubdomain'

const UsersDetails = () => {
  const { customerAccountId } = useParams()
  const [userData, setUserData] = useState<CustomerType>()
  const [customerReferrals, setCustomerReferrals] = useState<CustomerType[]>()

  const [request, setRequest] = useState(false)
  const [transferLoading, setTransferLoading] = useState(false)

  const [debitLoading, setDebitLoading] = useState(false)

  const navigate = useNavigate()

  const [showTransferModal, setShowTransferModal] = useState(false)

  const toggleTransferModal = () => {
    setShowTransferModal((prevState) => !prevState)
  }

  const getUser = async () => {
    setRequest(true)
    await customerService.getDetails(customerAccountId).then(
      (response) => {
        setUserData(response.data.data.dataset[0])
        console.log('getUser', response.data.data.dataset[0])

        setRequest(false)
      },
      (error: { response: { data: { error: string } } }) => {
        setRequest(false)
        console.log('orderService error', error.response)
      },
    )
  }

  useEffect(() => {
    getUser()
  }, [])

  const [userLp, setUserLp] = useState<
    {
      accountLoanProvider: string
      isDefault: boolean
    }[]
  >()

  const [lpRequest, setLpRequest] = useState(false)

  const getUserlp = async (msisdn: string) => {
    setLpRequest(true)
    await customerService.getUserLPs(msisdn).then(
      (response) => {
        console.log('getUser getUserLPs 00', response.data.data)
        setUserLp(response.data.data)
        setLpRequest(false)
      },
      (error: { response: { data: { error: string } } }) => {
        setLpRequest(false)
        console.log('orderService error', error.response)
      },
    )
  }

  useEffect(() => {
    if (userData) getUserlp(userData.msisdn)
  }, [userData])

  const [showDebitModal, setShowDebitModal] = useState(false)

  const toggleDebitModal = () => {
    setShowDebitModal((prevState) => !prevState)
  }

  const { showAlert } = ShowTGAlert()

  const sendDebitRequest = async () => {
    setDebitLoading(true)
    const msisdn = encodeURIComponent(userData?.msisdn ?? '')
    await loanService.vfdAllowFundDebit(msisdn).then(
      () => {
        toggleDebitModal
        setDebitLoading(false)
        toggleDebitModal()
        showAlert({
          message: 'Debit approved succesfully',
          show: true,
          title: 'Approval Successful',
          type: 'success',
        })
      },
      (error: { response: { data: { error: string } } }) => {
        showAlert({ message: error.response.data.error, show: true, title: 'Error', type: 'error' })
        setDebitLoading(false)
        toggleDebitModal()
      },
    )
  }

  const vfdKycConsentUrlExists = !!userData?.loanProviderAccount?.VFD?.kycConsent?.url

  const { user } = useAuth()

  const [pageNumber, setPageNumber] = useState(1)

  const [order, setOrder] = useState([])
  const [loading, setLoading] = useState(false)
  const [paginationData, setPaginationData] = useState<{
    pageNumber: number
    pageSize: number
    total: number
  }>({
    pageNumber: 1,
    pageSize: 20,
    total: 1,
  })

  const getLpOrders = async () => {
    if (userData && userData?.loanProvider) {
      setLoading(true)
      await loanService
        .loanProvidersCustomerOrders(pageNumber, userData.loanProvider ?? 'VFD', customerAccountId)
        .then(
          (response: {
            data: {
              data: {
                dataset: React.SetStateAction<never[]>
                pageNumber: number
                pageSize: number
                total: number
              }
            }
          }) => {
            setLoading(false)
            setOrder(response?.data.data.dataset)

            Logger('response loanProvidersCustomerOrders', response)
            setPaginationData({
              pageNumber: response?.data.data.pageNumber,
              pageSize: response?.data.data.pageSize,
              total: response?.data.data.total,
            })
          },
          (error: { response: { data: { error: string } } }) => {
            setLoading(false)
            Logger('response error', error.response)
          },
        )
    }
  }

  const getUserReferals = async () => {
    setLoading(true)
    await customerService.allReferrals(pageNumber, userData?.id).then(
      (response: {
        data: {
          data: {
            dataset: CustomerType[] | undefined
            pageNumber: number
            pageSize: number
            total: number
          }
        }
      }) => {
        setLoading(false)

        setCustomerReferrals(response?.data.data.dataset)

        Logger('response getUserReferals', response?.data.data.dataset)
        setPaginationData({
          pageNumber: response?.data.data.pageNumber,
          pageSize: response?.data.data.pageSize,
          total: response?.data.data.total,
        })
      },
      (error: { response: { data: { error: string } } }) => {
        setLoading(false)
        Logger('response error', error.response)
      },
    )
  }

  useEffect(() => {
    if (userData) {
      getLpOrders()
      getUserReferals()
    }
  }, [userData])

  const [refreshReq, setRefreshReq] = useState(false)

  const refreshConsentLink = () => {
    Swal.fire({
      title: 'Alert',
      text: 'Are you sure you want to refresh this consent link?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, refresh it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setRefreshReq(true)

        await customerService
          .refreshConsentLink(userData?.msisdn, userData?.loanProvider ?? 'VFD')
          .then(
            () => {
              Swal.fire({
                title: 'Success!',
                text: 'Consent link has been refreshed successfully.',
                icon: 'success',
              })

              setTimeout(() => {
                getUser()
              }, 3000)
            },
            (error) => {
              setRefreshReq(false)
              Swal.fire({
                title: 'Oops!',
                text: error.response.data.error,
                icon: 'warning',
              })
            },
          )
      }
    })
  }

  const [reconcileReq, setReconcileReq] = useState(false)

  const reconcileConsentLink = () => {
    Swal.fire({
      title: 'Alert',
      text: 'Are you sure you want to reconcile this user wallet with tradegride?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, reconcile!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setReconcileReq(true)

        await customerService.reconcileAccount(userData?.msisdn).then(
          () => {
            Swal.fire({
              title: 'Success!',
              text: 'User account has been reconciled successfully.',
              icon: 'success',
            })

            setReconcileReq(false)

            setTimeout(() => {
              getUser()
            }, 3000)
          },
          (error) => {
            setReconcileReq(false)
            Swal.fire({
              title: 'Oops!',
              text: error.response.data.error,
              icon: 'warning',
            })
          },
        )
      }
    })
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ amount: number }>()

  const submitTransfer = async (data: { amount: number }) => {
    if (userData && userData?.loanProvider) {
      setTransferLoading(true)

      await walletService
        .initiateTransfer(userData?.loanProvider, userData.msisdn, { amount: data.amount })
        .then(
          (response) => {
            Swal.fire({
              title: 'Success!',
              text: 'Transfer initialized successfully, awaiting approval.',
              icon: 'success',
            })

            setTransferLoading(false)

            toggleTransferModal()
          },
          (error) => {
            setTransferLoading(false)

            Swal.fire({
              title: 'Oops!',
              text: error.response.data.error,
              icon: 'warning',
            })
          },
        )
    }
  }

  const [lp, setLp] = useState<string>()
  const [inhouseDomain] = useState<Array<string>>([
    'admin-prod.tradegrid.co',
    'admin-dev.tradegrid.co',
    'localhost:2024',
  ])

  useEffect(() => {
    const domain = window
    const domainHost = domain.location.host

    const domainProvider = ExtractSubdomain(domainHost)

    if (!inhouseDomain.includes(domainHost)) {
      setLp(domainProvider?.toLowerCase())
    }
  }, [])

  return (
    <div className='UsersDetails'>
      <div className='container'>
        {!user.loanProviderAdminRole && (
          <BreadCrumb
            title={`#${customerAccountId}`}
            parent={'Users'}
            parentUrl={'users'}
            showTitle={false}
          ></BreadCrumb>
        )}
        <div className='container mt-2'>
          <div className='card border-0'>
            <div className='card-header border-0 px-0'>
              <div className='row'>
                <div className='col-12 col-md-6 col-xl-8 my-auto'>
                  {!request && (
                    <div className='title'>
                      {userData?.firstName} {userData?.lastName}
                    </div>
                  )}
                  {request && (
                    <div className='title'>
                      <span className='spinner-border  spinner-border-sm'></span>
                    </div>
                  )}
                </div>

                <div className='col-6 col-md-3 col-xl-3 text-md-end my-auto'>
                  {userData?.loanProvider && lp !== 'zedvance' && (
                    <Link
                      to={`/loan-providers/users/post-transaction/${userData?.loanProvider}/${userData?.accountId}/${userData?.loanProviderAccount?.VFD?.beneficiaryAccountEnquiry?.account?.number}`}
                    >
                      <button className='btn btn-default btn-def-0'>
                        {userData?.loanProvider} Transaction
                      </button>
                    </Link>
                  )}
                </div>
                <div className='col-6 col-md-3 col-xl-1 text-md-end my-auto'>
                  <span className='btn btn-respond-req'>
                    {userData?.isEnabled ? 'Active' : 'Inactive'}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {!user.loanProviderAdminRole && (
            <div className='row details-section'>
              <div className='col-md-12'>
                <div className='card border-0 mt-3 pt-4'>
                  <div className='card-header bg-white border-0'>
                    <div className='container h-100'>
                      <div className='row h-100'>
                        <div className='col-6 col-sm-8 col-md-7 col-xl-10 my-auto'>
                          <div className='title'>User Profile</div>
                        </div>
                        {/* <div className="col-12 col-sm-6 col-md-3 col-lg-2 col-xl-2">
                                                {!user.loanProviderAdminRole && <div className="sub-title">
                                                        <button className="btn btn-allow-debit mt-1 mt-md-0" onClick={toggleDebitModal}>VFD - Allow Debit</button>
                                                    </div>}
                                                </div>
                                        
                                                <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-2 mt-2 mt-sm-0">
                                                    <button className="btn btn-allow-debit mt-1 mt-md-0" onClick={refreshConsentLink}>
                                                        Refresh Consent Link {refreshReq ===true && <span className='spinner-border spinner-border-sm'></span>}
                                                    </button>
                                                </div>

                                                <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-2 mt-2 mt-sm-0">
                                                    <button className="btn btn-allow-debit mt-1 mt-md-0" onClick={reconcileConsentLink}>
                                                        Reconcile Wallet {reconcileReq ===true && <span className='spinner-border spinner-border-sm'></span>}
                                                    </button>
                                                </div>

                                                <div className="col-4 col-sm-3 col-md-3 col-lg-1 col-xl-1 mt-2 mt-sm-0">
                                                    {!user.loanProviderAdminRole && <div className="sub-title mt-1 mt-md-0">
                                                    <Link to={`/user/edit/${userData?.accountId}`}><button className='btn btn-edit w-100'>Edit</button></Link>
                                                    </div>}
                                                </div>
                                                <div className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-1 mt-2 mt-sm-0"> 
                                                    <button className='btn btn-edit w-100 mt-1 mt-md-0' onClick={()=> setShowTransferModal(true)}>Transfer</button> 
                                                </div> 
                                                */}

                        <div className='col-6 col-sm-4 col-md-5 col-xl-2 mt-2 mt-sm-0 my-auto'>
                          <div className='dropdown'>
                            <button
                              className='btn btn-default btn-def-0 dropdown-toggle'
                              type='button'
                              data-bs-toggle='dropdown'
                              aria-expanded='false'
                            >
                              Profile Actions
                            </button>
                            <ul className='dropdown-menu shadow-sm border'>
                              {!user.loanProviderAdminRole && (
                                <li>
                                  {' '}
                                  <Link
                                    to={`/user/edit/${userData?.accountId}`}
                                    className='dropdown-item border-bottom'
                                  >
                                    <span className='d-icon d-pencil'></span> Edit
                                  </Link>
                                </li>
                              )}
                              {userData?.loanProvider && (
                                <li>
                                  <a
                                    className='dropdown-item border-bottom'
                                    href='#'
                                    onClick={() => setShowTransferModal(true)}
                                  >
                                    <span className='d-icon d-arrow-back'></span> Transfer
                                  </a>
                                </li>
                              )}
                              <li>
                                <a
                                  className='dropdown-item border-bottom'
                                  href='#'
                                  onClick={reconcileConsentLink}
                                >
                                  <span className='d-icon d-wallet'></span> Reconcile Wallet{' '}
                                  {reconcileReq === true && (
                                    <span className='spinner-border spinner-border-sm'></span>
                                  )}
                                </a>
                              </li>
                              {/* <li><a className="dropdown-item border-bottom" href="#" onClick={refreshConsentLink}><span className='d-icon d-link'></span> Refresh Consent Link {refreshReq ===true && <span className='spinner-border spinner-border-sm'></span>}</a></li>*/}
                              {!user.loanProviderAdminRole && (
                                <li>
                                  <a
                                    className='dropdown-item border-bottom'
                                    href='#'
                                    onClick={refreshConsentLink}
                                  >
                                    <span className='d-icon d-refresh'></span> Refresh Consent Link{' '}
                                    {refreshReq === true && (
                                      <span className='spinner-border spinner-border-sm'></span>
                                    )}
                                  </a>
                                </li>
                              )}
                              <li>
                                <a className='dropdown-item' href='#' onClick={toggleDebitModal}>
                                  <span className='d-icon d-file-minus'></span> VFD - Allow Debit
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='card-body'>
                    {request && <HorizontalLoader></HorizontalLoader>}
                    {!request && (
                      <div className='row mt-4'>
                        <div className='col-12 col-md-2 text-md-center mb-3'>
                          <div className='profile-image'>
                            <img
                              src={userData?.photo.url ? userData?.photo.url : thumbnail}
                              className=''
                            />
                          </div>
                        </div>

                        <div className='col-md-5 text-start'>
                          <div className='profile-item'>
                            <div className='name'>Full Name</div>
                            <div className='value'>
                              {userData?.firstName} {userData?.lastName}
                            </div>
                          </div>
                          <br />
                          <div className='profile-item'>
                            <div className='name'>Email</div>
                            <div className='value'>
                              {userData?.emailAddress ? userData?.emailAddress : '-'}
                            </div>
                          </div>
                          <br />
                          <div className='profile-item'>
                            <div className='name'>Phone Number</div>
                            <div className='value'>{userData?.msisdn}</div>
                          </div>
                          <br />
                          <div className='profile-item'>
                            <div className='name'>BVN</div>
                            <div className='value'>-</div>
                          </div>
                          <br />
                          <div className='profile-item'>
                            <div className='name'>Account Plan</div>
                            <div className='value'>{userData?.accountPlan}</div>
                          </div>
                          <br />
                          <div className='profile-item'>
                            <div className='name'>PLP Ultra Local Government Area</div>
                            <div className='value'>{userData?.plpUltraLocalGovernmentArea}</div>
                          </div>
                          <br />
                          <div className='profile-item'>
                            <div className='name'>PLP Ultra default order quantity</div>
                            <div className='value'>
                              {userData?.plpUltraDefaultQuoteRequestQuantity}
                            </div>
                          </div>
                          <br />
                          <div className='profile-item'>
                            <div className='name'>Country</div>
                            <div className='value'>{userData?.country}</div>
                          </div>
                          <br />
                          <div className='profile-item'>
                            <div className='name'>Tags</div>
                            <div className='value'>{userData?.tags}</div>
                          </div>
                          <br />
                          <div className='profile-item'>
                            <div className='name'>Time created</div>
                            <div className='value'>{TimeConverter(userData?.createdAt)}</div>
                          </div>
                        </div>

                        <div className='col-md-5'>
                          {lpRequest === false && (
                            <div className='col-md-5'>
                              <div className='profile-item'>
                                <div className='name'>Assigned Loan Provider:</div>
                                <div className='value'>
                                  <ul>
                                    {userLp &&
                                      userLp.map(
                                        (
                                          list: {
                                            accountLoanProvider: string
                                            isDefault: boolean
                                          },
                                          index: Key,
                                        ) => {
                                          return (
                                            <li key={index}>
                                              {list.accountLoanProvider}{' '}
                                              <small>
                                                {list.isDefault === true ? (
                                                  <b className='text-success'>(Primary)</b>
                                                ) : (
                                                  <b className='text-dark'>(Secondary)</b>
                                                )}
                                              </small>
                                            </li>
                                          )
                                        },
                                      )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )}

                          <div className='profile-item'>
                            <div className='name'>Enabled for multiple PLP:</div>
                            <div className='value'>
                              {userData?.accountSettings?.multipleProductLeaseAtOnce
                                ? 'Enabled'
                                : 'Disabled'}
                            </div>
                          </div>
                          <div className='profile-item'>
                            <div className='name'>Loan Provider:</div>
                            <div className='value'>{userData?.loanProvider}</div>
                          </div>

                          <div className='profile-item'>
                            <div className='name'>TG Wallet Balance:</div>
                            <div className='value'>
                              {userData?.walletBalance?.currency}
                              {CurrencyFormatter(userData?.walletBalance?.amountMajor ?? 0)}
                            </div>
                          </div>
                          <div className='profile-item'>
                            <div className='name'>VFD Wallet Balance:</div>
                            <div className='value'>
                              {userData?.walletBalance?.currency}
                              {CurrencyFormatter(
                                Number(userData?.loanProviderAccount?.VFD.bankAccountBalance) ?? 0,
                              )}
                            </div>
                          </div>
                          <div className='profile-item'>
                            <div className='name'>VFD Bank Account Number:</div>
                            <div className='value'>
                              {
                                userData?.loanProviderAccount?.VFD?.beneficiaryAccountEnquiry
                                  ?.account?.number
                              }
                            </div>
                          </div>
                          <div className='profile-item'>
                            <div className='name'>VFD Bank Bvn Consent Link:</div>
                            {vfdKycConsentUrlExists && (
                              <div className='value'>
                                <a href={userData?.loanProviderAccount?.VFD?.kycConsent?.url}>
                                  Link
                                </a>
                              </div>
                            )}
                          </div>
                          <div className='profile-item'>
                            <div className='name'>VFD Bank Bvn Consent Given:</div>
                            <div className='value'>
                              {userData?.vfdBankBvnConsentGiven ? 'YES' : 'NO'}
                            </div>
                          </div>
                          <div className='profile-item'>
                            <div className='name'>VFD Bank Post No Debit Released:</div>
                            <div className='value'>
                              {userData?.vfdBankPostNoDebitReleased ? 'YES' : 'NO'}
                            </div>
                          </div>
                          <div className='profile-item'>
                            <div className='name'>
                              VFD Loan account number(when loan is disbursed):
                            </div>
                            <div className='value'>{userData?.vfdBankLoanAccountNumber}</div>
                          </div>
                          <div className='profile-item'>
                            <div className='name'>VFD Loan account create transaction id:</div>
                            <div className='value'>{userData?.vfdBankLoanTransactionId}</div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className='user-order-list card border-0 mt-3'>
            <div className='card-header bg-white fw-bolder'>Orders</div>
            <div className='card-body'>
              <div className='table-responsive'>
                <table className='table'>
                  <thead>
                    <tr>
                      <td>Order ID</td>
                      <td>Customer</td>
                      <td>Product</td>
                      <td>Amount</td>
                      <td>Status</td>
                      <td>Payment Method</td>
                      <td>Date</td>
                    </tr>
                  </thead>
                  <tbody>
                    {order.length > 0 &&
                      order.map((data: OrderType, index: Key) => (
                        <tr
                          key={index}
                          onClick={() =>
                            navigate(`/order/${data.referenceNumber}`, { replace: true })
                          }
                        >
                          <td>#{data.referenceNumber}</td>
                          <td>
                            {data.quoteRequest.customer.firstName}{' '}
                            {data.quoteRequest.customer.lastName}
                          </td>
                          <td> {data.product.fullName} </td>
                          <td>
                            ₦
                            {CurrencyFormatter(
                              Number(data.quoteRequest.productPrice.unitSellingPrice) *
                                Number(data.quantity),
                            )}
                          </td>
                          <td>
                            <span className='status'>{data.status}</span>
                          </td>
                          <td>{data.paidWithPlp === true ? 'PLP' : 'Upfront'}</td>
                          <td>{TimeConverter(data.createdAtUtc)}</td>
                        </tr>
                      ))}

                    {loading === false && order.length === 0 && (
                      <tr>
                        <td colSpan={7} className='text-center'>
                          No order found
                        </td>
                      </tr>
                    )}
                    {loading === true && order.length === 0 && (
                      <tr>
                        <td colSpan={7} className='text-center'>
                          <HorizontalLoader></HorizontalLoader>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {paginationData.total >= paginationData.pageSize && (
                <div className='pagination w-100 d-flex justify-content-center'>
                  {paginationData.total && (
                    <Pagination
                      className='pagination-bar ps-0'
                      currentPage={Number(paginationData.pageNumber)}
                      totalCount={paginationData.total}
                      pageSize={paginationData.pageSize}
                      onPageChange={(page: React.SetStateAction<number>) => setPageNumber(page)}
                    />
                  )}
                </div>
              )}
            </div>
          </div>

          <div className='user-order-list card border-0 mt-3'>
            <div className='card-header bg-white fw-bolder'>
              <div className='row'>
                <div className='col-6'>Referrals</div>
                <div className='col-6 text-end'>
                  ₦{CurrencyFormatter(userData?.totalReferralEarnings ?? 0)}
                </div>
              </div>
            </div>
            <div className='card-body'>
              <div className='table-responsive'>
                <table className='table'>
                  <thead>
                    <tr>
                      <td>ID</td>
                      <td>Customer</td>
                      <td>Email</td>
                      <td>Phone</td>
                      <td>Date</td>
                    </tr>
                  </thead>
                  <tbody>
                    {customerReferrals &&
                      customerReferrals.length > 0 &&
                      customerReferrals.map((data: CustomerType, index: Key) => (
                        <tr
                          key={index}
                          onClick={() => {
                            navigate(`/user/${data.accountId}`)
                          }}
                        >
                          <td>#{data.accountId}</td>
                          <td>
                            {data.firstName} {data.lastName}
                          </td>
                          <td>{data.emailAddress}</td>
                          <td>{data.msisdn}</td>
                          <td>{TimeConverter(data.createdAt)}</td>
                        </tr>
                      ))}

                    {loading === false && !customerReferrals && (
                      <tr>
                        <td colSpan={7} className='text-center'>
                          No referal found
                        </td>
                      </tr>
                    )}
                    {loading === true && !customerReferrals && (
                      <tr>
                        <td colSpan={7} className='text-center'>
                          <HorizontalLoader></HorizontalLoader>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {paginationData.total >= paginationData.pageSize && (
                <div className='pagination w-100 d-flex justify-content-center'>
                  {paginationData.total && (
                    <Pagination
                      className='pagination-bar ps-0'
                      currentPage={Number(paginationData.pageNumber)}
                      totalCount={paginationData.total}
                      pageSize={paginationData.pageSize}
                      onPageChange={(page: React.SetStateAction<number>) => setPageNumber(page)}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ModalWrapper showModal={showDebitModal} closeModal={toggleDebitModal} title='Confirmation!'>
        <small>
          Are you sure you want to authorize TradeGrid to debit the customer's VFD bank account?
        </small>
        <div className='mt-4'>
          <button className='btn btn-default' onClick={sendDebitRequest} disabled={debitLoading}>
            Continue {debitLoading && <span className='spinner-border spinner-border-sm'></span>}
          </button>
        </div>
      </ModalWrapper>

      <ModalWrapper
        showModal={showTransferModal}
        closeModal={toggleTransferModal}
        title='Wallet Transfer'
      >
        <small>
          This action is not reversible. Are you sure you want to continue? if yes, kindly input the
          amount in the field below.
        </small>

        <form onSubmit={handleSubmit((data: { amount: number }) => submitTransfer(data))}>
          <div className='form-input mt-4'>
            <label htmlFor='amount' className='amount-label'>
              Amount
            </label>
            <input type='number' id='amount' className='form-control' {...register('amount')} />
            {errors.amount && <span className='errors'>{errors.amount.message}</span>}
          </div>

          <div className='mt-3'>
            <button className='btn btn-default'>
              Submit {transferLoading && <span className='spinner-border spinner-border-sm'></span>}
            </button>
          </div>
        </form>
      </ModalWrapper>
    </div>
  )
}

export default UsersDetails
