import React, { useState, FC, useContext } from 'react'
import './NavMenu.scss'  
import message from '../../../Assets/Images/message.png'
import bell from '../../../Assets/Images/bell.png' 
import { useSelector } from 'react-redux'
import { Scanner } from '../../../Assets/Icons'
import { ModalWrapper, ProductScanner } from '../../../Components' 
import logo from 'Assets/Images/logo-dark.jpeg'
import { todaysDateTime } from 'Helpers/todaysDateTime'
import { useAuth } from 'Components/AuthProvider/AuthProvider'
import { getImageForProvider } from 'Pages/Frontend/Interface/LoanProviders/LoanProviders'
import { ThemeContext } from 'Components/ThemeProvider/ThemeProvider'
 
interface propsType {
  publicAccess? :boolean
}

const NavMenu:FC<propsType> = ({ publicAccess }) => { 
  const themeContext = useContext(ThemeContext);
  
  const [scroll, setScroll] = useState(false)
 
  window.onscroll = function () {
    const currentScrollPos = window.pageYOffset;

    if (currentScrollPos > 200) {
      setScroll(true)
    } else {
      setScroll(false)
    }
  }

  const activeMenu = useSelector((state: { activeMenu: string })=> state.activeMenu)
  
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
  };


  const { user } = useAuth()
  
  return ( 
    <nav className={scroll ? 'navbar topnavbar navbar-expand-lg' : 'topnavbar navbar navbar-expand-lg'}>
      
      <div className="container">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
          </button>
        
          {!publicAccess && <a className="navbar-brand" href="#">{activeMenu}</a>}
          {publicAccess && <a className="navbar-brand" href="#"> 
             <img src={logo} className="w-50 mb-1"/>
            </a>}
                
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
                
              {!publicAccess && <ul className="navbar-nav ms-auto mb-2 mb-lg-0"> 
           

                {!user.loanProviderAdminRole && <li className="nav-item d-none">
                    <a className="nav-link navdate" href='#' onClick={toggleModal}>
                     <Scanner></Scanner> 
                    </a> 
                  </li> }


                  {!user.loanProviderAdminRole && <li className="nav-item">
                    <a className="nav-link navdate" href='#' onClick={themeContext && themeContext.toggleTheme}>
                      {/* {themeContext && themeContext.theme === 'light' ? 'Light Mode' : 'Dark Mode'}
                        Switch to  */}
                        <button className='btn p-1'>
                          {themeContext && themeContext.theme === 'light' ? <i className="d-icon d-lightbulb-solid" aria-hidden="true"></i> : <i className="d-icon d-lightbulb" aria-hidden="true"></i>} 
                        </button>
                    </a> 
                  </li> }
                 
                  <li className="nav-item">
                    <a className="nav-link navdate">{todaysDateTime(new Date())}</a> 
                  </li> 
                  
                  {user.loanProviderAdminRole && <li className="nav-item">
                    <a href="" className="nav-link px-0">
                      <img src={getImageForProvider(user.user.loanProvider)} className='lp-logo'></img>
                    </a>
                  </li> }

                  {!user.loanProviderAdminRole && <li className="nav-item">
                    <a href="" className="nav-link px-0">
                      <img src={message} className='notice-icon'></img>
                    </a>
                  </li> }

                  {!user.loanProviderAdminRole && <li className="nav-item">
                    <a href="" className="nav-link px-0">
                      <img src={bell} className='notice-icon'></img>
                    </a>
                  </li>} 
              </ul>}
          </div>
        </div>  
        <ModalWrapper showModal={showModal} closeModal={toggleModal} title="Product Scanner" size={'lg'}>
           <ProductScanner toggleModal={toggleModal}></ProductScanner>
        </ModalWrapper>
    </nav>
  )
}

export default NavMenu
