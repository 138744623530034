import axios from 'axios'
import { AxiosInterceptor } from '../Config/AxiosInterceptor';

AxiosInterceptor()

const uploadDocumentService = {
  upload (documentName: string, entityUuid: string|undefined, formData: any) {
    return axios.put(`/tradegridplus/${entityUuid}/fileUpload?fileUploadCategory=${documentName}&id=${entityUuid}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
  }

}

export default uploadDocumentService;
