import React, { Key, useEffect, useState } from 'react'
import './AdminRequest.scss'
import { RequestType } from '../../../../../Models/requestType';
import { TimeConverter } from '../../../../../Helpers/TimeConverter';
import { HorizontalLoader, Pagination } from '../../../../../Components';
import { Link, useNavigate } from 'react-router-dom';
import requestService from '../../../../../Services/requestService';
import { Logger } from '../../../../../Helpers/Logger';
 
const AdminRequest = () => {  
    const [pageNumber, setPage] = useState(1) 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [sortOrder, setSortOrder] = useState('DESC') 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [status, setStatus] = useState('ALL') 
    
    const [request, setRequest] = useState([]); 
    const [loading, setLoading] = useState(false);

    const [paginationData, setPaginationData] = useState<{
        pageNumber: number,
        pageSize: number,
        total:number
      }>({
        pageNumber: 1,
        pageSize: 20,
        total: 1
      })

    const getOrders = async () => {
        setLoading(true)
        await requestService.index(pageNumber, sortOrder, status, true)
          .then((response)=>{    
            setLoading(false)
            setRequest(response?.data.data.dataset)
            console.log('response', response)

            setPaginationData({
                pageNumber: response?.data.data.pageNumber,
                pageSize: response?.data.data.pageSize,
                total: response?.data.data.total
              })

          }, (error: { response: object }) =>{     
            setLoading(false)
            Logger('response error', error.response) 
          })
    }

    useEffect(() => {
     getOrders()
    }, [pageNumber])

    const navigate = useNavigate()
    return (
        <div className='AdminRequest'>
               <div className="card-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr> 
                                            <td>Request Number</td>
                                            <td>User</td>
                                            <td>Product</td>
                                            <td>Quantity</td>
                                            <td>Status</td>
                                            <td>Date</td>
                                            <td></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {request.length > 0 && request.map((data: RequestType, index:Key)=><tr key={index}  onClick={()=>{ navigate(`/request/${data.referenceNumber}`, {replace:true})}}>
                                            <td>#{data.referenceNumber}</td>
                                            <td>{data.customer?.firstName} {data.customer?.lastName}</td>
                                            <td>{data.product.fullName}</td>
                                            <td>{data.quantity}Ltrs</td>
                                            <td><span className='status'>{data.status}</span></td>
                                            <td>{TimeConverter(data.createdAtUtc)}</td> 
                                            <td><Link to={`/request/${data.referenceNumber}`}><button className='btn btn-sm btn-transaction-detail'>Details</button></Link></td>
                                          
                                            {/* <td className='dropdown option-dropdown'>
                                                <button className='btn border-0 dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false" data-bs-popper-config='{"strategy":"fixed"}'>
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7.49991 8.4705C7.49991 7.72933 8.0955 7.14697 8.82344 7.14697C9.55138 7.14697 10.147 7.74256 10.147 8.4705C10.147 9.19844 9.55138 9.79403 8.82344 9.79403C8.0955 9.79403 7.49991 9.21168 7.49991 8.4705Z" fill="#707070"/>
                                                        <path d="M7.49991 14.1175C7.49991 13.3763 8.0955 12.7939 8.82344 12.7939C9.55138 12.7939 10.147 13.3895 10.147 14.1175C10.147 14.8454 9.55138 15.441 8.82344 15.441C8.0955 15.441 7.49991 14.8587 7.49991 14.1175Z" fill="#707070"/>
                                                        <path d="M7.49991 2.82353C7.49991 2.08235 8.0955 1.5 8.82344 1.5C9.55138 1.5 10.147 2.09559 10.147 2.82353C10.147 3.55147 9.55138 4.14706 8.82344 4.14706C8.0955 4.14706 7.49991 3.56471 7.49991 2.82353Z" fill="#707070"/>
                                                    </svg>

                                                </button>

                                                <ul className="dropdown-menu dropdown-menu-end export-dropdown border-0 shadow">
                                                    <li className='dropdown-item'><Link to={`/request/${data.referenceNumber}`}>Quote Request Details</Link></li> 
                                                    <li className='dropdown-item'>Respond to Request</li> 
                                                    <li className='dropdown-item text-danger'>Decline Request </li> 
                                                </ul> 
                                            </td> */}
                                            </tr>)} 

                                            {loading === false && request.length === 0 &&<tr>
                                                <td colSpan={8} className='text-center'>No admin created request found</td>
                                            </tr>}
                                            {loading===true && request.length === 0 && <tr>
                                                <td colSpan={8} className='text-center'><HorizontalLoader></HorizontalLoader></td>
                                            </tr>}
                                    </tbody>
                                </table>
                            </div>

                            {paginationData.total >= paginationData.pageSize && <div className="pagination w-100 d-flex justify-content-center">
                              {paginationData.total && <Pagination
                                  className="pagination-bar ps-0"
                                  currentPage={Number(paginationData.pageNumber)}
                                  totalCount={paginationData.total}
                                  pageSize={paginationData.pageSize}
                                  onPageChange={(page: React.SetStateAction<number>) => setPage(page)}/>
                                  }
                            </div>}
                        </div>
        </div>
    )
}

export default AdminRequest
