import React, { FC, ChangeEvent } from 'react'
import DocumentUploadCard from './DocumentUploadCard/DocumentUploadCard'


type propTypes = {
    handleFileUpload: (value:ChangeEvent<HTMLInputElement>, doc:string)=>void;
    checkIfExist:(value:string)=>boolean ;
    children: React.ReactNode;
    publicAccess? :boolean
  }
  
  const PlpDocumentUpload:FC<propTypes>  = ({handleFileUpload, checkIfExist, publicAccess}) => {
    return  <div className="row">  
        <div className="col-sm-6 my-4"> 
            <div className="step-title">
            Document Upload
            </div> 
        </div> 
        <div className="col-sm-6 text-sm-end my-4"> 
            <div className="step-page-number">
            Page 3 of 3
            </div> 
        </div> 
       
        <div className={publicAccess? 'col-md-8' : 'col-md-6'}>  
            <div className="instruction">Allowable upload format: png, jpg and jpeg. Maximum allowable size: 500kb</div> 
            <div className="form-group"> 
                <DocumentUploadCard  
                    changeHandler={(event:ChangeEvent<HTMLInputElement>, fileName:string)=>handleFileUpload(event, fileName)}
                    description="Form CAC7A - Particluars of Directors OR BN Registration Form showing Proprietor / Owner."
                    name="PLP_APPLICATION_CAC_7"
                    doc="PLP_APPLICATION_CAC_7"
                    checkIfExist={(e:string)=>checkIfExist(e)}
                />
                 <DocumentUploadCard  
                    changeHandler={(event:ChangeEvent<HTMLInputElement>, fileName:string)=>handleFileUpload(event, fileName)}
                    description="Corporate bank statement for the last 12 months."
                    name="PLP_APPLICATION_COMPANY_BANK_STATEMENT_LAST_12_MONTHS"
                    doc="PLP_APPLICATION_COMPANY_BANK_STATEMENT_LAST_12_MONTHS"
                    checkIfExist={(e:string)=>checkIfExist(e)}
                />
                <DocumentUploadCard  
                    changeHandler={(event:ChangeEvent<HTMLInputElement>, fileName:string)=>handleFileUpload(event, fileName)}
                    description="Lease Agreement or Dealership Agreement."
                    name="PLP_APPLICATION_FILLING_STATION_REAL_OWNER_LEASE_AGREEMENT"
                    doc="PLP_APPLICATION_FILLING_STATION_REAL_OWNER_LEASE_AGREEMENT"
                    checkIfExist={(e:string)=>checkIfExist(e)}
                />
                <DocumentUploadCard  
                    changeHandler={(event:ChangeEvent<HTMLInputElement>, fileName:string)=>handleFileUpload(event, fileName)}
                    description="Government issued ID card."
                    name="PLP_APPLICATION_ID"
                    doc="PLP_APPLICATION_ID"
                    checkIfExist={(e:string)=>checkIfExist(e)}
                />
                <DocumentUploadCard  
                    changeHandler={(event:ChangeEvent<HTMLInputElement>, fileName:string)=>handleFileUpload(event, fileName)}
                    description="Image of fuel pump motherboard."
                    name="PLP_APPLICATION_FUEL_PUMP_MOTHERBOARD"
                    doc="PLP_APPLICATION_FUEL_PUMP_MOTHERBOARD"
                    checkIfExist={(e:string)=>checkIfExist(e)}
                />  
            </div>  
        </div>
    </div>
}

export default PlpDocumentUpload