import React, { Fragment, useEffect, useState } from 'react'
import './PLPDetails.scss'
import { BreadCrumb } from '../../../../../Components'
import plpService from '../../../../../Services/plpService'
import { useParams } from 'react-router-dom'
import { plpType } from '../../../../../Models/plpType' 
import { ShowTGAlert } from '../../../../../Helpers/ShowTGAlert'

const PLPDetails = () => {
    const { referenceId } = useParams()
    const [plpData, setPlpData] = useState<plpType>()
    const [aprovalReq, setAprovalReq] = useState(false)
    
    const { showAlert } = ShowTGAlert() 

    const getPlpDetails = async () => {
        await plpService.getDetails(referenceId).then((response)=>{
          console.log('getPlpDetails', response.data.data.dataset[0])
          setPlpData(response.data.data.dataset[0])
        }, (error: { response: {data: { error: string }} }) =>{
            console.log('getPlpDetails error', error.response)
        })
    }

    const transformFileUploadCategory = (fileUploadCategory: string): string => {
      if(fileUploadCategory === 'PLP_APPLICATION_ID') {
        return 'Identification'
      } else if(fileUploadCategory === 'PLP_APPLICATION_CAC_CERTIFICATE') {
        return 'CTC of CAC Registration form'

      } else if(fileUploadCategory === 'PLP_APPLICATION_CTC_OF_CAC_REGISTRATION_FORM') {
        return 'CTC of CAC Registration form'
      } else if(fileUploadCategory === 'PLP_APPLICATION_CAC_7') {
        return 'CAC 7'
      } else if(fileUploadCategory === 'PLP_APPLICATION_MEMORANDUM_AND_ARTICLES_OF_ASSOCIATION') {
        return 'Memorandum and articles of association'       
      } else if(fileUploadCategory === 'PLP_APPLICATION_RECENT_UTILITY_BILL') {
        return 'Recent Utility bill'
      } else if(fileUploadCategory === 'PLP_APPLICATION_COMPANY_BANK_STATEMENT_LAST_12_MONTHS') {
        return 'Company bank statement in last 12 months'        
      } else if(fileUploadCategory === 'PLP_APPLICATION_DEPARTMENT_OF_PETROLEUM_RESOURCES_LICENSE') {
        return 'Department of petroleum resources license'
      } else if(fileUploadCategory === 'PLP_APPLICATION_FILLING_STATION_REAL_OWNER_LEASE_AGREEMENT') {
        return 'Filling station real owner lease agreement'
      }
      return 'Unknown file upload category'
    }

    useEffect(() => {
        getPlpDetails()
    }, [])


    const submitApproval = async (aproval:string) => { 
        setAprovalReq(true)
        await plpService.aproval(referenceId, aproval).then((response)=>{
            console.log('getPlpDetails', response) 
            setAprovalReq(false)  
            showAlert({message: 'PLP status saved successfully', show:true, title: 'Success', type: 'success'})
         
            setTimeout(()=>{
              window.location.reload();
            }, 3000)

          }, (error: { response: {data: { error: string }} }) =>{
              setAprovalReq(false)
              console.log('getPlpDetails error', error.response)
              showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'}) 
            })
    }

    return (
        <div className='PLPDetails'>
            <div className="container mt-4">
                 <BreadCrumb title={'#112434 -Christiana Johnson'} parent={'PLP'} parentUrl={'-1'} showTitle={false}></BreadCrumb>
            </div>

            <div className="container mt-2"> 
                <div className="card border-0">
                    <div className="card-header border-0">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-8 my-auto">
                                <div className="title">
                                {plpData?.companyName} PLP Application
                                </div>
                            </div>
                              <Fragment> 
                                <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2">
                                  <span className='order-status'>{
                                    plpData?.plpApplicationVerified
                                   }</span>
                                </div>
                                <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2"> 
                                   {aprovalReq &&  <button className='btn btn-respond-req'> 
                                      <span className='spinner-border spinner-border-sm'></span>
                                    </button>} 
                                   {!aprovalReq &&  plpData?.plpApplicationVerified !=='APPROVED' && <button className='btn btn-respond-req' onClick={()=>submitApproval('approve')}> 
                                       Approve Application
                                    </button>}
                                   {!aprovalReq &&  plpData?.plpApplicationVerified ==='APPROVED' && <button className='btn btn-respond-req bg-white text-secondary' onClick={()=>submitApproval('reject')}> 
                                       Reject Application
                                    </button>}
                                </div>
                            </Fragment>
                        </div> 
                    </div>
                </div>


                <div className="card border-0 mt-2">
                    <div className="card-body pb-5 pb-4">
                        <div className="row">
                            <div className="col-md-6"> 
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="24" cy="24" r="24" fill="#0E1F33"/>
                                    <path d="M33.09 18.9795C32.24 18.0395 30.82 17.5695 28.76 17.5695H28.52V17.5295C28.52 15.8495 28.52 13.7695 24.76 13.7695H23.24C19.48 13.7695 19.48 15.8595 19.48 17.5295V17.5795H19.24C17.17 17.5795 15.76 18.0495 14.91 18.9895C13.92 20.0895 13.95 21.5695 14.05 22.5795L14.06 22.6495L14.16 23.6995C14.17 23.7095 14.19 23.7295 14.21 23.7395C14.54 23.9595 14.88 24.1795 15.24 24.3795C15.38 24.4695 15.53 24.5495 15.68 24.6295C17.39 25.5695 19.27 26.1995 21.18 26.5095C21.27 27.4495 21.68 28.5495 23.87 28.5495C26.06 28.5495 26.49 27.4595 26.56 26.4895C28.6 26.1595 30.57 25.4495 32.35 24.4095C32.41 24.3795 32.45 24.3495 32.5 24.3195C32.96 24.0595 33.39 23.7795 33.81 23.4695C33.83 23.4595 33.85 23.4395 33.86 23.4195L33.9 23.0595L33.95 22.5895C33.96 22.5295 33.96 22.4795 33.97 22.4095C34.05 21.3995 34.03 20.0195 33.09 18.9795ZM25.09 25.8295C25.09 26.8895 25.09 27.0495 23.86 27.0495C22.63 27.0495 22.63 26.8595 22.63 25.8395V24.5795H25.09V25.8295ZM20.91 17.5695V17.5295C20.91 15.8295 20.91 15.1995 23.24 15.1995H24.76C27.09 15.1995 27.09 15.8395 27.09 17.5295V17.5795H20.91V17.5695Z" fill="white"/>
                                    <path opacity="0.4" d="M32.5002 24.2984C32.4502 24.3284 32.4002 24.3584 32.3502 24.3884C30.5702 25.4284 28.6002 26.1284 26.5602 26.4684C26.4802 27.4284 26.0602 28.5284 23.8702 28.5284C21.6802 28.5284 21.2602 27.4384 21.1802 26.4884C19.2702 26.1884 17.3902 25.5584 15.6802 24.6084C15.5302 24.5284 15.3802 24.4484 15.2402 24.3584C14.8802 24.1584 14.5402 23.9384 14.2102 23.7184C14.1902 23.7084 14.1702 23.6884 14.1602 23.6784L14.7702 30.1884C14.9802 32.1784 15.8002 34.2284 20.2002 34.2284H27.8202C32.2202 34.2284 33.0402 32.1784 33.2502 30.1784L33.8802 23.3984C33.8702 23.4184 33.8502 23.4384 33.8302 23.4484C33.4002 23.7584 32.9602 24.0484 32.5002 24.2984Z" fill="white"/>
                                </svg>

                                <div className="value mb-4">
                                    Company Details
                                </div>

                                <div className="label mt-3">
                                    Registration Type
                                </div>
                                <div className="value">
                                    {plpData?.businessRegistrationType}
                                </div>

                                <div className="label mt-4">
                                     BVN
                                </div>
                                <div className="value">
                                {plpData?.bvn? plpData?.bvn: null}
                                </div> 

                                <div className="label mt-4">
                                    Customer Missdn
                                </div>
                                <div className="value">
                                   {plpData?.customer.msisdn}
                                </div> 

                                <div className="label mt-4">
                                    Company Name
                                </div>
                                <div className="value">
                                {plpData?.companyName}
                                </div> 

                                <div className="label mt-4">
                                    CAC Number
                                </div>
                                <div className="value">
                                {plpData?.cacNumber}
                                </div> 

                                <div className="label mt-4">
                                    Company State
                                </div>
                                <div className="value">
                                {plpData?.companyState}
                                </div> 

                                <div className="label mt-4">
                                    Company L.G.A
                                </div>
                                <div className="value">
                                {plpData?.companyLGA}
                                </div> 

                                <div className="label mt-4">
                                    Company Daily Sales Capacity(Litres)
                                </div>
                                <div className="value">
                                {plpData?.dailySalesCapacity}
                                </div> 

                                <div className="label mt-4">
                                    Company Document Type
                                </div>
                                <div className="value">
                                {plpData?.documentType}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className="value mt-5 mb-4">
                                   Deferred Payment Details
                                </div>

                                <div className="label mt-4">
                                 Product Name
                                </div>
                                <div className="value">
                                {plpData?.productName}
                                </div> 
                                <div className="label mt-4">
                                PLP Application Verified
                                </div>
                                <div className="value text-success">
                                {plpData?.plpApplicationVerified}
                                </div> 


                                <div className="label mt-4">
                                Document Type:
                                </div>
                                <div className="value text-success">
                                {plpData?.documentType}
                                </div> 
                            </div>

                            {plpData?.verificationDocuments && <div className="col-md-12"> 
                                <div className="label my-4">
                                Uploaded files
                                </div>
                                <div className="row">
                                    {plpData?.verificationDocuments.map((res, index)=>{ 
                                        return  <div className="col-6 col-md-2" key={index}> 
                                            <div className="uploadedDocument">
                                                <img src={res.url} className="w-100 rounded"></img>
                                                
                                                <div className="icons">
                                                    <a href={res.url} target="_blank" rel="noreferrer">
                                                        <button className="btn">
                                                            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M11.9999 16.3319C9.60992 16.3319 7.66992 14.3919 7.66992 12.0019C7.66992 9.61187 9.60992 7.67188 11.9999 7.67188C14.3899 7.67188 16.3299 9.61187 16.3299 12.0019C16.3299 14.3919 14.3899 16.3319 11.9999 16.3319ZM11.9999 9.17188C10.4399 9.17188 9.16992 10.4419 9.16992 12.0019C9.16992 13.5619 10.4399 14.8319 11.9999 14.8319C13.5599 14.8319 14.8299 13.5619 14.8299 12.0019C14.8299 10.4419 13.5599 9.17188 11.9999 9.17188Z" fill="#292D32"/>
                                                                <path d="M12.0001 21.0205C8.24008 21.0205 4.69008 18.8205 2.25008 15.0005C1.19008 13.3505 1.19008 10.6605 2.25008 9.00047C4.70008 5.18047 8.25008 2.98047 12.0001 2.98047C15.7501 2.98047 19.3001 5.18047 21.7401 9.00047C22.8001 10.6505 22.8001 13.3405 21.7401 15.0005C19.3001 18.8205 15.7501 21.0205 12.0001 21.0205ZM12.0001 4.48047C8.77008 4.48047 5.68008 6.42047 3.52008 9.81047C2.77008 10.9805 2.77008 13.0205 3.52008 14.1905C5.68008 17.5805 8.77008 19.5205 12.0001 19.5205C15.2301 19.5205 18.3201 17.5805 20.4801 14.1905C21.2301 13.0205 21.2301 10.9805 20.4801 9.81047C18.3201 6.42047 15.2301 4.48047 12.0001 4.48047Z" fill="#292D32"/>
                                                            </svg> 
                                                        </button>
                                                    </a>
                                                    <a href={res.url} target="_blank" rel="noreferrer" download>
                                                        <button className="btn">
                                                            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M9 17.75C8.9 17.75 8.81 17.73 8.71 17.69C8.43 17.58 8.25 17.3 8.25 17V11C8.25 10.59 8.59 10.25 9 10.25C9.41 10.25 9.75 10.59 9.75 11V15.19L10.47 14.47C10.76 14.18 11.24 14.18 11.53 14.47C11.82 14.76 11.82 15.24 11.53 15.53L9.53 17.53C9.39 17.67 9.19 17.75 9 17.75Z" fill="#292D32"/>
                                                                <path d="M9.00043 17.7514C8.81043 17.7514 8.62043 17.6814 8.47043 17.5314L6.47043 15.5314C6.18043 15.2414 6.18043 14.7614 6.47043 14.4714C6.76043 14.1814 7.24043 14.1814 7.53043 14.4714L9.53043 16.4714C9.82043 16.7614 9.82043 17.2414 9.53043 17.5314C9.38043 17.6814 9.19043 17.7514 9.00043 17.7514Z" fill="#292D32"/>
                                                                <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H14C14.41 1.25 14.75 1.59 14.75 2C14.75 2.41 14.41 2.75 14 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V10C21.25 9.59 21.59 9.25 22 9.25C22.41 9.25 22.75 9.59 22.75 10V15C22.75 20.43 20.43 22.75 15 22.75Z" fill="#292D32"/>
                                                                <path d="M22 10.7485H18C14.58 10.7485 13.25 9.41852 13.25 5.99852V1.99852C13.25 1.69852 13.43 1.41852 13.71 1.30852C13.99 1.18852 14.31 1.25852 14.53 1.46852L22.53 9.46852C22.74 9.67852 22.81 10.0085 22.69 10.2885C22.57 10.5685 22.3 10.7485 22 10.7485ZM14.75 3.80852V5.99852C14.75 8.57852 15.42 9.24852 18 9.24852H20.19L14.75 3.80852Z" fill="#292D32"/>
                                                            </svg>
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="doc-name">
                                                {transformFileUploadCategory(res.fileCategory)}
                                            </div>
                                        </div>  
                                    })}
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PLPDetails
