import { BreadCrumb, HorizontalLoader, ImageSrc } from '../../../../../Components' 
import { Fragment, useEffect, useState } from 'react'
import orderService from '../../../../../Services/orderService'
import { Link, useParams } from 'react-router-dom'
import { OrderType } from '../../../../../Models/orderType'
import { CurrencyFormatter, FormatTimeAgo } from '../../../../../Helpers'
import './OrderDetails.scss'
import { useAuth } from 'Components/AuthProvider/AuthProvider'
import Swal from 'sweetalert2'

const OrderDetails = () => {
    const { referenceId } = useParams()
    const [orderData, setOrderData] = useState<OrderType>()
    
    const { user } = useAuth()

    const getOrder = async () => {

        await orderService.getDetails(referenceId, user.loanProviderAdminRole, user.user.loanProvider).then((response)=>{
        //   console.log('orderService', response.data.data.dataset[0])
          setOrderData(response.data.data.dataset[0])
        }, error =>{
            console.log('orderService error', error.response)
        })
    }

    useEffect(() => {
        getOrder()
    }, [])


    return (
        <div className='OrderDetails'>
            
           <div className="container">
             {!user.loanProviderAdminRole && <BreadCrumb title={'Order Details'} parent={'Orders'} parentUrl={'orders'} showTitle={false}></BreadCrumb>}
                <div className="container mt-2">
                     
                    <div className="card border-0">
                        <div className="card-header border-0 px-0">
                            <div className="row">
                                <div className="col-12 col-md-7 my-auto">
                                    <div className="title">
                                    Order Details
                                    </div>
                                </div> 
                                {orderData && <Fragment> 
                                    <div className="col-6 col-md-3">
                                    <span className='order-status'>{orderData.status}</span>
                                    </div>
                                    <div className="col-6 col-md-2"> 
                                     <Link to={`/order-tracking/${orderData.referenceNumber}`}><button className='btn btn-respond-req'>Track Order</button></Link>
                                    </div> 
                                   
                                </Fragment>}
                            </div> 
                        </div>
                    </div>

                    {!orderData && <HorizontalLoader></HorizontalLoader>}
                    {orderData && <div className="row details-section">
                        <div className="col-md-6"> 
                            <div className='card border-0 mt-3'> 
                                <div className='card-header bg-white'>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="title">
                                            Order Summary
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="sub-title">
                                             { FormatTimeAgo(orderData.createdAtUtc)}
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                                <div className="card-body">  
                                    <div className="row">
                                        <div className="col-md-4"> 
                                        <div className="product-image w-100">
                                        <ImageSrc src={orderData.depotLocation.company.logo? orderData.depotLocation.company.logo.url : ''} alt="" title="" width='w-100 rounded'/>
                                        </div>
                                       </div>
                                        <div className="col-md-4 text-start"> 
                                         <div className='seller-name'>{orderData.product.fullName}</div>
                                         <div className='quantity'>{orderData.quantity}Ltrs</div>
                                       </div>
                                        <div className="col-md-4 text-end"> 
                                        ₦{orderData.pricePerUnitMajor && CurrencyFormatter(orderData.pricePerUnitMajor)}/Ltr
                                       </div>
                                    </div>
                                    <ul className="list-group rounded-0 border-0">
                                        <li className="list-group-item border-start-0 border-end-0 px-0"> 
                                            <div className="row w-100 mx-0">
                                                <div className="col-6">
                                                Subtotal
                                                </div>
                                                <div className="col-6 text-end value px-0">
                                                ₦{orderData.totalCostMajor && CurrencyFormatter(orderData.totalCostMajor)}
                                                </div>
                                            </div>
                                        </li> 
                                        <li className="list-group-item border-start-0 border-end-0 px-0"> 
                                            <div className="row w-100 mx-0">
                                                <div className="col-6">
                                                Delivery
                                                </div>
                                                <div className="col-6 text-end value px-0">
                                                ₦0.0
                                                </div>
                                            </div>
                                        </li> 
                                        <li className="list-group-item border-start-0 border-end-0 border-bottom-0 px-0"> 
                                            <div className="row w-100 mx-0">
                                                <div className="col-6">
                                                Total
                                                </div>
                                                <div className="col-6 text-end value px-0">
                                                ₦{ orderData.totalCostMajor? CurrencyFormatter(orderData.totalCostMajor): CurrencyFormatter(orderData.quantity * orderData.quoteRequest.productPrice.unitSellingPrice)}
                                                </div>
                                            </div>
                                        </li> 
                                    </ul>   
                                </div>
                            </div> 
                        </div>
                        <div className="col-md-6"> 
                            <div className='card border-0 mt-3'>  
                                <div className='card-header bg-white'>
                                    <div className="title">
                                    Payment and Delivery Information
                                    </div>
                                </div>
                                <div className="card-body">  
                                    <div className="title-2"> 
                                        Payment method
                                    </div>
                                    <div className="value-2 border-bottom pb-3"> 
                                        <div className="row">
                                            <div className="col-md-6">Wallet</div>
                                            <div className="col-md-6">
                                            { !user.loanProviderAdminRole && <Link to="#" className='download-link'>
                                                <svg width="16" height="16" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 17.75C8.9 17.75 8.81 17.73 8.71 17.69C8.43 17.58 8.25 17.3 8.25 17V11C8.25 10.59 8.59 10.25 9 10.25C9.41 10.25 9.75 10.59 9.75 11V15.19L10.47 14.47C10.76 14.18 11.24 14.18 11.53 14.47C11.82 14.76 11.82 15.24 11.53 15.53L9.53 17.53C9.39 17.67 9.19 17.75 9 17.75Z" fill="#E873A8"/>
                                                    <path d="M8.99994 17.7514C8.80994 17.7514 8.61994 17.6814 8.46994 17.5314L6.46994 15.5314C6.17994 15.2414 6.17994 14.7614 6.46994 14.4714C6.75994 14.1814 7.23994 14.1814 7.52994 14.4714L9.52994 16.4714C9.81994 16.7614 9.81994 17.2414 9.52994 17.5314C9.37994 17.6814 9.18994 17.7514 8.99994 17.7514Z" fill="#E873A8"/>
                                                    <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H14C14.41 1.25 14.75 1.59 14.75 2C14.75 2.41 14.41 2.75 14 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V10C21.25 9.59 21.59 9.25 22 9.25C22.41 9.25 22.75 9.59 22.75 10V15C22.75 20.43 20.43 22.75 15 22.75Z" fill="#E873A8"/>
                                                    <path d="M22 10.7485H18C14.58 10.7485 13.25 9.41852 13.25 5.99852V1.99852C13.25 1.69852 13.43 1.41852 13.71 1.30852C13.99 1.18852 14.31 1.25852 14.53 1.46852L22.53 9.46852C22.74 9.67852 22.81 10.0085 22.69 10.2885C22.57 10.5685 22.3 10.7485 22 10.7485ZM14.75 3.80852V5.99852C14.75 8.57852 15.42 9.24852 18 9.24852H20.19L14.75 3.80852Z" fill="#E873A8"/>
                                                </svg> Download Receipt</Link>}
                                            </div>
                                        </div> 
                                    </div> 

                                    <div className="value-2 border-bottom pb-3">  
                                        <div className="title-2 mt-4"> 
                                            Delivery method
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">{orderData.quoteRequest.orderReceiveType}</div> 
                                        </div> 
                                    </div> 

                                    <div className="value-2 border-bottom pb-3"> 
                                        <div className="title-2 mt-4"> 
                                        Delivery Address
                                        </div>
                                        <div className="value-2"> 
                                            <svg width="24" height="24" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.9999 14.1714C9.86988 14.1714 8.12988 12.4414 8.12988 10.3014C8.12988 8.16141 9.86988 6.44141 11.9999 6.44141C14.1299 6.44141 15.8699 8.17141 15.8699 10.3114C15.8699 12.4514 14.1299 14.1714 11.9999 14.1714ZM11.9999 7.94141C10.6999 7.94141 9.62988 9.00141 9.62988 10.3114C9.62988 11.6214 10.6899 12.6814 11.9999 12.6814C13.3099 12.6814 14.3699 11.6214 14.3699 10.3114C14.3699 9.00141 13.2999 7.94141 11.9999 7.94141Z" fill="#292D32"/>
                                                <path d="M12.0002 22.76C10.5202 22.76 9.03018 22.2 7.87018 21.09C4.92018 18.25 1.66018 13.72 2.89018 8.33C4.00018 3.44 8.27018 1.25 12.0002 1.25C12.0002 1.25 12.0002 1.25 12.0102 1.25C15.7402 1.25 20.0102 3.44 21.1202 8.34C22.3402 13.73 19.0802 18.25 16.1302 21.09C14.9702 22.2 13.4802 22.76 12.0002 22.76ZM12.0002 2.75C9.09018 2.75 5.35018 4.3 4.36018 8.66C3.28018 13.37 6.24018 17.43 8.92018 20C10.6502 21.67 13.3602 21.67 15.0902 20C17.7602 17.43 20.7202 13.37 19.6602 8.66C18.6602 4.3 14.9102 2.75 12.0002 2.75Z" fill="#292D32"/>
                                            </svg>{orderData.quoteRequest.deliveryLocation?.address}
                                        </div>

                                        <div className="">
                                            <svg width="24" height="24" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.1596 11.62C12.1296 11.62 12.1096 11.62 12.0796 11.62C12.0296 11.61 11.9596 11.61 11.8996 11.62C8.99957 11.53 6.80957 9.25 6.80957 6.44C6.80957 3.58 9.13957 1.25 11.9996 1.25C14.8596 1.25 17.1896 3.58 17.1896 6.44C17.1796 9.25 14.9796 11.53 12.1896 11.62C12.1796 11.62 12.1696 11.62 12.1596 11.62ZM11.9996 2.75C9.96957 2.75 8.30957 4.41 8.30957 6.44C8.30957 8.44 9.86957 10.05 11.8596 10.12C11.9096 10.11 12.0496 10.11 12.1796 10.12C14.1396 10.03 15.6796 8.42 15.6896 6.44C15.6896 4.41 14.0296 2.75 11.9996 2.75Z" fill="#333333"/>
                                                <path d="M12.1696 22.55C10.2096 22.55 8.23961 22.05 6.74961 21.05C5.35961 20.13 4.59961 18.87 4.59961 17.5C4.59961 16.13 5.35961 14.86 6.74961 13.93C9.74961 11.94 14.6096 11.94 17.5896 13.93C18.9696 14.85 19.7396 16.11 19.7396 17.48C19.7396 18.85 18.9796 20.12 17.5896 21.05C16.0896 22.05 14.1296 22.55 12.1696 22.55ZM7.57961 15.19C6.61961 15.83 6.09961 16.65 6.09961 17.51C6.09961 18.36 6.62961 19.18 7.57961 19.81C10.0696 21.48 14.2696 21.48 16.7596 19.81C17.7196 19.17 18.2396 18.35 18.2396 17.49C18.2396 16.64 17.7096 15.82 16.7596 15.19C14.2696 13.53 10.0696 13.53 7.57961 15.19Z" fill="#333333"/>
                                            </svg>{orderData.quoteRequest.deliveryLocation?.contact.fullName}, {orderData.quoteRequest.deliveryLocation?.contact.phoneNumber}
                                        </div>
                                    </div>
                                    <div className="value-2 border-bottom pb-3"> 
                                        <div className="title-2 mt-4"> 
                                        Depot details
                                        </div>
                                        <div className="value-2"> 
                                             <span className='d-icon d-briefcase me-2'></span>  {orderData.depotLocation?.company.name}
                                        </div>

                                        <div className="value-2"> 
                                            <svg width="24" height="24" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.9999 14.1714C9.86988 14.1714 8.12988 12.4414 8.12988 10.3014C8.12988 8.16141 9.86988 6.44141 11.9999 6.44141C14.1299 6.44141 15.8699 8.17141 15.8699 10.3114C15.8699 12.4514 14.1299 14.1714 11.9999 14.1714ZM11.9999 7.94141C10.6999 7.94141 9.62988 9.00141 9.62988 10.3114C9.62988 11.6214 10.6899 12.6814 11.9999 12.6814C13.3099 12.6814 14.3699 11.6214 14.3699 10.3114C14.3699 9.00141 13.2999 7.94141 11.9999 7.94141Z" fill="#292D32"/>
                                                <path d="M12.0002 22.76C10.5202 22.76 9.03018 22.2 7.87018 21.09C4.92018 18.25 1.66018 13.72 2.89018 8.33C4.00018 3.44 8.27018 1.25 12.0002 1.25C12.0002 1.25 12.0002 1.25 12.0102 1.25C15.7402 1.25 20.0102 3.44 21.1202 8.34C22.3402 13.73 19.0802 18.25 16.1302 21.09C14.9702 22.2 13.4802 22.76 12.0002 22.76ZM12.0002 2.75C9.09018 2.75 5.35018 4.3 4.36018 8.66C3.28018 13.37 6.24018 17.43 8.92018 20C10.6502 21.67 13.3602 21.67 15.0902 20C17.7602 17.43 20.7202 13.37 19.6602 8.66C18.6602 4.3 14.9102 2.75 12.0002 2.75Z" fill="#292D32"/>
                                            </svg>{orderData.depotLocation.address}, {orderData.depotLocation?.state}, {orderData.depotLocation.country}
                                        </div> 
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="view-logistics value-2 pt-3 pb-3"> 
                                                { !user.loanProviderAdminRole && <Link to={`/order-logistics/${orderData.referenceNumber}`}>
                                                    View Logistics 
                                                    <svg width="18" height="18" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8.91016 19.9181L15.4302 13.3981C16.2002 12.6281 16.2002 11.3681 15.4302 10.5981L8.91016 4.07812" stroke="#E873A8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                </Link>} 
                                            </div>
                                        </div>

                                        <div className="col-6 text-end">
                                            <div className="view-logistics value-2 pt-3 pb-3"> 
                                                { !user.loanProviderAdminRole && <Link to={`/request/${orderData.quoteRequest.referenceNumber}`}>
                                                    View Quote 
                                                    <svg width="18" height="18" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8.91016 19.9181L15.4302 13.3981C16.2002 12.6281 16.2002 11.3681 15.4302 10.5981L8.91016 4.07812" stroke="#E873A8" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                </Link>} 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                   
                    </div>}
                </div>
            </div> 
        </div>
    )
}

export default OrderDetails
